import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { RESSOURCE_TABLE_ACTION } from 'src/app/actions/ressource-table.actions';
import { HOURS_PER_WORKING_DAY, SECONDS_PER_HOUR } from 'src/app/model/constants';
import { CELL_TYPE, UNIT, VIEW_STATE } from 'src/app/model/enums';
import { AppState } from 'src/app/reducers';

@Component({
	selector: 'app-cell-item-renderer-project-workload-number',
	templateUrl: './cell-item-renderer-project-workload-number.component.html',
	styleUrls: ['./cell-item-renderer-project-workload-number.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellItemRendererProjectWorkloadNumberComponent implements OnInit, OnDestroy {

	@ViewChild('valueInput', { static: false }) valueInputElement: ElementRef;

	@Input() public type: CELL_TYPE = CELL_TYPE.PLANNED_NUMBER;
	@Input() public editable = true;
	@Input() public valueObject: any;
	@Input() public key: any;
	@Input() public projectId: any;
	@Input() public month: any;
	@Input() public threshold1: number;
	@Input() public threshold2: number;

	private _viewUnit: UNIT = UNIT.HOURS;
	@Input() public get viewUnit (): UNIT {
		return this._viewUnit;
	}
	public set viewUnit (value: UNIT) {
		this._viewUnit = value;
		this._value = undefined;
	}

	@Output() refresh = new EventEmitter<any>();

	@Input() public showSpinner = true;
	private _updated = false;
	@Input() public get updated () {
		return this._updated;
	}
	public set updated (value: boolean) {
		this._updated = value;
	}

	public bClickNext = false;

	public state: VIEW_STATE = VIEW_STATE.SHOW;

	VIEW_STATE = VIEW_STATE;

	private _value: any = undefined;
	private _isRed: any = undefined;
	private _isYellow: any = undefined;

	private reduxSubscription: Subscription = new Subscription();

	constructor (
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
	) { }

	ngOnInit (): void {
		this.reduxSubscription.add(this.store.select((state: AppState) => state.ressourcesTable).subscribe((state) => {
			switch (state.action) {
				case RESSOURCE_TABLE_ACTION.INITIALIZING:

					this.showSpinner = true;
					this._ChangeDetectorRef.detectChanges();

					break;

				case RESSOURCE_TABLE_ACTION.INITIALIZED:

					this.showSpinner = false;
					this._isRed = false;
					this._isYellow = false;
					this._ChangeDetectorRef.detectChanges();

					break;

				case RESSOURCE_TABLE_ACTION.UPDATE_PROJECT_OVERVIEW_CELL:

					if (state.projectId === this.projectId || this.projectId?.startsWith(state.projectId)) {

						this.showSpinner = false;
						this._isRed = false;
						this._isYellow = false;
						this._ChangeDetectorRef.detectChanges();

					}

					break;

				default:
					break;
			}

		}));

	}

	ngOnDestroy (): void {
		this.reduxSubscription.unsubscribe();
	}

	get value (): any {
		if (this._value === undefined) {
			if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
				let value = this.valueObject.getWorkload(this.key);
				if (value === undefined || value === null) {
					value = 0;
				}

				switch (this.viewUnit) {
					case UNIT.DAYS:
						value = value / 8;
						break;

					default:
						break;
				}

				this._value = value;
			} else {

				let value = this.valueObject.getValue(this.key);
				if (value === undefined || value === null) {
					value = this.valueObject[this.key];
				}
				this._value = value;
			}
		}
		return this._value;
	}
	set value (value: any) {
		this._value = value;
		if (value === undefined) {
			this._isRed = false;
			this._isYellow = false;
		}
	}

	get valueString (): string {
		try {
			return (this.value === undefined || this.value === null
        || isNaN(this.value)
        || (this.value <= 0.005 && this.value >= -0.005)) ? '-' : formatNumber(
					this.value,
					'de',
					'1.0-2',
				);
		} catch (error) {
			return '-';
		}
	}

	set valueString (value: string) {
		//
	}

	get isRed (): boolean {
		if (this._isRed !== true) {
			if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
				if (this.valueObject.getWorkload(this.key) * SECONDS_PER_HOUR < (1 * this.threshold1)) {
					this._isRed = true;
					this._isYellow = false;
				} else {
					this._isRed = undefined;
					this._isYellow = undefined;
				}
			}
		}
		return this._isRed;
	}
	get isYellow (): boolean {
		if (this._isYellow !== true) {
			if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
				if (this.valueObject.getWorkload(this.key) * SECONDS_PER_HOUR > (1 * this.threshold1)) {
					this._isYellow = true;
					this._isRed = false;
				} else {
					this._isYellow = undefined;
					this._isRed = undefined;
				}
			}
		}
		return this._isYellow;
	}
	get isGreen (): boolean {
		return false;
	}

	handleFocus (event) {
		try {
			event.target.focus();
			event.target.select();
		} catch (error) {
		}
	}

	handleValueClick () {
		if (this.editable) {
			this.state = VIEW_STATE.EDIT;
		}
	}

	handleBlur (value: any, bClickNext: boolean = false) {
		this.bClickNext = bClickNext;

		this.state = VIEW_STATE.SHOW;

		if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
			try {
				value = (value as string).replace('.', '');
				value = (value as string).replace(',', '.');

				console.log('VALUE: ' + value);
				console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));

				if (isNaN(1 * (value as number))) {
					value = this.convertDurationToSeconds(value);
				} else {
					switch (this.viewUnit) {
						case UNIT.DAYS:
							value = (value as number) * HOURS_PER_WORKING_DAY;
							break;

						default:
							break;
					}
				}

				this.valueObject.setManuallyPlanned(this.key, 1 * (value as number) * SECONDS_PER_HOUR);

				this.valueString = '';
				this.value = undefined;

			} catch (error) {
				console.log(error);
			}

		} else {

			try {
				if (this.type === CELL_TYPE.EURO_VALUE) {
					value = (value as string).replace('.', '');
					value = (value as string).replace(',', '.');

					console.log('VALUE: ' + value);
					console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));
					this.valueObject.setValue(this.key, 1 * (value as number));
				} else if (this.type === CELL_TYPE.STRING) {
					this.valueObject[this.key] = value;
				}
			} catch (error) {
				console.log(error);
			}

		}

		this.refresh.emit(this);

	}

	convertDurationToSeconds (sValue: string): number {
		let seconds = 0;
		let current = '';

		function isNumeric (s) {
			return !isNaN(s - parseFloat(s));
		}

		for (const c of sValue) {

			if (isNumeric(c)) {
				current = current + c;
			} else if (c === '.' || c === ',') {
				current = current + '.';
			} else if (!c.trim().length) {
				continue;
			} else {
				let multiplier;

				switch (c) {
					case 's': multiplier = 1; break;      // seconds
					case 'm': multiplier = 60; break;     // minutes
					case 'h': multiplier = 3600; break;   // hours
					case 'd': multiplier = SECONDS_PER_HOUR * 8; break;  // days
					case 'w': multiplier = SECONDS_PER_HOUR * 8 * 5; break; // weeks
					default:

				}

				try {
					seconds += parseFloat(current) * multiplier;
				} catch (error) { }
				current = '';
			}
		}

		if (current !== '') {
			return 0;
		}

		return seconds / SECONDS_PER_HOUR;
	}

	handleKeyPress (event: any): void {
		const charCode = !event.charCode ? event.which : event.charCode;
		if ([0, 8].indexOf(charCode) !== -1) {
			return;
		}
	}

	handleKeyTab (event) {
		const element = event.srcElement.nextElementSibling; // get the sibling element

		if (element == null) {  // check if its null
			return;
		}
		else {
			element.focus();
		}   // focus if not null
	}

}
