import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../model/User';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {

	transform (value: any, callback: any, sessionUser: User): any {
		if (sessionUser.hasGroup('p-cockpit-ceos')) {
			return value;
		} else {
			return value.filter(callback);
		}
	}

}
