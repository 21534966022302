import { Action, createReducer, on } from '@ngrx/store';
import { GetFullProjects, GetProjects, PROJECT_ACTION, SetProjects } from '../actions/project-service.actions';
import { Project } from '../model/Project';

export const projectServiceFeatureKey = 'projectService';

export interface State {
	action: PROJECT_ACTION;
	projects: Project[];
}

export const initialState: State = {
	action: PROJECT_ACTION.NOTHING,
	projects: []
};

export const reducer = createReducer(
	initialState,

	on(GetProjects, (state) => ({ ...state, action: PROJECT_ACTION.GET_PROJECTS })),
	on(GetFullProjects, (state) => ({ ...state, action: PROJECT_ACTION.GET_FULL_PROJECTS })),
	on(SetProjects, (state, {projects}) => ({ ...state, action: PROJECT_ACTION.SET_PROJECTS, projects })),

);

