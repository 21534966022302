import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-side-nav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

	@Input() isExpanded: boolean;
	@Output() toggleMenu = new EventEmitter();

	public routeLinks = [
		{ link: 'about', name: 'About', icon: 'dashboard' },
		{ link: 'locations', name: 'Locations', icon: 'account_balance' }
	];
	constructor () { }

	ngOnInit (): void {
	}

}
