import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { JIRA_ACTION } from 'src/app/actions/jira-api.actions';
import { GetRequirementAnalysis, GetRequirementAnalysisWorkload, RequirementAnalysisNothing, RequirementAnalysisWorkloadLoaded,
	REQUIREMENT_ANALYSIS_ACTION, SaveRequirementAnalysis } from 'src/app/actions/requirement-analysis.actions';
import { REQUIRED_SECONDS_PER_DAY } from 'src/app/model/constants';
import { COLUMN_TYPE, PROJECT_TYPE } from 'src/app/model/enums';
import { round, trackByFn } from 'src/app/model/globalFunctions';
import { LocalStorageWorker } from 'src/app/model/LocalStorageWorker';
import { Project } from 'src/app/model/Project';
import { ProjectGroup } from 'src/app/model/ProjectGroup';
import { ProjectRequirementAnalysisWrapper } from 'src/app/model/ProjectRequirementAnalysisWrapper';
import { RequirementAnalysis } from 'src/app/model/RequirementAnalysis';
import { AppState } from 'src/app/reducers';
import { ProjectService } from 'src/app/services/project.service';
import { TempoIoService, TEMPO_IO_URL } from 'src/app/services/tempo.io.service';

@Component({
	selector: 'app-requirement-analysis',
	templateUrl: './requirement-analysis.component.html',
	styleUrls: ['./requirement-analysis.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementAnalysisComponent implements OnInit, AfterContentInit, OnDestroy {

	@ViewChild('RequirementAnalysisTable', { static: false }) oRequirementAnalysisTable: MatTable<any>;
	@ViewChild('deleteDialog', {static: false}) deleteDialog: TemplateRef<any>;

	public isLoading = false;

	public newProjectGroupName = '';

	ProjectGroupModel = new UntypedFormGroup({
		newProjectGroupName: new UntypedFormControl(this.newProjectGroupName)
	});

	SelectProjectGroupModel = new UntypedFormGroup({
	});

	public expandedElement;
	public expandedSubElement;

	public userList: any = [];
	public userMap: any = {};

	public selectedProjectGroup: ProjectGroup;

	public columnsToDisplay = [];
	public columnsToDisplayInfo = {};

	public workingDaysToAccountIds = {};
	public accountIdsToworkingDays = {};
	public contractType = {};

	private reduxSubscription: Subscription = new Subscription();

	public isInitalizing = true;

	public startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
	public endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 3, 1, 0, 0, 0, 0).getTime() - 1000);

	public groupingMode?: string = 'standard';
	public get groupingModeSuffix (): string {
		return (this.groupingMode && this.groupingMode !== 'standard') ? '-' + this.groupingMode : '';
	}

	range = new UntypedFormGroup({
		start: new UntypedFormControl(this.startDate),
		end: new UntypedFormControl(this.endDate)
	});

	public requirementAnalysis: RequirementAnalysis = new RequirementAnalysis();

	private ar_selectedMonth = [];

	public get selectedMonths (): any[] {

		if (this.ar_selectedMonth.length === 0 &&
      this.range.valid && this.range.value.start !== null && this.range.value.end !== null) {

			const dateBegin = this.range.value.start;
			const dateEnd = this.range.value.end;

			let startDate = moment(dateBegin).startOf('month');
			const endDate = moment(dateEnd).endOf('month');

			const ar_month = [];

			while (startDate.isBefore(endDate)) {
				const monthDescription = {
					key: moment(startDate).format('YYYY_M'),
					label: moment(startDate).format('MM/YY'),
					month: moment(startDate).format('YYYY_M'),
					date: moment(startDate).format('YYYY-MM-01')
				};
				ar_month.push(monthDescription);

				startDate = startDate.add(1, 'month');
			}
			this.ar_selectedMonth = ar_month;
		}

		return this.ar_selectedMonth;

	}

	COLUMN_TYPE = COLUMN_TYPE;
	PROJECT_TYPE = PROJECT_TYPE;
	trackByFn = trackByFn;
	Object = Object;
	Math = Math;
	JSON = JSON;

	constructor (
		private store: Store<AppState>,
		private dialog: MatDialog,
		public _ChangeDetectorRef: ChangeDetectorRef,

		@Inject(TEMPO_IO_URL) public tempoIoUrl: string,
		public tempoIoService: TempoIoService,

		public projectService: ProjectService,

	) { }
	ngOnDestroy (): void {
		this.reduxSubscription.unsubscribe();
		this.store.dispatch(RequirementAnalysisNothing());
	}

	ngAfterContentInit (): void {
		//    console.log('REQUIREMENT ANALYSIS: ngAfterContentInit');

	}

	ngOnInit (): void {
		const requirement_analysis_startDate = LocalStorageWorker.instance.get('requirement_analysis_startDate');
		if (requirement_analysis_startDate !== undefined && requirement_analysis_startDate !== null) {
			this.startDate = new Date(requirement_analysis_startDate);
		}
		const requirement_analysis_endDate = LocalStorageWorker.instance.get('requirement_analysis_endDate');
		if (requirement_analysis_endDate !== undefined && requirement_analysis_endDate !== null) {
			this.endDate = new Date(requirement_analysis_endDate);

			this.range = new UntypedFormGroup({
				start: new UntypedFormControl(this.startDate),
				end: new UntypedFormControl(this.endDate)
			});

		}

		this.reduxSubscription.add(this.store.select((state: AppState) => state.jiraApi).subscribe((state) => {
			switch (state.action) {
				case JIRA_ACTION.SET_USERS:
					this.userList = state.users.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
					this.userMap = state.userMap;

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.requirementAnalysis).subscribe((state) => {
			switch (state.action) {
				case REQUIREMENT_ANALYSIS_ACTION.NOTHING:

					this.isLoading = true;

					this.store.dispatch(GetRequirementAnalysis({suffix: this.groupingModeSuffix}));

					break;

				case REQUIREMENT_ANALYSIS_ACTION.REQUIREMENT_ANALYSIS_LOADED:

					this.requirementAnalysis = state.requirementAnalysis ?? new RequirementAnalysis();

					this.requirementAnalysis.invoices = this.projectService.invoices;

					this.getWorklogs();

					this._ChangeDetectorRef.detectChanges();

					break;

				case REQUIREMENT_ANALYSIS_ACTION.GET_REQUIREMENT_ANALYSIS_WORKLOAD:

					this.isLoading = true;

					break;

				case REQUIREMENT_ANALYSIS_ACTION.REQUIREMENT_ANALYSIS_WORKLOAD_LOADED:

					this.isLoading = false;

					this.refreshTable();

					break;

				default:
					break;
			}

		}));

		this.setColumns();
	}

	setColumns () {

		const columns = [];
		const columnsInfo = {};

		const groupColumns = [];
		const groupColumnsInfo = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-name', key: 'name', title: 'Name', column_type: COLUMN_TYPE.NAME };

		colName = 'timeSpentWorkDays';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-timeSpentWorkDays', key: 'timeSpentWorkDays',
			title: 'PT im Zeitraum', column_type: COLUMN_TYPE.WORKDAY_NUMBER };

		colName = 'workDayQuota';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-timeSpentWorkDaysQuotaPercent', key: 'workDayQuota',
			title: 'Anteil im Zeitraum', column_type: COLUMN_TYPE.PERCENTAGE_NUMBER };

		colName = 'personCount';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-personCount', key: 'personCount',
			title: 'Personen im Zeitraum', column_type: COLUMN_TYPE.NUMBER };

		colName = 'fullTimeEquivalent';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-fullTimeEquivalent', key: 'fullTimeEquivalent',
			title: 'FTE im Zeitraum', column_type: COLUMN_TYPE.FTE_NUMBER };

		colName = 'budgetInRange';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-budgetInRange', key: 'budgetInRange',
			title: 'Budget im Zeitraum', column_type: COLUMN_TYPE.EURO_NUMBER };

		colName = 'budgetInRangePerWorkDay';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-budgetInRangePerWorkDay', key: 'budgetInRangePerWorkDay',
			title: 'Budget / PT', column_type: COLUMN_TYPE.EURO_NUMBER };

		colName = 'budgetInRangePerFullTimeEquivalent';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-budgetInRangePerFullTimeEquivalent', key: 'budgetInRangePerFullTimeEquivalent',
			title: 'Budget / FTE', column_type: COLUMN_TYPE.EURO_NUMBER };

		colName = 'invoiceNettoInRange';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-invoiceNettoInRange', key: 'invoiceNettoInRange',
			title: 'Rechnungen im Zeitraum', column_type: COLUMN_TYPE.EURO_NUMBER };

		colName = 'invoiceInRangePerWorkDay';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-invoiceInRangePerWorkDay', key: 'invoiceInRangePerWorkDay',
			title: 'Rechnung / PT', column_type: COLUMN_TYPE.EURO_NUMBER };

		colName = 'invoiceNettoInRangePerFullTimeEquivalent';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-invoiceNettoInRangePerFullTimeEquivalent', key: 'invoiceNettoInRangePerFullTimeEquivalent',
			title: 'Rechnung / FTE', column_type: COLUMN_TYPE.EURO_NUMBER };

		//    colName = 'budget';
		//    columns.push(colName);
		//    columnsInfo[colName] = { class: 'c-budget', key: 'budget', title: 'Budget Gesamt', column_type: COLUMN_TYPE.EURO_NUMBER };

		colName = 'relatedProjectGroup';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-relatedProjectGroup', key: 'relatedProjectGroup', title: '', column_type: COLUMN_TYPE.INFO };

		colName = 'space';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-spacer', title: '', column_type: COLUMN_TYPE.SPACE };

		this.columnsToDisplay = [];
		this.columnsToDisplayInfo = [];

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;

		this.refreshTable();
	}

	refreshTable () {
		this.requirementAnalysis.refreshData();

		this.oRequirementAnalysisTable?.renderRows();

		this.requirementAnalysis.projectGroups = [...this.requirementAnalysis.projectGroups];

		this._ChangeDetectorRef.detectChanges();
	}

	monthAdd (monthAdd: number) {
		const dateBegin = this.range.value.start;
		const dateEnd = this.range.value.end;

		const startDate = moment(dateBegin).add(monthAdd, 'M').startOf('month');
		const endDate = moment(dateEnd).add(monthAdd, 'M').endOf('month');

		this.startDate = startDate.toDate();
		this.endDate = endDate.toDate();

		this.range = new UntypedFormGroup({
			start: new UntypedFormControl(this.startDate),
			end: new UntypedFormControl(this.endDate)
		});

		this.dateRangeChange(undefined);
	}

	dateRangeChange ($event) {

		if (!this.range.valid || this.range.value.start === null || this.range.value.end === null) {
			return;
		}

		const dateBegin = this.range.value.start;
		const dateEnd = this.range.value.end;

		const selectedStartDate = moment(dateBegin).startOf('month');
		const selectedEndDate = moment(dateEnd).endOf('month');

		this.startDate = selectedStartDate.toDate();
		this.endDate = selectedEndDate.toDate();

		this.ar_selectedMonth = [];

		const startMonth = this.selectedMonths[0];
		const endMonth = this.selectedMonths[this.selectedMonths.length - 1];
		const endDate = new Date(new Date(new Date(endMonth.date).getFullYear(),
			new Date(endMonth.date).getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);

		this.startDate = selectedStartDate.toDate();
		this.endDate = endDate;

		LocalStorageWorker.instance.set('requirement_analysis_startDate', startMonth.date.toString());
		LocalStorageWorker.instance.set('requirement_analysis_endDate', endDate.toString());

		//    this.getWorklogs();
		this.isLoading = true;
		this.store.dispatch(GetRequirementAnalysis({suffix: this.groupingModeSuffix}));

		this._ChangeDetectorRef.detectChanges();
	}

	getWorklogs () {

		this.requirementAnalysis?.resetData();

		this.store.dispatch(GetRequirementAnalysisWorkload());

		this.requirementAnalysis.projectWorkload = {};
		this.requirementAnalysis.projectAccountId = {};

		this.requirementAnalysis.startDate = this.startDate;
		this.requirementAnalysis.endDate = this.endDate;

		const startDateString = moment(this.startDate).format('YYYY-MM-DD');
		const endDateString = moment(this.endDate).format('YYYY-MM-DD');

		this._ChangeDetectorRef.detectChanges();

		const monthDifference =  moment(this.endDate).diff(moment(this.startDate), 'months', true);
		const limit = Math.round(monthDifference * 1500);

		const observableUserSchedule = this.tempoIoService.getUserScheduleForMonth(undefined, startDateString, endDateString);
		const observableWorkloads = this.tempoIoService.getCachedAll<any>(
			this.tempoIoUrl + 'worklogs?from=' + startDateString + '&to=' + endDateString + '&limit=' + limit, {}
		);

		console.log('REQUIREMENT ANALYSIS: GET WORKLOADS');

		const allObservablesSubscribtions = forkJoin([observableUserSchedule, observableWorkloads]).subscribe((responses) => {

			for (const res of responses) {

				if (res.sumWorkingDay) {
					console.log('user-schedule: ' + JSON.stringify(res));
					let sumWorkingDay = 0;
					for (const actSumWorkingDay of Object.values<number>(res.sumWorkingDay)) {
						sumWorkingDay += actSumWorkingDay;
					}

					this.requirementAnalysis.maxWorkingDaysInRange = Math.floor(sumWorkingDay / REQUIRED_SECONDS_PER_DAY);

					console.log('user-schedule: ' + sumWorkingDay + ' -> ' + this.requirementAnalysis.maxWorkingDaysInRange);

				} else if (res.results) {
					const ar_oItem = res.results;

					console.log('REQUIREMENT ANALYSIS: ' + JSON.stringify(ar_oItem));

					for (const item of ar_oItem) {

						const month = moment(item.startDate).format('YYYY_MM');
						const day = moment(item.startDate).format('DD');
						const monthDate = moment(item.startDate).toDate();

						const accountId = item.author.accountId;
						const issueId = item.issue.key;
						let projectId = issueId.split('-')[0];

						if (issueId === 'POR-57') {
							projectId = 'Urlaub';
						}
						if (issueId === 'POR-58') {
							projectId = 'Krank';
						}
						if (projectId === 'PIN') {
							projectId = 'Internes';
						}

						const timeSpentSeconds = item.timeSpentSeconds * 1;
						const actWorkload = this.requirementAnalysis.projectWorkload[projectId] ?? 0;
						this.requirementAnalysis.projectWorkload[projectId] = actWorkload + timeSpentSeconds;

						const actProjectAccountIdWorkload = this.requirementAnalysis.projectAccountIdWorkload[projectId] ?? {};
						actProjectAccountIdWorkload[accountId] = (actProjectAccountIdWorkload[accountId] ?? 0) + timeSpentSeconds;
						this.requirementAnalysis.projectAccountIdWorkload[projectId] = actProjectAccountIdWorkload;

						let project: Project = this.projectService.projectsById[projectId];
						if (project === undefined) {
							project = new Project();
							project.id = projectId;
							project.name = projectId;
							project.avatar = 'https://projektionisten.atlassian.net/secure/projectavatar?size=small&s=small&pid=15412&avatarId=14419';
						}

						const projectAccountIds = this.requirementAnalysis.projectAccountId[project.id] ?? [];
						if (accountId && projectAccountIds.indexOf(accountId) === -1) {
							projectAccountIds.push(accountId);
						}
						this.requirementAnalysis.projectAccountId[project.id] = projectAccountIds;

						this.requirementAnalysis.putProjectToGroup(project);

					}

					this.requirementAnalysis.reassignProjects(this.projectService.projectsById);

					for (const projectId of Object.keys(this.projectService.projectsById)) {
						if (projectId === 'DVBVVOWART') {
							const xxx = 1;
						}

						const actProject = this.projectService.projectsById[projectId];
						if (actProject?.type === PROJECT_TYPE.FULL) {
							const projectGroup: ProjectGroup = this.requirementAnalysis?.projectGroupByProjectId[projectId];
							if (projectGroup) {
								const project = projectGroup.projects.find((item) => item.id === projectId && item.name !== projectId);
								if (!project) {
									projectGroup.projects.push(actProject);
									// this.requirementAnalysis.putProjectToGroup(this.projectService.projectsById[projectId], projectGroup.id);
								}
							} else {
								const newProjectGroup = this.requirementAnalysis.projectGroups.find((item) => item.name === 'nicht zugeordnet');
								this.requirementAnalysis.putProjectToGroup(actProject, newProjectGroup.id);
							}	
						}
					}

					this.getUserScheduleForAccountIds();

					console.log('REQUIREMENT ANALYSIS: ' + JSON.stringify(this.requirementAnalysis));

					this.store.dispatch(RequirementAnalysisWorkloadLoaded());

				}

			}

		});
	}

	getUserScheduleForAccountIds () {
		this.requirementAnalysis.startDate = this.startDate;
		this.requirementAnalysis.endDate = this.endDate;

		const startDateString = moment(this.startDate).format('YYYY-MM-DD');
		const endDateString = moment(this.endDate).format('YYYY-MM-DD');

		const ar_observableUserSchedule: Observable<any>[] = [];
		for (const accountId of this.requirementAnalysis.accountIds) {
			const observableUserSchedule = this.tempoIoService.getUserScheduleForMonth(accountId, startDateString, endDateString);
			ar_observableUserSchedule.push(observableUserSchedule);
		}

		const allObservablesSubscribtions = forkJoin(ar_observableUserSchedule).subscribe((responses) => {

			const contractType = {};
			const workingDaysToAccountIds = {};
			const accountIdsToworkingDays = {};
			for (const res of responses) {

				if (res.sumWorkingDay) {
					let sumWorkingDay = 0;
					for (const actSumWorkingDay of Object.values<number>(res.sumWorkingDay)) {
						sumWorkingDay += actSumWorkingDay;
					}

					const workingDays = Math.floor(sumWorkingDay / REQUIRED_SECONDS_PER_DAY);

					const accountIds: string[] = workingDaysToAccountIds[workingDays] ?? [];
					accountIds.push(res.accountId);
					workingDaysToAccountIds[workingDays] = accountIds;
					accountIdsToworkingDays[res.accountId] = workingDays;

					const actContractType = Math.min(round(workingDays / this.requirementAnalysis.maxWorkingDaysInRange, 1) * 100, 100);
					let actContractTypeName = 'Vollzeit';
					if (actContractType < 100) {
						actContractTypeName = 'Teilzeit (' + actContractType + '%)';
					}
					contractType[actContractType] = contractType[actContractType] ?? {};
					const accountIsForContractType = contractType[actContractType].accountIds ?? [];
					accountIsForContractType.push(res.accountId);
					contractType[actContractType].name = actContractTypeName;
					contractType[actContractType].workingDays = actContractType / 100 * this.requirementAnalysis.maxWorkingDaysInRange;
					contractType[actContractType].accountIds = accountIsForContractType;

					console.log('user-schedule: ' + res.accountId + ' -> ' + sumWorkingDay / REQUIRED_SECONDS_PER_DAY);

				}
			}

			this.workingDaysToAccountIds = workingDaysToAccountIds;
			this.accountIdsToworkingDays = accountIdsToworkingDays;
			this.contractType = contractType;

			this._ChangeDetectorRef.detectChanges();

			console.log('this.workingDaysToAccountIds: ' + JSON.stringify(this.workingDaysToAccountIds));
		});

	}

	handleProjectGroupSelect (projectWrapper: ProjectRequirementAnalysisWrapper, projectGroupId: string) {
		this.requirementAnalysis.putProjectToGroup(projectWrapper.project, projectGroupId);

		this.refreshTable();

		this.store.dispatch(SaveRequirementAnalysis({ requirementAnalysis: this.requirementAnalysis, suffix: this.groupingModeSuffix }));
	}

	handleProjectGroupAdd (toObject: ProjectGroup | RequirementAnalysis = this.requirementAnalysis) {
		const newProjectGroupName = this.ProjectGroupModel.value.newProjectGroupName;
		if (newProjectGroupName && newProjectGroupName !== '') {
			console.log(newProjectGroupName);

			toObject.addProjectGroup(newProjectGroupName);
			this.ProjectGroupModel.value.newProjectGroupName = '';

			this.refreshTable();

			this.store.dispatch(SaveRequirementAnalysis({ requirementAnalysis: this.requirementAnalysis, suffix: this.groupingModeSuffix }));

		}
	}

	handleProjectGroupDelete (selectedProjectGroup: ProjectGroup, fromObject: RequirementAnalysis | ProjectGroup) {

		this.selectedProjectGroup = selectedProjectGroup;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {

				fromObject.deleteProjectGroup(this.selectedProjectGroup);

				this.refreshTable();

				this.store.dispatch(SaveRequirementAnalysis({ requirementAnalysis: this.requirementAnalysis, suffix: this.groupingModeSuffix }));

			}
		});

	}

	onModesGroupChange (value: string) {
		this.groupingMode = value;

		this._ChangeDetectorRef.detectChanges();

		this.store.dispatch(RequirementAnalysisNothing());

	}

}
