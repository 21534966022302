import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CELL_TYPE, UNIT } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { FundingProject, Timesheet, TimeSheetRow } from 'src/app/model/Timesheet';
import { User } from 'src/app/model/User';

@Injectable({
	providedIn: 'root'
})

@Component({
	selector: 'app-time-statement-hourly-rate-table',
	templateUrl: './time-statement-hourly-rate-table.component.html',
	styleUrls: ['./time-statement-hourly-rate-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeStatementHourlyRateTableComponent implements OnInit {
	@Input()
	public get userTimesheets (): Timesheet[] {
		return this._userTimesheets;
	}
	public set userTimesheets (value: Timesheet[]) {

		this._userTimesheets = value;

		this.generateMaps();

		this._years = null;
		this._fundingProjects = null;

	}
	public get years (): number[] {
		if (this._years === undefined || this._years === null) {
			try {
				const ar_nYear: number[] = [];
				for (const timesheet of this.userTimesheets) {
					if (ar_nYear.indexOf(timesheet.monthDate.getFullYear()) === -1) {
						ar_nYear.push(timesheet.monthDate.getFullYear());
					}
				}

				this._years = ar_nYear;

			} catch (error) {

			}

		}
		return this._years;
	}
	public set year (value: number[]) {
		this._years = value;
	}
	public get fundingProjects () {
		// if (this._fundingProjects === undefined || this._fundingProjects === null) {
		try {
			const ar_fundingProjects: FundingProject[] = [];
			const hm_fundingProjects = {};

			for (const timesheet of this.userTimesheets) {
				for (const fundingProject of timesheet.fundingProjects) {
					hm_fundingProjects[fundingProject.id] = fundingProject;
				}
			}
			for (const fundingProjectId of Object.keys(hm_fundingProjects)) {
				ar_fundingProjects.push(hm_fundingProjects[fundingProjectId]);
			}

			this._fundingProjects = ar_fundingProjects;

		} catch (error) {

		}

		// }
		return this._fundingProjects;
	}
	public set fundingProjects (value) {
		this._fundingProjects = value;
	}

	constructor (
		public _ChangeDetectorRef: ChangeDetectorRef,
		private _jiraconnector: JiraconnectorService,
	) { }

	@Input() userRow: User;

	private _monthmap_timesheets = {};
	private _columnIds = {};
	private _columnInfo = {};
	private _timeSheetRows = {};
	private _fundingTimeSheetRows = {};

	private _funding_values = {};

	private _userTimesheets: Timesheet[];

	@Input() timesheets: any = {};

	@Input() holidayDates: any = {};

	private _years: number[];

	private _fundingProjects;

	private ar_month_for_year: any[] = [];

	@Output() refresh = new EventEmitter<any>();

	moment = moment;
	UNIT = UNIT;
	CELL_TYPE = CELL_TYPE;
	trackByFn = trackByFn;

	generateMaps () {
		try {
			const _self = this;

			_self._funding_values = {};

			function addDataToFundingValues (timesheet: Timesheet, month_key: string, id: string) {
				const timeSheetRow = timesheet.fundingTimeSheetRows.find((item) => item.projectId === id);
				const no_social_insurance = timesheet.no_social_insurance === true;
				if (timeSheetRow) {
					const value = timeSheetRow.getValue('SUM');
					if (!_self._funding_values[id + '_' + month_key + '_' + no_social_insurance]) {
						_self._funding_values[id + '_' + month_key + '_' + no_social_insurance] = value;

						const sum_key = id + '_' + month_key.split('_')[0] + '_SUM';
						_self._funding_values[sum_key + '_' + no_social_insurance] =
              (_self._funding_values[sum_key + '_' + no_social_insurance] || 0) + value;
					}
				}
			}

			if (this.fundingProjects !== undefined && this.fundingProjects !== null) {
				for (const fundingProject of this.fundingProjects) {
					for (const timesheet of this.userTimesheets) {
						const tmpfundingProjects = timesheet.fundingProjects;
						timesheet.fundingProjects = this.fundingProjects;
						timesheet.refresh();
						const month_key = moment(timesheet.monthDate).format('YYYY_MM');

						this._columnIds[month_key] = timesheet.columnIds;
						this._columnInfo[month_key] = timesheet.columnsInfo;
						this._timeSheetRows[month_key] = timesheet.timeSheetRows;
						this._fundingTimeSheetRows[month_key] = timesheet.fundingTimeSheetRows;
						this._monthmap_timesheets[month_key] = timesheet;

						addDataToFundingValues(timesheet, month_key, fundingProject.id);
						addDataToFundingValues(timesheet, month_key, 'Other');
						addDataToFundingValues(timesheet, month_key, 'SummeProd');
						addDataToFundingValues(timesheet, month_key, 'HOLIDAY');

						timesheet.fundingProjects = tmpfundingProjects;
						timesheet.refresh();
					}
				}
			}

		} catch (error) {

		}
		this._ChangeDetectorRef.detectChanges();

	}

	ngOnInit (): void {
	}

	filterRows (array, key: string, value: string, equals: boolean = false) {
		if (equals) {
			return array.filter((item) => item[key] === value);
		} else {
			return array.filter((item) => item[key] !== value);
		}
	}

	isFundingTimeSheetRowEditable (timesheet: Timesheet, column: string, fundingTimeSheetRow: TimeSheetRow) {
		if (timesheet.columnsInfo[column].key.indexOf('day') !== -1) {
			if (('' + fundingTimeSheetRow.getValue('name')).indexOf('onstige') !== -1) {
				return false;
			}

			if (fundingTimeSheetRow.projectName.indexOf('umme') !== -1 ||
      fundingTimeSheetRow.projectName.indexOf('esamt') !== -1 ||
      fundingTimeSheetRow.projectName.indexOf('HOLIDAY') !== -1) {
				return true;
			}
			return true;
		} else {
			return false;
		}

	}

	getSocialInsurance () {
		const ar_no_social_insurance = [];
		for (const timesheet of this.userTimesheets) {
			const act_no_social_insurance = timesheet.no_social_insurance ? 'true' : 'false';
			if (ar_no_social_insurance.indexOf(act_no_social_insurance) === -1) {
				ar_no_social_insurance.push(act_no_social_insurance);
			}
		}
		return ar_no_social_insurance;
	}

	getSocialInsuranceButtonLabel (act_no_social_insurance) {
		if (this.getSocialInsurance().length < 2) {
			return '';
		} else {
			switch (act_no_social_insurance) {
				case 'true':
					return ' (ohne Sozialversicherungspflicht)';
					break;

				default:
					return ' (mit Sozialversicherungspflicht)';
					break;
			}
		}
	}

	getTimeSheetByYear (year: number, act_no_social_insurance: string) {
		const no_social_insurance = act_no_social_insurance === 'true';
		const timeSheet = this.userTimesheets.find(
			(item) => item.monthDate.getFullYear() === year &&
                item.no_social_insurance === no_social_insurance &&
          (
          	(item.hourly_rate !== undefined && item.hourly_rate !== '') ||
            item.total_working_days !== 0 ||
            item.gross_salary !== 0
          )
		);

		if (timeSheet === undefined) {
			this.userTimesheets.find((item) => item.monthDate.getFullYear() === year &&
          item.no_social_insurance === no_social_insurance);
		}
		return timeSheet;
	}

	getMonthsByYear (year: number) {
		if (this.ar_month_for_year[year] === undefined) {
			const ar_month: Date[] = [];
			for (let month = 0; month < 12; month++) {
				const date = new Date(year, month, 1);
				ar_month.push(date);
			}
			this.ar_month_for_year[year] = ar_month;
		}
		return this.ar_month_for_year[year];
	}

	getFundingValueByMonth (monthDateKey: string, id: string, act_no_social_insurance: string) {
		const month_key = monthDateKey;
		const no_social_insurance = act_no_social_insurance === 'true';

		const funding_value = this._funding_values[id + '_' + month_key + '_' + no_social_insurance];
		if (funding_value) {
			return formatNumber(
				funding_value,
				'de',
				'1.0-2',
			);
		}

		return '0';
	}

	getFundingOtherValueByMonth (monthDateKey: string, id: string, act_no_social_insurance: string) {
		const month_key = monthDateKey;
		const no_social_insurance = act_no_social_insurance === 'true';

		let other = 0;
		for (const fundingProject of this.fundingProjects) {
			if ('' + fundingProject.id !== id) {
				other += this._funding_values[fundingProject.id + '_' + month_key + '_' + no_social_insurance] ?? 0;
			}
		}

		const funding_value = other + this._funding_values['Other_' + month_key + '_' + no_social_insurance];
		if (funding_value) {
			return formatNumber(
				funding_value,
				'de',
				'1.0-2',
			);
		}

		return '0';
	}

	handleRefresh (event) {

		if ('valueObject' in event) {
			const actTimesheet = event.valueObject;
			const act_no_social_insurance = actTimesheet.no_social_insurance === true;

			for (const timesheet of this.userTimesheets) {
				const no_social_insurance = timesheet.no_social_insurance === true;
				if (timesheet.monthDate.getFullYear() === actTimesheet.monthDate.getFullYear() &&
            act_no_social_insurance === no_social_insurance) {
					timesheet.gross_salary = actTimesheet.gross_salary;
					timesheet.total_working_days = actTimesheet.total_working_days;
				}
			}
		}

		this.refresh.emit();
	}

}

