import * as moment from 'moment';
import { SECONDS_PER_HOUR } from './constants';
import { Project } from './Project';
import CryptoES from 'crypto-es';
import { formatNumber } from '@angular/common';

export class Timesheet {
	private crypt_string = 'P_COCKPIT$2021';
	private _ar_FundingTimeSheetRow: TimeSheetRow[];

	private _funding_manually_data: any = {};

	public gross_salary = 0;
	public total_working_days = 0;

	public no_social_insurance = false;

	public fundingProjects: Project[] = [];

	public accountId: string;
	public monthDate: Date;
	private _projectWorkloadData: any = {};
	public projectNames: any = {};

	private _ar_TimeSheetRow: TimeSheetRow[];

	public get hourly_rate () {
		try {

			const hourly_rate = this.gross_salary / this.total_working_days;
			if (isNaN(hourly_rate)) {
				return '-';
			}

			return formatNumber(
				this.gross_salary / this.total_working_days,
				'de',
				'1.0-2',
			);

		} catch (error) {

		}

		return '-';
	}
	public set hourly_rate (value) {
		//
	}

	public get gross_salary_crypt (): string {
		return CryptoES.DES.encrypt('' + this.gross_salary, this.crypt_string).toString();
	}
	public set gross_salary_crypt (value: string) {
		this.gross_salary = value ? parseFloat(CryptoES.DES.decrypt(value, this.crypt_string).toString(CryptoES.enc.Utf8)) : 0;
	}
	public get total_working_days_crypt (): string {
		return CryptoES.DES.encrypt('' + this.total_working_days, this.crypt_string).toString();
	}
	public set total_working_days_crypt (value: string) {
		this.total_working_days = value ? parseFloat(CryptoES.DES.decrypt(value, this.crypt_string).toString(CryptoES.enc.Utf8)) : 0;
	}

	public get funding_manually_data (): any {
		return this._funding_manually_data;
	}
	public set funding_manually_data (value: any) {
		this._funding_manually_data = value;
	}

	get prefix (): string {
		const sPrefix = this.accountId + '-' + moment(this.monthDate).format('YYYY_MM') + '-';
		return sPrefix;
	}

	private _ar_oColumnId: string[];
	get columnIds () {
		if (this._ar_oColumnId === undefined) {
			const ar_oColumnId: string[] = [];

			ar_oColumnId.push(this.prefix + 'name');

			for (let day = 1; day <= moment(this.monthDate).endOf('month').date(); day++) {
				ar_oColumnId.push(this.prefix + 'day-' + day);
			}

			ar_oColumnId.push(this.prefix + 'day-SUM');

			this._ar_oColumnId = ar_oColumnId;
		}

		return this._ar_oColumnId;
	}

	private _ar_oColumnInfo: {};
	get columnsInfo () {
		if (this._ar_oColumnInfo === undefined) {
			const ar_oColumnInfo: any = {};

			ar_oColumnInfo[this.prefix + 'name'] = {class: 'c-name', key: 'name', title: 'Name'};

			for (let day = 1; day <= moment(this.monthDate).endOf('month').date(); day++) {
				ar_oColumnInfo[this.prefix + 'day-' + day] = {class: 'c-m', key: 'day-' + day, title: '' + (day < 10 ? '0' : '') + day};
			}

			ar_oColumnInfo[this.prefix + 'day-SUM'] = {class: 'c-m bold', key: 'SUM', title: 'Summe'};

			this._ar_oColumnInfo = ar_oColumnInfo;
		}

		return this._ar_oColumnInfo;
	}

	get timeSheetRows (): TimeSheetRow[] {
		if (this._ar_TimeSheetRow === undefined) {
			const ar_TimeSheetRow: TimeSheetRow[] = [];

			for (const projectId of this.getProjectKeys()) {
				const oTimeSheetRow = new TimeSheetRow();
				oTimeSheetRow.timesheet = this;
				oTimeSheetRow.projectId = (projectId.indexOf('umme') !== -1) ? 'Summe' : projectId;
				oTimeSheetRow.projectName = this.projectNames[projectId] || projectId;

				if (oTimeSheetRow.selectedFundingProjectId !== 'unallocated' &&
                    this.fundingProjectIds.indexOf(oTimeSheetRow.projectId) !== -1) {
					if (oTimeSheetRow.selectedFundingProjectId === undefined) {
						oTimeSheetRow.selectedFundingProjectId = projectId;
					}
				}

				for (let day = 1; day <= moment(this.monthDate).endOf('month').date(); day++) {
					oTimeSheetRow.data['day-' + day] = this.getWorkload(projectId, '' + (day < 10 ? '0' : '') + day);
				}
				ar_TimeSheetRow.push(oTimeSheetRow);
			}

			ar_TimeSheetRow.sort((a, b) => {
				try {
					if (!a.projectName.startsWith('Summe') && b.projectName.startsWith('Summe')) { return -1; }
					if (a.projectName.startsWith('Summe') && !b.projectName.startsWith('Summe')) { return 1; }
					if (!a.projectName.startsWith('Urlaub') && b.projectName.startsWith('Urlaub')) { return -1; }
					if (a.projectName.startsWith('Urlaub') && !b.projectName.startsWith('Urlaub')) { return 1; }
					if (!a.projectName.startsWith('Krank') && b.projectName.startsWith('Krank')) { return -1; }
					if (a.projectName.startsWith('Krank') && !b.projectName.startsWith('Krank')) { return 1; }
					if (!a.projectName.startsWith('[') && b.projectName.startsWith('[')) { return -1; }
					if (a.projectName.startsWith('[') && !b.projectName.startsWith('[')) { return 1; }
					return a.projectName.localeCompare(b.projectName, undefined, {sensitivity: 'base'});
				} catch (error) {
					return -1;
				}
			});

			this._ar_TimeSheetRow = ar_TimeSheetRow;

		}
		return this._ar_TimeSheetRow;
	}
	set timeSheetRows (array: TimeSheetRow[]) {
		//
	}

	public get fundingTimeSheetRows (): TimeSheetRow[] {
		return this.getFundingTimeSheetRows();
	}
	public set fundingTimeSheetRows (array: TimeSheetRow[]) {
		this._ar_FundingTimeSheetRow = array;
	}

	public getFundingTimeSheetRows (localFundingProjects: Project[] = this.fundingProjects): TimeSheetRow[] {
		if (this._ar_FundingTimeSheetRow === undefined || localFundingProjects !== this.fundingProjects) {

			const keys: string[] = [];
			for (let day = 1; day <= moment(this.monthDate).endOf('month').date(); day++) {
				keys.push('day-' + day);
			}

			const baseSumTimeSheetRow = this.timeSheetRows.filter((item) => item.projectName.indexOf('umme') !== -1)[0];

			const otherTimeSheetRow = new TimeSheetRow();
			otherTimeSheetRow.timesheet = this;
			otherTimeSheetRow.projectId = 'Other';
			otherTimeSheetRow.projectName = 'Sonstige';

			const sumTimeSheetRow = new TimeSheetRow();
			sumTimeSheetRow.timesheet = this;
			sumTimeSheetRow.projectId = 'SummeProd';
			sumTimeSheetRow.projectName = 'Produktive Gesamtstunden';
			for (const key of keys) {
				try {
					sumTimeSheetRow.data[key] = baseSumTimeSheetRow.getRawValue(key);
				} catch (error) {}
				// timeSheetRow.data[key] = sumTimeSheetRow.data[key];
			}

			const holidayTimeSheetRow = new TimeSheetRow();
			holidayTimeSheetRow.timesheet = this;
			holidayTimeSheetRow.projectId = 'HOLIDAY';
			holidayTimeSheetRow.projectName = 'Fehlzeiten (z.B. Urlaub, Krankheit, Fortbildung)';

			const holidayTimeSheetRows = this.timeSheetRows.filter((item) =>
				item.projectId === 'Urlaub' || item.projectId === 'Krank');

			for (const key of keys) {
				let sum = 0;
				for (const actHolidayTimeSheetRow of holidayTimeSheetRows) {
					sum += actHolidayTimeSheetRow.getRawValue(key);
					// sum += actHolidayTimeSheetRow.data[key] || 0;
				}

				holidayTimeSheetRow.data[key] = sum;
			}

			const ar_FundingTimeSheetRow: TimeSheetRow[] = [];
			for (const project of localFundingProjects) {
				const timeSheetRow = new TimeSheetRow();
				timeSheetRow.timesheet = this;
				timeSheetRow.projectId = project.id;
				timeSheetRow.projectName = project.name + ' ' + project.fundingNumber;
				timeSheetRow.selectedFundingProjectId = project.id;

				const projectTimeSheetRows = this.timeSheetRows.filter((item) =>
					item.selectedFundingProjectId !== 'unallocated' &&
                    (item.selectedFundingProjectId === timeSheetRow.selectedFundingProjectId ||
                    (item.projectId === timeSheetRow.selectedFundingProjectId &&
                        (item.selectedFundingProjectId === undefined || item.selectedFundingProjectId === item.projectId))
                    )
				);

				/*
                console.log('FUNDING TIMESHEETROW');
                if (this.accountId === '603bc56b81a8400068a8a0e5' && projectTimeSheetRows.length > 2 &&
                    projectTimeSheetRows[2].projectId === 'SIM') {
                    let xxx = 1;
                }
*/
				for (const key of keys) {
					let sum = 0;
					for (const projectTimeSheetRow of projectTimeSheetRows) {
						sum += projectTimeSheetRow.getRawValue(key);
						// sum += projectTimeSheetRow .data[key] || 0;
					}

					timeSheetRow.data[key] = sum;

					if (otherTimeSheetRow.data[key] === undefined) {
						otherTimeSheetRow.data[key] = sumTimeSheetRow.getRawValue(key);
						// otherTimeSheetRow.data[key] = sumTimeSheetRow.data[key];
					}
					otherTimeSheetRow.data[key] = (otherTimeSheetRow.data[key] || 0) - timeSheetRow.getRawValue(key);
				}

				ar_FundingTimeSheetRow.push(timeSheetRow);
			}

			ar_FundingTimeSheetRow.push(otherTimeSheetRow);

			ar_FundingTimeSheetRow.push(sumTimeSheetRow);

			ar_FundingTimeSheetRow.push(holidayTimeSheetRow);

			this._ar_FundingTimeSheetRow = ar_FundingTimeSheetRow;

		}
		return this._ar_FundingTimeSheetRow;
	}

	public get fundingProjectIds (): string[] {
		const ar_sFundingProjectId: string[] = [];
		for (const fundingProject of this.fundingProjects) {
			ar_sFundingProjectId.push(fundingProject.id);
		}

		return ar_sFundingProjectId;
	}

	public addWorkload (projectKey: string, dayKey: string, value: number) {
		const projectWorkload: any = this._projectWorkloadData[projectKey] || {};
		const projectDayWorkload: number = projectWorkload[dayKey] || 0;
		projectWorkload[dayKey] = projectDayWorkload + value;
		this._projectWorkloadData[projectKey] = projectWorkload;
	}

	public getWorkload (projectKey: string, dayKey: string): number {
		const projectWorkload: any = this._projectWorkloadData[projectKey] || {};
		let projectDayWorkload: number = projectWorkload[dayKey] || 0;

		if (dayKey === 'SUM') {
			projectDayWorkload = 0;

			for (let day = 1; day <= moment(this.monthDate).endOf('month').date(); day++) {
				const actProjectDayWorkload: number = projectWorkload[((day < 10) ? '0' : '') + day] || 0;
				projectDayWorkload += actProjectDayWorkload;
			}
		}

		return projectDayWorkload;
	}

	public getProjectNames (): string[] {
		return Object.keys(this._projectWorkloadData);
	}

	public getProjectKeys (): string[] {
		return Object.keys(this._projectWorkloadData);
	}

	isDayWeekend (day: string): boolean {
		return this.getDayOfWeek(day) === 6 || this.getDayOfWeek(day) === 7;
	}

	getDayOfWeek (day: string): number {
		try {
			return moment(this.monthDate).date(parseInt(day, 10)).isoWeekday();
		} catch (error) {
			return 0;
		}
	}

	refresh () {
		this.timeSheetRows = [];
		this.fundingTimeSheetRows = undefined;
	}

	toJSON () {
		const jsonObj = {
			accountId: this.accountId,
			timeSheetRows: this.timeSheetRows,
			fundingProjectIds: this.fundingProjectIds,
			funding_manually_data: this.funding_manually_data,
			gross_salary_crypt: this.gross_salary_crypt,
			total_working_days_crypt: this.total_working_days_crypt,
			no_social_insurance: this.no_social_insurance
		};

		return jsonObj;
	}

	clone (): Timesheet {
		const cloneTimesheet = new Timesheet();
		Object.assign(cloneTimesheet, this);

		return cloneTimesheet;
	}

}

export class TimeSheetRow {
	public timesheet: Timesheet;
	public projectId: string;
	public projectName: string;

	public selectedFundingProjectId: string;

	public data: any = {};

	getValue (key: string) {
		if (key === 'name') {
			return this.projectName;
		}

		if (this.timesheet.funding_manually_data[this.projectId] && this.timesheet.funding_manually_data[this.projectId][key]) {
			return (this.timesheet.funding_manually_data[this.projectId][key] || 0) / SECONDS_PER_HOUR;
		}

		if (key === 'SUM') {

			let value = 0;
			for (const sKey of Object.keys(this.data)) {
				value += this.getRawValue(sKey);
				// value += this.data[sKey];
			}
			return value / SECONDS_PER_HOUR;
		}

		return (this.data[key] || 0) / SECONDS_PER_HOUR;
	}

	getRawValue (key: string): number {

		if (this.timesheet.funding_manually_data[this.projectId] && this.timesheet.funding_manually_data[this.projectId][key]) {
			return (this.timesheet.funding_manually_data[this.projectId][key] || 0);
		}

		return (this.data[key] || 0);
	}

	setManuallyData (key: string, value: number) {
		if (value === undefined || value === null) {
			if (this.timesheet.funding_manually_data[this.projectId]) {
				delete this.timesheet.funding_manually_data[this.projectId][key];
			}
		} else {
			if (this.timesheet.funding_manually_data[this.projectId] === undefined) {
				this.timesheet.funding_manually_data[this.projectId] = {};
			}
			this.timesheet.funding_manually_data[this.projectId][key] = value;
		}
	}

	toJSON () {
		const jsonObj = {
			projectId: this.projectId,
			projectName: this.projectName,
			selectedFundingProjectId: this.selectedFundingProjectId
		};

		return jsonObj;
	}

}

export class FundingProject extends Project {
	public baseProjects: Project[] = [];
	public monthDate: Date;
	private _workloadData: any = {};

	public getValue (key: string): number {
		const value = this._workloadData[key];
		if (value === undefined || value === null) {
			this._workloadData[key] = this.calc_value(key);
		}
		return this._workloadData[key];
	}

	private calc_value (key: string) {
		return 0;
	}
}
