import { Component, OnInit, Input, ViewChild, TemplateRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { SubBudget } from 'src/app/model/SubBudget';
import { VIEW_STATE, VIEW_TYPE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { Invoice } from 'src/app/model/Invoice';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { ProjectService } from 'src/app/services/project.service';
import { trackByFn } from 'src/app/model/globalFunctions';

@Component({
	selector: 'app-invoice-list',
	templateUrl: './invoice-list.component.html',
	styleUrls: ['./invoice-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceListComponent implements OnInit {

	@Input() public project: Project;
	@Input() public oSubBudget: SubBudget;
	@Input() public columnsToDisplay: any[];
	@Input() public columnsToDisplayInfo: any[];

	@ViewChild('InvoicesTable', {static: true}) oInvoicesTable: MatTable<any>;
	@ViewChild('deleteDialog', {static: true}) deleteDialog: TemplateRef<any>;

	public expandedElement;

	public VIEW_STATE = VIEW_STATE;
	public VIEW_TYPE = VIEW_TYPE;
	trackByFn = trackByFn;

	public state: VIEW_STATE = VIEW_STATE.ADD;

	selectedInvoice: Invoice;

	public yearString = new Date().getFullYear();

	constructor (
		private _jiraconnector: JiraconnectorService,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private dialog: MatDialog,
		public projectService: ProjectService
	) { }

	ngOnInit () {
		setTimeout(() => {
			this._ChangeDetectorRef.detectChanges();
		}, 100);
	}

	saveInvoicePaid (oInvoice: Invoice) {
		// console.debug(oInvoice);

		this.projectService.saveProject(this.project, () => {
			this.oInvoicesTable.renderRows();
		});
	}

	public copyInvoice (invoice: Invoice): void {

		invoice.stored_values = {};

		const newInvoice = new Invoice();
		Object.assign(newInvoice, invoice);
		newInvoice.invoiceDate = new Date(invoice.invoiceDate.getTime());

		const invoiceDate = newInvoice.invoiceDate;
		const addDays = this.oSubBudget.clearingAddDays;

		const clearingDate = new Date(invoiceDate.getTime() + addDays * 24 * 60 * 60 * 1000);

		newInvoice.clearingDate = clearingDate;

		newInvoice.stored_values = {};

		this.oSubBudget.invoices.push(newInvoice);

		this.oInvoicesTable.renderRows();

		this.state = VIEW_STATE.ADD;

		this.projectService.saveProjectsToDataStore();
	}

	public removeInvoiceFromList (invoice: Invoice): void {
		// console.debug('Delete: ' + invoice);

		this.selectedInvoice = invoice;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				this.oSubBudget.invoices.splice(this.oSubBudget.invoices.findIndex((item) => item.nameString === this.selectedInvoice.nameString), 1);

				const oResult = this._jiraconnector.putProjectProperty(this.project.id, 'p-cockpit-project-data', this.project);
				oResult.subscribe((res: any) => {
					console.log('cockpit-projekt-list: ' + res);
					this.oInvoicesTable.renderRows();

					this.state = VIEW_STATE.ADD;

					this.projectService.saveProjectsToDataStore();

				});

			}

		});

	}

}
