import { StorageItem } from './IStorageItem';

// class for working with local storage in browser (common that can use other classes for store some data)

export class LocalStorageWorker {
	static get instance (): LocalStorageWorker {
		if (this._instance === null) {
			this._instance = new LocalStorageWorker();
		}
		return this._instance;
	}

	constructor () {
		this.localStorageSupported = typeof window.localStorage !== 'undefined' && window.localStorage != null;
	}

	private static _instance: LocalStorageWorker = null;
	localStorageSupported: boolean;

	// set value to storage
	set (key: string, item: string) {
		if (this.localStorageSupported) {
			localStorage.setItem(key, item);
		}
	}

	// get all values from storage (all items)
	getAllItems (): Array<StorageItem> {
		const list = new Array<StorageItem>();

		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			const value = localStorage.getItem(key);

			list.push(new StorageItem({
				key,
				value
			}));
		}

		return list;
	}

	// get only all values from localStorage
	getAllValues (): Array<any> {
		const list = new Array<any>();

		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			const value = localStorage.getItem(key);

			list.push(value);
		}

		return list;
	}

	// get one item by key from storage
	get (key: string): string {
		if (this.localStorageSupported) {
			const item = localStorage.getItem(key);
			return item;
		} else {
			return null;
		}
	}

	// remove value from storage
	remove (key: string) {
		if (this.localStorageSupported) {
			localStorage.removeItem(key);
		}
	}

	// clear storage (remove all items from it)
	clear () {
		if (this.localStorageSupported) {
			localStorage.clear();
		}
	}
}
