import { CLASS_TYPE } from './enums';

export class Settings {
	items: SettingItem[] = [
		Object.assign(new SettingItem(),
			{
				type: CLASS_TYPE.SETTING_ITEM_VIEW_PART,
				id: 'calc_annual_hourly_rate',
				label: 'Ermittlung des Jahresstundensatzes',
				jiraGroups: [],
				jiraUsers: []
			})
	];
}

export class SettingItem {
	type: string;
	id: string;
	label: string;

	jiraGroups: string[] = [];
	jiraUsers: string[] = [];
}
