// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-standard-chip {
  border-radius: 5px;
  min-height: 24px;
  padding: 0;
}

mat-mdc-chip {
  width: 90%;
  font: inherit;
  font-weight: 500;
}

mat-mdc-chip span {
  margin: 0 auto;
}

mat-tab-group {
  width: 100%;
}

body,
.dev-background {
  background-color: rgba(255, 255, 0, 0.2);
}

.add-project {
  float: left;
}

.tab-group-reports {
  top: -3em;
  position: sticky;
}

.connected-user {
  top: -2.5em;
  position: sticky;
  float: right;
  padding: 10px;
}

.connected-user img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-left: -8px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.05);
}

.content-container {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,gBAAA;EACA,UAAA;AACD;;AAEA;EACC,UAAA;EACA,aAAA;EACA,gBAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,WAAA;AACD;;AAIA;;EAEC,wCAAA;AADD;;AAIA;EACC,WAAA;AADD;;AAIA;EACC,SAAA;EACA,gBAAA;AADD;;AAIA;EACC,WAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AADD;;AAIA;EACC,WAAA;EACA,YAAA;EACA,sBAAA;EAGA,iBAAA;EACA,kBAAA;EACA,qCAAA;AAHD;;AAMA;EACC,iBAAA;EACA,sBAAA;EACA,UAAA;AAHD","sourcesContent":[".mat-mdc-standard-chip {\n\tborder-radius: 5px;\n\tmin-height: 24px;\n\tpadding: 0;\n}\n\nmat-mdc-chip {\n\twidth: 90%;\n\tfont: inherit;\n\tfont-weight: 500;\n}\n\nmat-mdc-chip span {\n\tmargin: 0 auto;\n}\n\nmat-tab-group {\n\twidth: 100%;\n\n\t//height: 100%;\n}\n\nbody,\n.dev-background {\n\tbackground-color: rgb(255 255 0 / 0.2);\n}\n\n.add-project {\n\tfloat: left;\n}\n\n.tab-group-reports {\n\ttop: -3em;\n\tposition: sticky;\n}\n\n.connected-user {\n\ttop: -2.5em;\n\tposition: sticky;\n\tfloat: right;\n\tpadding: 10px;\n}\n\n.connected-user img {\n\twidth: 24px;\n\theight: 24px;\n\tvertical-align: middle;\n\n\t//padding-right: 3px;\n\tmargin-left: -8px;\n\tborder-radius: 50%;\n\tborder: 3px solid rgb(0 0 0 / 0.05);\n}\n\n.content-container {\n\tmin-height: 100vh;\n\tbox-sizing: border-box;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
