import { createReducer, on } from '@ngrx/store';
import { InitializedRessourceTables, InitializingRessourceTables, RefreshColumnsRessourceTables, RESSOURCE_TABLE_ACTION,
	ToggleReportModeRessourceTables,
	UpdateProjectCellRessourceTables, UpdateProjectOverviewCellRessourceTables, UserLoadingRessourceTables } from '../actions/ressource-table.actions';

export const ressourcesTableFeatureKey = 'ressourcesTable';
// tslint:disable-next-line
export interface State {
	action: RESSOURCE_TABLE_ACTION;
	projectId: string;
	accountId: string;
}
// tslint:disable-next-line
export const initialState: State = {
	action: RESSOURCE_TABLE_ACTION.NOTHING,
	projectId: undefined,
	accountId: undefined
};

export const reducer = createReducer(
	initialState,

	on(UserLoadingRessourceTables, (state) => ({ ...state, action: RESSOURCE_TABLE_ACTION.USER_LOADING })),
	on(InitializingRessourceTables, (state) => ({ ...state, action: RESSOURCE_TABLE_ACTION.INITIALIZING })),
	on(InitializedRessourceTables, (state) => ({ ...state, action: RESSOURCE_TABLE_ACTION.INITIALIZED })),
	on(UpdateProjectCellRessourceTables, (state, {projectId, accountId}) => ({
		...state,
		action: RESSOURCE_TABLE_ACTION.UPDATE_PROJECT_CELL,
		projectId,
		accountId
	})),
	on(UpdateProjectOverviewCellRessourceTables, (state, {projectId}) => ({
		...state,
		action: RESSOURCE_TABLE_ACTION.UPDATE_PROJECT_OVERVIEW_CELL,
		projectId
	})),
	on(RefreshColumnsRessourceTables, (state) => ({ ...state, action: RESSOURCE_TABLE_ACTION.REFRESH_COLUMNS })),
	on(ToggleReportModeRessourceTables, (state) => ({ ...state, action: RESSOURCE_TABLE_ACTION.TOGGLE_REPORTMODE })),

);
