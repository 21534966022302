import { createAction, props } from '@ngrx/store';
import { RessourceCube } from '../model/RessourceCube';

export enum RESSOURCE_CUBE_ACTION {
	NOTHING = 'NOTHING',
	RELOAD_DATA = 'RELOAD_DATA',
	INITIALIZED = 'INITIALIZED',
}

export const loadRessourceCubes = createAction(
	'[RessourceCube] Load RessourceCubes'
);

export const ReloadDataRessourceCube = createAction(
	'[ReloadDataRessourceCube] Action RessourceRube',
	props<{ startDate: string, endDate: string }>(),
);

export const InitializedRessourceCube = createAction(
	'[InitializedRessourceCube] Action RessourceRube',
	props<{ userSchedules: any, ressourceCube: RessourceCube }>(),
);

