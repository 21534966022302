import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Project } from 'src/app/model/Project';
import { Timesheet, TimeSheetRow } from 'src/app/model/Timesheet';

@Component({
	selector: 'app-timesheet-row-table',
	templateUrl: './timesheet-row-table.component.html',
	styleUrls: ['./timesheet-row-table.component.scss']
})
export class TimesheetRowTableComponent implements OnInit {

	@Input() public timesheet: Timesheet;
	@Input() public timesheetRows: TimeSheetRow[];
	@Input() public fundingProjects: Project[];

	@Input() holidayDates: any = {};

	@Output() public refresh = new EventEmitter<any>();

	constructor () { }

	ngOnInit (): void {
	}

	handleRefreshRow () {
		this.refresh.emit();
	}

	isHolidayClass (timesheet: Timesheet, column: string): boolean {
		try {
			const monthDate = new Date(timesheet.monthDate);
			const day = column.split('day-')[1];
			monthDate.setDate(parseFloat(day));
			const monthDateString = moment(monthDate).format('YYYY_MM_DD');
			if (this.holidayDates[monthDateString] === true) {
				return true;
			}
		} catch (error) {}

		return false;
	}

}
