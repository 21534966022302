import { DATA_TYPE } from './enums';

export class DataDescription {
	uuid: string;
	type: DATA_TYPE;
	id: string;
	row: string;
	column: string;
	body: any;
}
