import { Action, createReducer, on } from '@ngrx/store';
import { InitializedRessourceCube, ReloadDataRessourceCube, RESSOURCE_CUBE_ACTION } from '../actions/ressource-cube.actions';
import { RessourceCube } from '../model/RessourceCube';

export const ressourceCubeFeatureKey = 'ressourceCube';

export interface State {
	action: RESSOURCE_CUBE_ACTION,
	userSchedules: any,
	ressourceCube: RessourceCube,
	startDate: string,
	endDate: string,

}

export const initialState: State = {
	action: RESSOURCE_CUBE_ACTION.NOTHING,
	userSchedules: undefined,
	ressourceCube: undefined,
	startDate: undefined,
	endDate: undefined

};

export const reducer = createReducer(
	initialState,

	on(ReloadDataRessourceCube, (state, {startDate, endDate}) => ({
		...state,
		action: RESSOURCE_CUBE_ACTION.RELOAD_DATA,
		startDate,
		endDate
	})),
	on(InitializedRessourceCube, (state, {userSchedules, ressourceCube}) => ({
		...state,
		action: RESSOURCE_CUBE_ACTION.INITIALIZED,
		userSchedules,
		ressourceCube
	})),

);
