import { Action, createReducer, on } from '@ngrx/store';
import * as JiraApiActions from '../actions/jira-api.actions';
import { User } from '../model/User';

export const jiraApiFeatureKey = 'jiraApi';

export interface UserMap {
	[key: string]: User;
}
export interface State {
	action: JiraApiActions.JIRA_ACTION;
	sessionUser: User;
	users: User[];
	userMap: UserMap;
}

export const initialState: State = {
	action: JiraApiActions.JIRA_ACTION.NOTHING,
	sessionUser: undefined,
	users: [],
	userMap: {}
};

export const reducer = createReducer(
	initialState,

	on(JiraApiActions.GetSessionUser, (state) => ({ ...state, action: JiraApiActions.JIRA_ACTION.GET_SESSION_USER })),
	on(JiraApiActions.SetSessionUser, (state, { sessionUser }) =>
		({ ...state, action: JiraApiActions.JIRA_ACTION.SET_SESSION_USER, sessionUser })),
	on(JiraApiActions.GetUsers, (state) => ({ ...state, action: JiraApiActions.JIRA_ACTION.GET_USERS })),
	on(JiraApiActions.SetUsers, (state, { users }) => {
		const userMap: UserMap = {};
		users.forEach((user) => {
			userMap[user.accountId] = user;
		});
		return { ...state, action: JiraApiActions.JIRA_ACTION.SET_USERS, users, userMap };
	}),

);

