// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  margin-right: 4px;
}

.mainContainer {
  clear: both;
  padding-left: 1em;
  padding-right: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/report-generator-issue-project-tree/report-generator-issue-project-tree.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AAEE;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".mat-mdc-form-field {\n\tmargin-right: 4px;\n  }\n\n  .mainContainer {\n    clear: both;\n    padding-left: 1em;\n    padding-right: 1em;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
