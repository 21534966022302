// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width {
  width: 350px;
}

td.mat-cell:last-of-type {
  padding-right: 0;
}

.width-funding-number,
.width-funding-number {
  margin-left: 1em;
  padding-left: 1em;
  width: 12em;
}

.width-funding-title {
  margin-left: 1em;
  padding-left: 1em;
  width: 50em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/project-group-input/project-group-input.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;;EAEC,gBAAA;EACA,iBAAA;EACA,WAAA;AACD;;AAEA;EACC,gBAAA;EACA,iBAAA;EACA,WAAA;AACD","sourcesContent":[".full-width {\n\twidth: 350px;\n}\n\ntd.mat-cell:last-of-type {\n\tpadding-right: 0;\n}\n\n.width-funding-number,\n.width-funding-number {\n\tmargin-left: 1em;\n\tpadding-left: 1em;\n\twidth: 12em;\n}\n\n.width-funding-title {\n\tmargin-left: 1em;\n\tpadding-left: 1em;\n\twidth: 50em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
