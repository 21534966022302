import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trackByFn } from 'src/app/model/globalFunctions';
import { ProjectGroup } from 'src/app/model/ProjectGroup';

@Component({
	selector: 'app-requirement-analysis-project-group-selector',
	templateUrl: './requirement-analysis-project-group-selector.component.html',
	styleUrls: ['./requirement-analysis-project-group-selector.component.scss']
})
export class RequirementAnalysisProjectGroupSelectorComponent implements OnInit {

	@Input() projectGroup: ProjectGroup;
	@Input() projectGroups: ProjectGroup[];

	@Output() refresh = new EventEmitter<ProjectGroup>();

	trackByFn = trackByFn;

	constructor () { }

	ngOnInit (): void {
	}

	handleChange (selectedProjectGroup: ProjectGroup) {
		this.refresh.emit(selectedProjectGroup);
	}

}
