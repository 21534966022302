import { Inject, Injectable, InjectionToken } from '@angular/core';
// import { Http } from '@angular/common';
// import { Observable } from 'rxjs/Rx';
import { Project } from '../model/Project';
import { ProjectShort } from '../model/ProjectShort';
import { LiqudityTableRow } from '../model/LiqudityTableRow';
import { environment } from '../../environments/environment';
import { AP$ } from 'src/polyfills';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Timesheet } from '../model/Timesheet';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { JIRA_ACTION, SetSessionUser, SetUsers } from '../actions/jira-api.actions';
import { User } from '../model/User';
import { SocketMessage } from 'socket-io-server/src/model/model';
import { CAPABILITY_TYPE, CAPABILITY_CATEGORY, PROJECT_TYPE } from '../model/enums';
import { CAPABILITY_ACTION, GetCapabilities, SetCapabilities } from '../actions/capability-service.actions';
import { Capability } from '../model/Capability';
import { compressToUTF16, decompressFromUTF16 } from 'async-lz-string';
import { compress, decompress } from 'lzutf8';
import { values } from 'sequelize/types/lib/operators';
import { RequirementAnalysisLoaded, RequirementAnalysisSaved, REQUIREMENT_ANALYSIS_ACTION } from '../actions/requirement-analysis.actions';
import { RequirementAnalysis } from '../model/RequirementAnalysis';
import { ProjectGroup } from '../model/ProjectGroup';
import { ProjectService } from '../services/project.service';
import { PROJECT_ACTION } from '../actions/project-service.actions';
import { JiraReportGenerator } from '../model/JiraReportGenerator';

// var jwt = require('atlassian-jwt');

export interface IProjectPartial {
	id?: string | number;
	key?: string;
	name?: string;
}
export interface IIssuePriority {
	id?: string | number;
	name?: string;
}
export interface IIssueType {
	description?: string;
	id?: string | number;
	name?: string;
	subtask?: boolean;
}
export interface IIssueFields {
	components?: any[];
	customfields?: object;
	description?: string;
	duedate?: any;
	issuetype: IIssueType;
	labels?: any[];
	priority?: IIssuePriority;
	project: IProjectPartial;
	summary: string;
}

export const JIRA_URL = new InjectionToken<string>('JiraUrl');
export const JIRA_AUTH_URL = new InjectionToken<string>('JiraUrl');

@Injectable()
export class JiraconnectorService {

	private reduxSubscription: Subscription = new Subscription();

	public headers: Headers = new Headers({ 'Content-type': 'application/json', 'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, HEAD, OPTIONS',
		'X-HTTP-Method-Override': 'GET, POST, PUT, DELETE, HEAD, OPTIONS' });

	private prefix: string = environment.production ? '' : 'dev-';

	public jwtToken = '';

	public tempoOauthToken = '';

	private projects: Project[] = [];

	constructor (
		private store: Store<AppState>,
		private _http: HttpClient,
		@Inject(JIRA_URL) public jiraUrl: string,
		@Inject(JIRA_AUTH_URL) public jiraAuthUrl: string
	) {
		// this.setBasicAuth();

		this.reduxSubscription.add(this.store.select((state: AppState) => state.jiraApi).subscribe((state) => {
			switch (state.action) {
				case JIRA_ACTION.GET_USERS:

					const subscription = this.getUsersByGroupname('jira-developers').subscribe((result) => {
						subscription.unsubscribe();

						const users: User[] = [];

						console.log('userResults: ' + JSON.stringify(result));

						if (result.values) {
							for (const jsonUser of result.values) {
								const user = new User();
								user.displayName = jsonUser.displayName;
								user.avatarUrl = jsonUser.avatarUrls['48x48'];
								user.accountId = jsonUser.accountId;
								users.push(user);
							}

							this.store.dispatch(SetUsers({ users }));

						}
					});

					break;

				case JIRA_ACTION.GET_SESSION_USER:
					this.getSessionUser().subscribe((actUser) => {
						actUser.userAgent = window.navigator.userAgent;

						const user = new User();
						user.timestamp = actUser.timestamp;
						user.userAgent = actUser.userAgent;
						user.accountId = actUser.accountId;
						if (actUser.avatarUrls) {
							user.avatarUrl = actUser.avatarUrls['48x48'];
						}
						user.displayName = actUser.displayName;

						const jiraGroups: string[] = [];
						for (const jiraGroup of actUser.groups.items) {
							jiraGroups.push(jiraGroup.name);
						}
						user.jiraGroups = jiraGroups;
						this.store.dispatch(SetSessionUser({ sessionUser: user }));

					});

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.capabilityService).subscribe((state) => {
			switch (state.action) {
				case CAPABILITY_ACTION.GET_CAPABILITIES:

					const oResult = this.getCockpitCapabilities();
					oResult.subscribe((json: any) => {

						if (json.value) {

							const capabilities: Capability[] = [];

							for (const item of json.value) {

								const oCapability: Capability = new Capability();
								Object.assign(oCapability, {
									name: item.name,
									description: item.description,
									type: item.type,
									category: item.category,
									group: item.group,

									relatedProjectIds: item.relatedProjectIds ?? [],
									relatedAccountIdsAndSkillLevel: item.relatedAccountIdsAndSkillLevel ?? []
								});

								capabilities.push(oCapability);
							}

							this.store.dispatch(SetCapabilities({ capabilities }));
						}

					}, (error) => {
						const capabilities: Capability[] = [];

						const oTestCapability: Capability = new Capability();
						Object.assign(oTestCapability, {
							name: 'item.name fgdfgdf dsfsdfdf sdf',
							description: 'item.description',
							type: CAPABILITY_TYPE.TECHNOLOGY,
							category: CAPABILITY_CATEGORY.SOFTWARE,
							group: 'item.group',

							relatedProjectIds: ['CME'],
							relatedAccountIdsAndSkillLevel: []
						});

						capabilities.push(oTestCapability);

						this.store.dispatch(SetCapabilities({ capabilities }));

					});

					break;

				case CAPABILITY_ACTION.SAVE_CAPABILITIES:

					const capabilities = [...state.capabilities];

					const oSaveResult = this.putCockpitCapabilities( capabilities );
					oSaveResult.subscribe((json: any) => {
						this.store.dispatch(SetCapabilities({ capabilities }));
					});

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.projectService).subscribe((state) => {
			switch (state.action) {
				case PROJECT_ACTION.SET_PROJECTS:

					let hasShortProjects = false;
					for (const project of state.projects) {
						if (project.type === PROJECT_TYPE.SHORT) {
							hasShortProjects = true;
							break;
						}
						for (const subProject of project.subProjects) {
							if (subProject.type === PROJECT_TYPE.SHORT) {
								hasShortProjects = true;
								break;
							}
						}
					}

					if (!hasShortProjects) {
						this.projects = state.projects;
					}

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.requirementAnalysis).subscribe((state) => {
			switch (state.action) {
				case REQUIREMENT_ANALYSIS_ACTION.GET_REQUIREMENT_ANALYSIS:

					const oResult = this.getRequirementAnalysis(this.prefix, state.suffix);
					oResult.subscribe((json: any) => {

						if (json.value) {

							console.log('REQUIREMENT_ANALYSIS_ACTION.GET_REQUIREMENT_ANALYSIS');
							console.log('RESPONSE: ' + JSON.stringify(json));

							const oRequirementAnalysis: RequirementAnalysis = new RequirementAnalysis();
							Object.assign(oRequirementAnalysis, json.value);
							const projectGroups: ProjectGroup[] = [];
							if (json.value.projectGroups) {
								for (const projectGroup of json.value.projectGroups) {
									const oProjectGroup = new ProjectGroup();
									oProjectGroup.requirementAnalysis = oRequirementAnalysis;
									Object.assign(oProjectGroup, projectGroup);

									projectGroups.push(oProjectGroup);

									if (projectGroup.name !== 'nicht zugeordnet') {
										const projectGroupProjects: Project[] = [];
										for (const projectId of projectGroup.projectIds) {
											let project = this.projects.find((item) => item.id === projectId);
											if (project === undefined) {
												project = new Project();
												project.id = projectId;
												project.name = projectId;
												project.avatar = 'https://projektionisten.atlassian.net/secure/projectavatar?size=small&s=small&pid=15412&avatarId=14419';
											}
											projectGroupProjects.push(project);

											oRequirementAnalysis.projectGroupByProjectId[project.id] = oProjectGroup;

										}
										oProjectGroup.projects = projectGroupProjects;

										oProjectGroup.projectGroups = [];
										if (projectGroup.projectGroups && projectGroup.projectGroups.length > 0) {
											for (const subProjectGroup of projectGroup.projectGroups) {

												const oSubProjectGroup = new ProjectGroup();
												oSubProjectGroup.parentProjectGroup = oProjectGroup;
												oSubProjectGroup.requirementAnalysis = oRequirementAnalysis;
												Object.assign(oSubProjectGroup, subProjectGroup);

												oProjectGroup.projectGroups.push(oSubProjectGroup);

												const subProjectGroupProjects: Project[] = [];

												for (const projectId of subProjectGroup.projectIds) {
													let project = this.projects.find((item) => item.id === projectId);
													if (project === undefined) {
														project = new Project();
														project.id = projectId;
														project.name = projectId;
														project.avatar = 'https://projektionisten.atlassian.net/secure/projectavatar?size=small&s=small&pid=15412&avatarId=14419';
													}
													subProjectGroupProjects.push(project);

													oRequirementAnalysis.projectGroupByProjectId[project.id] = oSubProjectGroup;

												}
												oSubProjectGroup.projects = subProjectGroupProjects;
											}

										}

									}
								}
							}
							oRequirementAnalysis.projectGroups = projectGroups;

							this.store.dispatch(RequirementAnalysisLoaded({ requirementAnalysis: oRequirementAnalysis }));
						}

					}, (error) => {

						this.store.dispatch(RequirementAnalysisLoaded({ requirementAnalysis: undefined }));

					});

					break;

				case REQUIREMENT_ANALYSIS_ACTION.SAVE_REQUIREMENT_ANALYSIS:

					this.putRequirementAnalysis(state.requirementAnalysis, this.prefix, state.suffix).subscribe((json: any) => {

						this.store.dispatch(RequirementAnalysisSaved({ requirementAnalysis: state.requirementAnalysis }));

					}, (error) => {

						this.store.dispatch(RequirementAnalysisSaved({ requirementAnalysis: state.requirementAnalysis }));

					});

					break;

				default:
					break;
			}

		}));

	}

	private setBasicAuth () {
		this.setBasicAuthorization(environment.hosts['https://projektionisten.atlassian.net'].username,
			environment.hosts['https://projektionisten.atlassian.net'].password);
	}

	public setBasicAuthorization (username: string, password: string): void {
		this.headers.delete('Authorization');
		this.headers.append('Authorization', 'Basic ' + window.btoa(`${username}:${password}`));
	}

	public createComment (issueId: string, comment: string): Observable<any> {
		return this._post(`${this.jiraUrl}issue/${issueId}/comment`, JSON.stringify({ body: comment }));
	}

	public createIssue (fields: IIssueFields): Observable<any> {
		if (fields.customfields) {
			this.mapCustomfields(fields);
		}
		return this._post(`${this.jiraUrl}issue`, JSON.stringify({ fields }));
	}

	public editIssue (issueId: string, fields: any): Observable<any> {
		if (fields.customfields) {
			this.mapCustomfields(fields);
		}
		return this._put(`${this.jiraUrl}issue/${issueId}`, JSON.stringify({ fields }));
	}

	public getIssue (issueId: string): Observable<any> {
		return this._get(`${this.jiraUrl}issue/${issueId}`);
	}

	/*
	public searchIssues (jqlString: string): Observable<any> {
		return this._post(`${this.jiraUrl}search`, { jql: jqlString });
	}
	*/
	
	public searchIssues (query: string = ''): Observable<any> {
		return this._get(`${this.jiraUrl}issue/picker?query=${query}`);
	}

	public searchProjects (querySuffix: string): Observable<any> {
		return this._get(`${this.jiraUrl}project/search?p-cockpit=true${querySuffix}`);
	}

	public searchGroups (query: string = ''): Observable<any> {
		return this._get(`${this.jiraUrl}groups/picker?query=${query}`);
	}

	public getUsersByGroupname (groupname: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		// 		return this._get(`${this.jiraUrl}users/search`, isJWT);
		return this._getAll(`${this.jiraUrl}group/member?groupname=${groupname}`, isJWT);
	}

	public getProjectProperty (projectIdOrKey: string, propertyKey: string,
		prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this._get(`${this.jiraUrl}project/${projectIdOrKey}/properties/${prefix}${propertyKey}`, isJWT, true);
	}

	public getProject (projectIdOrKey: string,
		prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this._get(`${this.jiraUrl}project/${projectIdOrKey}`, isJWT);
	}

	public putProjectProperty (projectIdOrKey: string, propertyKey: string,
		propertyData: any, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this._put(`${this.jiraUrl}project/${projectIdOrKey}/properties/${prefix}${propertyKey}`,
			JSON.stringify(propertyData), isJWT, true);
	}

	public getProjectComponents (projectIdOrKey: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this._get(`${this.jiraUrl}project/${projectIdOrKey}/components`, isJWT);
	}

	public getSearchIssueKeys (jql: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		const actJqlQueryObject: any = {
			jql: '',
			fields: [
				'key'
			],
			validateQuery: 'warn',
			maxResults: 10000
		};
		return this.getSearch(jql, actJqlQueryObject, prefix, isJWT);
	}

	public getSearch (jql: string, jqlQueryObject: any = null , prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		let actJqlQueryObject: any = {
			jql: '',
			fields: [
				'summary',
				'issuetype',
				'avatar',
				'parent',
				'project'
			],
			validateQuery: 'warn',
			maxResults: 1000
		};
		if (jqlQueryObject !== null) {
			actJqlQueryObject = jqlQueryObject;
		}
		actJqlQueryObject.jql = jql;
		return this._post(`${this.jiraUrl}search`, JSON.stringify(actJqlQueryObject), isJWT);
	}

	public getSearchAll (jql: string, jqlQueryObject: any = null , prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		let actJqlQueryObject: any = {
			jql: '',
			fields: [
				'summary',
				'issuetype',
				'avatar',
				'parent',
				'project'
			],
			validateQuery: 'warn',
			startAt: 0,
			maxResults: 1000
		};
		if (jqlQueryObject !== null) {
			actJqlQueryObject = jqlQueryObject;
		}
		actJqlQueryObject.jql = jql;

		if (actJqlQueryObject.jql === '') {
			return of({});
		}

		return this._postAll(`${this.jiraUrl}search`, actJqlQueryObject, 'issues', isJWT);
	}

	public getUserProperty (accountId: string, propertyKey: string,
		prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this._get(`${this.jiraUrl}user/properties/${prefix}${propertyKey}?accountId=${accountId}`, isJWT);
	}

	public putUserProperty (accountId: string, propertyKey: string,
		propertyData: any, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this._put(`${this.jiraUrl}user/properties/${prefix}${propertyKey}?accountId=${accountId}`,
			JSON.stringify(propertyData), isJWT);
	}

	public getUserTimeSheet (accountId: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this.getUserProperty(accountId, 'p-cockpit-time-sheet', prefix, isJWT);
	}

	public putUserTimeSheet (accountId: string, timeSheet: Timesheet, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this.putUserProperty(accountId, 'p-cockpit-time-sheet', timeSheet, prefix, isJWT);
	}

	public getCockpitProjectList (prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.getPCockpitProperty('p-cockpit-project-list', prefix);
		// 		return this.getGlobalProperty('p-cockpit-project-list', prefix, isJWT);
	}

	public putCockpitProjectList (projectList: Project[] | ProjectShort[],
		prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.putPCockpitProperty('p-cockpit-project-list', projectList, prefix);
		// 		return this.putGlobalProperty('p-cockpit-project-list', projectList, prefix, isJWT);
	}

	public getCockpitLiqidityTable (year: string, prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.getPCockpitProperty('p-cockpit-liqidity-table-' + year, prefix);
		// 		return this.getGlobalProperty(`p-cockpit-liqidity-table-` + year, prefix, isJWT);
	}

	public putCockpitLiqidityTable (year: string, liqudityTable: LiqudityTableRow[],
		prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.putPCockpitProperty('p-cockpit-liqidity-table-' + year, liqudityTable, prefix);
		// 		return this.putGlobalProperty(`p-cockpit-liqidity-table-` + year, liqudityTable, prefix, isJWT);
	}

	public getCockpitCapabilities (prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.getPCockpitProperty('p-cockpit-capabilities', prefix);
	}

	public putCockpitCapabilities (capabilities: Capability[],
		prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.putPCockpitProperty('p-cockpit-capabilities', capabilities, prefix);
	}

	public getCockpitReportGeneratorList (prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.getPCockpitProperty('p-cockpit-report-generator-list', prefix);
	}

	public putCockpitReportGeneratorList (reports: JiraReportGenerator[],
		prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
		return this.putPCockpitProperty('p-cockpit-report-generator-list', reports, prefix);
	}

	public getRequirementAnalysis (prefix: string = this.prefix, suffix: string = '', isJWT: boolean = false): Observable<RequirementAnalysis> {
		return this.getPCockpitProperty('p-cockpit-requirement-analysis' + suffix, prefix);
	}

	public putRequirementAnalysis (requirementAnalysis: RequirementAnalysis,
		prefix: string = this.prefix, suffix: string = '', isJWT: boolean = false): Observable<any> {
		return this.putPCockpitProperty('p-cockpit-requirement-analysis' + suffix, requirementAnalysis, prefix);
	}

	public getPCockpitProperty (propertyKey: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this.getProjectProperty('PCOCKPIT', propertyKey, prefix, isJWT);
	}

	public putPCockpitProperty (propertyKey: string, propertyValue: any,
		prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
		return this.putProjectProperty('PCOCKPIT', propertyKey, propertyValue, prefix, isJWT);
	}

	// https://your-domain.atlassian.net/jira/rest/atlassian-connect/1/addon/example.app.key/properties/propertyKey

	public getSessionUser (): Observable<any> {

		const _self = this;
		AP$.context.getToken((token) => {
			console.log('JWT token string', token);
			_self.jwtToken = token;
		});

		return this._get(`${this.jiraUrl}myself?expand=groups`);
	}

	public mapCustomfields (fields: IIssueFields): void {
		// eslint-disable-next-line guard-for-in
		for (const key in fields.customfields) {
			fields[key] = fields.customfields[key];
		}

		delete fields.customfields;
	}

	public handleError (error: any): Observable<string> {
		return throwError(error.message || error);
	}

	getUrlWithSuffix (url: string, cryptSuffix: string = '-crypt') {
		let backUrl = url;
		if (backUrl?.indexOf('?') !== -1) {
			backUrl = backUrl?.replaceAll('?', cryptSuffix + '?');
		} else {
			backUrl = backUrl + cryptSuffix;
		}
		return backUrl;
	}

	private _getAll (url: string, isJWT: boolean = false, responseAll: any = null): Observable<any> {

		const _self = this;

		if (responseAll === null) {
			responseAll = {
				total: 0,
				values: []
			};
		}

		const observable = new Observable<any>((observer) => {
			this._get(url, isJWT).subscribe((response) => {
				if (response.isLast !== false) {
					responseAll.total = response.total;
					responseAll.values = [...responseAll.values, ...response.values];

					observer.next(responseAll);
					observer.complete();
				} else {
					responseAll.total = response.total;
					responseAll.values = [...responseAll.values, ...response.values];

					_self._getAll(url + '&startAt=' + (response.startAt + response.maxResults), isJWT,
						responseAll).subscribe({ 
							next: (resAll: any) => {
								observer.next(responseAll);
							}, 
							error: (error) => {
								observer.error(error);
							}
						});
				}
			});

		});

		return observable;
	}

	private _get (url: string, isJWT: boolean = false, crypt: boolean = false): Observable<any> {
		if (crypt) {
			const observable = new Observable<any>((observer) => {
				this._internal_get(url, isJWT, crypt).subscribe((response) => {
					observer.next(response);
					observer.complete();
				}, (error) => {
					this._internal_get(url, isJWT).subscribe((response) => {
						observer.next(response);
						observer.complete();
					}, (error) => {
						observer.error(error);
						observer.complete();
					});
				});
			});

			return observable;
		} else {
			return this._internal_get(url, isJWT);
		}

	}

	private _internal_get (url: string, isJWT: boolean = false, crypt: boolean = false): Observable<any> {

		if (crypt) {
			url = this.getUrlWithSuffix(url);
		}

		const observable = new Observable<any>((observer) => {
			AP$.request({ url, type: 'GET', cache: false, contentType: 'application/json' })
				.then((data) => {

					const body = JSON.parse((data as any).body);

					if (body.value === undefined || (body.value && body.value.position === undefined)) {
						observer.next(data);
						observer.complete();
					} else {
						let cryptPart = false;

						const parts: string[] = [];

						parts[0] = body.value.value;

						cryptPart = (body.value.crypt === true);

						const length = body.value.length;

						let receivedParts = 1;

						const partRequests = [];
						for (let iCount = 1; iCount < length; iCount++) {
							const suffix = ((iCount === 0) ? '' : '-part' + iCount);

							const partRequest = AP$.request({ url: this.getUrlWithSuffix(url, suffix), type: 'GET', cache: false, contentType: 'application/json' });
							partRequests.push(partRequest);

						}

						const doResponseRealJson = () => {
							const concatJson = parts.join('');
							console.log('parts: ' + concatJson);

							const decompressedConcatJson = (cryptPart) ? decompress(concatJson, { inputEncoding: 'Base64' }) : atob(concatJson);

							try {
								const realJson = JSON.parse(decompressedConcatJson);

								observer.next({ body: JSON.stringify({ value: realJson }) });
								observer.complete();
							} catch (error) {
								console.log('parts: ' + parts);

								observer.error(error);
								observer.complete();

							}
						};

						if (partRequests.length === 0) {
							doResponseRealJson();
						} else {
							forkJoin( partRequests ).subscribe((responses) => {
								for (const response of responses) {
									const partBody = JSON.parse((response as any).body);

									parts[partBody.value.position] = partBody.value.value;

									if (cryptPart !== true) {
										cryptPart = (partBody.value.crypt === true);
									}

									receivedParts = receivedParts + 1;
								}

								if (receivedParts === parts.length) {
									doResponseRealJson();
								}

							}, (error) => {
								doResponseRealJson();
							});

						}

						/*

            const doRequest = (url: string) => {
              AP$.request({ url, type: 'GET', cache: false, contentType: 'application/json', })
                .then((partData) => {
                  const partBody = JSON.parse((partData as any).body);

                  parts[partBody.value.position] = partBody.value.value;

                  receivedParts = receivedParts + 1;
                  if (receivedParts === parts.length) {
                    const concatJson = parts.join('');
                    console.log('parts: ' + concatJson);
                    const realJson = JSON.parse(atob(concatJson));

                    try {
                      observer.next({ body: JSON.stringify({ value: realJson }) });
                      observer.complete();
                    } catch (error) {
                      console.log('parts: ' + parts);
                    }
                  }
                })
                .catch((error) => {
                  console.log('url + suffix: ' + url);
                  console.log('error: ' + error);

                  doRequest(url);
                  // observer.error(error);
                });

            };

            for (let iCount = 1; iCount < length; iCount++) {
              const suffix = ((iCount === 0) ? '' : '-part' + iCount);

              setTimeout(() => {
                doRequest(url + suffix);
              }, 150);

            }
*/

					}

				})
				.catch((error) => {
					observer.error(error);
				});
		})
			.pipe(
				map((data) => JSON.parse((data as any).body)),
				catchError((error) => this.handleError(error))
			);

		return observable;
	}

	private _put (url: string, body: any, isJWT: boolean = false, crypt: boolean = false): Observable<any> {

		let bodyLength = 20000;

		if (crypt) {
			bodyLength = 25000;
			url = this.getUrlWithSuffix(url);
		}

		console.log('COUNT: ' + url + ' -> ' + (body ? body.length : -1));

		if (body.length > bodyLength) {
			/*
      const parts = this.jsonToParts(body, bodyLength);
      console.log(JSON.stringify(parts));

      const compressedParts = this.compressedJsonToParts(body, bodyLength);
      console.log('compressedParts: ' + JSON.stringify(compressedParts));
      */

			const parts = (crypt) ? this.compressedJsonToParts(body, bodyLength) : this.jsonToParts(body, bodyLength);
			console.log('crypt = ' + crypt + ' parts: ' + JSON.stringify(parts));

			const observable = new Observable<any>((observer) => {
				let submittedParts = 0;

				let pos = 0;
				for (const part of parts) {
					const partObject = {
						crypt,
						position: pos,
						length: parts.length,
						value: part
					};

					const suffix = ((pos === 0) ? '' : '-part' + pos);

					this._interal_put(this.getUrlWithSuffix(url, suffix), JSON.stringify(partObject), isJWT).subscribe((res) => {
						submittedParts = submittedParts + 1;
						if (submittedParts === parts.length) {
							observer.next();
							observer.complete();
						}
					}, (error) => {
						observer.error(error);
						observer.complete();
					});

					pos = pos + 1;

				}
			});

			return observable;
			/*
      const concatJson = parts.join('');
      const realJson = atob(concatJson);

      console.log('CONCATED JSON: ' + realJson);
      */
		} else {
			return this._interal_put(url, body, isJWT);
		}

	}

	private _interal_put (url: string, body: any, isJWT: boolean = false): Observable<any> {

		const observable = new Observable<any>((observer) => {
			AP$.request({ url, type: 'PUT', contentType: 'application/json', data: body })
				.then(() => {
					observer.next();
					observer.complete();
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		})
			.pipe(
				catchError((error) => this.handleError(error))
			);

		return observable;

	}

	private _post (url: string, body: any, isJWT: boolean = false): Observable<any> {

		const observable = new Observable<any>((observer) => {
			AP$.request({ url, type: 'POST', contentType: 'application/json', data: body })
				.then((data) => {
					observer.next(JSON.parse(data.body));
					observer.complete();
				})
				.catch((error) => {
					observer.error(error);
					observer.complete();
				});
		})
			.pipe(
				catchError((error) => this.handleError(error))
			);

		return observable;

	}

	private _postAll (url: string, body: any, responseKey: string = 'issues', isJWT: boolean = false, responseAll: any = null): Observable<any> {

		const _self = this;

		if (responseAll === null) {
			responseAll = {
				startAt: 0,
    			maxResults: 0,
				total: 0
			};
			responseAll[responseKey] = [];
		}

		const observable = new Observable<any>((observer) => {
			this._post(url, JSON.stringify(body), isJWT).subscribe((response) => {
				console.log('_postAll: ' + (response.startAt + response.maxResults));
				if (response.startAt + response.maxResults >= response.total ||
					response[responseKey].length === 0) {
					responseAll.total = response.total;
					responseAll.startAt = (response.startAt ?? 0);
					responseAll[responseKey] = [...responseAll[responseKey], ...response[responseKey]];

					observer.next(responseAll);
					observer.complete();
				} else {
					responseAll.total = response.total;
					responseAll[responseKey] = [...responseAll[responseKey], ...response[responseKey]];

					body.startAt = (responseAll.startAt ?? 0) + response.maxResults;
					responseAll.startAt = (responseAll.startAt ?? 0) + response.maxResults;

					if (responseAll[responseKey].length < responseAll.total) {
						_self._postAll(url, body, responseKey, isJWT,
							responseAll).subscribe({ 
								next: (resAll: any) => {
									observer.next(responseAll);
									observer.complete();
								}, 
								error: (error) => {
									observer.error(error);
								}
							});
	
					} else {
						observer.next(responseAll);
						observer.complete();
					}

				}
			});

		});

		return observable;
	}


	private jsonToParts (json: string, length: number = 20000): string[] {
		const parts: string[] = [];

		/*
    function en(c){let x = 'charCodeAt', b, e = {}, f = c.split(''), d = [], a = f[0], g = 256; for (b = 1; b < f.length; b++) {c = f[b], null != e[a + c] ? a += c : (d.push(1 < a.length ? e[a] : a[x](0)), e[a + c] = g, g++, a = c); }d.push(1 < a.length ? e[a] : a[x](0)); for (b = 0; b < d.length; b++) {d[b] = String.fromCharCode(d[b]); }return d.join(''); }

    function de(b){let a, e = {}, d = b.split(''), f, c = f = d[0], g = [c], o, h = o = 256; for (b = 1; b < d.length; b++) {a = d[b].charCodeAt(0), a = h > a ? d[b] : e[a] ? e[a] : f + c, g.push(a), c = a.charAt(0), e[o] = f + c, o++, f = a; }return g.join(''); }
    */

		const jsonBase64 = btoa(json);

		/*
    try {
      const compressedJson = en(json);
      const compressedJsonBase64 = btoa(compressedJson);

      const decompressed = de(compressedJson);

      console.log('compressedJsonBase64.length: ' + compressedJsonBase64.length + ' --> jsonBase64.length: ' + jsonBase64.length);
      console.log('compressed.length: ' + compressedJson.length + ' --> decompressed.length: ' + decompressed.length);

      console.log(JSON.parse(decompressed));
    } catch (error) {

    }

    try {

      const jsonpack = require('jsonpack/main');

      console.log('PACKED: BEGIN');

      const packed = jsonpack.pack(json);

      console.log('PACKED: ' + packed);

      const compressedPackedBase64 = btoa(packed);

      console.log('compressedJsonBase64.length: ' + compressedPackedBase64.length +
        ' --> compressedPackedBase64.length: ' + compressedPackedBase64.length);

      console.log('json.length: ' + json.length);

      const lzUTF8 = compress(json, { outputEncoding: 'Base64' });

      console.log('lzUTF8.length: ' + lzUTF8.length + ' --> json.length: ' + json.length);

      const decompressLzUTF8 = decompress(lzUTF8, { inputEncoding: 'Base64' });

      console.log('decompressLzUTF8.length: ' + decompressLzUTF8.length);

    } catch (error) {
      console.log('PACKED: ' + error);

    }
    */

		let position = 0;
		for (let iCount = 0; iCount < jsonBase64.length; iCount = iCount + length) {
			parts.push(jsonBase64.substr(position, length));
			position += length;
		}

		return parts;
	}

	private compressedJsonToParts (json: string, length: number = 20000): string[] {
		const parts: string[] = [];

		const compressedJsonBase64 = compress(json, { outputEncoding: 'Base64' });

		try {

			const lzUTF8 = compressedJsonBase64;

			console.log('lzUTF8.length: ' + lzUTF8.length + ' --> json.length: ' + json.length);

			const decompressLzUTF8 = decompress(lzUTF8, { inputEncoding: 'Base64' });

			console.log('decompressLzUTF8.length: ' + decompressLzUTF8.length);

		} catch (error) {
			console.log('PACKED: ' + error);

		}

		let position = 0;
		for (let iCount = 0; iCount < compressedJsonBase64.length; iCount = iCount + length) {
			parts.push(compressedJsonBase64.substr(position, length));
			position += length;
		}

		return parts;
	}

	/*
    private  old_get(url: string, isJWT: boolean = false): Observable<any> {
      if (isJWT) {
        this.setJWTHeader('GET', url);

        //url = 'https://projektionisten.atlassian.net' + url;
        //this.headers.set('Referrer', 'https://projektionisten.atlassian.net/plugins/servlet/' +
        'ac/dev-p-ressources-dev-addon/dev-p-ressources-jira?project.key=DSW&project.id=14900');

      } else {
        this.setBasicAuth();
      }
      return this._http.get(url, { headers: this.headers, withCredentials: true })
        .map((response) => {
          const json = response.json();
          return json;
        })
        .catch((error) => this.handleError(error));
    }

    private old_post(url: string, body: any, isJWT: boolean = false): Observable<any> {
      if (isJWT) {
        this.setJWTHeader('POST', url);
      } else {
        this.setBasicAuth();
      }
      return this._http.post(url, body, { headers: this.headers, withCredentials: true })
        .map((response) => response.json())
        .catch((error) => this.handleError(error));
    }

    private old_put(url: string, body: any, isJWT: boolean = false): Observable<string> {
      if (isJWT) {
        this.setJWTHeader('PUT', url);
      } else {
        this.setBasicAuth();
      }
      return this._http.put(url, body, { headers: this.headers, withCredentials: true })
        .map((response) => { try { return response.json() } catch (e) {} return null; })
        .catch((error) => this.handleError(error));
    }
  */
	/*
    setJWTHeader(method: string, url: string) {
      const token = this.getJWTToken(method, url);
      this.headers.delete('Authorization');
      this.headers.append('Authorization', 'JWT ' + token);
    }

    getJWTToken(method: string, url: string): string {
      const now = moment().utc();

      // Simple form of [request](https://npmjs.com/package/request) object
      const req = jwt.fromMethodAndUrl(method, url);

      const tokenData = {
        "iss": 'dev-p-ressources-dev-addon',
        "iat": now.unix(),                    // The time the token is generated
        "exp": now.add(3, 'minutes').unix(),  // Token expiry time (recommend 3 minutes after issuing)
        "qsh": jwt.createQueryStringHash(req) // [Query String Hash](https://developer.atlassian.com/' +
        'cloud/jira/platform/understanding-jwt/#a-name-qsh-a-creating-a-query-string-hash)
      };

      const secret = '5oG5SLbGS/221GrbKLw1gSP+vHeaxcrBzxqmoPPdd/XzBceA4m+YlB9a0kFQ8GTA9pmv/cu0S8VoRRvHWT/NdQ';

      const token = jwt.encode(tokenData, secret);

      return token;
    }
  */
}
