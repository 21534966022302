export function trackByFn (index: any, item: any): any {
	if (item) {
		if (typeof(item) === 'string') {
			return index + '_' + item;
		} else if (typeof(item) === 'number') {
			return index + '_' + item;
		} else if ('key' in item) {
			return index + '_' + item.key;
		} else if ('id' in item) {
			return index + '_' + item.id;
		}	
	}
	return index;
}

export function round (num: number, decimalPlaces: number): number {
	const p = Math.pow(10, decimalPlaces);
	const e = Number.EPSILON * num * p;
	return Math.round((num * p) + e) / p;
}
