import { CLASS_TYPE } from './enums';

export class JiraIssue {
	type = CLASS_TYPE.JIRA_ISSUE;
	id: string;
	name: string;

	parentId: string;

	projectId: string;
	projectName: string;

	timeSpentSeconds: number = 0;

	childIssues: JiraIssue[] = [];

	public get allTimeSpentSeconds(): number {
		let childTimeSpentSeconds = this.timeSpentSeconds;
		for (const childJiraIssue of this.childIssues) {
			childTimeSpentSeconds += childJiraIssue.allTimeSpentSeconds;
		}

		return childTimeSpentSeconds;
	}

	public get allTimeSpentMinutes(): number {
		return this.allTimeSpentSeconds / 60;
	}

	public get allTimeSpentHours(): number {
		return this.allTimeSpentMinutes / 60;
	}

	getPercentage(baseAllTimeSpentSeconds: number, splitUpAllTimeSpentSeconds: number): {
		percentage: number,
		splitUpAllTimeSpentSeconds: number, 
		splitUpAllTimeSpentMinutes: number, 
		splitUpAllTimeSpentHours: number 
	} {
		const percentage = this.allTimeSpentSeconds / baseAllTimeSpentSeconds;
		return {
			percentage: percentage,
			splitUpAllTimeSpentSeconds: splitUpAllTimeSpentSeconds * percentage,
			splitUpAllTimeSpentMinutes: splitUpAllTimeSpentSeconds * percentage / 60,
			splitUpAllTimeSpentHours: splitUpAllTimeSpentSeconds * percentage / 60 / 60
		};
	}

	toJSON () {
		const jsonObj = {
			type: this.type,
			id: this.id,
			name: this.name,
			parentId: this.parentId,
			projectName: this.projectName,
			timeSpentSeconds: this.timeSpentSeconds,
			childIssues: this.childIssues,
			allTimeSpentSeconds: this.allTimeSpentSeconds,
			allTimeSpentMinutes: this.allTimeSpentMinutes,
			allTimeSpentHours: this.allTimeSpentHours
		};

		return jsonObj;
	}

}

