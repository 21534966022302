import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Project } from 'src/app/model/Project';
import { Timesheet } from 'src/app/model/Timesheet';
import { trackByFn } from 'src/app/model/globalFunctions';
import { User } from 'src/app/model/User';

@Component({
	selector: 'app-time-statement-table-group',
	templateUrl: './time-statement-table-group.component.html',
	styleUrls: ['./time-statement-table-group.component.scss']
})
export class TimeStatementTableGroupComponent implements OnInit {
	@Input()
	public get selectedTimesheets (): Timesheet[] {
		return this._selectedTimesheets;
	}
	public set selectedTimesheets (value: Timesheet[]) {
		this._selectedTimesheets = value;
		try {
			if (this.fundingProjects !== value[0]?.fundingProjects) {
				this.fundingProjects = value[0]?.fundingProjects;

				this.selectedYear = moment(value[0]?.monthDate).format('YYYY');
			} else {
				this.fundingProjects = [];
			}
		} catch (error) {
			console.log(error);
		}
	}

	constructor () { }

	@Input() timestamp: number;

	@Input() userRow: User;
	private _selectedTimesheets: Timesheet[];
	@Input() timesheets: any = {};

	@Input() fundingProjects: Project[];

	@Input() holidayDates: any = {};

	selectedYear: string;

	@Output() refresh = new EventEmitter<any>();

	moment = moment;
	trackByFn = trackByFn;

	ngOnInit (): void {
	}

	handleRefresh () {
		this.refresh.emit();
	}
}
