// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.month-column div {
  min-height: 6px;
  line-height: 6px;
  margin-top: 3px;
  padding-bottom: 1px;
}

.month-column div.tempo-info {
  min-height: 21px;
  line-height: 21px;
  margin-top: 3px;
  padding-bottom: 1px;
}

.column-c-spacer {
  width: 100%;
}

.planned-red {
  border-radius: 5px;
  font-weight: bold;
}

.planned-yellow {
  border-radius: 5px;
  font-weight: bold;
}

.planned-green {
  border-radius: 5px;
  font-weight: bold;
}

.planned-white {
  border-radius: 5px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ressources-table-content-info-cell/ressources-table-content-info-cell.component.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AACD;;AAEA;EACC,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;AACD;;AAEA;EACC,WAAA;AACD;;AAEA;EACC,kBAAA;EACA,iBAAA;AACD;;AAEA;EACC,kBAAA;EACA,iBAAA;AACD;;AAEA;EACC,kBAAA;EACA,iBAAA;AACD;;AAEA;EACC,kBAAA;EACA,iBAAA;AACD","sourcesContent":[".month-column div {\n\tmin-height: 6px;\n\tline-height: 6px;\n\tmargin-top: 3px;\n\tpadding-bottom: 1px;\n}\n\n.month-column div.tempo-info {\n\tmin-height: 21px;\n\tline-height: 21px;\n\tmargin-top: 3px;\n\tpadding-bottom: 1px;\n}\n\n.column-c-spacer {\n\twidth: 100%;\n}\n\n.planned-red {\n\tborder-radius: 5px;\n\tfont-weight: bold;\n}\n\n.planned-yellow {\n\tborder-radius: 5px;\n\tfont-weight: bold;\n}\n\n.planned-green {\n\tborder-radius: 5px;\n\tfont-weight: bold;\n}\n\n.planned-white {\n\tborder-radius: 5px;\n\tfont-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
