import { Pipe, PipeTransform } from '@angular/core';
import { AnyPtrRecord } from 'dns';
import { UNIT } from '../model/enums';
import { User } from '../model/User';

@Pipe({
	name: 'timeScheduleInfo'
})
export class TimeScheduleInfoPipe implements PipeTransform {

	transform (observable: any, user: User, actColumn: string,
		monthDescription: any, format: string = UNIT.HOURS, digits: number = 0): string {

		const check = observable;

		try {
			switch (actColumn) {
				case 'holiday_column':
					return user.getHolidayDays(monthDescription.month, format, digits);
					break;

				case 'pot_column':
					return user.getPotDays(monthDescription.month, format, digits);
					break;

				case 'available_column':
					return user.getAvailableDays(monthDescription.month, format, digits);
					break;

				case 'real_column':
					return user.getRealDays(monthDescription.month, format, digits);
					break;

				case 'rest_column':
					return user.getRestDays(monthDescription.month, format, digits);
					break;

				case 'all_column':
					return user.getAllDays(monthDescription.month, format, digits);
					break;

				case 'real_plan_column':
					return user.getNotYetPlannedTime(monthDescription.month, format, digits);
					break;

				case 'month_column':
					return monthDescription.label;
					break;

				default:
					return '';
					break;
			}

		} catch (error) {
			return '';
		}

	}

}
