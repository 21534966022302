// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.funding-project-list {
  width: 100%;
}

mat-chip, mat-chip-option, mat-chip-row {
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/timesheet-funding-project-selector/timesheet-funding-project-selector.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;;AAEA;EACC,uBAAA;EAAA,kBAAA;EACA,iBAAA;AACD","sourcesContent":[".funding-project-list {\n\twidth: 100%;\n}\n\nmat-chip, mat-chip-option, mat-chip-row {\n\twidth: fit-content;\n\tpadding-left: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
