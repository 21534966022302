import { Pipe, PipeTransform } from '@angular/core';
import { REQUIRED_SECONDS_PER_DAY } from '../model/constants';
import { round } from '../model/globalFunctions';
import { ProjectGroup } from '../model/ProjectGroup';
import { ProjectRequirementAnalysisWrapper } from '../model/ProjectRequirementAnalysisWrapper';
import { RequirementAnalysis } from '../model/RequirementAnalysis';
import { User } from '../model/User';

@Pipe({
	name: 'employeeNames'
})
export class EmployeeNamesPipe implements PipeTransform {

	transform (accountIds: string[], userMap: any, accountIdsToworkingDays: any,
		requirementAnalysis: RequirementAnalysis,
		projectOrProjectGroup?: ProjectRequirementAnalysisWrapper | ProjectGroup, ...args: unknown[]): string {
		if (accountIds) {

			const labelPersonen = (count: number) => count + (count === 1 ? ' Person' : ' Personen');

			let employeeNames = labelPersonen(accountIds.length) + ':';

			let userNames: string[] = [];
			const userCount = {};

			const contractType = {};

			for (const accountId of accountIds) {
				const workingDays = accountIdsToworkingDays ? accountIdsToworkingDays[accountId] : undefined;
				if (workingDays) {
					userCount[workingDays] = (userCount[workingDays] ?? 0) + 1;

					const actContractType = Math.min(round(workingDays / requirementAnalysis.maxWorkingDaysInRange, 1) * 100, 100);
					let actContractTypeName = 'Vollzeit';
					if (actContractType < 100) {
						actContractTypeName = 'Teilzeit (' + actContractType + '%)';
					}
					contractType[actContractType] = contractType[actContractType] ?? {};
					const accountIsForContractType = contractType[actContractType].accountIds ?? [];
					accountIsForContractType.push(accountId);
					contractType[actContractType].name = actContractTypeName;
					contractType[actContractType].workingDays = actContractType / 100 * requirementAnalysis.maxWorkingDaysInRange;
					contractType[actContractType].accountIds = accountIsForContractType;
				}
			}

			if (accountIdsToworkingDays) {

				for (const actContractType of Object.values<any>(contractType)) {
					employeeNames += '\n\n' + actContractType.name + ': ' + actContractType.accountIds.length + ' Pers.\n\n';

					let displayNames: string[] = [];

					for (const accountId of actContractType.accountIds) {
						const user: User = userMap[accountId];
						let displayName = user?.displayName ?? 'ZZ: (' + accountId + ')';

						if (projectOrProjectGroup) {
							displayName += ': ' + (requirementAnalysis.getWorkloadForProjectAndAccountId(projectOrProjectGroup, accountId) /
                                    REQUIRED_SECONDS_PER_DAY)?.toFixed(1) + ' PT';
						}
						displayNames.push(displayName);
					}

					displayNames = displayNames.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
					employeeNames += '- ' + displayNames.join('\n- ');

				}

			} else {
				const workingDaysSchemas = accountIdsToworkingDays ? Object.keys(userCount) : [];
				console.log('EmployeeNamesPipe');

				employeeNames += '\n\n';

				for (const accountId of accountIds) {
					const workingDays = accountIdsToworkingDays ? accountIdsToworkingDays[accountId] : undefined;
					const user: User = userMap[accountId];
					let userName = user?.displayName ?? 'ZZ: (' + accountId + ')';
					const workingDaysSchemaIndex = workingDaysSchemas.findIndex((item) => parseInt(item, 10) * 1 === workingDays * 1);
					if (workingDaysSchemaIndex !== -1) {
						userName += ' *[' + (1 + workingDaysSchemaIndex) + ']';
					}
					userNames.push(userName);

				}
				let countVersion = 1;
				for (const workingDays of Object.keys(userCount)) {
					const count = userCount[workingDays];
					employeeNames += '[' + countVersion + '] ' + workingDays + ' PT: ' + labelPersonen(count) + '\n';

					countVersion += 1;
				}
				if (accountIdsToworkingDays) {
					employeeNames += '\n';
				}

				userNames = userNames.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

				employeeNames += userNames.join('\n');

			}
			return employeeNames;
		}
		return JSON.stringify(accountIds);
	}

}
