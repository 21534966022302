// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-input-form {
  padding-top: 10px;
  padding-bottom: 10px;
}

mat-form-field,
mat-checkbox {
  padding-right: 10px;
}

.invoice-input-form .right-align {
  text-align: right;
}

tr.invoice-row:hover {
  background: #EEE;
}

.text-center {
  text-align: center;
  margin: 0 auto;
}

.column-year {
  background: #CCC;
}

.column-q1,
.column-q2,
.column-q3,
.column-q4,
.column-year,
.column-tools {
  width: 150px;
  max-width: 150px;
  padding: 0;
  padding-bottom: 1px;
  border-bottom: 1px solid #CCC;
}

.column-name {
  padding: 0;
  padding-bottom: 1px;
  border-bottom: 1px solid #CCC;
}

.chip-color-green {
  background-color: green;
  color: white;
}

.mat-mdc-standard-chip {
  border-radius: 5px;
  min-height: 24px;
}

mat-mdc-chip {
  font: inherit;
  font-weight: 500;
}

.width-invoice-date,
.width-invoice-number,
.width-clearing-date {
  width: 15em;
}

.width-invoice-name {
  width: 20em;
}

.width-netto,
.width-customer-number {
  width: 14.5em;
}

.width-vat {
  width: 12.25em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/invoice-input/invoice-input.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,oBAAA;AACD;;AAEA;;EAEC,mBAAA;AACD;;AAEA;EACC,iBAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,kBAAA;EACA,cAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;;;;;;EAMC,YAAA;EACA,gBAAA;EACA,UAAA;EACA,mBAAA;EACA,6BAAA;AACD;;AAEA;EACC,UAAA;EACA,mBAAA;EACA,6BAAA;AACD;;AAEA;EACC,uBAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;EACA,gBAAA;AACD;;AAEA;EACC,aAAA;EACA,gBAAA;AACD;;AAEA;;;EAGC,WAAA;AACD;;AAEA;EACC,WAAA;AACD;;AAEA;;EAEC,aAAA;AACD;;AAEA;EACC,cAAA;AACD","sourcesContent":[".invoice-input-form {\n\tpadding-top: 10px;\n\tpadding-bottom: 10px;\n}\n\nmat-form-field,\nmat-checkbox {\n\tpadding-right: 10px;\n}\n\n.invoice-input-form .right-align {\n\ttext-align: right;\n}\n\ntr.invoice-row:hover {\n\tbackground: #EEE;\n}\n\n.text-center {\n\ttext-align: center;\n\tmargin: 0 auto;\n}\n\n.column-year {\n\tbackground: #CCC;\n}\n\n.column-q1,\n.column-q2,\n.column-q3,\n.column-q4,\n.column-year,\n.column-tools {\n\twidth: 150px;\n\tmax-width: 150px;\n\tpadding: 0;\n\tpadding-bottom: 1px;\n\tborder-bottom: 1px solid #CCC;\n}\n\n.column-name {\n\tpadding: 0;\n\tpadding-bottom: 1px;\n\tborder-bottom: 1px solid #CCC;\n}\n\n.chip-color-green {\n\tbackground-color: green;\n\tcolor: white;\n}\n\n.mat-mdc-standard-chip {\n\tborder-radius: 5px;\n\tmin-height: 24px;\n}\n\nmat-mdc-chip {\n\tfont: inherit;\n\tfont-weight: 500;\n}\n\n.width-invoice-date,\n.width-invoice-number,\n.width-clearing-date {\n\twidth: 15em;\n}\n\n.width-invoice-name {\n\twidth: 20em;\n}\n\n.width-netto,\n.width-customer-number {\n\twidth: 14.5em;\n}\n\n.width-vat {\n\twidth: 12.25em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
