import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CELL_TYPE, UNIT } from 'src/app/model/enums';
import { LocalStorageWorker } from 'src/app/model/LocalStorageWorker';
import { Project, User } from 'src/app/model/model';
import { Timesheet, TimeSheetRow } from 'src/app/model/Timesheet';
import { ProjectService } from 'src/app/services/project.service';
import { SocketService } from 'src/app/services/socket.service';
import { TempoIoService, TEMPO_IO_URL } from 'src/app/services/tempo.io.service';
import { Observable, Subscription } from 'rxjs';
import { trackByFn } from 'src/app/model/globalFunctions';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { JIRA_ACTION } from 'src/app/actions/jira-api.actions';

@Injectable({
	providedIn: 'root'
})

@Component({
	selector: 'app-timesheets-table',
	templateUrl: './timesheets-table.component.html',
	styleUrls: ['./timesheets-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetsTableComponent implements OnInit {

	private reduxSubscription: Subscription = new Subscription();

	constructor (
		private store: Store<AppState>,
		private _jiraconnector: JiraconnectorService,

		@Inject(TEMPO_IO_URL) public tempoIoUrl: string,

		public fb: UntypedFormBuilder,

		public _ChangeDetectorRef: ChangeDetectorRef,

		public projectService: ProjectService,
		public tempoIoService: TempoIoService,
		public socketService: SocketService,

	) {

	}

	@ViewChild('TimesheetsTable', { static: false }) oTimesheetsTable: MatTable<any>;

	holidayDates: any;

	timestamp: number;

	// form: FormGroup;

	public userList: any = [];
	public userMap: any = {};
	public userExpandMap: any = {};

	public columnsToDisplay = [];
	public columnsToDisplayInfo = {};

	public selectedColumnName: string;

	public selectedUser: User;

	private _timesheets: any = {};

	public loadedTimesheets = false;
	public loadingData = false;

	public get timesheets (): any {
		return this._timesheets;
	}

	public set timesheets (value: any) {
		//
	}

	public startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1, 0, 0, 0, 0);
	public endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);

	range = new UntypedFormGroup({
		start: new UntypedFormControl(this.startDate),
		end: new UntypedFormControl(this.endDate)
	});

	private ar_selectedMonth = [];
	public get selectedMonths (): any[] {

		if (this.ar_selectedMonth.length === 0 &&
      this.range.valid && this.range.value.start !== null && this.range.value.end !== null) {
			//      const dateBegin = this.form.value.date.begin;
			//      const dateEnd = this.form.value.date.end;

			const dateBegin = this.range.value.start;
			const dateEnd = this.range.value.end;
			let startDate = moment(dateBegin).startOf('month');
			const endDate = moment(dateEnd).endOf('month');

			const ar_month = [];

			while (startDate.isBefore(endDate)) {
				const monthDescription = {
					label: moment(startDate).format('MM/YY'),
					month: moment(startDate).format('YYYY_M'),
					date: moment(startDate).format('YYYY-MM-01')
				};
				ar_month.push(monthDescription);

				startDate = startDate.add(1, 'month');
			}
			this.ar_selectedMonth = ar_month;
		}

		return this.ar_selectedMonth;

	}

	moment = moment;
	UNIT = UNIT;
	CELL_TYPE = CELL_TYPE;
	trackByFn = trackByFn;

	ngOnInit (): void {

		moment.locale('de');

		// this.projectService.getProjectsFromDataStore();

		const ressource_startDate = LocalStorageWorker.instance.get('timesheets_startDate');
		if (ressource_startDate !== undefined && ressource_startDate !== null) {
			this.startDate = new Date(ressource_startDate);
		}
		const ressource_endDate = LocalStorageWorker.instance.get('timesheets_endDate');
		if (ressource_endDate !== undefined && ressource_endDate !== null) {
			this.endDate = new Date(ressource_endDate);

			this.range = new UntypedFormGroup({
				start: new UntypedFormControl(this.startDate),
				end: new UntypedFormControl(this.endDate)
			});
		}

		const timesheets_userExpandedMap = LocalStorageWorker.instance.get('timesheets_userExpandedMap');
		if (timesheets_userExpandedMap !== undefined && timesheets_userExpandedMap !== null) {
			try {
				this.userExpandMap = JSON.parse(timesheets_userExpandedMap);

			} catch (error) { }
		}

		this.reduxSubscription.add(this.store.select((state: AppState) => state.jiraApi).subscribe((state) => {
			switch (state.action) {
				case JIRA_ACTION.SET_USERS:
					this.userList = state.users.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
					this.userMap = state.userMap;

					setTimeout(() => {
						this.getWorkloadsForExpandedUsers();
					}, 1000);

					break;

				default:
					break;
			}
		}));

		this.setColumns();

	}

	filterUserList (item): boolean {
		return !item.isCEO;
	}

	getWorkloadsForExpandedUsers () {
		for (const accountId of Object.keys(this.userMap)) {
			if (this.userExpandMap[accountId] === true) {
				const user = this.userMap[accountId];
				if (user) {
					this.getWorkloads(user);
				}
			}
		}
		this._ChangeDetectorRef.detectChanges();

	}

	getHolidayDates (user: User = null) {
		const startDateString = moment(this.startDate).format('YYYY-MM-DD');
		const endDateString = moment(this.endDate).format('YYYY-MM-DD');

		let accountId = '';
		if (user !== null) {
			accountId = user.accountId;
		}
		this.tempoIoService.getUserScheduleForMonth(accountId, startDateString, endDateString).subscribe((res) => {

			this.holidayDates = res.holidayDates;

			this.setColumns();

			this._ChangeDetectorRef.detectChanges();

		});
	}

	getWorkloads (user: User = null) {
		const startDateString = moment(this.startDate).format('YYYY-MM-DD');
		const endDateString = moment(this.endDate).format('YYYY-MM-DD');

		this.loadingData = true;
		this._ChangeDetectorRef.detectChanges();

		let observableWorkloads;

		this.getHolidayDates(user);

		if (user !== null) {
			observableWorkloads = this.tempoIoService.getWorklogsForMonth(user.accountId, startDateString, endDateString);
		} else {
			observableWorkloads = this.tempoIoService.getAll<any>(this.tempoIoUrl + 'worklogs?from=' + startDateString + '&to=' + endDateString + '&limit=1000', {});
		}

		observableWorkloads.subscribe((res) => {

			if (user !== null) {
				this._timesheets[user.accountId] = {};
			} else {
				this._timesheets = {};
			}

			const ar_oItem = res.results;

			for (const item of ar_oItem) {

				const month = moment(item.startDate).format('YYYY_MM');
				const day = moment(item.startDate).format('DD');
				const monthDate = moment(item.startDate).toDate();

				const accountId = item.author.accountId;
				const issueId = item.issue.key;
				let projectId = issueId.split('-')[0];

				if (issueId === 'POR-57') {
					projectId = 'Urlaub';
				}
				if (issueId === 'POR-58') {
					projectId = 'Krank';
				}
				if (projectId === 'PIN') {
					projectId = 'Internes';
				}

				const accountTimesheets: any = this.timesheets[accountId] || {};
				const accountMonthTimesheet: Timesheet = accountTimesheets[month] || new Timesheet();

				accountMonthTimesheet.accountId = accountId;
				accountMonthTimesheet.monthDate = monthDate;

				const project: Project = this.projectService.projectsById[projectId];

				accountMonthTimesheet.projectNames[projectId] = project ? project.name : projectId;

				accountMonthTimesheet.addWorkload(projectId, day, item.timeSpentSeconds * 1);

				if (projectId !== 'Urlaub' && projectId !== 'Krank') {
					accountMonthTimesheet.addWorkload('Summe Arbeitszeit', day, item.timeSpentSeconds * 1);
				}

				accountTimesheets[month] = accountMonthTimesheet;
				this.timesheets[accountId] = accountTimesheets;

			}

			for (const expandedUserKey of Object.keys(this.userExpandMap)) {
				if (this.userExpandMap[expandedUserKey] === true) {
					this.getUserTimeSheet(this.userMap[expandedUserKey]).subscribe((res) => {

						const timesheetsForUserId = this.timesheets[expandedUserKey];

						for (const month of Object.keys(timesheetsForUserId)) {
							const timesheet = timesheetsForUserId[month];
							timesheet.refresh();
						}

						this.loadedTimesheets = true;
						this.loadingData = false;
						this.timesheetsForAccountId = {};
						this._ChangeDetectorRef.detectChanges();

					}, (error) => {
						this.loadingData = false;
						this.timesheetsForAccountId = {};
						this._ChangeDetectorRef.detectChanges();
					});
				}
			}

			console.log(this.timesheets);

		}, (error) => {
			this.loadingData = false;
			this._ChangeDetectorRef.detectChanges();
		});

	}

	public get timesheetsForAccountId (): any {
		const hmTimesheetsForAccountId = {};
		for (const accountId of Object.keys(this.userMap)) {
			if (this.userExpandMap[accountId] === true) {
				hmTimesheetsForAccountId[accountId] = this.getTimesheetsForAccountId(accountId);
				for (const timesheet of hmTimesheetsForAccountId[accountId]) {
					timesheet.refresh();
				}
			}
		}
		return hmTimesheetsForAccountId;
	}
	public set timesheetsForAccountId (value: any) {
		//
	}

	getTimesheetsForAccountId (accountId) {
		const ar_oTimesheets: Timesheet[] = [];
		try {
			for (const key of Object.keys(this.timesheets[accountId])) {
				ar_oTimesheets.push(this.timesheets[accountId][key]);
			}
		} catch (error) {

		}

		return ar_oTimesheets;
	}

	getUserTimeSheet (user: User): Observable<any> {

		const observable = new Observable<any>((observer) => {

			this._jiraconnector.getUserTimeSheet(user.accountId).subscribe((res) => {
				if (res.value) {

					for (const month of Object.keys(res.value)) {
						const oTimeSheet = res.value[month];

						const oExistingTimeSheet: Timesheet = this.timesheets[user.accountId][month];

						if (oExistingTimeSheet) {
							oExistingTimeSheet.gross_salary_crypt = oTimeSheet.gross_salary_crypt;
							oExistingTimeSheet.total_working_days_crypt = oTimeSheet.total_working_days_crypt;
							oExistingTimeSheet.no_social_insurance = oTimeSheet.no_social_insurance;
							oExistingTimeSheet.funding_manually_data = {...oExistingTimeSheet.funding_manually_data, ...oTimeSheet.funding_manually_data};
						}

						if (oExistingTimeSheet && oTimeSheet.timeSheetRows) {
							for (const oActTimeSheetRow of oTimeSheet.timeSheetRows) {

								const oExistingTimeSheetRow = oExistingTimeSheet.timeSheetRows.find(
									(item) => item.projectId === oActTimeSheetRow.projectId);

								if (oExistingTimeSheet) {
									oExistingTimeSheetRow.selectedFundingProjectId = oActTimeSheetRow.selectedFundingProjectId;
								}

							}

						}

						if (oExistingTimeSheet) {
							oExistingTimeSheet.refresh();
						}

					}

				}

				observer.next(res);
				observer.complete();

			}, (error) => {
				observer.error(error);
			});

		});

		return observable;

	}

	putUserTimeSheet (user: User) {

		const oExistingTimesheets = this.timesheets[user.accountId];

		if (oExistingTimesheets) {
			this._jiraconnector.getUserTimeSheet(user.accountId).subscribe((res) => {
				let oUserTimeSheets = this.timesheets[user.accountId];
				if (res.value) {
					oUserTimeSheets = res.value;
					try {
						for (const month of Object.keys(this.timesheets[user.accountId])) {
							const oExistingTimesheet = this.timesheets[user.accountId][month];
							const oJson = oExistingTimesheet.toJSON();
							oUserTimeSheets[month] = oJson;
						}
					} catch (error) {
						console.log(error);
					}
				}

				this._jiraconnector.putUserTimeSheet(user.accountId, oUserTimeSheets).subscribe((resPut) => { });

				for (const key of Object.keys(oExistingTimesheets)) {
					oExistingTimesheets[key].refresh();
				}

				this.timestamp = new Date().getTime();

				this._ChangeDetectorRef.detectChanges();

			}, (error) => {
				const oUserTimeSheets = this.timesheets[user.accountId];
				this._jiraconnector.putUserTimeSheet(user.accountId, oUserTimeSheets).subscribe((resPut) => { });

				for (const key of Object.keys(oExistingTimesheets)) {
					oExistingTimesheets[key].refresh();
				}

				this.timestamp = new Date().getTime();

				this._ChangeDetectorRef.detectChanges();

			});

		}
	}

	expandRow (user: User) {
		if (this.loadingData) {
			return;
		}

		this.userExpandMap[user.accountId] = this.userExpandMap[user.accountId] !== true;

		if (this.userExpandMap[user.accountId] === true) {
			this.selectedUser = user;

			this.getWorkloads(user);

		}

		try {
			LocalStorageWorker.instance.set('timesheets_userExpandedMap', JSON.stringify(this.userExpandMap));
		} catch (error) { }

		this._ChangeDetectorRef.detectChanges();

	}

	selectedColumn (column: string) {
		if (column !== this.selectedColumnName) {
			this.selectedColumnName = column;

			this._ChangeDetectorRef.detectChanges();
		}
	}

	setColumns () {

		const columns = [];
		const columnsInfo = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-name', key: 'name', title: 'Name' };

		const sClass = 'c-m';

		colName = 'data';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, title: 'Daten' };

		colName = 'tools';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, title: '' };

		this.columnsToDisplay = [];
		this.columnsToDisplayInfo = [];

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;

		this._ChangeDetectorRef.detectChanges();

	}

	getDataForTimeSheet (timesheet: Timesheet) {

		const ar_oTimeSheetRow: TimeSheetRow[] = [...timesheet.timeSheetRows.filter((item) => item.projectName.indexOf('umme') === -1)];

		ar_oTimeSheetRow.sort((a, b) => {
			if (a.getValue('SUM') > b.getValue('SUM')) { return -1; }
			if (a.getValue('SUM') < b.getValue('SUM')) { return 1; }
			return 0;
		}
		);

		const labels = [];
		const data = [];

		for (const item of ar_oTimeSheetRow) {
			labels.push(item.projectName);
			data.push(item.getValue('SUM'));
		}

		const oCharData = {
			labels,
			datasets: [
				{
					label: moment(timesheet.monthDate).format('MM / YYYY'),
					data,
					fill: false,
					backgroundColor: [
						'rgba(117, 180, 209,0.5)'
					],
					borderColor: [
						'rgb(117, 180, 209)'
					],
					borderWidth: 1
				}
			],
			options: {
				animation: false
			}
		};

		return oCharData;
	}

	dateRangeChange ($event) {

		const test = this.selectedMonths;

		this.ar_selectedMonth = [];

		const startMonth = this.selectedMonths[0];
		const endMonth = this.selectedMonths[this.selectedMonths.length - 1];
		const endDate = new Date(new Date(new Date(endMonth.date).getFullYear(),
			new Date(endMonth.date).getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);

		LocalStorageWorker.instance.set('timesheets_startDate', startMonth.date.toString());
		LocalStorageWorker.instance.set('timesheets_endDate', endDate.toString());

		this.startDate = startMonth.date;
		this.endDate = endDate;

		this.getWorkloadsForExpandedUsers();

		this._ChangeDetectorRef.detectChanges();
	}

	getLabelsForTimeSheet (timesheet: Timesheet) {
		return this.getDataForTimeSheet(timesheet).labels;
	}
	getDatasetsForTimeSheet (timesheet: Timesheet) {
		return this.getDataForTimeSheet(timesheet).datasets;
	}
	getOptionsForTimeSheet (timesheet: Timesheet) {
		return this.getDataForTimeSheet(timesheet).options;
	}
	getChartHeightForTimeSheets (user: User) {
		let maxCount = 0;
		for (const timesheet of this.getTimesheetsForAccountId(user.accountId)) {
			const labels = this.getLabelsForTimeSheet(timesheet);
			maxCount = Math.max(maxCount, labels.length);
		}
		return maxCount * 16 + 50;
	}

}
