import { formatCurrency, formatDate, formatNumber } from '@angular/common';
import { Project } from './Project';

export class Invoice {
	name: string;
	project: Project;
	projectName: string;

	incoming = false;

	get projectLongName (): string {
		let sPrefix = '';
		if (this.project.parentProject) {
			sPrefix = this.project.parentProject.name + ' -> ';
		}
		return sPrefix + this.projectName;
	}

	documentNumber: string;

	get documentNumberString (): string {
		return (this.virtual === true) ? 'RestBudget' : (this.planed === true) ? 'geplant' : this.documentNumber;
	}

	planed = false;
	virtual = false;
	customerNumber: string;
	private _invoiceDate: Date;
	private _clearingDate: Date;

	get invoiceDate (): Date {
		return this._invoiceDate;
	}
	set invoiceDate (date: Date) {
		this._invoiceDate = new Date(date);
	}

	get clearingDate (): Date {
		return this._clearingDate;
	}
	set clearingDate (date: Date) {
		this._clearingDate = new Date(date);
	}

	get clearingDateOrActDate (): Date {
		if (this.paid) {
			return this.clearingDate;
		}
		const actDate = new Date();
		return this._clearingDate > actDate ? this.clearingDate : actDate;
	}

	get notPaidJustLikeThat (): boolean {
		if (this.paid || this.planed || this.virtual || this.notPaidYet) {
			return false;
		}
		const today = new Date();
		if (this.clearingDate < today) {
			return true;
		}
		return false;
	}
	get notPaidYet (): boolean {
		if (this.paid || this.planed || this.virtual) {
			return false;
		}

		const todayPlus7 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

		// if (this.projectName && this.projectName.indexOf('DVB') != -1) {
		//  console.log('DVB: ' + this.clearingDate + ' < ' + todayPlus7);
		// }
		if (this.clearingDate < todayPlus7) {
			return true;
		}
		return false;
	}

	paid = false;

	private _netto: number;
	public get netto (): number {
		return this._netto;
	}
	public set netto (value: number) {
		this._netto = value;
	}
	_vat = '0.19';

	get vat (): string {
		if (this._vat === '0.07') {
			if (this.invoiceDate >= new Date('2020-07-01T00:00:00') && this.invoiceDate <= new Date('2020-12-31T23:59:59')) {
				return '0.05';
			}
		}
		if (this._vat === '0.19') {
			if (this.invoiceDate >= new Date('2020-07-01T00:00:00') && this.invoiceDate <= new Date('2020-12-31T23:59:59')) {
				return '0.16';
			}
		}
		return this._vat;
	}
	set vat (value: string) {
		this._vat = value;
	}

	stored_values: any = {};

	getValue (key: string) {
		const stored_values_key = this.stored_values[key];
		if (stored_values_key != null && stored_values_key !== 0 && this.incoming === false) {
			return stored_values_key;
		} else {
			return this.calc_value(key);
		}
	}
	setValue (key: string, value: number) {
		this.stored_values[key] = value;
	}

	get vatNumber (): number {
		return parseFloat(this.vat);
	}

	get vatString (): string {
		return this.vat ? formatNumber(this.vatNumber * 100, 'de', '0.0') + '%' : '---';
	}

	get nettoString (): string {
		return this.netto ? formatCurrency(this.netto, 'de', '€') : '---';
	}

	get bruttoString (): string {
		return this.netto ? formatCurrency(this.netto * (1 + this.vatNumber), 'de', '€') : '---';
	}

	get invoiceDateString (): string {
		return this.invoiceDate ? formatDate(this.invoiceDate, 'dd.MM.yyyy', 'de') : '---';
	}

	get clearingDateString (): string {
		return this.clearingDate ? formatDate(this.clearingDate, 'dd.MM.yyyy', 'de') : '---';
	}

	get nameString (): string {
		return this.name + ' | ' + ((this.planed === true) ? 'geplant' :
			('R-Nr: ' + this.documentNumber)) + ' | R-Datum: ' + this.invoiceDateString + ' | Bezahl-Datum: ' + this.clearingDateString + ' | K-Nr: ' + this.customerNumber + ' | ' + this.nettoString;
	}

	setNetto (netto: any): void {

		try {
			netto = (netto as string).replace('.', '');
			netto = (netto as string).replace(',', '.');

			Number.parseFloat(netto as string);
			// console.debug('NETTO: ' + netto + ' --- ' + Number.parseFloat(netto as string));
			this.netto = 1 * netto as number;
		} catch (error) {
			// do nothing
		}
	}

	toJSON () {
		const jsonObj = {
			name: this.name,
			projectName: this.projectName,
			planed: this.planed,
			documentNumber: this.documentNumber,
			customerNumber: this.customerNumber,
			invoiceDate: this.invoiceDate,
			clearingDate: this.clearingDate,
			netto: this.netto,
			vat: this.vat,
			paid: this.paid,
			incoming: this.incoming
		};

		return jsonObj;
	}

	isValid (): boolean {
		return !(this.name == null ||
      this.invoiceDate == null ||
      this.clearingDate == null ||
      (!this.planed && this.documentNumber == null) ||
      this.customerNumber == null ||
      this.netto == null);
	}

	calc_value (key: string): number {
		try {
			const qartalString = this.getQuartalString(this.invoiceDate);
			const monthString = this.invoiceDate.getFullYear() + '_' + (this.invoiceDate.getMonth() + 1);
			const yearString = this.invoiceDate.getFullYear() + '_year';

			const sign = this.incoming ? -1 : 1;

			this.stored_values[yearString] = sign * this.netto;
			this.stored_values[monthString] = sign * this.netto;
			this.stored_values[qartalString] = sign * this.netto;
		} catch (error) {
			// console.debug('error: ' + error);
		}

		return this.stored_values[key];
	}

	getQuartalString (oActDate: Date): string {
		if (oActDate == null) {
			return 'InvalidDate';
		}
		let qartalString = oActDate.getFullYear() + '_q1';
		switch (oActDate.getMonth() + 1) {
			case 4:
			case 5:
			case 6:
				qartalString = oActDate.getFullYear() + '_q2';
				break;
			case 7:
			case 8:
			case 9:
				qartalString = oActDate.getFullYear() + '_q3';
				break;
			case 10:
			case 11:
			case 12:
				qartalString = oActDate.getFullYear() + '_q4';
				break;
		}
		return qartalString;
	}

}
