import { Action, createReducer, on } from '@ngrx/store';
import { GetRequirementAnalysis, GetRequirementAnalysisWorkload, RequirementAnalysisLoaded, RequirementAnalysisNothing,
	RequirementAnalysisSaved,
	RequirementAnalysisWorkloadLoaded,
	REQUIREMENT_ANALYSIS_ACTION, SaveRequirementAnalysis, SetRequirementAnalysis } from '../actions/requirement-analysis.actions';
import { RequirementAnalysis } from '../model/RequirementAnalysis';

export const requirementAnalysisFeatureKey = 'requirementAnalysis';

export interface State {
	action: REQUIREMENT_ANALYSIS_ACTION;
	suffix: string;
	requirementAnalysis: RequirementAnalysis;
}

export const initialState: State = {
	action: REQUIREMENT_ANALYSIS_ACTION.NOTHING,
	suffix: '',
	requirementAnalysis: undefined
};

export const reducer = createReducer(
	initialState,

	on(RequirementAnalysisNothing, (state) => ({ ...state, action: REQUIREMENT_ANALYSIS_ACTION.NOTHING })),
	on(GetRequirementAnalysis, (state, { suffix }) => ({ ...state, action: REQUIREMENT_ANALYSIS_ACTION.GET_REQUIREMENT_ANALYSIS, suffix })),
	on(SetRequirementAnalysis, (state, { requirementAnalysis }) => ({
		...state,
		action: REQUIREMENT_ANALYSIS_ACTION.SET_REQUIREMENT_ANALYSIS,
		requirementAnalysis
	})),
	on(RequirementAnalysisLoaded, (state, { requirementAnalysis }) => ({
		...state,
		action: REQUIREMENT_ANALYSIS_ACTION.REQUIREMENT_ANALYSIS_LOADED,
		requirementAnalysis
	})),

	on(SaveRequirementAnalysis, (state, { requirementAnalysis, suffix }) => ({
		...state,
		action: REQUIREMENT_ANALYSIS_ACTION.SAVE_REQUIREMENT_ANALYSIS,
		suffix,
		requirementAnalysis
	})),
	on(RequirementAnalysisSaved, (state, { requirementAnalysis }) => ({
		...state,
		action: REQUIREMENT_ANALYSIS_ACTION.REQUIREMENT_ANALYSIS_SAVED,
		requirementAnalysis
	})),
	on(GetRequirementAnalysisWorkload, (state) => ({ ...state, action: REQUIREMENT_ANALYSIS_ACTION.GET_REQUIREMENT_ANALYSIS_WORKLOAD })),
	on(RequirementAnalysisWorkloadLoaded, (state) => ({
		...state,
		action: REQUIREMENT_ANALYSIS_ACTION.REQUIREMENT_ANALYSIS_WORKLOAD_LOADED
	})),

);
