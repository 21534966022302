// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-content-form {
  width: 100%;
}

.dialog-content-field {
  display: inline;
}

.dialog-content-min-width {
  min-width: 500px;
}

.dialog-content-spinner {
  float: right;
}

mat-paginator {
  bottom: 0;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-project-dialog/select-project-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;;AAEA;EACC,eAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,YAAA;AACD;;AAEA;EAEC,SAAA;EACA,cAAA;AAAD","sourcesContent":[".dialog-content-form {\n\twidth: 100%;\n}\n\n.dialog-content-field {\n\tdisplay: inline;\n}\n\n.dialog-content-min-width {\n\tmin-width: 500px;\n}\n\n.dialog-content-spinner {\n\tfloat: right;\n}\n\nmat-paginator {\n\t//position: absolute;\n\tbottom: 0;\n\tmargin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
