import { Pipe, PipeTransform } from '@angular/core';
import { Project } from '../model/Project';

@Pipe({
	name: 'projectOverAllValue'
})
export class ProjectOverAllValuePipe implements PipeTransform {

	private storedOverAllValues = {};

	transform (actColumn: string, projects: Project[]): number {

		let storedValue = this.storedOverAllValues[actColumn];
		if (storedValue === null || storedValue === undefined || storedValue === 0) {
			storedValue = 0;
			for (const oProject of projects) {
				storedValue += oProject.getValue(actColumn) || 0;
			}
			this.storedOverAllValues[actColumn] = storedValue;
		}
		// storedValue = 1;
		return storedValue;
	}

}
