import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

	transform (value: any[], order: string = '', property: string = ''): any[] {
		if (!value || order === '' || !order) { return value; } // no array
		if (!property || property === '') { return value; } // sort 1d array
		if (value.length <= 1) { return value; } // array with only one item
		return this.orderBy(value, property, order);
	}

	orderBy (array: any[], property: string, order: string): any[] {
		const orderValue = (order === 'asc') ? 1 : -1;
		const sortedArray = array.sort((a, b) => {
			const aValue = a[property];
			const bValue = b[property];
			try {
				if (typeof aValue === 'string' && typeof bValue === 'string') {
					return ((aValue && bValue) ?
						aValue.localeCompare(bValue, undefined, { sensitivity: 'base' }) : 0) * orderValue;
				}
				if (aValue > bValue) { return 1 * orderValue; }
				if (aValue < bValue) { return -1 * orderValue; }
				return 0;
			} catch (error) {
				if (aValue > bValue) { return 1 * orderValue; }
				if (aValue < bValue) { return -1 * orderValue; }
				return 0;
			}
		});

		return sortedArray;
	}
}
