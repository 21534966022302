import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, OnDestroy, OnInit, AfterContentInit, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import { JiraconnectorService } from './jiraconnector/jiraconnector.service';

import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from './services/project.service';
import { DataDescription } from './model/DataDescription';
import { SocketMessage } from './model/SocketMessage';
import { LocalStorageWorker } from './model/LocalStorageWorker';
import { REPORT_MODE, SOCKET_SUBJECT, DATA_TYPE, PROJECT_TYPE } from './model/enums';
import { LiquidityTableComponent } from './components/liquidity-table/liquidity-table.component';
import { SocketService } from './services/socket.service';
import { environment } from './../environments/environment';
import { LiquidityService } from './services/liquidity.service';
import { AP$ } from 'src/polyfills';
import { TempoIoService } from './services/tempo.io.service';
import { Store } from '@ngrx/store';
import { AppState } from './reducers';
import { GetSessionUser, GetUsers, JIRA_ACTION } from './actions/jira-api.actions';
import { Subscription } from 'rxjs';
import { GetFullProjects, GetProjects, PROJECT_ACTION } from './actions/project-service.actions';
import { UntypedFormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { RessourceCubeIoService } from './services/ressource-cube.io.service';

interface DateMonth {
	value: Date;
	viewValue: string;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnDestroy, OnInit, AfterContentInit, AfterViewInit {

	private reduxSubscription: Subscription = new Subscription();

	@ViewChild('LiquidityTable') oLiquidityTable: LiquidityTableComponent;
	@ViewChild('TabGroup') oTabGroup: MatTabGroup;

	REPORT_MODE = REPORT_MODE;
	environment = environment;

	public tabIndex = 0;
	public selectedTabIndex = new UntypedFormControl(0);

	public isCEO = false;

	message: string;
	messages: string[] = [];

	private _dateMonths: DateMonth[];
	public get dateMonths (): DateMonth[] {

		if (this._dateMonths === undefined) {
			const ar_oDateMonth: DateMonth[] = [];

			const startDate = new Date(2021, 6, 1);
			const endDate = new Date(new Date().getFullYear() + 4, 0, 1);

			let actDate = new Date(startDate);
			do {
				const viewValue: string = '' + actDate.getFullYear() + (actDate.getMonth() >= 6 ? '/' + (actDate.getFullYear() - 1999) : '');

				ar_oDateMonth.push({ value: actDate, viewValue });

				startDate.setMonth(startDate.getMonth() + 6);

				actDate = new Date(startDate);

			} while (actDate < endDate);

			this._dateMonths = ar_oDateMonth;
		}
		return this._dateMonths;
	}

	private _datesLiquidity: DateMonth[];
	public get datesLiquidity (): DateMonth[] {

		if (this._datesLiquidity === undefined) {
			const ar_oDateLiquidity: DateMonth[] = [];

			const startDate = new Date(2022, 0, 1);
			const endDate = new Date(new Date().getFullYear() + 6, 0, 1);

			let actDate = new Date(startDate);
			do {
				const viewValue: string = '' + actDate.getFullYear();

				ar_oDateLiquidity.push({ value: actDate, viewValue });

				startDate.setMonth(startDate.getMonth() + 12);

				actDate = new Date(startDate);

			} while (actDate < endDate);

			this._datesLiquidity = ar_oDateLiquidity;
		}
		return this._datesLiquidity;
	}

	selectedDateMonthView: Date = this.dateMonths[6].value;
	selectedDateMonthViewLabel: string = this.dateMonths[6].viewValue;

	selectedDateLiquidityView: Date = this.datesLiquidity[2].value;
	selectedDateLiquidityViewLabel: string = this.datesLiquidity[2].viewValue;

	public isExpanded = false;

	public demoMode = false;

	constructor (
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,

		private store: Store<AppState>,
		private _ChangeDetectorRef: ChangeDetectorRef,
		private dialog: MatDialog,

		public _jiraconnector: JiraconnectorService,
		public projectService: ProjectService,
		public liquidityService: LiquidityService,
		public socketService: SocketService,

		public tempoIoService: TempoIoService,
		public ressourceCubeIoService: RessourceCubeIoService,

		private translate: TranslateService
	) {
		translate.setDefaultLang('de');
		translate.use('de');
	}
	ngAfterViewInit (): void {
		setTimeout(() => {
			this.tabIndex = parseInt(LocalStorageWorker.instance.get('tabIndex'), 10);
			this.selectedTabIndex.setValue(this.tabIndex);
			this.refreshTabIndex();
			this.projectService.projectSubject.next(this.projectService.projects);

		}, 1000);

	}
	ngAfterContentInit (): void {
	}

	refreshTabIndex () {
		const localTabIndex = this.tabIndex;
		this.tabIndex = 0;

		this.selectedTabIndex.setValue(this.tabIndex);
		this._ChangeDetectorRef.detectChanges();

		this.tabIndex = localTabIndex;

		this.selectedTabIndex.setValue(this.tabIndex);

		this.oTabGroup.focusTab(this.tabIndex);

		this._ChangeDetectorRef.detectChanges();

	}

	public ngOnInit (): void {
		const _self = this;

		const demoMode = LocalStorageWorker.instance.get('demo-mode');
		if (demoMode === 'true') {
			this.demoMode = true;
		} else {
			this.demoMode = false;
		}
		this.setDemoMode();

		this.socketService
			.getMessages()
			.subscribe((message: any) => {
				this.messages.push(message.body);
				this._ChangeDetectorRef.detectChanges();
			});

		this.socketService
			.handleUserManagement()
			.subscribe((message: any) => {
				this.messages.push(message.body);

				if (this.socketService.sessionUser) {
					if (this.socketService.sessionUser.hasGroup('p-cockpit-ceos')) {
						this.isCEO = true;
					} else {
						this.isCEO = true;
					}

					this._ChangeDetectorRef.detectChanges();

					// this.refreshTabIndex();

				}

				this._ChangeDetectorRef.detectChanges();
			});

		this.socketService
			.handleDataChange()
			.subscribe((message: any) => {

				const dataDescription: DataDescription = JSON.parse(message.body);

				if (dataDescription.uuid !== this.socketService.sessionUser.getUUID()) {

					switch (message.subject) {
						case SOCKET_SUBJECT.DATA_CHANGE_PROJECT_LIST:

							this.store.dispatch(GetProjects());
							// this.projectService.getProjectsFromDataStore();

							break;

						case SOCKET_SUBJECT.DATA_CHANGE_PROJECT_DETAIL:

							break;

						case SOCKET_SUBJECT.DATA_CHANGE_LIQUIDITY_TABLE:

							this.oLiquidityTable.reloadLiqudityTableData();

							break;

						case SOCKET_SUBJECT.REQUEST_TEMPO_ACCESS:

							AP$.cookie.read('tempo_access_token', (tempo_access_token) => {
								AP$.cookie.read('tempo_refresh_token', (tempo_refresh_token) => {
									const dataDescription: DataDescription = new DataDescription();
									dataDescription.type = DATA_TYPE.SEND_TEMPO_ACCESS;
									dataDescription.body = {
										tempo_access_token,
										tempo_refresh_token
									};
									_self.socketService.dataChange(dataDescription);

								});
							});

							break;

						default:

							break;
					}

				}

				this.messages.push(message.body);
				this._ChangeDetectorRef.detectChanges();
			});

		this.reduxSubscription.add(this.store.select((state: AppState) => state.projectService).subscribe((state) => {
			switch (state.action) {
				case PROJECT_ACTION.SET_PROJECTS:

					if (this.projectService.hasShortProjects()) {
						this.store.dispatch(GetFullProjects());
					}

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.jiraApi).subscribe((state) => {
			switch (state.action) {
				case JIRA_ACTION.SET_SESSION_USER:
					const oSocketMessage: SocketMessage = new SocketMessage();
					oSocketMessage.subject = SOCKET_SUBJECT.USER_CONNECT;
					oSocketMessage.body = JSON.stringify(state.sessionUser);
					this.socketService.userManagement(oSocketMessage);

					break;

				default:
					break;
			}

		}));

		this.store.dispatch(GetSessionUser());
		this.store.dispatch(GetUsers());

		const projectListSubscribtions = this.projectService.projectSubject.subscribe(() => {
			projectListSubscribtions.unsubscribe();

			const monthDate_selectedDate = LocalStorageWorker.instance.get('monthDate_selectedDate');
			if (monthDate_selectedDate !== undefined && monthDate_selectedDate !== null) {

				const dateMonth = new Date(monthDate_selectedDate);

				const item = this.dateMonths.find((item) => item.value.getTime() === dateMonth.getTime());

				if (item) {
					this.selectedDateMonthView = item.value;
					this.selectedDateMonthViewLabel = item.viewValue;
				}
			}

			const liquidityDate_selectedDate = LocalStorageWorker.instance.get('liquidityDate_selectedDate');
			if (liquidityDate_selectedDate !== undefined && liquidityDate_selectedDate !== null) {

				const dateLiquidity = new Date(liquidityDate_selectedDate);

				const item = this.datesLiquidity.find((item) => item.value.getTime() === dateLiquidity.getTime());

				if (item) {
					this.selectedDateLiquidityView = item.value;
					this.selectedDateLiquidityViewLabel = item.viewValue;
				}
			}

			this._ChangeDetectorRef.detectChanges();

		});

		// this.projectService.getProjectsFromDataStore();

		// this._ChangeDetectorRef.detectChanges();

		this.store.dispatch(GetProjects());

	}

	@HostListener('window:beforeunload')
	async ngOnDestroy () {
		this.disconnectSessionUser();
	}

	public toggleMenu () {
		this.isExpanded = !this.isExpanded;
	}

	changeMonthDate (dateMonth: any) {
		const item = this.dateMonths.find((item) => item.value === dateMonth.value);

		if (item) {
			this.selectedDateMonthViewLabel = item.viewValue;

			LocalStorageWorker.instance.set('monthDate_selectedDate', item.value.toString());

			this._ChangeDetectorRef.detectChanges();
		}
	}

	changeLiquidityDate (dateLiquidity: any) {
		const item = this.datesLiquidity.find((item) => item.value === dateLiquidity.value);

		if (item) {
			this.selectedDateLiquidityViewLabel = item.viewValue;

			LocalStorageWorker.instance.set('liquidityDate_selectedDate', item.value.toString());

			this._ChangeDetectorRef.detectChanges();
		}
	}

	/*
  connectSessionUser(): void {
    this._jiraconnector.getSessionUser().subscribe(session => {
      session.userAgent = window.navigator.userAgent;

      const oSocketMessage: SocketMessage = new SocketMessage();
      oSocketMessage.subject = SOCKET_SUBJECT.USER_CONNECT;
      oSocketMessage.body = JSON.stringify(session);
      this.socketService.userManagement(oSocketMessage);
    });
  }
*/
	disconnectSessionUser (): void {
		const oSocketMessage: SocketMessage = new SocketMessage();
		oSocketMessage.subject = SOCKET_SUBJECT.USER_DISCONNECT;
		oSocketMessage.body = JSON.stringify(this.socketService.sessionUser);
		this.socketService.userManagement(oSocketMessage);
	}

	sendMessage (): void {
		const oSocketMessage: SocketMessage = new SocketMessage();
		oSocketMessage.body = this.message;
		this.socketService.sendMessage(oSocketMessage);
		this.message = '';
	}

	onSelectedTabChange (selectedIndex): void {
		// this.tabIndex = selectedIndex;
		this.selectedTabIndex.setValue(selectedIndex);

		LocalStorageWorker.instance.set('tabIndex', selectedIndex.toString());

		this.projectService.projectSubject.next(this.projectService.projects);

		if (selectedIndex === 1 || selectedIndex === 2 || selectedIndex === 3) { // } || this.tabIndex === 4) {
			try {
				this.oLiquidityTable.reloadLiqudityTableData();
			} catch (error) {}
		}

		this._ChangeDetectorRef.detectChanges();

	}

	copyProdToDev (): void {
		this.projectService.copyProdToDev();
		this.liquidityService.copyProdToDev();
	}

	handleChangeDemoMode (checked: boolean) {
		this.demoMode = !this.demoMode;

		LocalStorageWorker.instance.set('demo-mode', this.demoMode.toString());

		this.setDemoMode();
	}

	setDemoMode () {
		if (this.demoMode) {
			this.renderer.addClass(this.document.body, 'demo-mode');
		} else {
			this.renderer.removeClass(this.document.body, 'demo-mode');
		}
	}

}
