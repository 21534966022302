import { Action, createReducer, on } from '@ngrx/store';
import { GetFullProjects, GetProjects, PROJECT_ACTION, SetProjects } from '../actions/project-service.actions';
import { GetReportGeneratorList, GetReportGeneratorListComplete, PutReportGeneratorList, PutReportGeneratorListComplete, ReportGeneratorWorklogsRefreshed, ReportGeneratorWorklogsRefreshing, REPORT_GENERATOR_ACTION, SetActReportGenerator } from '../actions/report-generator-service.actions';
import { JiraReportGenerator } from '../model/JiraReportGenerator';
import { Project } from '../model/Project';

export const reportGeneratorServiceFeatureKey = 'reportGeneratorService';

export interface State {
	action: REPORT_GENERATOR_ACTION;
	reports: JiraReportGenerator[];
	actReport: JiraReportGenerator;
}

export const initialState: State = {
	action: REPORT_GENERATOR_ACTION.NOTHING,
	reports: undefined,
	actReport: undefined
};

export const reducer = createReducer(
	initialState,

	on(ReportGeneratorWorklogsRefreshing, (state) => ({ ...state, action: REPORT_GENERATOR_ACTION.REPORT_GENERATOR_WORKLOGS_REFRESHING })),
	on(ReportGeneratorWorklogsRefreshed, (state) => ({ ...state, action: REPORT_GENERATOR_ACTION.REPORT_GENERATOR_WORKLOGS_REFRESHED })),
	on(GetReportGeneratorList, (state) => ({ ...state, action: REPORT_GENERATOR_ACTION.GET_REPORT_GENERATOR_LIST })),
	on(GetReportGeneratorListComplete, (state, {reports}) => ({ ...state, action: REPORT_GENERATOR_ACTION.GET_REPORT_GENERATOR_LIST_COMPLETE, reports })),
	on(SetActReportGenerator, (state, {actReport}) => ({ ...state, action: REPORT_GENERATOR_ACTION.SET_ACT_REPORT_GENERATOR, actReport })),
	on(PutReportGeneratorList, (state, {reports}) => ({ ...state, action: REPORT_GENERATOR_ACTION.PUT_REPORT_GENERATOR_LIST, reports })),
	on(PutReportGeneratorListComplete, (state) => ({ ...state, action: REPORT_GENERATOR_ACTION.PUT_REPORT_GENERATOR_LIST_COMPLETE })),

);

