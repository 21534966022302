// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-checkbox {
  width: 1em;
  height: 1em;
  margin-right: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/invoice-paid-checkbox/invoice-paid-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;EACA,iBAAA;AACJ","sourcesContent":[".invoice-checkbox {\n    width: 1em;\n    height: 1em;\n    margin-right: 1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
