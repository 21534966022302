import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CLASS_TYPE, COLUMN_TYPE, PROJECT_TYPE } from 'src/app/model/enums';
import { JiraIssue } from 'src/app/model/JiraIssue';
import { JiraProject } from 'src/app/model/JiraProject';
import { JiraReportGenerator } from 'src/app/model/JiraReportGenerator';
import { LocalStorageWorker } from 'src/app/model/LocalStorageWorker';
import { IssueOrProject, ReportGeneratorService } from '../../services/report-generator.service';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { REPORT_GENERATOR_ACTION } from 'src/app/actions/report-generator-service.actions';

@Component({
	selector: 'app-report-generator-body',
	templateUrl: './report-generator-body.component.html',
	styleUrls: ['./report-generator-body.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGeneratorBodyComponent implements OnInit, OnDestroy {
	private _report: JiraReportGenerator;
	@Input()
	public get report(): JiraReportGenerator {
		return this._report;
	}
	public set report(value: JiraReportGenerator) {
			this._report = value;	
	}

	public reportName = new FormControl();
	public reportDescription = new FormControl();

	public range = new UntypedFormGroup({
		start: new UntypedFormControl(),
		end: new UntypedFormControl()
	});

	private reduxSubscription: Subscription = new Subscription();

	private ar_selectedMonth = [];

	public get selectedMonths(): any[] {

		if (this.ar_selectedMonth.length === 0 &&
			this.range.valid && this.range.value.start !== null && this.range.value.end !== null) {

			const dateBegin = this.range.value.start;
			const dateEnd = this.range.value.end;

			let startDate = moment(dateBegin).startOf('month');
			const endDate = moment(dateEnd).endOf('month');

			const ar_month = [];

			while (startDate.isBefore(endDate)) {
				const monthDescription = {
					key: moment(startDate).format('YYYY_M'),
					label: moment(startDate).format('MM/YY'),
					month: moment(startDate).format('YYYY_M'),
					date: moment(startDate).format('YYYY-MM-01')
				};
				ar_month.push(monthDescription);

				startDate = startDate.add(1, 'month');
			}
			this.ar_selectedMonth = ar_month;
		}

		return this.ar_selectedMonth;

	}

	COLUMN_TYPE = COLUMN_TYPE;
	PROJECT_TYPE = PROJECT_TYPE;
	Object = Object;
	Math = Math;
	JSON = JSON;

	constructor(
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private _jiraconnector: JiraconnectorService,
		private _reportGeneratorService: ReportGeneratorService
	) {
		this.reportName.valueChanges.subscribe((value) => {
			this.report.name = value;
		})
		this.reportDescription.valueChanges.subscribe((value) => {
			this.report.description = value;
		})
	}

	ngOnDestroy(): void {
		this.reduxSubscription.unsubscribe();
	}

	ngOnInit(): void {

		this.reduxSubscription.add(this.store.select((state: AppState) => state.reportGeneratorService).subscribe((state) => {
			switch (state.action) {
				case REPORT_GENERATOR_ACTION.SET_ACT_REPORT_GENERATOR:

					const actReport = state.actReport;

					this.range = new UntypedFormGroup({
						start: new UntypedFormControl(actReport.startDate),
						end: new UntypedFormControl(actReport.endDate)
					});
			
					this.reportName.setValue(actReport.name);
					this.reportDescription.setValue(actReport.description);
			
					this.refreshReportGeneratorService();
					
					this.report = actReport;

					this._ChangeDetectorRef.detectChanges();

					break;

				default:
					break;
			}

		}));
		this._ChangeDetectorRef.detectChanges();
	}

	private refreshReportGeneratorService() {
		this._reportGeneratorService.init(this.report?.startDate, this.report?.endDate).subscribe(res => res);
	}

	monthAdd(monthAdd: number) {
		const dateBegin = this.range.value.start;
		const dateEnd = this.range.value.end;

		const startDate = moment(dateBegin).add(monthAdd, 'M').startOf('month');
		const endDate = moment(dateEnd).add(monthAdd, 'M').endOf('month');

		this.report.startDate = startDate.toDate();
		this.report.endDate = endDate.toDate();

		this.range = new UntypedFormGroup({
			start: new UntypedFormControl(this.report?.startDate),
			end: new UntypedFormControl(this.report?.endDate)
		});

		this.dateRangeChange(undefined);
	}

	dateRangeChange($event) {

		if (!this.range.valid || this.range.value.start === null || this.range.value.end === null) {
			return;
		}

		const dateBegin = this.range.value.start;
		const dateEnd = this.range.value.end;
/*
		const selectedStartDate = moment(dateBegin).startOf('month');
		const selectedEndDate = moment(dateEnd).endOf('month');

		this.report.startDate = selectedStartDate.toDate();
		this.report.endDate = selectedEndDate.toDate();

		this.ar_selectedMonth = [];

		const startMonth = this.selectedMonths[0];
		const endMonth = this.selectedMonths[this.selectedMonths.length - 1];
		const endDate = new Date(new Date(new Date(endMonth.date).getFullYear(),
			new Date(endMonth.date).getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);
*/
		this.report.startDate = moment(dateBegin).toDate();
		this.report.endDate = moment(dateEnd).toDate();

		LocalStorageWorker.instance.set('report_generator_startDate', this.report?.startDate.toString());
		LocalStorageWorker.instance.set('report_generator_endDate', this.report?.endDate.toString());

		this.refreshReportGeneratorService();

		this._ChangeDetectorRef.detectChanges();
	}

	public handleWorklogsToSplit(issueOrProjects: IssueOrProject[]): void {
		this.report.worklogsToSplit = issueOrProjects ?? [];
	}

	public handleWorklogsToSum(issueOrProjects: IssueOrProject[]): void {
		this.report.worklogsToSum = issueOrProjects ?? [];
	}

}
