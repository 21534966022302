// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.sub-budget-row:hover {
  background: #EEE;
  cursor: pointer;
}

tr.edit-row {
  height: 0;
}

.text-center {
  text-align: center;
  margin: 0 auto;
}

.column-year {
  background: #CCC;
}

.column-c-q {
  width: 150px;
  max-width: 150px;
  padding: 0;
  padding-bottom: 1px;
  border-bottom: 1px solid #CCC;
}

.column-c-m {
  text-align: center;
  font-size: 0.85em;
  width: 8.25em;
  max-width: 8.25em;
  padding: 0;
  padding-bottom: 1px;
  border-bottom: 1px solid #CCC;
}

.column-name {
  padding: 0;
  padding-bottom: 1px;
  border-bottom: 1px solid #CCC;
}

.chip-color-green {
  background-color: green;
  color: white;
}

.mat-mdc-standard-chip {
  border-radius: 5px;
  min-height: 24px;
  padding: 0;
}

mat-mdc-chip {
  width: 90%;
  font: inherit;
  font-weight: 500;
}

mat-mdc-chip span {
  margin: 0 auto;
}

td.mat-cell:last-of-type {
  padding-right: 0;
}

td:first-child div {
  padding-left: 20px;
}

td:first-child:hover {
  background-color: #EEE;
}

.table-container {
  height: 100%;
  width: 100%;
  overflow: auto;
}

table {
  min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sub-budget-list/sub-budget-list.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,eAAA;AACD;;AAEA;EACC,SAAA;AACD;;AAEA;EACC,kBAAA;EACA,cAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,YAAA;EACA,gBAAA;EACA,UAAA;EACA,mBAAA;EACA,6BAAA;AACD;;AAEA;EACC,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,UAAA;EACA,mBAAA;EACA,6BAAA;AACD;;AAEA;EACC,UAAA;EACA,mBAAA;EACA,6BAAA;AACD;;AAEA;EACC,uBAAA;EACA,YAAA;AACD;;AAEA;EACC,kBAAA;EACA,gBAAA;EACA,UAAA;AACD;;AAEA;EACC,UAAA;EACA,aAAA;EACA,gBAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,gBAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,sBAAA;AACD;;AAEA;EACC,YAAA;EACA,WAAA;EACA,cAAA;AACD;;AAEA;EAEC,eAAA;AAAD","sourcesContent":["tr.sub-budget-row:hover {\n\tbackground: #EEE;\n\tcursor: pointer;\n}\n\ntr.edit-row {\n\theight: 0;\n}\n\n.text-center {\n\ttext-align: center;\n\tmargin: 0 auto;\n}\n\n.column-year {\n\tbackground: #CCC;\n}\n\n.column-c-q {\n\twidth: 150px;\n\tmax-width: 150px;\n\tpadding: 0;\n\tpadding-bottom: 1px;\n\tborder-bottom: 1px solid #CCC;\n}\n\n.column-c-m {\n\ttext-align: center;\n\tfont-size: 0.85em;\n\twidth: 8.25em;\n\tmax-width: 8.25em;\n\tpadding: 0;\n\tpadding-bottom: 1px;\n\tborder-bottom: 1px solid #CCC;\n}\n\n.column-name {\n\tpadding: 0;\n\tpadding-bottom: 1px;\n\tborder-bottom: 1px solid #CCC;\n}\n\n.chip-color-green {\n\tbackground-color: green;\n\tcolor: white;\n}\n\n.mat-mdc-standard-chip {\n\tborder-radius: 5px;\n\tmin-height: 24px;\n\tpadding: 0;\n}\n\nmat-mdc-chip {\n\twidth: 90%;\n\tfont: inherit;\n\tfont-weight: 500;\n}\n\nmat-mdc-chip span {\n\tmargin: 0 auto;\n}\n\ntd.mat-cell:last-of-type {\n\tpadding-right: 0;\n}\n\ntd:first-child div {\n\tpadding-left: 20px;\n}\n\ntd:first-child:hover {\n\tbackground-color: #EEE;\n}\n\n.table-container {\n\theight: 100%;\n\twidth: 100%;\n\toverflow: auto;\n}\n\ntable {\n\t//width: 2000px;\n\tmin-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
