import { Action, createReducer, on } from '@ngrx/store';
import { CAPABILITY_ACTION, GetCapabilities, SaveCapabilities, SetCapabilities } from '../actions/capability-service.actions';
import { Capability } from '../model/Capability';

export const capabilityServiceFeatureKey = 'capabilityService';

export interface State {
	action: CAPABILITY_ACTION;
	capabilities: Capability[];
}

export const initialState: State = {
	action: CAPABILITY_ACTION.NOTHING,
	capabilities: []
};

export const reducer = createReducer(
	initialState,

	on(GetCapabilities, (state) => ({ ...state, action: CAPABILITY_ACTION.GET_CAPABILITIES })),
	on(SetCapabilities, (state, {capabilities}) => ({ ...state, action: CAPABILITY_ACTION.SET_CAPABILITIES, capabilities })),
	on(SaveCapabilities, (state, {capabilities}) => ({ ...state, action: CAPABILITY_ACTION.SAVE_CAPABILITIES, capabilities })),

);

