import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Invoice } from 'src/app/model/Invoice';
import { ProjectService } from 'src/app/services/project.service';

@Component({
	selector: 'app-invoice-paid-checkbox',
	templateUrl: './invoice-paid-checkbox.component.html',
	styleUrls: ['./invoice-paid-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoicePaidCheckboxComponent implements OnInit {

	@ViewChild('actClearingDateDialog', {static: false}) actClearingDateDialog: TemplateRef<any>;

	@Input() public invoice: Invoice;
	@Output() refresh = new EventEmitter<any>();

	public selectedInvoice: Invoice;

	constructor (
		private dialog: MatDialog,
		public projectService: ProjectService,
		private _ChangeDetectorRef: ChangeDetectorRef
	) { }

	ngOnInit (): void {
	}

	saveInvoicePaid (oInvoice: Invoice) {
		// console.debug(oInvoice);

		this.selectedInvoice = oInvoice;

		if (oInvoice.project !== null) {
			const actDate = new Date();
			const clearingDateOrActDate = oInvoice.clearingDate > actDate ? oInvoice.clearingDate : actDate;

			const oldClearingDate = new Date(oInvoice.clearingDate);
			const oldPaid = oInvoice.paid;

			console.debug(oInvoice.clearingDate + ' !== ' + clearingDateOrActDate);

			const dialogRef = this.dialog.open(this.actClearingDateDialog);

			this._ChangeDetectorRef.detectChanges();

			dialogRef.afterClosed().subscribe((result) => {
				console.log(`Dialog result: ${result}`);

				if (result === 'confirm') {
					this.saveProject(this.selectedInvoice);
				} else {
					this.selectedInvoice.clearingDate = oldClearingDate;
					this.selectedInvoice.paid = oldPaid;
				}
			});

		}
	}

	saveProject (oInvoice: Invoice) {
		this.projectService.saveProject(oInvoice.project, () => {
			this.refresh.emit(oInvoice);
		});
	}

}
