
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { SubBudget } from 'src/app/model/SubBudget';
import { VIEW_STATE, VIEW_TYPE, PROJECT_TYPE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { ProjectService } from 'src/app/services/project.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { trackByFn } from 'src/app/model/globalFunctions';

@Component({
	selector: 'app-sub-budget-list',
	templateUrl: './sub-budget-list.component.html',
	styleUrls: ['./sub-budget-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubBudgetListComponent implements OnInit {

	@Input() public oProjectTable: MatTable<any>;
	@Input() public project: Project;
	// @Input() public subBudgets: SubBudget[];
	@Input() public columnsToDisplay: any[];
	@Input() public columnsToDisplayInfo: any[];
	@Input() type: VIEW_TYPE;

	public get subBudgets (): SubBudget[] {
		try {
			return this.project.subBudgets;
		} catch (error) {
			// do nothing
		}
	}

	@ViewChild('ProjectSubBudgetTable', {static: true}) oProjectSubBudgetTable: MatTable<any>;
	@ViewChild('deleteDialog', {static: true}) deleteDialog: TemplateRef<any>;

	selectedSubBudget: SubBudget;

	public expandedElement;

	public VIEW_STATE = VIEW_STATE;
	public VIEW_TYPE = VIEW_TYPE;
	public PROJECT_TYPE = PROJECT_TYPE;
	trackByFn = trackByFn;

	public state: VIEW_STATE = VIEW_STATE.ADD;
	public yearString = new Date().getFullYear();

	constructor (
		private _jiraconnector: JiraconnectorService,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private dialog: MatDialog,
		public projectService: ProjectService
	) { }

	ngOnInit () {
		setTimeout(() => {
			this._ChangeDetectorRef.detectChanges();
		}, 100);
	}

	public removeSubBudgetFromList (subBudget: SubBudget): void {
		// console.debug('Delete: ' + subBudget);

		this.selectedSubBudget = subBudget;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				this.subBudgets.splice(this.subBudgets.findIndex((item) => item.nameString === this.selectedSubBudget.nameString), 1);

				const oResult = this._jiraconnector.putProjectProperty(this.project.id, 'p-cockpit-project-data', this.project);
				oResult.subscribe((res: any) => {
					console.log('cockpit-projekt-list: ' + res);
					this.oProjectSubBudgetTable.dataSource = new MatTableDataSource( this.subBudgets );

					this.state = VIEW_STATE.ADD;
					this._ChangeDetectorRef.detectChanges();

					this.projectService.saveProjectsToDataStore();

				});

			}

		});

	}

}
