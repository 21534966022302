import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subscription, throwError, timer } from 'rxjs';
import * as moment from 'moment';
import { JiraconnectorService } from '../jiraconnector';
import { JiraIssue } from '../model/JiraIssue';
import { ProjectService } from './project.service';
import { TempoIoService, TEMPO_IO_URL } from './tempo.io.service';
import { JiraProject } from '../model/JiraProject';
import { CLASS_TYPE } from '../model/enums';
import { AppState } from '../reducers';
import { GetReportGeneratorListComplete, PutReportGeneratorListComplete, ReportGeneratorWorklogsRefreshed, ReportGeneratorWorklogsRefreshing, REPORT_GENERATOR_ACTION } from '../actions/report-generator-service.actions';
import { JiraReportGenerator } from '../model/JiraReportGenerator';

export interface IssueOrProject {
	type: CLASS_TYPE;
	id: string;
	name: string;
}

@Injectable({
	providedIn: 'root'
})
export class ReportGeneratorService {

	private reduxSubscription: Subscription = new Subscription();
	private allWorklogs: any[] = [];

    private startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
	private endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 3, 1, 0, 0, 0, 0).getTime() - 1000);

	constructor (
        @Inject(TEMPO_IO_URL) public tempoIoUrl: string,
        public tempoIoService: TempoIoService,
        public projectService: ProjectService,
		private store: Store<AppState>,
        private _jiraconnector: JiraconnectorService
    
        ) {
        // this.init(this.startDate, this.endDate).subscribe(res => res);  

		this.reduxSubscription.add(this.store.select((state: AppState) => state.reportGeneratorService).subscribe((state) => {
			switch (state.action) {
				case REPORT_GENERATOR_ACTION.GET_REPORT_GENERATOR_LIST:
					this.getReportGeneratorList().subscribe( (reports) => {
						this.store.dispatch(GetReportGeneratorListComplete({ reports }));
					});

					break;

				case REPORT_GENERATOR_ACTION.PUT_REPORT_GENERATOR_LIST:
					this.putReportGeneratorList(state.reports).subscribe( () => {
						this.store.dispatch(PutReportGeneratorListComplete());
					});

					break;

				default:
					break;
			}

		}));
    }

    private getAllWorklogs(startDate: Date, endDate: Date): Observable<any> {

		const startDateString = moment(startDate).format('YYYY-MM-DD');
		const endDateString = moment(endDate).format('YYYY-MM-DD');

		const monthDifference = moment(endDate).diff(moment(startDate), 'months', true);
		const limit = Math.round(monthDifference * 1500);

		const observableWorkloads = this.tempoIoService.getCachedAll<any>(
			this.tempoIoUrl + 'worklogs?from=' + startDateString + '&to=' + endDateString + '&limit=' + limit, {}, false
		);

		console.log('REPORT GENERATOR: GET ALL WORKLOADS');

		const observable = new Observable<JiraIssue[]>((observer) => {
			observableWorkloads.subscribe((res) => {

				if (res.results) {
					const ar_oItem = res.results;
					observer.next(ar_oItem);
					observer.complete();

				} else {
					observer.error();
				}

			});

		});

		return observable;
	}

	public getReportGeneratorList(): Observable<JiraReportGenerator[]> {
		const observableReportGeneratorList = this._jiraconnector.getCockpitReportGeneratorList();

		console.log('REPORT GENERATOR: GET REPORT GENERATOR LIST');

		const observable = new Observable<JiraReportGenerator[]>((observer) => {
			observableReportGeneratorList.subscribe((json) => {

				if (json.value) {

					const reports: JiraReportGenerator[] = [];

					for (const item of json.value) {

						const report: JiraReportGenerator = new JiraReportGenerator();
						Object.assign(report, item);

						reports.push(report);
					}

					observer.next(reports);
					observer.complete();

				} else {
					observer.error();
				}

			});
		});

		return observable;
	}

	public putReportGeneratorList(reports: JiraReportGenerator[]): Observable<any> {
		const observableReportGeneratorList = this._jiraconnector.putCockpitReportGeneratorList(reports);

		console.log('REPORT GENERATOR: PUT REPORT GENERATOR LIST');

		const observable = new Observable<any>((observer) => {
			observableReportGeneratorList.subscribe((json) => {
				observer.next({});
				observer.complete();
			});
		});

		return observable;
	}

    public init(startDate: Date, endDate: Date): Observable<any> {
        this.startDate = startDate;
        this.endDate = endDate;

		const observable = new Observable<any>((observer) => {

			this.store.dispatch(ReportGeneratorWorklogsRefreshing());

			this.getAllWorklogs(this.startDate, this.endDate).subscribe((res) => {
                this.allWorklogs = res;

				this.store.dispatch(ReportGeneratorWorklogsRefreshed());

                observer.next({init: true});
                observer.complete();
            }, error => {
                observer.error(error);
            });  
        });

        return observable;
    }

	public getIssueOrProjectsWorklogs(issueOrProjects: IssueOrProject[]): Observable<Array<JiraIssue | JiraProject>[]> {

		const observable = new Observable<Array<JiraIssue | JiraProject>[]>((observer) => {

			const issueOrProjectsObservables: Observable<Array<JiraIssue | JiraProject>>[] = [];

			for (const issueOrProject of issueOrProjects) {
				if (issueOrProject.type === CLASS_TYPE.JIRA_ISSUE) {
					issueOrProjectsObservables.push(this.getIssueWithSubIssuesWorklogs(issueOrProject.id));
				}
			}

			const projectIds = [];
			for (const issueOrProject of issueOrProjects) {
				if (issueOrProject.type === CLASS_TYPE.JIRA_PROJECT) {
					projectIds.push(issueOrProject.id);
				}
			}
			if (projectIds.length > 0) {
				issueOrProjectsObservables.push(this.getProjectsWithSubIssuesWorklogs(projectIds));
			}

			const allObservablesSubscribtions = forkJoin(issueOrProjectsObservables).subscribe((responses) => {
				observer.next(responses);
				observer.complete();
			});

		});

		return observable;
	}

	public getIssueWithSubIssuesWorklogs(issueId: string): Observable<JiraIssue[]> {
		console.log('REPORT GENERATOR: GET ISSUE WORKLOADS');

		const observableJiraIssues = this._jiraconnector.getSearchAll(`issue = ${issueId} OR (parent = ${issueId} OR key in linkedIssues("${issueId}"))`);

		const observable = new Observable<JiraIssue[]>((observer) => {
			this.getJQLIssueWorklogs(observableJiraIssues).subscribe((ar_oIssue) => {
				const resultJiraIssues: JiraIssue[] = [];

				const jiraIssue = ar_oIssue.find((issue: JiraIssue) => issue.id === issueId);
				if (jiraIssue) {
					resultJiraIssues.push(jiraIssue);
				}
				observer.next(resultJiraIssues);
				observer.complete();
			});
		});
		return observable;
	}

	public getEpicWithSubIssuesWorklogs(epicId: string): Observable<JiraIssue[]> {
		console.log('REPORT GENERATOR: GET EPIC WORKLOADS');

		const observableJiraIssues = this._jiraconnector.getSearchAll(`issue = ${epicId} OR parentEpic in (${epicId})`);

		const observable = new Observable<JiraIssue[]>((observer) => {
			this.getJQLIssueWorklogs(observableJiraIssues).subscribe((ar_oIssue) => {
				const resultJiraIssues: JiraIssue[] = [];

				const jiraIssue = ar_oIssue.find((issue: JiraIssue) => issue.id === epicId);
				if (jiraIssue) {
					resultJiraIssues.push(jiraIssue);
				}
				observer.next(resultJiraIssues);
				observer.complete();
			});
		});
		return observable;
	}

	public getEpicsWithSubIssuesWorklogs(epicIds: string[]): Observable<JiraIssue[]> {
		console.log('REPORT GENERATOR: GET EPICS WORKLOADS');

		const jqlParts: string[] = [];
		for (const epicId of epicIds) {
			jqlParts.push(`issue = ${epicId} OR parentEpic in (${epicId})`);
		}

		const observableJiraIssues = this._jiraconnector.getSearchAll(jqlParts.join(' OR '));

		const observable = new Observable<JiraIssue[]>((observer) => {
			this.getJQLIssueWorklogs(observableJiraIssues).subscribe((ar_oIssue) => {
				const resultJiraIssues: JiraIssue[] = [];

				for (const epicId of epicIds) {
					const jiraIssue = ar_oIssue.find((issue: JiraIssue) => issue.id === epicId);
					if (jiraIssue) {
						resultJiraIssues.push(jiraIssue);
					}
				}

				observer.next(resultJiraIssues);
				observer.complete();
			});
		});
		return observable;
	}

	public getProjectsWithSubIssuesWorklogs(projectIds: string[]): Observable<JiraProject[]> {
		console.log('REPORT GENERATOR: GET PROJECTS WORKLOADS');

		const jqlParts: string[] = [];
		for (const projectId of projectIds) {
			jqlParts.push(`project = ${projectId}`);
		}

		const observableJiraIssues = this._jiraconnector.getSearchAll(jqlParts.join(' OR '));

		const observable = new Observable<JiraProject[]>((observer) => {
			this.getJQLIssueWorklogs(observableJiraIssues).subscribe((ar_oIssue) => {
				const resultJiraIssues = ar_oIssue.filter((jiraIssue: JiraIssue) =>
					projectIds.indexOf(jiraIssue.projectId) !== -1 &&
					jiraIssue.allTimeSpentSeconds !== 0 &&
					jiraIssue.parentId === undefined
				);

				const jiraProjects: JiraProject[] = [];
				for (const projectId of projectIds) {
					const jiraProject = new JiraProject();
					jiraProject.id = projectId;
					jiraProject.childIssues = resultJiraIssues.filter((jiraIssue: JiraIssue) => jiraIssue.projectId === projectId);
					jiraProject.name = jiraProject.childIssues[0]?.projectName;

					jiraProjects.push(jiraProject);
				}

				observer.next(jiraProjects);
				observer.complete();
			});
		});
		return observable;
	}

	public getJQLIssueWorklogs(observableJiraIssues: Observable<any>): Observable<JiraIssue[]> {
		console.log('REPORT GENERATOR: GET JQL WORKLOADS');

		//		const observableJiraIssues = this._jiraconnector.getSearch('project = TEAM1KLANG');
		//		const observableJiraIssues = this._jiraconnector.getSearch('issue = TEAM1KLANG-12 OR (parent = TEAM1KLANG-12 OR key in linkedIssues("TEAM1KLANG-12"))');
		//		const observableJiraIssues = this._jiraconnector.getSearchAll('issue = DHTD-47 OR parentEpic in (DHTD-47)');

		const observable = new Observable<JiraIssue[]>((observer) => {
			observableJiraIssues.subscribe((res) => {

				const ar_oIssue: JiraIssue[] = [];

				if (res.issues) {

					for (const jsonIssue of res.issues) {
						const issue = new JiraIssue();
						issue.id = jsonIssue.key;
						issue.name = jsonIssue.fields.summary;

						issue.parentId = jsonIssue.fields?.parent?.key;
						issue.projectId = jsonIssue.fields?.project?.key;
						issue.projectName = jsonIssue.fields?.project?.name;

						ar_oIssue.push(issue);
					}

					for (const jiraIssue of ar_oIssue) {
						if (jiraIssue.parentId) {
							const parentJiraIssue = ar_oIssue.find((issue) => issue.id === jiraIssue.parentId);
							parentJiraIssue?.childIssues.push(jiraIssue);
						}
					}

					this.injectWorkloadsToIssues(ar_oIssue, this.allWorklogs);

					observer.next(ar_oIssue);
					observer.complete();

				} else {
					observer.error();
				}

			});

		});

		return observable;

	}

	public injectWorkloadsToIssues(jiraIssues: JiraIssue[], allWorklogs: any[]) {
		for (const jiraIssue of jiraIssues) {
			jiraIssue.timeSpentSeconds = 0;
		}

		for (const item of allWorklogs) {
			const issueId = item.issue.key;
			const timeSpentSeconds = item.timeSpentSeconds * 1;

			const jiraIssue = jiraIssues.find((issue) => issue.id === issueId);
			if (jiraIssue) {
				jiraIssue.timeSpentSeconds += timeSpentSeconds;
			}

		}
	}

}