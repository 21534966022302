// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weekend {
  background-color: #DDD;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.container {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  text-align: center;
  font-size: 14px;
  background-color: #FFF !important;
  position: relative;
  width: 100%;
  margin-bottom: 2em;
}

.timesheet-print-name {
  text-align: left;
  min-width: 20em;
}

.timesheet-print-name-small {
  text-align: left;
  min-width: 20em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/time-statement-hourly-rate-table/time-statement-hourly-rate-table.component.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,iBAAA;AACD;;AAEA;EACC,iDAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;EACA,kBAAA;EACA,WAAA;EAGA,kBAAA;AADD;;AAIA;EACC,gBAAA;EACA,eAAA;AADD;;AAIA;EACC,gBAAA;EACA,eAAA;AADD","sourcesContent":[".weekend {\n\tbackground-color: #DDD;\n}\n\n.text-center {\n\ttext-align: center;\n}\n\n.bold {\n\tfont-weight: bold;\n}\n\n.container {\n\tfont-family: Roboto, 'Helvetica Neue', sans-serif;\n\ttext-align: center;\n\tfont-size: 14px;\n\tbackground-color: #FFF !important;\n\tposition: relative;\n\twidth: 100%;\n\n\t// height: calc(100vw * 0.7071);\n\tmargin-bottom: 2em;\n}\n\n.timesheet-print-name {\n\ttext-align: left;\n\tmin-width: 20em;\n}\n\n.timesheet-print-name-small {\n\ttext-align: left;\n\tmin-width: 20em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
