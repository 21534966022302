// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-standard-chip {
  border-radius: 5px;
  min-height: 24px;
  padding: 0;
}

mat-mdc-chip {
  width: 90%;
  min-width: 2em;
  font: inherit;
}

mat-mdc-chip span {
  margin: 0 auto;
}

.workload-max-width {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 30px;
  max-height: 20px;
  text-align: center;
  font-weight: bold;
}

.planned-time-max-width {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 30px;
  max-height: 20px;
  text-align: center;
  font-weight: bold;
}

.timesheet-max-width {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 30px;
  max-height: 1em;
}

.planned-time label {
  font-size: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cell-item-renderer/cell-item-renderer.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,gBAAA;EACA,UAAA;AACD;;AAEA;EACC,UAAA;EACA,cAAA;EACA,aAAA;AACD;;AAIA;EACC,cAAA;AADD;;AAIA;EACC,cAAA;EACA,iBAAA;EAGA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AAHD;;AAMA;EACC,cAAA;EACA,iBAAA;EAGA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AALD;;AAQA;EACC,cAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;AALD;;AAQA;EACC,cAAA;AALD","sourcesContent":[".mat-mdc-standard-chip {\n\tborder-radius: 5px;\n\tmin-height: 24px;\n\tpadding: 0;\n}\n\nmat-mdc-chip {\n\twidth: 90%;\n\tmin-width: 2em;\n\tfont: inherit;\n\n\t//font-weight: 500;\n}\n\nmat-mdc-chip span {\n\tmargin: 0 auto;\n}\n\n.workload-max-width {\n\tpadding-top: 0;\n\tpadding-bottom: 0;\n\n\t//  font-size: 11px;\n\tmax-width: 30px;\n\tmax-height: 20px;\n\ttext-align: center;\n\tfont-weight: bold;\n}\n\n.planned-time-max-width {\n\tpadding-top: 0;\n\tpadding-bottom: 0;\n\n\t// font-size: 11px;\n\tmax-width: 30px;\n\tmax-height: 20px;\n\ttext-align: center;\n\tfont-weight: bold;\n}\n\n.timesheet-max-width {\n\tpadding-top: 0;\n\tpadding-bottom: 0;\n\tmax-width: 30px;\n\tmax-height: 1em;\n}\n\n.planned-time label {\n\tfont-size: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
