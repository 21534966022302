// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .requirement-analysis-form-field .mat-form-field-wrapper {
  padding-bottom: 1.25em !important;
}
:host ::ng-deep .requirement-analysis-form-field .mat-form-field-infix {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/requirement-analysis-project-group-selector/requirement-analysis-project-group-selector.component.scss"],"names":[],"mappings":"AAEC;EACC,iCAAA;AADF;AAIC;EACC,UAAA;AAFF","sourcesContent":[":host ::ng-deep .requirement-analysis-form-field {\n\n\t.mat-form-field-wrapper {\n\t\tpadding-bottom: 1.25em !important;\n\t}\n\n\t.mat-form-field-infix {\n\t\tpadding: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
