import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef, Input, ViewChildren, AfterViewInit } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { SubBudget } from 'src/app/model/SubBudget';
import { PROJECT_TYPE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { ProjectService } from 'src/app/services/project.service';
import { REPORT_MODE } from '../../model/enums';
import { SelectProjectDialogComponent } from '../select-project-dialog/select-project-dialog.component';
import { trackByFn } from 'src/app/model/globalFunctions';
import { GetFullProjects, GetProjects } from 'src/app/actions/project-service.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';

@Component({
	selector: 'app-project-list',
	templateUrl: './project-list.component.html',
	styleUrls: ['./project-list.component.scss'],
	/*
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  */
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class ProjectListComponent implements OnInit, AfterViewInit {
//  @ViewChild('selectProjectDialog', { static: false }) oSelectedProjectDialog: SelectProjectDialogComponent;
//  @ViewChild('ProjectTable', {static: false}) oProjectTable: MatTable<any>;
	@ViewChild('deleteDialog', {static: false}) deleteDialog: TemplateRef<any>;

	@ViewChildren('ProjectTable') oProjectTable: MatTable<any>;
	@ViewChildren('selectProjectDialog') oSelectedProjectDialog: SelectProjectDialogComponent;
	//  @ViewChildren('deleteDialog') deleteDialog: TemplateRef<any>;

	REPORT_MODE = REPORT_MODE;
	PROJECT_TYPE = PROJECT_TYPE;
	trackByFn = trackByFn;

	private _selectedDateMonthView: Date = new Date();

	@Input() public reportMode: REPORT_MODE = REPORT_MODE.MONTH_FULL_YEAR;
	@Input() public projectList;
	@Input() public isSubProjectList = false;
	@Input() public get selectedDateMonthView (): Date {
		return this._selectedDateMonthView;
	}
	public set selectedDateMonthView (value: Date) {
		this._selectedDateMonthView = value;
		this.previousYearString = this._selectedDateMonthView.getFullYear() - 1;
		this.yearString = this._selectedDateMonthView.getFullYear();
		this.nextYearString = this._selectedDateMonthView.getFullYear() + 1;

		this.setColumns();
	}

	public expandedElement;

	public previousYearString = this._selectedDateMonthView.getFullYear() - 1;
	public yearString = this._selectedDateMonthView.getFullYear();
	public nextYearString = this._selectedDateMonthView.getFullYear() + 1;

	public columnsToDisplay     = [];
	public columnsToDisplayInfo = {};

	public selectedProject: Project;

	private storedOverAllValues = {};
	public showSpinner = false;

	constructor (
		private store: Store<AppState>,
		private _jiraconnector: JiraconnectorService,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private dialog: MatDialog,
		public projectService: ProjectService
	) {
	}
	ngAfterViewInit (): void {
		//    throw new Error('Method not implemented.');

		this.setColumns();

		if (this.isSubProjectList !== true) {
			this.projectService.projectSubject.subscribe((res) => {

				if (this.projectService.projects.length === 0) {
					this.store.dispatch(GetProjects());
				} else {
					if (this.projectService.hasShortProjects()) {
						this.store.dispatch(GetFullProjects());
					}
				}

				this._ChangeDetectorRef.detectChanges();
			});
		}
	}

	ngOnInit () {
		console.log('Component initialised!');

		const projectServiceSubscription = this.projectService.projectSubject.subscribe((res) => {

			if (this.isSubProjectList !== true) {
				if (this.projectService.projects.length === 0) {
					this.store.dispatch(GetProjects());
				} else {
					if (this.projectService.hasShortProjects()) {
						this.store.dispatch(GetFullProjects());
					}
				}

				projectServiceSubscription.unsubscribe();

				this._ChangeDetectorRef.detectChanges();
			}
		});

		if (this.isSubProjectList !== true) {
			if (this.projectService.projects.length === 0) {
				this.store.dispatch(GetProjects());
			} else {
				this.store.dispatch(GetFullProjects());
			}
		}
	}

	handleSelectedProjects (result) {
		this.projectService.addProjectsToList(result);
	}

	setColumns () {
		console.log('Component initialised!');

		const columns = [];
		const columnsInfo = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = {class: 'c-name', title: 'Name'};

		let sClass = 'c-m';

		if (this.selectedDateMonthView.getMonth() >= 6) {

			if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
				for (let iCount = 1; iCount <= 12; iCount++) {

					const sMonth = ((iCount < 10) ? '0' : '') + iCount;

					colName = 'm' + sMonth;
					columns.push(colName);

					columnsInfo[colName] = {class: sClass, key: this.yearString + '_' + iCount, title: sMonth + ' / ' + this.yearString};
				}
			} else {
				sClass = 'c-q';

				for (let iCount = 1; iCount <= 4; iCount++) {

					const sQuarter = ((iCount < 10) ? '0' : '') + iCount;

					colName = 'q' + sQuarter;
					columns.push(colName);

					columnsInfo[colName] = {class: sClass, key: this.yearString + '_q' + iCount, title: 'Q' + iCount + ' / ' + this.yearString};
				}
			}

			colName = 'year';
			columns.push(colName);
			columnsInfo[colName] = {class: sClass + ' column-year', key: this.yearString + '_year', title: this.yearString};

			if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
				for (let iCount = 1; iCount <= 6; iCount++) {

					const sMonth = ((iCount < 10) ? '0' : '') + iCount;

					colName = 'm' + this.nextYearString + sMonth;
					columns.push(colName);

					columnsInfo[colName] = {class: sClass, key: this.nextYearString + '_' + iCount,
						title: sMonth + ' / ' + this.nextYearString, isEditable: true};
				}
			}
		} else {

			if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
				for (let iCount = 7; iCount <= 12; iCount++) {

					const sMonth = ((iCount < 10) ? '0' : '') + iCount;

					colName = 'm' + this.previousYearString + sMonth;
					columns.push(colName);

					columnsInfo[colName] = {class: sClass, key: this.previousYearString + '_' + iCount,
						title: sMonth + ' / ' + this.previousYearString, isEditable: true};
				}
			}

			colName = 'previous_year';
			columns.push(colName);
			columnsInfo[colName] = {class: sClass + ' column-year', key: this.previousYearString + '_year', title: this.previousYearString};

			if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
				for (let iCount = 1; iCount <= 12; iCount++) {

					const sMonth = ((iCount < 10) ? '0' : '') + iCount;

					colName = 'm' + sMonth;
					columns.push(colName);

					columnsInfo[colName] = {class: sClass, key: this.yearString + '_' + iCount, title: sMonth + ' / ' + this.yearString};
				}
			} else {
				sClass = 'c-q';

				for (let iCount = 1; iCount <= 4; iCount++) {

					const sQuarter = ((iCount < 10) ? '0' : '') + iCount;

					colName = 'q' + sQuarter;
					columns.push(colName);

					columnsInfo[colName] = {class: sClass, key: this.yearString + '_q' + iCount, title: 'Q' + iCount + ' / ' + this.yearString};
				}
			}

			colName = 'year';
			columns.push(colName);
			columnsInfo[colName] = {class: sClass + ' column-year', key: this.yearString + '_year', title: this.yearString};

		}

		colName = 'tools';
		columns.push(colName);
		columnsInfo[colName] = {class: sClass, title: 'Tools'};

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;

		this.refreshProjectTable();

		try {
			// this._ChangeDetectorRef.detectChanges();
		} catch (error) {

		}

	}

	public removeProjectFromList (project: Project): void {
		// console.debug('Delete: ' + project);

		this.selectedProject = project;

		this._ChangeDetectorRef.detectChanges();

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				this.projectService.removeProjectFromList(project);
			}

		});

	}

	public getSelectedProjectData (project: Project) {

		this.storedOverAllValues = {};

		if (project != null && project.type === PROJECT_TYPE.SHORT) {

			this.showSpinner = true;
			this._ChangeDetectorRef.detectChanges();

			const projectData = this._jiraconnector.getProjectProperty(project.id, 'p-cockpit-project-data');

			const projectDataSubscribe = projectData.subscribe((res) => {
				projectDataSubscribe.unsubscribe();

				project.setType(PROJECT_TYPE.FULL);

				const ar_subBudget: SubBudget[] = [];
				if (res.value && res.value.subBudgets) {
					for (const oItem of res.value.subBudgets) {

						const oSubBudget = new SubBudget();
						Object.assign(oSubBudget, oItem);

						// oSubBudget.name = 'Test Budget';
						// oSubBudget.startDate = new Date();
						// oSubBudget.endDate = new Date();

						ar_subBudget.push(oSubBudget);
					}
				}
				project.subBudgets = ar_subBudget;

				setTimeout(() => {
					this.showSpinner = false;

					this.refreshProjectTable();

					this._ChangeDetectorRef.detectChanges();
				}, 500);

			}, (complete) => {

				setTimeout(() => {
					this.showSpinner = false;
					this._ChangeDetectorRef.detectChanges();
				}, 300);

			});
		} else {
			this._ChangeDetectorRef.detectChanges();
			setTimeout(() => {
				this.showSpinner = false;
				this._ChangeDetectorRef.detectChanges();
			}, 300);
		}
	}

	public getOverAllValue (key: string) {
		let storedValue = this.storedOverAllValues[key];
		if (storedValue === null || storedValue === undefined || storedValue === 0) {
			storedValue = 0;
			for (const oProject of this.projectService.projects) {
				storedValue += oProject.getValue(key) || 0;
			}
			this.storedOverAllValues[key] = storedValue;
		}
		// storedValue = 1;
		return storedValue;
	}

	public refreshProjectTable () {
		try {
			this.oProjectTable.renderRows();
		} catch (error) {}
	}

}
