import { createAction, props } from '@ngrx/store';
import { User } from '../model/User';

export enum JIRA_ACTION {
	NOTHING = 'NOTHING',
	GET_USERS = 'GET_USERS',
	SET_USERS = 'SET_USERS',
	GET_SESSION_USER = 'GET_SESSION_USER',
	SET_SESSION_USER = 'SET_SESSION_USER',
}

export const GetSessionUser = createAction(
	'[GetSessionUser] Get Session User'
);

export const SetSessionUser = createAction(
	'[SetSessionUser] Set Session User',
	props<{ sessionUser: User }>()
);

export const GetUsers = createAction(
	'[GetUsers] Get Users'
);

export const SetUsers = createAction(
	'[SetUsers] Set Users',
	props<{ users: User[] }>()
);
