import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-jira-user-selector',
	templateUrl: './jira-user-selector.component.html',
	styleUrls: ['./jira-user-selector.component.scss']
})
export class JiraUserSelectorComponent implements OnInit {

	constructor () { }

	ngOnInit (): void {
	}

}
