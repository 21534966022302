import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { GetReportGeneratorList, PutReportGeneratorList, REPORT_GENERATOR_ACTION, SetActReportGenerator } from 'src/app/actions/report-generator-service.actions';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CLASS_TYPE, COLUMN_TYPE, PROJECT_TYPE } from 'src/app/model/enums';
import { JiraIssue } from 'src/app/model/JiraIssue';
import { JiraProject } from 'src/app/model/JiraProject';
import { JiraReportGenerator } from 'src/app/model/JiraReportGenerator';
import { LocalStorageWorker } from 'src/app/model/LocalStorageWorker';
import { AppState } from 'src/app/reducers';
import { IssueOrProject, ReportGeneratorService } from '../../services/report-generator.service';
import {v4 as uuidv4} from 'uuid';

export interface Tile {
	color: string;
	cols: number;
	rows: number;
	text: string;
  }

@Component({
	selector: 'app-report-generator',
	templateUrl: './report-generator.component.html',
	styleUrls: ['./report-generator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGeneratorComponent implements OnInit, AfterContentInit, OnDestroy {

	@ViewChild('deleteDialog', {static: false}) deleteDialog: TemplateRef<any>;

	public reports: JiraReportGenerator[] = [];

	public get reportsToShow(): JiraReportGenerator[] {
		return [...this.reports, undefined];
	}

	public actReport: JiraReportGenerator;

	public treeIssueOrProjects: Array<JiraIssue | JiraProject>[] = [];

	public allWorklogs: any[] = [];

	public myControl = new FormControl();
	public resultData: { type: CLASS_TYPE, id: string, name: string }[] = [];

	private reduxSubscription: Subscription = new Subscription();

	public isInitalizing = true;

	public startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
	public endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 3, 1, 0, 0, 0, 0).getTime() - 1000);

	public screenWidth: number;
	public screenHeight: number;

	public groupingMode?: string = 'standard';
	public get groupingModeSuffix(): string {
		return (this.groupingMode && this.groupingMode !== 'standard') ? '-' + this.groupingMode : '';
	}

	range = new UntypedFormGroup({
		start: new UntypedFormControl(this.startDate),
		end: new UntypedFormControl(this.endDate)
	});

	private ar_selectedMonth = [];

	public get selectedMonths(): any[] {

		if (this.ar_selectedMonth.length === 0 &&
			this.range.valid && this.range.value.start !== null && this.range.value.end !== null) {

			const dateBegin = this.range.value.start;
			const dateEnd = this.range.value.end;

			let startDate = moment(dateBegin).startOf('month');
			const endDate = moment(dateEnd).endOf('month');

			const ar_month = [];

			while (startDate.isBefore(endDate)) {
				const monthDescription = {
					key: moment(startDate).format('YYYY_M'),
					label: moment(startDate).format('MM/YY'),
					month: moment(startDate).format('YYYY_M'),
					date: moment(startDate).format('YYYY-MM-01')
				};
				ar_month.push(monthDescription);

				startDate = startDate.add(1, 'month');
			}
			this.ar_selectedMonth = ar_month;
		}

		return this.ar_selectedMonth;

	}

	COLUMN_TYPE = COLUMN_TYPE;
	PROJECT_TYPE = PROJECT_TYPE;
	Object = Object;
	Math = Math;
	JSON = JSON;

	constructor(
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private _jiraconnector: JiraconnectorService,
		private dialog: MatDialog,
		private _reportGeneratorService: ReportGeneratorService
	) {
/*
		let report = new JiraReportGenerator();
		report.name = 'Report 1';
		report.description = 'Dies ist ein Report für den Zeitraum 01.09.2023-30.09.2023';
		report.startDate = new Date(2023, 8, 1, 0, 0, 0);
		report.endDate = new Date(2023, 8, 30, 0, 0, 0);
		this.reports.push(report);

		report = new JiraReportGenerator();
		report.name = 'Report 2';
		report.description = 'Dies ist ein Report für den Zeitraum 01.10.2023-31.10.2023';
		report.startDate = new Date(2023, 9, 1, 0, 0, 0);
		report.endDate = new Date(2023, 9, 31, 0, 0, 0);
		this.reports.push(report);
*/

	}

	filter(keyOrName: string): Observable<any[]> {
		return this.getData(keyOrName)
			.pipe(
				map(response => response)
			)
	}

	ngOnDestroy(): void {
		this.reduxSubscription.unsubscribe();
	}

	ngAfterContentInit(): void {
		//    console.log('REQUIREMENT ANALYSIS: ngAfterContentInit');

	}

	ngOnInit(): void {
		this.screenWidth = window.innerWidth;
		this.screenHeight = window.innerHeight;

		this.reduxSubscription.add(this.store.select((state: AppState) => state.reportGeneratorService).subscribe((state) => {
			switch (state.action) {
				case REPORT_GENERATOR_ACTION.GET_REPORT_GENERATOR_LIST_COMPLETE:
					this.reports = state.reports;

					this._ChangeDetectorRef.detectChanges();

					break;

				default:
					break;
			}

		}));

		this.store.dispatch(GetReportGeneratorList());

	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
	  this.screenWidth = window.innerWidth;
	  this.screenHeight = window.innerHeight;
	}

	editReport(report: JiraReportGenerator): void {
		this.actReport = report;

		this.store.dispatch(SetActReportGenerator({actReport: this.actReport}));

	}

	cloneReport(report: JiraReportGenerator): void {
		let cloneReport = new JiraReportGenerator();
		cloneReport = Object.assign(cloneReport, report);
		cloneReport.name = cloneReport.name + ' (Kopie)';
		cloneReport.id = uuidv4();

		this.reports.push(cloneReport);

		this.actReport = cloneReport;

		this.saveReports();
	}

	public deleteReport (report: JiraReportGenerator): void {
		this.actReport = report;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {

				const index = this.reports?.findIndex((item) => item.id === report.id);
				if (index !== -1) {
					this.reports.splice(index, 1);

					this.actReport = undefined;

					this._ChangeDetectorRef.detectChanges();

					this.saveReports();				
				}				

			}

		});

	}

	addReport(): void {
		let newReport = new JiraReportGenerator();
		newReport.name = 'Report ' + (this.reports.length + 1);
		newReport.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0);
		newReport.endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);
	
		this.reports.push(newReport);

		this.actReport = newReport;

		this.saveReports();
	}

	getData(nameOrKey: string) {
/*
		return this._jiraconnector.searchProjects('&query=' + nameOrKey).pipe(map(res => {
				const resultData: { type: CLASS_TYPE, id: string, name: string }[] = [];
				for (const item of res.values) {
					const projectObj = { type: CLASS_TYPE.JIRA_PROJECT, id: item.key, name: item.name };
					resultData.push(projectObj);
				}
				this.resultData = resultData;
				return resultData;
			}));
*/
		if (nameOrKey.indexOf('-') !== -1) {
			return this._jiraconnector.getSearchAll(`issue = ${nameOrKey} OR (parent = ${nameOrKey} OR key in linkedIssues("${nameOrKey}"))`).pipe(
				map(res => res?.issues?.map((item: any) => ({ type: CLASS_TYPE.JIRA_ISSUE, id: item.key, name: ((item.fields?.parent) ? 'Sub: ' : '') + item.fields?.summary })))
			);	
		} 

		return this._jiraconnector.searchProjects('&query=' + nameOrKey).pipe(
			map(res => res?.values?.map((item: any) => ({ type: CLASS_TYPE.JIRA_PROJECT, id: item.key, name: item.name })))
		);

	}

	handleRefreshed(issueOrProjects: IssueOrProject[]): void {
		console.log('refreshed');
		console.dir(issueOrProjects);

		this._reportGeneratorService.getIssueOrProjectsWorklogs(issueOrProjects).subscribe(res => {
			console.log('getIssueOrProjectsWorklogs -> result');
			console.dir(res);

			this.treeIssueOrProjects = res;

			this._ChangeDetectorRef.detectChanges();

		});
	}

	public saveReports() {
		this.store.dispatch(PutReportGeneratorList({reports: this.reports}));
	}
}
