import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { Settings } from 'src/app/model/Settings';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {

	settings: Settings = new Settings();
	groups: string[] = [];

	constructor (
		public _ChangeDetectorRef: ChangeDetectorRef,

		private _jiraconnector: JiraconnectorService,

	) { }

	ngOnInit (): void {
		this._jiraconnector.searchGroups().subscribe((res) => {
			const _groups: string[] = [];
			for (const item of res.groups) {
				_groups.push(item.name);
			}
			this.groups = _groups;

			this._ChangeDetectorRef.detectChanges();

		});

	}

}
