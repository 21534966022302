// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-cluster-div {
  line-height: 1em;
}

td .month-column div.tempo-info {
  min-height: 21px;
  line-height: 21px;
  margin-top: 3px;
  padding-bottom: 1px;
}

.workload-red {
  border-radius: 2px;
  width: 80%;
  display: inline-block;
}

.workload-yellow {
  border-radius: 2px;
  width: 80%;
  display: inline-block;
}

.workload-green {
  border-radius: 2px;
  width: 80%;
  display: inline-block;
}

.column-c-spacer {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ressources-table-content-project-cell/ressources-table-content-project-cell.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAEA;EACC,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;AACD;;AAEA;EACC,kBAAA;EACA,UAAA;EACA,qBAAA;AACD;;AAEA;EACC,kBAAA;EACA,UAAA;EACA,qBAAA;AACD;;AAEA;EACC,kBAAA;EACA,UAAA;EACA,qBAAA;AACD;;AAEA;EACC,WAAA;AACD","sourcesContent":[".project-cluster-div {\n\tline-height: 1em;\n}\n\ntd .month-column div.tempo-info {\n\tmin-height: 21px;\n\tline-height: 21px;\n\tmargin-top: 3px;\n\tpadding-bottom: 1px;\n}\n\n.workload-red {\n\tborder-radius: 2px;\n\twidth: 80%;\n\tdisplay: inline-block;\n}\n\n.workload-yellow {\n\tborder-radius: 2px;\n\twidth: 80%;\n\tdisplay: inline-block;\n}\n\n.workload-green {\n\tborder-radius: 2px;\n\twidth: 80%;\n\tdisplay: inline-block;\n}\n\n.column-c-spacer {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
