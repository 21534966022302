import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CAPABILITY_ACTION, GetCapabilities, SaveCapabilities } from 'src/app/actions/capability-service.actions';
import { PROJECT_ACTION } from 'src/app/actions/project-service.actions';
import { Capability } from 'src/app/model/Capability';
import { PROJECT_TYPE, REPORT_MODE, VIEW_TYPE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { AppState } from 'src/app/reducers';

@Component({
	selector: 'app-capabilities-table',
	templateUrl: './capabilities-table.component.html',
	styleUrls: ['./capabilities-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapabilitiesTableComponent implements OnInit {
	public projects: Project[];
	public capabilities: Capability[];

	public columnsToDisplay     = [];
	public columnsToDisplayInfo = {};

	public selectedColumnName: string;

	public selectedCapability: Capability;
	public dialogViewType: VIEW_TYPE = VIEW_TYPE.NEW;

	REPORT_MODE = REPORT_MODE;
	PROJECT_TYPE = PROJECT_TYPE;
	VIEW_TYPE = VIEW_TYPE;

	@ViewChild('AddCapabilityDialog', {static: false}) AddCapabilityDialog: TemplateRef<any>;
	@ViewChild('deleteDialog', {static: false}) deleteDialog: TemplateRef<any>;

	private reduxSubscription: Subscription = new Subscription();

	constructor (
		public _ChangeDetectorRef: ChangeDetectorRef,
		private store: Store<AppState>,
		private dialog: MatDialog,
	) {

	}

	ngOnInit (): void {

		this.reduxSubscription.add(this.store.select((state: AppState) => state.projectService).subscribe((state) => {
			switch (state.action) {
				case PROJECT_ACTION.SET_PROJECTS:

					const plainProjectList: Project[] = [];

					for (const project of state.projects) {
						plainProjectList.push(project);
						for (const subProject of project.subProjects) {
							plainProjectList.push(subProject);
						}
					}

					this.projects = plainProjectList;

					this.setColumns();

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.capabilityService).subscribe((state) => {
			switch (state.action) {
				case CAPABILITY_ACTION.SET_CAPABILITIES:

					this.capabilities = state.capabilities;

					this.setColumns();

					break;

				default:
					break;
			}

		}));

		this.store.dispatch(GetCapabilities());
	}

	setColumns (): void {
		const columns = [];
		const columnsInfo = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = {class: 'c-name', title: 'Name'};

		const sClass = 'c-m';

		let iCount = 0;

		const sortCapabilities = this.capabilities?.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })) ?? [];

		for (const oCapability of sortCapabilities) {
			colName = 'capability-' + iCount;
			columns.push(colName);
			columnsInfo[colName] = {class: sClass, title: oCapability.name, capability: oCapability};

			iCount++;

		}

		colName = 'tools';
		columns.push(colName);
		columnsInfo[colName] = {class: 'c-tools', title: ''};

		colName = 'space';
		columns.push(colName);
		columnsInfo[colName] = {class: 'c-spacer', title: ''};

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;

		this._ChangeDetectorRef.detectChanges();

	}

	selectedColumn (column: string) {
		if (column !== this.selectedColumnName) {
			this.selectedColumnName = column;

			this._ChangeDetectorRef.detectChanges();
		}
	}

	hasCapability (column: string, project: Project): boolean {
		return this.columnsToDisplayInfo[column].capability?.relatedProjectIds?.indexOf(project?.id) !== -1;
	}

	setProjectCapability (column: string, project: Project, event: any): void {
		const capability = this.columnsToDisplayInfo[column].capability;
		const index = capability?.relatedProjectIds?.indexOf(project?.id);

		if (index === -1) {
			if (event.checked) {
				capability?.relatedProjectIds?.push(project?.id);
			}
		} else {
			if (!event.checked) {
				capability?.relatedProjectIds?.splice(index, 1);
			}
		}

		this.store.dispatch(SaveCapabilities({ capabilities: this.capabilities }));

	}

	editCapability (capability: Capability) {
		this.dialogViewType = VIEW_TYPE.EDIT;
		this.selectedCapability = capability;

		this._openCapabilityDialog();

	}

	addCapability () {
		this.dialogViewType = VIEW_TYPE.NEW;
		this.selectedCapability = new Capability();

		this._openCapabilityDialog();
	}

	_openCapabilityDialog () {
		const dialogRef = this.dialog.open(this.AddCapabilityDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				if (this.dialogViewType == VIEW_TYPE.NEW) {
					this.capabilities.push(this.selectedCapability);
				}
				this.saveCapability();
			} else if (result === 'delete') {
				this.deleteCapability();
			}

		});

	}

	deleteCapability () {
		const _self = this;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				const index = this.capabilities.indexOf(this.selectedCapability);

				if (index !== -1) {
					this.capabilities.splice(index, 1);
					this.saveCapability();
				}

			}
		});

	}
	saveCapability () {

		this.setColumns();
		this.store.dispatch(SaveCapabilities({ capabilities: this.capabilities }));

	}

}
