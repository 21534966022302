import { Pipe, PipeTransform } from '@angular/core';
import { RessourceCube } from '../model/RessourceCube';

@Pipe({
	name: 'plannedTimeForProject'
})
export class PlannedTimeForProjectPipe implements PipeTransform {

	transform (ressourceCube: RessourceCube, projectId: string, monthDescription: any): number {
		return ressourceCube.getPlannedTimeForProject(projectId, monthDescription.month);
	}

}
