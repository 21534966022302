import { formatNumber } from '@angular/common';
import { UNIT } from './enums';
import { REQUIRED_SECONDS_PER_DAY } from './constants';

export class User {

	public get surName (): string {
		try {
			const ar_sSplit = this.displayName.split(' ');
			if (ar_sSplit.length >= 2) {
				return ar_sSplit[0];
			}
		} catch (error) {
			return '';
		}
	}

	public get lastName (): string {
		try {
			const ar_sSplit = this.displayName.split(' ');
			if (ar_sSplit.length >= 2) {
				return ar_sSplit[ar_sSplit.length - 1];
			}
		} catch (error) {
			return '';
		}
	}
	timestamp: Date;
	actTimestamp: Date;
	userAgent: string;
	accountId: string;
	displayName: string;

	pot_column: any = {};
	holiday_column: any = {};
	available_column: any = {};
	real_column: any = {};
	all_column: any = {};
	rest_column: any = {};

	real_plan_column: any = {};
	all_plan_column: any = {};
	rest_plan_column: any = {};

	holiday_dates: any[] = [];
	sick_dates: any[] = [];

	avatarUrl: string;

	private _isCEO = false;
	public get isCEO () {
		if (this.lastName === 'Löhdefink' || this.lastName === 'Göbel' || this.lastName === 'Johannsen') {
			this._isCEO = true;
		}

		return this._isCEO;
	}
	public set isCEO (value) {
		this._isCEO = value;
	}

	jiraGroups: string[] = [];

	updateColumnValues () {
		this.pot_column = Object.assign({}, this.pot_column);
		this.holiday_column = Object.assign({}, this.holiday_column);
		this.available_column = Object.assign({}, this.available_column);
		this.real_column = Object.assign({}, this.real_column);
		this.all_column = Object.assign({}, this.all_column);
		this.rest_column = Object.assign({}, this.rest_column);
	}
	updatePlanColumnValues () {
		this.real_plan_column = Object.assign({}, this.real_plan_column);
		this.all_plan_column = Object.assign({}, this.all_plan_column);
		this.rest_plan_column = Object.assign({}, this.rest_plan_column);
	}

	get avg_not_yet_planned_column (): number {
		try {
			let sum = 0;
			const keys = Object.keys(this.pot_column);
			for (const month of keys) {

				const availableDays = (this.pot_column[month] || 0) - (this.holiday_column[month] || 0);
				const notYetPlanned = availableDays - (this.real_plan_column[month] || 0);

				sum += notYetPlanned;
			}
			if (this.displayName.indexOf('Clara') !== -1) {
				const xxx = 1;
			}
			sum = sum / keys.length;
			return sum || 0;
		} catch (error) {
			return 0;
		}
	}

	hasGroup (groupName: string): boolean {
		return this.jiraGroups.indexOf(groupName) !== -1;
	}

	getPotDays (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			return formatNumber(((this.pot_column[month]) ?
				this.pot_column[month] : 0) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getRealDays (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			return formatNumber(((this.real_column[month]) ?
				this.real_column[month] : 0) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getRealPlannedTime (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			return formatNumber(((this.real_plan_column[month]) ? this.real_plan_column[month] : 0) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getNotYetPlannedTime (month: string, format: string = UNIT.HOURS, digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case UNIT.HOURS:
					factor = 8;
					break;

				default:
					break;
			}

			const availableDays = ((this.pot_column[month]) ?
				this.pot_column[month] : 0) - ((this.holiday_column[month]) ? this.holiday_column[month] : 0);
			return formatNumber(((this.real_plan_column[month]) ?
				availableDays - this.real_plan_column[month] : availableDays) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getRestDays (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			return formatNumber(((this.rest_column[month]) ?
				this.rest_column[month] : 0) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getAllDays (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			return formatNumber(((this.all_column[month]) ?
				this.all_column[month] : 0) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getHolidayDays (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			return formatNumber(((this.holiday_column[month]) ?
				this.holiday_column[month] : 0) / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getAvailableDays (month: string, format: string = 'HOURS', digits: number = 0): string {
		try {
			let factor = 1;
			switch (format) {
				case 'HOURS':
					factor = 8;
					break;

				default:
					break;
			}

			const availableDays = ((this.pot_column[month]) ?
				this.pot_column[month] : 0) - ((this.holiday_column[month]) ? this.holiday_column[month] : 0);
			return formatNumber(availableDays / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits);
		} catch (error) {
			return '';
		}
	}

	getUUID () {
		return this.accountId + '_' + this.userAgent + '_' + this.timestamp;
	}
}
