// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-standard-chip {
  border-radius: 5px;
  max-height: 24px;
  padding: 0;
}

mat-mdc-chip {
  width: 90%;
  min-width: 2em;
  font: inherit;
}

mat-mdc-chip span {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cell-item-renderer-euro-value/cell-item-renderer-euro-value.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,gBAAA;EACA,UAAA;AACD;;AAEA;EACC,UAAA;EACA,cAAA;EACA,aAAA;AACD;;AAIA;EACC,cAAA;AADD","sourcesContent":[".mat-mdc-standard-chip {\n\tborder-radius: 5px;\n\tmax-height: 24px;\n\tpadding: 0;\n}\n\nmat-mdc-chip {\n\twidth: 90%;\n\tmin-width: 2em;\n\tfont: inherit;\n\n\t//font-weight: 500;\n}\n\nmat-mdc-chip span {\n\tmargin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
