import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'projectIdFromColumn'
})
export class ProjectIdFromColumnPipe implements PipeTransform {

	transform (column: string, replaceString: string = ''): unknown {
		try {
			return column.replace(replaceString, '');
		} catch (error) {
			return '';
		}
	}

}
