import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { RESSOURCE_TABLE_ACTION } from 'src/app/actions/ressource-table.actions';
import { CELL_TYPE, REPORT_MODE, UNIT } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { RessourceCube } from 'src/app/model/RessourceCube';
import { User } from 'src/app/model/User';
import { AppState } from 'src/app/reducers';

@Component({
	selector: 'app-ressources-table-content-project-cell',
	templateUrl: './ressources-table-content-project-cell.component.html',
	styleUrls: ['./ressources-table-content-project-cell.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RessourcesTableContentProjectCellComponent implements OnInit, OnDestroy {

	@Input() public reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS;

	@Input() public updatedPlannedTimePosition: any;
	@Input() public selectedMonths: any[];
	@Input() public ressourceCube: RessourceCube;

	@Input() public user: User;
	@Input() public actProjectId: string;

	@Output() handlePlannedNumberUpdate = new EventEmitter<any>();

	trackByFn = trackByFn;
	UNIT = UNIT;
	REPORT_MODE = REPORT_MODE;
	CELL_TYPE = CELL_TYPE;

	public workloadDatas = {};

	private reduxSubscription: Subscription = new Subscription();

	constructor (
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,

	) { }

	ngOnInit (): void {
		this.reduxSubscription.add(this.store.select((state: AppState) => state.ressourcesTable).subscribe((state) => {
			switch (state.action) {
				case RESSOURCE_TABLE_ACTION.TOGGLE_REPORTMODE:
					this.updateWorkloadDatas();

					this._ChangeDetectorRef.detectChanges();

					break;

				case RESSOURCE_TABLE_ACTION.UPDATE_PROJECT_CELL:
					console.log('UPDATE_PROJECT_CELL: ' + state.accountId + ', ' + state.projectId);
					if (state.accountId === this.user?.accountId &&
            state.projectId === this.actProjectId) {

						this.updateWorkloadDatas();

						this._ChangeDetectorRef.detectChanges();

					}

					break;

				default:
					break;
			}

		}));

	}

	ngOnDestroy (): void {
		this.reduxSubscription.unsubscribe();
	}

	private updateWorkloadDatas () {
		const workloadDatas = {};
		for (const monthDescription of this.selectedMonths) {
			const workloadData = this.ressourceCube.getData(this.user.accountId, this.actProjectId, monthDescription.month)
				.getTimeSpendFormated((this.reportMode === REPORT_MODE.RESSOURCE_HOURS) ? UNIT.HOURS : UNIT.DAYS);

			workloadDatas[monthDescription.month] = workloadData;
		}

		this.workloadDatas = workloadDatas;

		/*
    const actRessourceCube = this.ressourceCube;
    this.ressourceCube = new RessourceCube();
    this._ChangeDetectorRef.detectChanges();
    this.ressourceCube = actRessourceCube;
    this._ChangeDetectorRef.detectChanges();
    */

		for (const monthDescription of this.selectedMonths) {
			this.updatedPlannedTimePosition[this.user.accountId + '_' + this.actProjectId + '_' + monthDescription.month] = true;
			this._ChangeDetectorRef.detectChanges();
			this.updatedPlannedTimePosition[this.user.accountId + '_' + this.actProjectId + '_' + monthDescription.month] = false;
			this._ChangeDetectorRef.detectChanges();
		}
	}

	public getWorkloadCssClass (accountId: string, projectId: string, monthDescription: any): string {

		const threshold1 = 0.2;
		const threshold2 = 0.3;

		const workloadSeconds = this.ressourceCube.getData(accountId, projectId, monthDescription.month).timeSpentSeconds || 0;
		const plannedSeconds = this.ressourceCube.getPlan(accountId, projectId, monthDescription.month).plannedSeconds || 0;

		if (workloadSeconds === plannedSeconds) {
			if (workloadSeconds === null || workloadSeconds === 0) {
				return '';
			}
			return 'workload-green bold';
		}
		if (workloadSeconds < plannedSeconds * (1.0 + threshold1) && workloadSeconds > plannedSeconds * (1.0 - threshold1)) {
			return 'workload-green';
		}
		if (workloadSeconds > plannedSeconds * (1.0 + threshold2) || workloadSeconds < plannedSeconds * (1.0 - threshold2)) {
			return 'workload-red';
		}
		return 'workload-yellow';
	}

	isPlannedTimeUpdated (accountId: string, projectId: string, monthDescription: any) {
		try {
			return this.updatedPlannedTimePosition[accountId + '_' + projectId + '_' + monthDescription.month] === true;
		} catch (error) {
			return false;
		}
	}

	handleBlur (accountId: string, projectId: string, monthDescription: any, cellRenderer: any) {
		this.handlePlannedNumberUpdate.emit({accountId, projectId, monthDescription, cellRenderer});
	}

}
