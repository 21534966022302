import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { REPORT_MODE } from '../model/enums';

@Pipe({
	name: 'ressourcesToolTip'
})
export class RessourcesToolTipPipe implements PipeTransform {

	transform (value: any, reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS): unknown {
		if (value === '-') { return value; }

		let returnString = '-';
		try {

			let sValue = value;
			sValue = (sValue as string).replace('.', '');
			sValue = (sValue as string).replace(',', '.');

			const nValue = 1 * sValue as number;

			switch (reportMode) {
				case REPORT_MODE.RESSOURCE_HOURS:
					returnString = value + ' h = ' + formatNumber(nValue / 8, 'de', '1.0-2') + ' PT';

					break;

				case REPORT_MODE.RESSOURCE_DAYS:
					returnString = formatNumber(nValue / 8, 'de', '1.0-2') + ' PT = ' + value + ' h';

					break;

				default:
					break;
			}

		} catch (error) {

		}
		return returnString;
	}

}
