import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild, TemplateRef, AfterViewInit, OnDestroy, EventEmitter, Inject } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { forkJoin, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { RessourceCube, RessourceMonthData, RessourceMonthPlan } from 'src/app/model/RessourceCube';
import { DataDescription } from 'src/app/model/DataDescription';
import { User } from 'src/app/model/User';
import { LocalStorageWorker } from 'src/app/model/LocalStorageWorker';
import { REQUIRED_SECONDS_PER_DAY, SECONDS_PER_HOUR } from 'src/app/model/constants';
import {
	PROJECT_TYPE, CELL_TYPE, SOCKET_SUBJECT, DATA_TYPE, REPORT_MODE, UNIT,
	CLASS_TYPE, WORKLOAD_UNIT, COLUMN_TYPE, USER_SHOWN_EXPANDED
} from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { MatTable } from '@angular/material/table';
import { formatNumber } from '@angular/common';
import { SocketService } from 'src/app/services/socket.service';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { VirtualGroup } from 'src/app/model/VirtualGroup';
import { MatSelect } from '@angular/material/select';
import { JiraVersion } from 'src/app/model/JiraVersion';
import { environment } from 'src/environments/environment';
import { TempoTeam } from 'src/app/model/TempoTeam';
import { trackByFn } from 'src/app/model/globalFunctions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { JIRA_ACTION } from 'src/app/actions/jira-api.actions';
import { GetFullProjects, GetProjects, PROJECT_ACTION, SetProjects } from 'src/app/actions/project-service.actions';
import { MatSort } from '@angular/material/sort';
import { InitializedRessourceTables, InitializingRessourceTables, RefreshColumnsRessourceTables, RESSOURCE_TABLE_ACTION,
	ToggleReportModeRessourceTables,
	UpdateProjectCellRessourceTables, UpdateProjectOverviewCellRessourceTables, UserLoadingRessourceTables } from 'src/app/actions/ressource-table.actions';
import { TempoIoService } from 'src/app/services/tempo.io.service';
import { RessourceCubeIoService, RESSOURCE_TEMPO_IO_URL } from 'src/app/services/ressource-cube.io.service';
import { ReloadDataRessourceCube, RESSOURCE_CUBE_ACTION } from 'src/app/actions/ressource-cube.actions';
@Component({
	selector: 'app-ressources-table',
	templateUrl: './ressources-table.component.html',
	styleUrls: ['./ressources-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RessourcesTableComponent implements OnInit, AfterViewInit, OnDestroy {

	public userSchedules = {};

	public get ressourceCube (): RessourceCube {
		return this._ressourceCube;
	}

	public set ressourceCube (value: RessourceCube) {
		this._ressourceCube = value;
	}

	constructor (

		@Inject(RESSOURCE_TEMPO_IO_URL) public ressourceTempoIoUrl: string,

		private store: Store<AppState>,
		private dialog: MatDialog,
		public _ChangeDetectorRef: ChangeDetectorRef,

		public projectService: ProjectService,
		public tempoIoService: TempoIoService,
		public ressourceCubeIoService: RessourceCubeIoService,
		public socketService: SocketService,
	) {

		const ressource_projectCollapsedMap = LocalStorageWorker.instance.get('ressource_projectCollapsedMap');
		if (ressource_projectCollapsedMap !== undefined && ressource_projectCollapsedMap !== null) {
			try {
				this.projectCollapsedMap = JSON.parse(ressource_projectCollapsedMap);
			} catch (error) { }
		}

		const ressource_userShowMap = LocalStorageWorker.instance.get('ressource_userShowMap');
		if (ressource_userShowMap !== undefined && ressource_userShowMap !== null) {
			try {
				this.userShowMap = JSON.parse(ressource_userShowMap);
			} catch (error) { }
		}

		const ressource_reportMode = LocalStorageWorker.instance.get('ressource_reportMode');
		if (ressource_reportMode !== undefined && ressource_reportMode !== null) {
			try {
				this.reportMode = (ressource_reportMode === REPORT_MODE.RESSOURCE_DAYS) ? REPORT_MODE.RESSOURCE_DAYS : REPORT_MODE.RESSOURCE_HOURS;
			} catch (error) { }
		}

	}

	public get selectedMonths (): any[] {

		if (this.ar_selectedMonth.length === 0 &&
      this.range.valid && this.range.value.start !== null && this.range.value.end !== null) {
			//      const dateBegin = this.form.value.date.begin;
			//      const dateEnd = this.form.value.date.end;

			const dateBegin = this.range.value.start;
			const dateEnd = this.range.value.end;

			let startDate = moment(dateBegin).startOf('month');
			const endDate = moment(dateEnd).endOf('month');

			const ar_month = [];

			while (startDate.isBefore(endDate)) {
				const monthDescription = {
					key: moment(startDate).format('YYYY_M'),
					label: moment(startDate).format('MM/YY'),
					month: moment(startDate).format('YYYY_M'),
					date: moment(startDate).format('YYYY-MM-01')
				};
				ar_month.push(monthDescription);

				startDate = startDate.add(1, 'month');
			}
			this.ar_selectedMonth = ar_month;
		}

		return this.ar_selectedMonth;

	}
	public get userList (): any {
		return this._userList.filter((filteredUser: User) => this.userShowMap[filteredUser.accountId] === true);
	}
	public set userList (value: any) {
		this._userList = value;
	}
	public get userShownExpanded (): USER_SHOWN_EXPANDED {
		if (this._userShownExpanded === undefined) {
			let countShownUser = 0;
			let countExpandedUser = 0;
			for (const accountId of Object.keys(this.userShowMap)) {
				if (this.userShowMap[accountId] === true) {
					countShownUser = countShownUser + 1;
				}
				if (this.userExpandMap[accountId] === true) {
					countExpandedUser = countExpandedUser + 1;
				}
			}
			if (countExpandedUser === 0) {
				this._userShownExpanded = USER_SHOWN_EXPANDED.NONE;
			} else if (countShownUser > countExpandedUser) {
				this._userShownExpanded = USER_SHOWN_EXPANDED.SOME;
			} else {
				this._userShownExpanded = USER_SHOWN_EXPANDED.ALL;
			}
		}
		return this._userShownExpanded;
	}
	public set userShownExpanded (value: USER_SHOWN_EXPANDED) {
		this._userShownExpanded = value;
	}

	private reduxSubscription: Subscription = new Subscription();

	@ViewChild('RessourcesTable', { static: false }) oRessourcesTable: MatTable<any>;
	@ViewChild('RessourcesMatSort', { static: false }) oRessourcesMatSort: MatSort;
	@ViewChild('calendarHoliday', { static: false }) calendarHoliday: MatCalendar<Date>;
	@ViewChild('calendarSick', { static: false }) calendarSick: MatCalendar<Date>;
	@ViewChild('virtualGroupDialog', { static: false }) virtualGroupDialog: TemplateRef<any>;
	@ViewChild('deleteDialog', { static: false }) deleteDialog: TemplateRef<any>;
	@ViewChild('hideShowProjectDialog', { static: false }) hideShowProjectDialog: TemplateRef<any>;
	@ViewChild('hideShowTeamsOrUsersDialog', { static: false }) hideShowTeamsOrUsersDialog: TemplateRef<any>;

	@ViewChild('selectEpic', { static: false }) selectEpic: MatSelect;
	@ViewChild('selectComponent', { static: false }) selectComponent: MatSelect;
	@ViewChild('selectVersion', { static: false }) selectVersion: MatSelect;

	@Input() public projectList: Subject<Project[]>;
	private projectListSubscribtion: Subscription;
	private projectSubjectSubscribtion: Subscription;

	public isInitalizing = true;

	public yearString = new Date().getFullYear();
	public nextYearString = new Date().getFullYear() + 1;

	public startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
	public endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 3, 1, 0, 0, 0, 0).getTime() - 1000);

	public columnsToDisplay = [];
	public columnsToDisplayInfo = {};

	public groupColumnsToDisplay = [];
	public groupColumnsToDisplayInfo = {};

	public expandedElement;

	public updatedPlannedTimePosition: any = {};

	private _ressourceCube: RessourceCube = new RessourceCube();

	public selectedColumnName: string;
	public selectedGroupColumnName: string;

	private _userList: any = [];

	public userMap: any = {};
	public userExpandMap: any = {};

	public projectCollapsedMap: any = {};

	public teamShowMap: any = {};
	public userShowMap: any = {};

	private _userShownExpanded: USER_SHOWN_EXPANDED;

	public selectedProject: Project;
	public selectedVirtualGroup: VirtualGroup;
	public showVirtualGroupDeleteButton = false;

	public updateInfoCellsEmitter = new EventEmitter<any>();

	public colors: string[] = ['#C3CFD9', '#788896', '#4B5C6B', '#4A58FF', '#73539F', '#F258FF', '#18D980', '#498C8A', '#5FFFB5', '#A35454', '#F55265', '#FE9055', '#FFD248'];

	reportModeLabel = {
		RESSOURCE_HOURS: 'h',
		RESSOURCE_DAYS: 'PT'
	};

	CELL_TYPE = CELL_TYPE;
	REPORT_MODE = REPORT_MODE;
	UNIT = UNIT;
	CLASS_TYPE = CLASS_TYPE;
	PROJECT_TYPE = PROJECT_TYPE;
	COLUMN_TYPE = COLUMN_TYPE;
	USER_SHOWN_EXPANDED = USER_SHOWN_EXPANDED;
	trackByFn = trackByFn;

	public sortControl = new UntypedFormControl();

	public sortGroups = of([
		{ value: { property: 'displayName', order: 1 }, valueView: 'alphabetisch A -> Z' },
		{ value: { property: 'displayName', order: -1 }, valueView: 'alphabetisch Z -> A' },
		{ value: { property: 'avg_not_yet_planned_column', order: 1 }, valueView: 'freie Zeit -> verplante Zeit' },
		{ value: { property: 'avg_not_yet_planned_column', order: -1 }, valueView: 'verplante Zeit -> freie Zeit' }
	]);

	range = new UntypedFormGroup({
		start: new UntypedFormControl(this.startDate),
		end: new UntypedFormControl(this.endDate)
	});

	public reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS;

	private ar_selectedMonth = [];

	private _projectWorkloadToolTip = {};

	holidaySickStartDate: Date;
	holidaySickStartUser: User;

	public tempoTeams: TempoTeam[] = [];

	private _allProjectsCollapsed = undefined;

	public pgColumnColspans = {};

	private isWorkloadLoading = false;

	ngAfterViewInit (): void {
	}

	ngOnInit () {
		console.log('RESSOURCES Component initialised!');

		const _self = this;

		this.socketService.handleDataChange().subscribe((message: any) => {

			const dataDescription: DataDescription = JSON.parse(message.body);

			if (dataDescription.uuid !== _self.socketService.sessionUser.getUUID()) {

				switch (message.subject) {
					case SOCKET_SUBJECT.REFRESH_RESSOURCES_TABLE:

						/*
            try {
              this.getWorkloadForSelectedMonthByUser(this.userMap[dataDescription.id], () => { // beforeCallback
                this.ressourceCube.setDataLoading(this.userMap[dataDescription.id]?.accountId);
                this.updatedPlannedTimePosition[dataDescription.body.updatedKey] = true;
                this._ChangeDetectorRef.detectChanges();

              }).subscribe(result => {
                if (result?.dataLoaded === true) {
                  this.updatedPlannedTimePosition[dataDescription.body.updatedKey] = false;
                  this._ChangeDetectorRef.detectChanges();
                }

              });

            } catch (error) {

            }
            */

						this.store.dispatch(InitializingRessourceTables());

						this.store.dispatch(ReloadDataRessourceCube(
							{
								startDate: moment(this.startDate).format('YYYY-MM-DD'),
								endDate: moment(this.endDate).format('YYYY-MM-DD')
							}
						));

						break;

					default:

						break;
				}

			}

		});

		const ressource_startDate = LocalStorageWorker.instance.get('ressource_startDate');
		if (ressource_startDate !== undefined && ressource_startDate !== null) {
			this.startDate = new Date(ressource_startDate);
		}
		const ressource_endDate = LocalStorageWorker.instance.get('ressource_endDate');
		if (ressource_endDate !== undefined && ressource_endDate !== null) {
			this.endDate = new Date(ressource_endDate);

			this.range = new UntypedFormGroup({
				start: new UntypedFormControl(this.startDate),
				end: new UntypedFormControl(this.endDate)
			});

		}

		const ressource_userExpandedMap = LocalStorageWorker.instance.get('ressource_userExpandedMap');
		if (ressource_userExpandedMap !== undefined && ressource_userExpandedMap !== null) {
			try {
				// Beim Refresh der Seite die User lieber doch geschlossen lassen (Abstimmung mit Yannic am 02.02.2022)
				// this.userExpandMap = JSON.parse(ressource_userExpandedMap);

			} catch (error) { }
		}

		this.reduxSubscription.add(this.store.select((state: AppState) => state.jiraApi).subscribe((state) => {
			switch (state.action) {
				case JIRA_ACTION.SET_USERS:
					this.userList = state.users.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
					this.userMap = state.userMap;

					this.getTeams().subscribe((tempoTeams) => {
						this.tempoTeams = tempoTeams;
					});

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.projectService).subscribe((state) => {
			switch (state.action) {
				case PROJECT_ACTION.SET_PROJECTS:

					if (this.isInitalizing) {

						this.store.dispatch(RefreshColumnsRessourceTables());

						this.getFullProjectsForTableData().subscribe(() => {

							//              this.refreshAllWorkloadAndPlan();

							this.store.dispatch(ReloadDataRessourceCube(
								{
									startDate: moment(this.startDate).format('YYYY-MM-DD'),
									endDate: moment(this.endDate).format('YYYY-MM-DD')
								}
							));

						});

					}
					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.ressourcesTable).subscribe((state) => {
			switch (state.action) {
				case RESSOURCE_TABLE_ACTION.INITIALIZING:

					this.isInitalizing = true;

					break;

				case RESSOURCE_TABLE_ACTION.INITIALIZED:

					this.isInitalizing = false;

					break;

				case RESSOURCE_TABLE_ACTION.REFRESH_COLUMNS:

					this.setColumns();
					this._ChangeDetectorRef.detectChanges();

					break;

				default:
					break;
			}

		}));

		this.reduxSubscription.add(this.store.select((state: AppState) => state.ressourceCube).subscribe((state) => {
			switch (state.action) {
				case RESSOURCE_CUBE_ACTION.INITIALIZED:

					this.userSchedules = state.userSchedules;
					this.ressourceCube = state.ressourceCube;

					this.store.dispatch(RefreshColumnsRessourceTables());

					this.store.dispatch(InitializedRessourceTables());
					this._ChangeDetectorRef.detectChanges();

					this.reloadExpandedUsers();
					this._ChangeDetectorRef.detectChanges();

					break;

				default:
					break;
			}

		}));
		this.projectSubjectSubscribtion = this.projectService.projectSubject.subscribe((projects) => {
			this.store.dispatch(InitializingRessourceTables());

			if (projects.length === 0) {
				this.store.dispatch(GetProjects());
			} else {
				if (this.projectService.hasShortProjects()) {
					this.store.dispatch(GetFullProjects());
				}
			}
			this.projectSubjectSubscribtion.unsubscribe();
		});

		this.store.dispatch(InitializingRessourceTables());

	}

	ngOnDestroy (): void {
		try {
			this.projectSubjectSubscribtion.unsubscribe();
		} catch (error) { }
		try {
			this.projectListSubscribtion.unsubscribe();
		} catch (error) { }
	}

	handleOrderTable (sortValue: { property: string, order: 1 | -1 }) {
		const tableArray: User[] = this._userList; // this.oRessourcesTable.dataSource as User[];

		const array = [];

		for (const user of tableArray) {
			array.push({ name: user.displayName, value: user.avg_not_yet_planned_column });
		}

		console.log(array);

		this.userList = tableArray.sort((a, b) => {
			const aValue = a[sortValue.property];
			const bValue = b[sortValue.property];
			try {
				return ((aValue && bValue) ?
					aValue.localeCompare(bValue, undefined, { sensitivity: 'base' }) : 0) * sortValue.order;
			} catch (error) {
				if (aValue > bValue) { return 1 * sortValue.order; }
				if (aValue < bValue) { return -1 * sortValue.order; }
				return 0;
			}
		});

		this.oRessourcesTable?.renderRows();
	}

	getTeams (): Observable<any> {

		const observable = new Observable((observer) => {
			if (this.tempoTeams === undefined || this.tempoTeams.length === 0 || this.tempoTeams.length > 0) {
				this.tempoIoService.getTeamsWithUserIds().subscribe((tempoTeams) => {

					console.log(this.userMap);

					this.mapUsersToTempoTeams(tempoTeams);

					tempoTeams = tempoTeams.sort((a, b) => {
						if (!a.name.toUpperCase().startsWith('TEAM') && b.name.toUpperCase().startsWith('TEAM')) { return 1; }
						if (a.name.toUpperCase().startsWith('TEAM') && !b.name.toUpperCase().startsWith('TEAM')) { return -1; }
						if (!a.name.toUpperCase().startsWith('PROJEKTIONISTEN') && b.name.toUpperCase().startsWith('PROJEKTIONISTEN')) { return 1; }
						if (a.name.toUpperCase().startsWith('PROJEKTIONISTEN') && !b.name.toUpperCase().startsWith('PROJEKTIONISTEN')) { return -1; }
						return a.name.toUpperCase().localeCompare(b.name.toUpperCase(), undefined, { sensitivity: 'base' });
					});

					observer.next(tempoTeams);
					observer.complete();

				});
			} else {
				observer.next(this.tempoTeams);
				observer.complete();
			}

		});

		return observable;
	}

	mapUsersToTempoTeams (tempoTeams: TempoTeam[]) {
		for (const tempoTeam of tempoTeams) {
			const users: User[] = [];
			for (const accountId of tempoTeam.memberUserIds) {
				const user = this.userMap[accountId];

				console.log(accountId + ' -> ' + user);

				if (user) {
					users.push(user);
				}
			}
			tempoTeam.memberUsers = users.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
		}
	}

	getFullProjectsForTableData (): Observable<any> {

		const observable = new Observable((observer) => {
			if (this.projectService.projects.length === 0) {
				this.projectListSubscribtion = this.projectList.subscribe((results) => {
					this._getFullProjectsForTableData().subscribe(() => {
						observer.next('FULL_PROJECT_DATA_LOADED');
						observer.complete();
					});
				});
			} else {
				this._getFullProjectsForTableData().subscribe(() => {
					observer.next('FULL_PROJECT_DATA_LOADED');
					observer.complete();
				});
			}
		});

		return observable;
	}

	_getFullProjectsForTableData (): Observable<any> {

		const observable = new Observable((observer) => {
			const ar_oObservable: Observable<any>[] = [];
			for (const project of this.projectService.projects) {
				const ar_projectObservables = this.projectService.getFullProjectData(project);
				ar_oObservable.push(...ar_projectObservables);
			}
			if (ar_oObservable.length > 0) {
				ar_oObservable.push(timer(1000));

				console.log('ar_oObservable: ' + ar_oObservable.length);

				const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((responses) => {
					console.log('Second Subscribtion ready!');

					allObservablesSubscribtions.unsubscribe();

					this.store.dispatch(RefreshColumnsRessourceTables());

					observer.next('INTERNAL_FULL_PROJECT_DATA_LOADED');
					observer.complete();
				});

			} else {
				observer.next('INTERNAL_FULL_PROJECT_DATA_LOADED');
				observer.complete();
			}

		});

		return observable;
	}

	getWorkloadForSelectedMonth (): Observable<any> {

		const observable = new Observable((observer) => {

			if (this.userList.length !== 0) {
				const startMonth = this.selectedMonths[0];
				const endMonth = this.selectedMonths[this.selectedMonths.length - 1];

				for (const user of this.userList) {
					user.holiday_dates = [];
					user.sick_dates = [];
					user.holiday_column = {};
				}

				const insertItem = (ar_dates: any[], newItem: any) => {
					const index = ar_dates.findIndex((x) => x.date === newItem.date);
					if (index < 0) {
						ar_dates.push(newItem);
					} else {
						ar_dates[index] = newItem;
					}
				};

				const ar_oObservable: Observable<any>[] = [];

				for (const monthDescription of this.selectedMonths) {
					const worklogsObservable = this.tempoIoService.getWorklogsForIssueForMonth('POR-57;POR-58', monthDescription.date);
					ar_oObservable.push(worklogsObservable);
				}

				const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((responses) => {
					allObservablesSubscribtions.unsubscribe();

					for (const res of responses) {

						const hmAccountIdHoliday = {};

						for (const item of res.results) {
							let month = 'undefined';
							try {
								const startDate = new Date(item.startDate);
								month = moment(startDate).format('YYYY_M');
							} catch (error) { }
							const accountId = item.author.accountId;
							hmAccountIdHoliday[accountId] = ((hmAccountIdHoliday[accountId]) ? hmAccountIdHoliday[accountId] : 0) +
                item.timeSpentSeconds * 1;

							const user = this.userMap[accountId];
							if (user) {
								const key = item.issue.key;
								const tempoWorklogId = item.tempoWorklogId;

								const newItem = { date: item.startDate, tempoWorklogId, timeSpentSeconds: item.timeSpentSeconds * 1 };
								if (key === 'POR-57') {
									insertItem(user.holiday_dates, newItem);
								}
								if (key === 'POR-58') {
									insertItem(user.sick_dates, newItem);
								}
							}

							for (const accountId of Object.keys(hmAccountIdHoliday)) {
								const user = this.userMap[accountId];

								if (user) {
									user.holiday_column[month] = hmAccountIdHoliday[accountId];
								}

							}
						}
					}

					observer.next('workload loaded');
					observer.complete();

				}, (error) => {
					observer.next('workload error');
					observer.complete();
				});

			} else {
				observer.next('empty userlist');
				observer.complete();
			}
		});

		return observable;
	}

	getWorkloadForSelectedMonthByUser (user: User, beforeCallback: any = null): Observable<any> {

		const observable = new Observable((observer) => {

			if (user === undefined && this.isWorkloadLoading) {
				observer.next({isLoading: true});
				observer.complete();
			}
			if (user === undefined) {
				this.isWorkloadLoading = true;
			}

			const startMonth = this.selectedMonths[0];
			const endMonth = this.selectedMonths[this.selectedMonths.length - 1];

			if (beforeCallback) {
				beforeCallback();
			}

			const ar_oObservable: Observable<any>[] = [];
			ar_oObservable.push(this.tempoIoService.getUserScheduleForMonth(user ? user?.accountId : '', startMonth.date, endMonth.date));
			ar_oObservable.push(this.tempoIoService.getWorklogsForMonth(user?.accountId, startMonth.date, endMonth.date));
			ar_oObservable.push(this.tempoIoService.getPlansForMonth(user?.accountId, startMonth.date, endMonth.date));

			const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((responses) => {
				allObservablesSubscribtions.unsubscribe();

				for (const res of responses) {
					if (res.sumWorkingDay) {
						if (user) {
							for (const month of Object.keys(res.sumWorkingDay)) {
								user.pot_column[month] = res.sumWorkingDay[month];
							}
						} else {
							for (const actUser of this.userList) {
								for (const month of Object.keys(res.sumWorkingDay)) {
									actUser.pot_column[month] = res.sumWorkingDay[month];
								}
							}
						}

						console.log(user?.accountId + ' -> ' + JSON.stringify(res));

					} else if (res.results && res.results.length > 0) {

						// Get Urlaub / Krank
						if (res.results[0].issue) {

							if (user) {
								for (const selectedMonth of this.selectedMonths) {
									this.ressourceCube.removeData(user.accountId, selectedMonth.month);
								}
							}

							const ar_oItem = res.results.filter((item) => item?.issue?.key !== 'POR-57' && item?.issue?.key !== 'POR-58');

							if (ar_oItem?.length > 0) {
								let accountId;
								const hmProjectIdWorkload = {};

								for (const item of ar_oItem) {
									const month = moment(item.startDate).format('YYYY_M');
									if (hmProjectIdWorkload[month] === undefined) {
										hmProjectIdWorkload[month] = {};
									}

									accountId = item.author.accountId;
									const issueId = item.issue.key;
									let projectId = issueId.split('-')[0];

									const actProject = this.projectService.projectsById[projectId];
									if (actProject) {
										for (const virtualGroup of actProject.virtualGroups) {
											if (virtualGroup.assosiated_issue_keys.indexOf(issueId) !== -1) {
												projectId = virtualGroup.id;
												break;
											}
										}
									}

									hmProjectIdWorkload[month][projectId] = ((hmProjectIdWorkload[month][projectId]) ?
										hmProjectIdWorkload[month][projectId] : 0) + item.timeSpentSeconds * 1;
								}

								for (const month of Object.keys(hmProjectIdWorkload)) {

									this.ressourceCube.removeData(accountId, month);

									const monthWorkload = hmProjectIdWorkload[month];
									let sumProjectReal = 0;
									let sumProjectAll = 0;

									for (const projectId of Object.keys(monthWorkload)) {

										const data = new RessourceMonthData();
										data.timeSpentSeconds = monthWorkload[projectId];
										this.ressourceCube.setData(accountId, projectId, month, data);

										sumProjectAll += data.timeSpentSeconds ? data.timeSpentSeconds : 0;

										const baseProjectId = (projectId.indexOf('___') !== -1) ? projectId.split('___')[0] : projectId;

										if (user) {
											this.projectCollapsedMap[baseProjectId] = false;
										}

										if (this.projectService.projectIds.includes(baseProjectId)) {
											sumProjectReal += data.timeSpentSeconds ? data.timeSpentSeconds : 0;
										}

										if (user) {
											this.store.dispatch(UpdateProjectCellRessourceTables({ projectId, accountId }));
										}
										this.store.dispatch(UpdateProjectOverviewCellRessourceTables({ projectId: baseProjectId }));

									}

									if (user) {
										user.real_column[month] = sumProjectReal;
										user.rest_column[month] = sumProjectAll - sumProjectReal;
										user.all_column[month] = sumProjectAll;

										user.updateColumnValues();

									}
								}

							}

						}

						const hmProjectIdPlan = {};
						const hmProjectIdPlanId = {};

						// Get Plan
						if (res.results[0].planItem) {

							if (user) {
								for (const selectedMonth of this.selectedMonths) {
									this.ressourceCube.removePlan(user.accountId, selectedMonth.month);
								}
							}

							const ar_oItem = res.results.filter((item) => item?.planItem?.type === 'PROJECT');

							if (ar_oItem?.length > 0) {

								/*
                if (user === undefined) {
                  const ar_oRMVItem = res.results.filter((item) => item?.planItem?.self.indexOf('RMV') !== -1);

                  const users = {};
                  for (const item of ar_oRMVItem) {
                    const month = moment(item.startDate).format('YYYY_M');
                    const reAccountId = /.*?accountId=(.*?)/;
                    const accountId = item.assignee.self.replace(reAccountId, '$1');
                    users[accountId] = this.userMap[accountId];
                  }

                  let xxx = 1;
                }
                */

								let accountId = user?.accountId;

								for (const item of ar_oItem) {
									const month = moment(item.startDate).format('YYYY_M');
									if (hmProjectIdPlanId[month] === undefined) {
										hmProjectIdPlanId[month] = {};
									}
									if (hmProjectIdPlan[month] === undefined) {
										hmProjectIdPlan[month] = {};
									}

									const reAccountId = /.*?accountId=(.*?)/;
									const reProjectId = /.*?project\/(.*?)/;

									accountId = item.assignee.self.replace(reAccountId, '$1');

									let projectId = item.planItem.self.replace(reProjectId, '$1');

									if (item.description.indexOf('SECTION-ID') !== -1) {
										const reVirtualGroupId = /.*?SECTION-ID.(.*?)\].*?/;
										const virtualGroupId = item.description.replace(reVirtualGroupId, '$1');
										projectId = virtualGroupId;
									}

									if (hmProjectIdPlanId[month][projectId] === undefined) {
										hmProjectIdPlanId[month][projectId] = [];
									}
									if (hmProjectIdPlanId[month][projectId][accountId] === undefined) {
										hmProjectIdPlanId[month][projectId][accountId] = [];
									}
									if (hmProjectIdPlan[month][projectId] === undefined) {
										hmProjectIdPlan[month][projectId] = [];
									}

									hmProjectIdPlanId[month][projectId][accountId].push(item.id);

									for (const planValue of item.dates.values) {
										hmProjectIdPlan[month][projectId][accountId] = ((hmProjectIdPlan[month][projectId][accountId]) ?
											hmProjectIdPlan[month][projectId][accountId] : 0) + planValue.timePlannedSeconds * 1;
									}

								}

								for (const month of Object.keys(hmProjectIdPlan)) {
									const monthPlan = hmProjectIdPlan[month];

									let sumProjectAll = 0;

									for (const projectId of Object.keys(monthPlan)) {

										//                    let sumProjectReal = 0;

										delete this._projectWorkloadToolTip[projectId];

										const monthProjectPlan = hmProjectIdPlan[month][projectId];

										let sumProjectReal = 0;

										for (const accountId of Object.keys(monthProjectPlan)) {

											const plan = new RessourceMonthPlan();
											plan.plannedSeconds = monthProjectPlan[accountId];
											plan.ids = hmProjectIdPlanId[month][projectId][accountId];

											if (accountId) {
												this.ressourceCube.setPlan(accountId, projectId, month, plan);
											}

											sumProjectAll += plan.plannedSeconds ? plan.plannedSeconds : 0;

											const checkProjectId = (projectId.indexOf('___') === -1) ? projectId : projectId.split('___')[0];

											if (user) {
												this.projectCollapsedMap[checkProjectId] = false;
											}

											if (this.projectService.projectIds.includes(checkProjectId)) {
												sumProjectReal += plan.plannedSeconds ? plan.plannedSeconds : 0;
											}

											this.store.dispatch(UpdateProjectCellRessourceTables({ projectId, accountId }));

										}

										this.store.dispatch(UpdateProjectOverviewCellRessourceTables({ projectId }));

									}

									if (user) {

										sumProjectAll = user.all_plan_column[month];
										const sumProjectReal = this.ressourceCube.getPlannedTimeForUser(user.accountId, month);

										user.real_plan_column[month] = sumProjectReal;
										user.rest_plan_column[month] = sumProjectAll - sumProjectReal;
										user.all_plan_column[month] = sumProjectAll;

										user.updatePlanColumnValues();
									}

								}
							}

						}
					}
				}

				this.ressourceCube.setDataLoaded(user?.accountId);

				if (user === undefined) {
					this.isWorkloadLoading = true;
				}

				observer.next({dataLoaded: true, user});
				observer.complete();

			}, (error) => {

				if (user === undefined) {
					this.isWorkloadLoading = true;
				}
				this.ressourceCube.setDataLoaded(user?.accountId);

				observer.next({dataLoaded: true, user});
				observer.complete();

			});

		});

		return observable;

	}

	selectedColumn (column: string) {
		if (column !== this.selectedColumnName) {
			this.selectedColumnName = column;

			if (this.columnsToDisplayInfo[column].groupColName !== this.selectedGroupColumnName) {
				this.selectedGroupColumnName = this.columnsToDisplayInfo[column].groupColName;
			}
			this._ChangeDetectorRef.detectChanges();
		}
	}

	setColumns () {

		const columns = [];
		const columnsInfo = {};

		const groupColumns = [];
		const groupColumnsInfo = {};

		this._projectWorkloadToolTip = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-name', key: 'name', title: 'Name', column_type: COLUMN_TYPE.NAME };

		let groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: 'c-name', title: '', colspan: 1 };

		const sClass = 'c-m';

		colName = 'pot_column';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, key: 'pot_column', title: 'Potential', isEditable: true, column_type: COLUMN_TYPE.INFO };

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		colName = 'holiday_column';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, key: 'holiday_column', title: 'Urlaub/Krank', isEditable: true, column_type: COLUMN_TYPE.INFO };

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		colName = 'available_column';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, key: 'available_column', title: 'verfügbar', isEditable: true, column_type: COLUMN_TYPE.INFO };

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		colName = 'real_column';
		columns.push(colName);
		columnsInfo[colName] = {
			class: sClass, key: 'real_column', title: 'gebucht auf Projekte',
			isEditable: true, column_type: COLUMN_TYPE.INFO
		};

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		colName = 'rest_column';
		columns.push(colName);
		columnsInfo[colName] = {
			class: sClass, key: 'rest_column', title: 'Intern/Orga/Anderes Projekt',
			isEditable: true, column_type: COLUMN_TYPE.INFO
		};

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		colName = 'all_column';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, key: 'all_column', title: 'gebucht gesamt', isEditable: true, column_type: COLUMN_TYPE.INFO };

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		colName = 'month_column';
		columns.push(colName);
		columnsInfo[colName] = { class: sClass, key: 'month_column', title: '', isEditable: true, column_type: COLUMN_TYPE.INFO };

		groupColName = 'pg-' + colName;
		groupColumns.push(groupColName);
		groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 };

		let iCount = 1;
		for (const project of this.projectService.projects) {

			if (iCount > 46) {
				// break;
			}

			if (project.type === PROJECT_TYPE.GROUP) {

				let countVGs = 0;
				for (const subProject of project.subProjects) {
					countVGs += subProject.virtualGroups.length;
				}
				groupColName = 'pg-' + 'p_' + project.id;
				groupColumns.push(groupColName);

				groupColumnsInfo[groupColName] = {
					class: sClass, title: project.name,
					colspan: project.subProjects.length > 0 ? project.subProjects.length + countVGs : 1
				};

				for (const subProject of project.subProjects) {
					const sMonth = ((iCount < 10) ? '0' : '') + iCount;

					const colName = 'p_' + subProject.id; // 'm' + this.yearString + sMonth;
					columns.push(colName);

					const toolTipString = this.getProjectWorkloadToolTip(colName, subProject.id);

					columnsInfo[colName] = {
						class: sClass, key: this.yearString + '_' + iCount, title: subProject.getPartName(),
						project: subProject, tooltip: toolTipString, avatar: subProject.avatar,
						groupColName, isEditable: true,
						column_type: COLUMN_TYPE.PROJECT
					};

					for (const virtualGroup of subProject.virtualGroups) {
						const colName = 'p_' + virtualGroup.id;
						columns.push(colName);

						const sColor = 'ressource-vg-' + virtualGroup.color.replace('#', '');

						columnsInfo[colName] = {
							class: sClass, colorClass: sColor, key: this.yearString + '_' + iCount,
							title: virtualGroup.getPartName(),
							project: virtualGroup, tooltip: virtualGroup.name, avatar: subProject.avatar,
							groupColName, isEditable: true,
							column_type: COLUMN_TYPE.PROJECT
						};

						iCount = iCount + 1;

					}

					iCount = iCount + 1;
				}

			} else {
				const sMonth = ((iCount < 10) ? '0' : '') + iCount;

				const colName = 'p_' + project.id; // 'm' + this.yearString + sMonth;
				columns.push(colName);

				const toolTipString = this.getProjectWorkloadToolTip(colName, project.id);

				columnsInfo[colName] = {
					class: sClass, key: this.yearString + '_' + iCount, title: project.getPartName(),
					project, tooltip: toolTipString, avatar: project.avatar, isEditable: true,
					column_type: COLUMN_TYPE.PROJECT
				};

				for (const virtualGroup of project.virtualGroups) {
					const colName = 'p_' + virtualGroup.id;
					columns.push(colName);

					const sColor = 'ressource-vg-' + virtualGroup.color.replace('#', '');

					columnsInfo[colName] = {
						class: sClass, colorClass: sColor, key: this.yearString + '_' + iCount,
						title: virtualGroup.getPartName(),
						project: virtualGroup, tooltip: virtualGroup.name, avatar: project.avatar,
						isEditable: true,
						column_type: COLUMN_TYPE.PROJECT
					};

					iCount = iCount + 1;
				}

				const countVGs = project.virtualGroups.length;

				groupColName = 'pg-' + colName;
				groupColumns.push(groupColName);
				groupColumnsInfo[groupColName] = { class: sClass, title: '', colspan: 1 + countVGs };

				iCount = iCount + 1;
			}
		}

		colName = 'real_plan_column';
		columns.push(colName);
		columnsInfo[colName] = {
			class: sClass, key: 'real_plan_column', title: 'noch nicht verplant',
			isEditable: true, column_type: COLUMN_TYPE.INFO
		};

		colName = 'space';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-spacer', title: '', column_type: COLUMN_TYPE.SPACE };

		this.columnsToDisplay = [];
		this.columnsToDisplayInfo = [];
		this.groupColumnsToDisplay = [];
		this.groupColumnsToDisplayInfo = [];

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;
		this.groupColumnsToDisplay = groupColumns;
		this.groupColumnsToDisplayInfo = groupColumnsInfo;

		this.pgColumnColspans = this.getAllGroupColumnColspans();

		this.oRessourcesTable?.renderRows();

		this._ChangeDetectorRef.detectChanges();

	}

	getProjectWorkloadToolTip (column: string, projectId: string): string {

		if (this._projectWorkloadToolTip[projectId]) {
			return this._projectWorkloadToolTip[projectId];
		}

		try {
			const project = this.projectService.projectsById[projectId];

			if (project === undefined || projectId.indexOf('___') !== -1) {
				// project = this.columnsToDisplayInfo['p_' + projectId].project.parentProject;
				return '';
			}

			const startMonth = this.selectedMonths[0];
			const startDate = new Date(new Date(startMonth.date));
			const endMonth = this.selectedMonths[this.selectedMonths.length - 1];
			const endDate = new Date(new Date(new Date(endMonth.date).getFullYear(),
				new Date(endMonth.date).getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);

			const workloadInRange = project.getFinancedWorkloadInRange(startDate, endDate,
				(this.reportMode === REPORT_MODE.RESSOURCE_HOURS) ?
					WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR : WORKLOAD_UNIT.MINUTES_PER_WORKING_DAY);

			const allInRangeFormated = formatNumber(workloadInRange.ALL, 'de', '1.0-2');
			const financedWorkloadInRangeFormated = formatNumber(workloadInRange.IN_RANGE, 'de', '1.0-2');

			const reportModeString = ((this.reportMode === REPORT_MODE.RESSOURCE_HOURS) ? 'h' : 'PT');
			let workloadTooltip = this.columnsToDisplayInfo[column].project.name +
        '\n\nFinanziert:\nAlle Budgets insgesamt: ' + allInRangeFormated + ' ' + reportModeString;

			workloadTooltip += '\nBudgets im gewählten Zeitraum: ' + financedWorkloadInRangeFormated + ' ' + reportModeString;

			this._projectWorkloadToolTip[projectId] = workloadTooltip;

			return workloadTooltip;
		} catch (error) {
			return '';
		}
	}

	monthAdd (monthAdd: number) {
		//    const dateBegin = this.form.value.date.begin;
		//    const dateEnd = this.form.value.date.end;

		const dateBegin = this.range.value.start;
		const dateEnd = this.range.value.end;

		const startDate = moment(dateBegin).add(monthAdd, 'M').startOf('month');
		const endDate = moment(dateEnd).add(monthAdd, 'M').endOf('month');

		this.startDate = startDate.toDate();
		this.endDate = endDate.toDate();

		this.range = new UntypedFormGroup({
			start: new UntypedFormControl(this.startDate),
			end: new UntypedFormControl(this.endDate)
		});

		this.dateRangeChange(undefined);
	}

	dateRangeChange ($event) {

		if (!this.range.valid || this.range.value.start === null || this.range.value.end === null) {
			return;
		}

		this.ar_selectedMonth = [];

		const startMonth = this.selectedMonths[0];
		const endMonth = this.selectedMonths[this.selectedMonths.length - 1];
		const endDate = new Date(new Date(new Date(endMonth.date).getFullYear(),
			new Date(endMonth.date).getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);

		LocalStorageWorker.instance.set('ressource_startDate', startMonth.date.toString());
		LocalStorageWorker.instance.set('ressource_endDate', endDate.toString());

		if (this.isInitalizing !== true) {
			//      this.refreshAllWorkloadAndPlan();

			/*
      this.store.dispatch(ReloadDataRessourceCube(
        {
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.endDate).format('YYYY-MM-DD')
        }
      ));
*/
			this.refreshTableContent();
		}

		// this.reloadExpandedUsers();

		this._ChangeDetectorRef.detectChanges();
	}

	refreshAllWorkloadAndPlan () {
		this.getWorkloadForSelectedMonth().subscribe((res) => {
			this.getWorkloadForSelectedMonthByUser(undefined).subscribe((result) => {
				if (result?.dataLoaded === true) {
					this.reloadExpandedUsers();
					this._ChangeDetectorRef.detectChanges();

					// this.ressourceCube = this.ressourceCube.clone();
					this.store.dispatch(RefreshColumnsRessourceTables());

					this.store.dispatch(InitializedRessourceTables());
					this._ChangeDetectorRef.detectChanges();

				}

			}, (error) => {
				this.store.dispatch(InitializedRessourceTables());
				this._ChangeDetectorRef.detectChanges();
			});

		});
	}

	reloadExpandedUsers () {

		if (1 === 1) {
			this.refreshTableContent();

			return;
		}
		const ar_oObservable = [];
		for (const accountId of Object.keys(this.userExpandMap)) {
			const isShown = this.userShowMap[accountId];
			const isExpanded = this.userExpandMap[accountId];
			if (isShown === true && isExpanded === true) {
				this.ressourceCube.setDataLoading(accountId);
				this.store.dispatch(UserLoadingRessourceTables());
				ar_oObservable.push(this.getWorkloadForSelectedMonthByUser(this.userMap[accountId]));
			}
		}

		if (ar_oObservable.length > 0) {
			const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((responses: any) => {
				allObservablesSubscribtions.unsubscribe();

				for (const response of responses) {
					if (response?.dataLoaded === true) {
						this.refreshTableContent();
					}
				}

			});

		} else {
			this.refreshTableContent();
		}

	}

	expandAllProjectRelatedUsers (projectId: string) {
		const projectUsers = {};
		for (const monthDescription of this.selectedMonths) {
			const monthProjectUsers = this.ressourceCube.getUsersForProject(projectId, monthDescription.month);
			for (const accountId of Object.keys(monthProjectUsers)) {
				projectUsers[accountId] = monthProjectUsers[accountId];
			}
		}
		for (const accountId of Object.keys(projectUsers)) {
			this.userShowMap[accountId] = true;
			this.userExpandMap[accountId] = true;
		}
		this.store.dispatch(UserLoadingRessourceTables());

		try {
			LocalStorageWorker.instance.set('ressource_userShowMap', JSON.stringify(this.userShowMap));
		} catch (error) { }

		try {
			LocalStorageWorker.instance.set('ressource_userExpandedMap', JSON.stringify(this.userExpandMap));
		} catch (error) { }

		this.reloadExpandedUsers();
	}

	expandRow (user: User) {
		this.userExpandMap[user.accountId] = this.userExpandMap[user.accountId] !== true;

		try {
			LocalStorageWorker.instance.set('ressource_userExpandedMap', JSON.stringify(this.userExpandMap));
		} catch (error) { }

		this.userShownExpanded = undefined;

		this._ChangeDetectorRef.detectChanges();

		this.refreshTableContent();
	}

	refreshTableContentForUser (accountId: string) {
		this.showProjectsForUser(accountId);

		for (const selectedMonth of this.selectedMonths) {
			this.reCalcSumsForUser(accountId, selectedMonth);
			this.reCalcPlanForUser(accountId, selectedMonth);
		}
	}

	refreshTableContentForAllExpandedUser () {
		for (const accountId of Object.keys(this.userExpandMap)) {
			const isShown = this.userShowMap[accountId];
			const isExpanded = this.userExpandMap[accountId];
			if (isShown === true && isExpanded === true) {
				this.refreshTableContentForUser(accountId);
			}
		}
	}

	refreshTableContent () {
		this.refreshTableContentForAllExpandedUser();

		this.store.dispatch(ToggleReportModeRessourceTables());
		this._ChangeDetectorRef.detectChanges();

		this.store.dispatch(RefreshColumnsRessourceTables());

		this.store.dispatch(InitializedRessourceTables());
		this._ChangeDetectorRef.detectChanges();
	}

	/*
  refreshTableContent() {
    // setTimeout(() => {

      this.ressourceCube = this.ressourceCube.clone();
      this.store.dispatch(RefreshColumnsRessourceTables());

      this.toggleReportMode();
      this._ChangeDetectorRef.detectChanges();
      this.toggleReportMode();
      this._ChangeDetectorRef.detectChanges();

      this.store.dispatch(UserLoadingRessourceTables());

      for (const projectId of Object.keys(this.projectCollapsedMap)) {
        if (this.projectCollapsedMap[projectId] !== true) {
          this.store.dispatch(UpdateProjectOverviewCellRessourceTables({ projectId }));
        }
      }

    // }, 300);
  }
  */

	collapseProject (projectId) {
		if (this.projectService.projectsById[projectId] === undefined || this.projectService.projectsById[projectId] === null) {
			return;
		}

		try {
			this.projectCollapsedMap[projectId] = this.projectCollapsedMap[projectId] !== true;
			LocalStorageWorker.instance.set('ressource_projectCollapsedMap', JSON.stringify(this.projectCollapsedMap));
		} catch (error) { }

		this._ChangeDetectorRef.detectChanges();
	}

	cssClassPlannedTime (accountId, monthDescription) {
		const user = this.userMap[accountId];
		const monthKey = monthDescription.month;

		const available = ((user.pot_column[monthKey]) ? user.pot_column[monthKey] : 0) -
      ((user.holiday_column[monthKey]) ? user.holiday_column[monthKey] : 0);

		if (user.real_plan_column[monthKey] === undefined || user.real_plan_column[monthKey] === 0) {
			return 'planned-green';
		}

		if (user.real_plan_column[monthKey] > available) {
			return 'planned-red';
		} else if (user.real_plan_column[monthKey] < available) {
			return 'planned-green';
		} else {
			return 'planned-white';
		}
	}

	getWorkloadCssClass (accountId, projectId, monthDescription) {

		const threshold1 = 0.2;
		const threshold2 = 0.3;

		const workloadSeconds = this.ressourceCube.getData(accountId, projectId, monthDescription.month).timeSpentSeconds || 0;
		const plannedSeconds = this.ressourceCube.getPlan(accountId, projectId, monthDescription.month).plannedSeconds || 0;

		if (workloadSeconds === plannedSeconds) {
			if (workloadSeconds === null || workloadSeconds === 0) {
				return '';
			}
			return 'workload-green bold';
		}
		if (workloadSeconds < plannedSeconds * (1.0 + threshold1) && workloadSeconds > plannedSeconds * (1.0 - threshold1)) {
			return 'workload-green';
		}
		if (workloadSeconds > plannedSeconds * (1.0 + threshold2) || workloadSeconds < plannedSeconds * (1.0 - threshold2)) {
			return 'workload-red';
		}
		return 'workload-yellow';
	}

	isPlannedTimeUpdated (accountId, projectId, monthDescription) {
		try {
			return this.updatedPlannedTimePosition[accountId + '_' + projectId + '_' + monthDescription.month] === true;
		} catch (error) {
			return false;
		}
	}

	/*
  reportModeLabel(): string {
    switch (this.reportMode) {
      case REPORT_MODE.RESSOURCE_HOURS:
        return 'h';
        break;
      case REPORT_MODE.RESSOURCE_DAYS:
        return 'PT';
        break;
      default:
        return '-';
        break;
    }
  }
  */

	toggleReportMode () {
		this.reportMode = (this.reportMode === REPORT_MODE.RESSOURCE_HOURS) ? REPORT_MODE.RESSOURCE_DAYS : REPORT_MODE.RESSOURCE_HOURS;

		this._ChangeDetectorRef.detectChanges();

		this.store.dispatch(ToggleReportModeRessourceTables());

		try {
			LocalStorageWorker.instance.set('ressource_reportMode', this.reportMode);
		} catch (error) { }

	}

	showProjectsForUser (accountId) {
		const ressourceProjectSlices = this.ressourceCube.getRessourceUserSlice(accountId).getProjects();

		for (const projectId of Object.keys(ressourceProjectSlices)) {
			const project = this.projectService.projectsById[projectId];

			if (project) {

				const ressourceProjectSlice = ressourceProjectSlices[projectId];

				let dataAndPlanSeconds = 0;
				for (const selectedMonth of this.selectedMonths) {
					dataAndPlanSeconds += ressourceProjectSlice.getData(selectedMonth.month)?.timeSpentSeconds ?? 0;
					dataAndPlanSeconds += ressourceProjectSlice.getPlan(selectedMonth.month)?.plannedSeconds ?? 0;
				}

				if (dataAndPlanSeconds > 0) {
					this.projectCollapsedMap[project.id] = false;

					if (project.type === PROJECT_TYPE.GROUP) {
						for (const subProject of project.subProjects) {
							this.projectCollapsedMap[subProject.id] = false;
						}
					} else {
						this.projectCollapsedMap[project.id] = false;
					}

				}

			}
		}

		this.pgColumnColspans = this.getAllGroupColumnColspans();

		this._allProjectsCollapsed = undefined;

		this._ChangeDetectorRef.detectChanges();

	}

	reCalcSumsForUser (accountId, monthDescription) {
		const user = this.userMap[accountId];

		user.pot_column[monthDescription.month] = this.userSchedules[accountId]?.sumWorkingDay[monthDescription.month];
		user.holiday_column[monthDescription.month] = this.userSchedules[accountId]?.holiday_column?.[monthDescription.month];

		let sumProjectAll = 0;
		let sumProjectReal = 0;
		const ressourceProjectSlices = this.ressourceCube.getRessourceUserSlice(accountId).getProjects();
		for (const projectId of Object.keys(ressourceProjectSlices)) {
			const data = ressourceProjectSlices[projectId].getData(monthDescription.month);

			const baseProjectId = (projectId.indexOf('___') !== -1) ? projectId.split('___')[0] : projectId;

			sumProjectAll += data.timeSpentSeconds ? data.timeSpentSeconds : 0;
			if (this.projectService.projectIds.includes(baseProjectId)) {
				sumProjectReal += data.timeSpentSeconds ? data.timeSpentSeconds : 0;
			}

		}

		user.real_column[monthDescription.month] = sumProjectReal;
		user.rest_column[monthDescription.month] = sumProjectAll - sumProjectReal;
		user.all_column[monthDescription.month] = sumProjectAll;

		user.updateColumnValues();

		this.updateInfoCellsEmitter.emit(true);
	}

	reCalcPlanForUser (accountId, monthDescription) {
		const user = this.userMap[accountId];

		const sumProjectAll = user.all_plan_column[monthDescription.month];
		const sumProjectReal = this.ressourceCube.getPlannedTimeForUser(accountId, monthDescription.month);

		user.real_plan_column[monthDescription.month] = sumProjectReal;
		user.rest_plan_column[monthDescription.month] = sumProjectAll - sumProjectReal;

		user.updatePlanColumnValues();

		this.updateInfoCellsEmitter.emit(true);
	}

	getProjectId (column: string, replaceString: string = 'p_'): { id: string, baseId: string } {
		try {
			const actProjectId = column.replace(replaceString, '');
			return {
				id: actProjectId,
				baseId: actProjectId.split('___')[0] ?? ''
			};
		} catch (error) {
			return {
				id: '',
				baseId: ''
			};
		}
	}

	updatePlannedTimePosition (id: string, value: boolean): void {
		this.updatedPlannedTimePosition[id] = value;
		this.updatedPlannedTimePosition = Object.assign({}, this.updatedPlannedTimePosition);

		this._ChangeDetectorRef.detectChanges();
	}

	handleBlur ({ accountId, projectId, monthDescription, cellRenderer }) {

		this.updatePlannedTimePosition(accountId + '_' + projectId + '_' + monthDescription.month, true);
		try {
			cellRenderer._ChangeDetectorRef.detectChanges();
		} catch (error) {
		}

		const ressourceMonthPlan = this.ressourceCube.getPlan(accountId, projectId, monthDescription.month);

		this.reCalcPlanForUser(accountId, monthDescription);

		if (ressourceMonthPlan.plannedSeconds !== undefined && !isNaN(ressourceMonthPlan.plannedSeconds)) {
			const saveObject = this.columnsToDisplayInfo['p_' + projectId].project;

			this.ressourceCubeIoService.savePlan(this.ressourceCube, accountId, saveObject, monthDescription).subscribe((res) => {
				//        this.tempoIoService.savePlan(this.ressourceCube, accountId, saveObject, monthDescription).subscribe(res => {

				//        this.tempoIoService.clearCachedPlans();

				this.updatePlannedTimePosition(accountId + '_' + projectId + '_' + monthDescription.month, false);

				// this.getWorkloadForSelectedMonthByUser(this.userMap[accountId], true);

				try {
					cellRenderer._ChangeDetectorRef.detectChanges();
				} catch (error) {
				}

				const dataDescription: DataDescription = new DataDescription();
				dataDescription.uuid = this.socketService.sessionUser.getUUID();
				dataDescription.type = DATA_TYPE.REFRESH_RESSOURCES_TABLE;
				dataDescription.id = accountId;
				dataDescription.body = { updatedKey: accountId + '_' + projectId + '_' + monthDescription.month };
				this.socketService.dataChange(dataDescription);

				try {
					if (cellRenderer.bClickNext) {
						this.clickNextRow(accountId, projectId, monthDescription);
					}
				} catch (error) {

				}

				this.ressourceCube = this.ressourceCube.clone();
				this._ChangeDetectorRef.detectChanges();

			});
		}
	}

	clickNextRow (accountId, projectId, monthDescription) {
		const sPart: string[] = monthDescription.month.split('_');
		const nextMonth = sPart[0] + '_' + (parseInt(sPart[1], 10) + 1);
		const element = document.getElementById(accountId + '_' + projectId + '_' + nextMonth);

		try {
			element.click();
		} catch (error) { }

	}

	saveProject (projectId: string) {
		let project = this.projectService.projectsById[projectId];

		if (projectId.indexOf('___') !== -1) {
			project = this.columnsToDisplayInfo['p_' + projectId].project.parentProject;
		}

		this.projectService.saveProject(project, () => {
			this._ChangeDetectorRef.detectChanges();
		});
	}

	setHolidaySickStartDate (accountId, monthDescription) {
		this.holidaySickStartUser = this.userMap[accountId];
		this.holidaySickStartDate = new Date(monthDescription.date);

		this.calendarHoliday.activeDate = this.holidaySickStartDate;
		this.calendarSick.activeDate = this.holidaySickStartDate;

		this.calendarHoliday.updateTodaysDate();
		this.calendarSick.updateTodaysDate();
	}

	isHolidaySelected = (event: any) => {
		try {
			return this.isSelected(event, this.holidaySickStartUser.holiday_dates, 'holiday');
		} catch (error) {
			return false;
		}
	};
	isSickSelected = (event: any) => {
		try {
			return this.isSelected(event, this.holidaySickStartUser.sick_dates, 'sick');
		} catch (error) {
			return false;
		}
	};

	isSelected (event: any, selected_dates: any[], label: string) {
		const date = event.getFullYear() + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + ('00' + event.getDate()).slice(-2);
		const item = selected_dates.find((x) => x.date === date);
		try {
			if (item.timeSpentSeconds === 0) {
				return null;
			} else if (item.timeSpentSeconds < REQUIRED_SECONDS_PER_DAY) {
				const hours = Math.round(item.timeSpentSeconds / SECONDS_PER_HOUR);
				return 'selected-' + label + ' strip-' + hours;
			} else {
				return 'selected-' + label;
			}
		} catch (error) {
			return null;
		}
	}
	select (event: any, selected_dates: any[], calendar: any) {
		const date = event.getFullYear() + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + ('00' + event.getDate()).slice(-2);
		const item = selected_dates.find((x) => x.date === date);
		if (item === undefined) {
			selected_dates.push({ date, timeSpentSeconds: REQUIRED_SECONDS_PER_DAY });
		} else {
			if (item.oldTimeSpentSeconds === undefined) {
				item.oldTimeSpentSeconds = item.timeSpentSeconds;
			}
			item.timeSpentSeconds -= SECONDS_PER_HOUR;
			if (item.timeSpentSeconds < 0) {
				item.timeSpentSeconds = REQUIRED_SECONDS_PER_DAY;
			}
		}

		calendar.updateTodaysDate();
	}

	handleBlurMenu () {

		const ar_oObservable: Observable<any>[] = [];

		for (const item of this.holidaySickStartUser.holiday_dates) {
			if (item.tempoWorklogId === undefined ||
        (item.oldTimeSpentSeconds !== undefined && item.timeSpentSeconds !== item.oldTimeSpentSeconds)) {
				const observable = this.tempoIoService.saveWorklogForUser(item.tempoWorklogId,
					this.holidaySickStartUser.accountId,
					'POR-57', item.timeSpentSeconds,
					'URLAUBSBLOCKER',
					item.date);
				ar_oObservable.push(observable);
			}
		}
		for (const item of this.holidaySickStartUser.sick_dates) {
			if (item.tempoWorklogId === undefined ||
        (item.oldTimeSpentSeconds !== undefined && item.timeSpentSeconds !== item.oldTimeSpentSeconds)) {
				const observable = this.tempoIoService.saveWorklogForUser(item.tempoWorklogId,
					this.holidaySickStartUser.accountId,
					'POR-58',
					item.timeSpentSeconds,
					'KRANK',
					item.date);
				ar_oObservable.push(observable);
			}
		}

		const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((err) => {
			console.log('Holiday and Sick saved!');

			allObservablesSubscribtions.unsubscribe();
			this.getWorkloadForSelectedMonth().subscribe((res) => {
				this.reloadExpandedUsers();
				this._ChangeDetectorRef.detectChanges();
			});

		},
		() => {
			console.log('Holiday and Sick saved!');

			allObservablesSubscribtions.unsubscribe();
			this.getWorkloadForSelectedMonth().subscribe((res) => {
				this.reloadExpandedUsers();
				this._ChangeDetectorRef.detectChanges();
			});

		});

		console.log('COLLAPSE MENU');
	}

	compareSelection (o1: any, o2: any): boolean {
		try {
			return o1.id === environment.selectedVirtualGroup.selection.id;
		} catch (error) {
			console.log(error);
		}
		try {
			return o2.id === environment.selectedVirtualGroup.selection.id;
		} catch (error) {
			console.log(error);
		}
		return false;
	}

	isVirtualGroup (column: string) {
		return column.indexOf('___') !== -1;
	}

	getFillColor (color: string) {
		return (color === this.selectedVirtualGroup.color) ? '#FFFFFF' : color;
	}

	selectedVersion (version: JiraVersion) {
		try {
			if (this.selectedVirtualGroup.selection.type === CLASS_TYPE.JIRA_VERSION) {
				return version.id === this.selectedVirtualGroup.selection.id;
			}
		} catch (error) {
			console.log(error);
		}

		return false;
	}
	addOrEditVirtualGroup (column: string) {

		const _self = this;

		const actSelectedProject = this.columnsToDisplayInfo[column].project;
		if (actSelectedProject.type === CLASS_TYPE.VIRTUAL_GROUP) {
			this.selectedProject = actSelectedProject.parentProject;
			this.showVirtualGroupDeleteButton = true;
		} else {
			this.selectedProject = actSelectedProject;
			this.showVirtualGroupDeleteButton = false;
		}

		if (this.selectedProject !== undefined || this.selectedProject !== null) {
			this.projectService.getProjectEpics(this.selectedProject);
			this.projectService.getProjectComponentsAndVersions(this.selectedProject);
		}

		this.selectedVirtualGroup = this.columnsToDisplayInfo[column].project;
		if (this.selectedVirtualGroup.type !== CLASS_TYPE.VIRTUAL_GROUP) {
			this.selectedVirtualGroup = VirtualGroup.create();
		}

		environment.selectedVirtualGroup = this.selectedVirtualGroup;

		const dialogRef = this.dialog.open(this.virtualGroupDialog);

		dialogRef.afterOpened().subscribe((result) => {
			try {
				switch (this.selectedVirtualGroup.selection.type) {
					case CLASS_TYPE.JIRA_ISSUE:
						this.selectEpic.value = this.selectedVirtualGroup.selection;
						break;
					case CLASS_TYPE.JIRA_COMPONENT:
						this.selectComponent.value = this.selectedVirtualGroup.selection;
						break;
					case CLASS_TYPE.JIRA_VERSION:
						this.selectVersion.value = this.selectedVirtualGroup.selection;
						break;
					default:
						break;
				}
			} catch (error) { }
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (_self.selectedVirtualGroup === undefined || _self.selectedVirtualGroup === null) {
				_self.selectedVirtualGroup = VirtualGroup.create();
			}

			if (result === 'confirm') {

				if (_self.selectEpic.value !== undefined ||
          _self.selectComponent.value !== undefined ||
          _self.selectVersion.value !== undefined) {
					_self.selectedVirtualGroup.selection =
            _self.selectEpic.value || _self.selectComponent.value || _self.selectVersion.value;
				}

				_self.selectedProject.addVirtualGroup(_self.selectedVirtualGroup);

				_self.saveProject(_self.selectedProject.id);

				console.log(_self.selectedVirtualGroup.name);

				this.store.dispatch(RefreshColumnsRessourceTables());
			} else if (result === 'delete') {
				this.deleteVirtualGroup();
			}

		});

	}

	deleteVirtualGroup () {
		const _self = this;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				_self.selectedProject.deleteVirtualGroup(_self.selectedVirtualGroup);

				_self.saveProject(_self.selectedProject.id);

				this.store.dispatch(RefreshColumnsRessourceTables());

			}
		});

	}

	selectShowHideProject (projectId: string, value) {
		this.projectCollapsedMap[projectId] = !value;

		this.pgColumnColspans = this.getAllGroupColumnColspans();

		try {
			LocalStorageWorker.instance.set('ressource_projectCollapsedMap', JSON.stringify(this.projectCollapsedMap));
		} catch (error) { }

		this._allProjectsCollapsed = undefined;

		this._ChangeDetectorRef.detectChanges();
	}

	selectShowHideAllProject (value) {
		for (const project of this.projectService.projects) {
			if (project.type === PROJECT_TYPE.GROUP) {
				for (const subProject of project.subProjects) {
					this.projectCollapsedMap[subProject.id] = value;
				}
			} else {
				this.projectCollapsedMap[project.id] = value;
			}
		}
		this.pgColumnColspans = this.getAllGroupColumnColspans();
		try {
			LocalStorageWorker.instance.set('ressource_projectCollapsedMap', JSON.stringify(this.projectCollapsedMap));
		} catch (error) { }

		this._allProjectsCollapsed = undefined;

		this._ChangeDetectorRef.detectChanges();
	}

	allProjectsCollapsed () {
		if (this._allProjectsCollapsed === undefined) {
			let countAll = 0;
			let countCollapsed = 0;
			for (const project of this.projectService.projects) {
				if (project.type === PROJECT_TYPE.GROUP) {
					for (const subProject of project.subProjects) {
						countAll += 1;
						countCollapsed += this.projectCollapsedMap[subProject.id] === true ? 1 : 0;
					}
				} else {
					countAll += 1;
					countCollapsed += this.projectCollapsedMap[project.id] === true ? 1 : 0;
				}
			}
			this._allProjectsCollapsed = (countCollapsed === 0) ? 'unchecked' : (countAll > countCollapsed) ? 'mixed' : 'checked';
		}
		return this._allProjectsCollapsed;
	}

	allTeamsShow () {
		return 'checked';
	}

	allUsersInTeamShow (tempoTeam: TempoTeam) {
		let countAll = 0;
		let countShow = 0;
		for (const user of tempoTeam.memberUsers) {
			countAll += 1;
			countShow += this.userShowMap[user.accountId] === true ? 1 : 0;
		}
		return (countShow === 0) ? 'unchecked' : (countAll > countShow) ? 'mixed' : 'checked';
	}

	selectShowHideTeams (tempoTeam: TempoTeam, value) {
		for (const user of tempoTeam.memberUsers) {
			this.userShowMap[user.accountId] = value;
		}

		try {
			LocalStorageWorker.instance.set('ressource_userShowMap', JSON.stringify(this.userShowMap));
		} catch (error) { }

		this._ChangeDetectorRef.detectChanges();

	}

	selectShowHideUsers (userId: string, value) {
		this.userShowMap[userId] = value;

		try {
			LocalStorageWorker.instance.set('ressource_userShowMap', JSON.stringify(this.userShowMap));
		} catch (error) { }

		this._ChangeDetectorRef.detectChanges();
	}

	hideShowProjects () {
		const _self = this;

		this._allProjectsCollapsed = undefined;

		const dialogRef = this.dialog.open(this.hideShowProjectDialog, {
			disableClose: true,
			// backdropClass: 'blur',  -----> root cause
			position: { top: '15vh' }
		});

		this._ChangeDetectorRef.detectChanges();

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				dialogRef.close();
				this._ChangeDetectorRef.detectChanges();
			}

			this.store.dispatch(InitializedRessourceTables());

		});

	}

	hideShowTeamsOrUsers () {
		const _self = this;

		this.getTeams().subscribe((tempoTeams) => {
			this.tempoTeams = tempoTeams;
			this._ChangeDetectorRef.detectChanges();
		});
		const dialogRef = this.dialog.open(this.hideShowTeamsOrUsersDialog, {
			disableClose: true,
			// backdropClass: 'blur',  -----> root cause
			position: { top: '15vh' }
		});

		dialogRef.afterOpened().subscribe((result) => {
			this.refreshTableContent();
			// this._ChangeDetectorRef.detectChanges();
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				dialogRef.close();
				this._ChangeDetectorRef.detectChanges();
			}
		});

	}

	getAllGroupColumnColspans (): any {
		const pgColumnColspans = {};
		for (const pgColumn of this.groupColumnsToDisplay) {
			pgColumnColspans[pgColumn] = this.calcGroupColumnColspan(pgColumn);
		}
		return pgColumnColspans;
	}

	calcGroupColumnColspan (pgColumn: string): number {
		let colspan = this.groupColumnsToDisplayInfo[pgColumn].colspan;

		const projectId = pgColumn.replace('pg-p_', '');

		const project = this.projectService.projectsById[projectId];

		if (project) {
			if (project.type === PROJECT_TYPE.GROUP) {
				for (const subProject of project.subProjects) {
					colspan -= this.projectCollapsedMap[subProject.id] === true ? 1 : 0;
					for (const virtualGroup of subProject.virtualGroups) {
						colspan -= this.projectCollapsedMap[virtualGroup.parentProject.id] === true ? 1 : 0;
					}
				}
			} else {
				colspan -= this.projectCollapsedMap[project.id] === true ? 1 : 0;
				for (const virtualGroup of project.virtualGroups) {
					colspan -= this.projectCollapsedMap[virtualGroup.parentProject.id] === true ? 1 : 0;
				}
			}

		}
		return colspan;
	}

	getColspan (pgColumn: string) {
		let colspan = this.pgColumnColspans[pgColumn];

		if (colspan === undefined) {
			colspan = this.calcGroupColumnColspan(pgColumn);
			this.pgColumnColspans[pgColumn] = colspan;
		}
		return colspan;
	}

	expandCollapseAllShownUser () {

		this._ChangeDetectorRef.detach();

		let newUserShownExpanded = true;
		if (this.userShownExpanded === USER_SHOWN_EXPANDED.ALL) {
			newUserShownExpanded = false;
		}

		for (const accountId of Object.keys(this.userShowMap)) {
			this.userExpandMap[accountId] = newUserShownExpanded;
			if (newUserShownExpanded === false) {
				delete this.userExpandMap[accountId];
			}
		}

		try {
			LocalStorageWorker.instance.set('ressource_userExpandedMap', JSON.stringify(this.userExpandMap));
		} catch (error) { }

		this.userShownExpanded = undefined;

		this._ChangeDetectorRef.reattach();

		this.reloadExpandedUsers();

	}
}
