import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { REPORT_MODE, UNIT } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { User } from 'src/app/model/User';

@Component({
	selector: 'app-ressources-table-content-info-cell',
	templateUrl: './ressources-table-content-info-cell.component.html',
	styleUrls: ['./ressources-table-content-info-cell.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RessourcesTableContentInfoCellComponent implements OnInit {

	@Input() updateInfoCellsEmitter: EventEmitter<any>;

	@Input() public reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS;

	@Input() public selectedMonths: any[];

	public _user: User;
	@Input()
	public get user (): User {
		return this._user;
	}
	public set user (value: User) {
		this._user = value;
	}
	@Input() public column: string;

	trackByFn = trackByFn;
	UNIT = UNIT;
	REPORT_MODE = REPORT_MODE;

	constructor (
		public _ChangeDetectorRef: ChangeDetectorRef,
	) { }

	ngOnInit (): void {
		this.updateInfoCellsEmitter.subscribe((value) => {
			this._ChangeDetectorRef.detectChanges();
		});
	}

	public getInfo (actColumn: string, monthDescription: any, format: string = UNIT.HOURS, digits: number = 0): string {

		switch (actColumn) {
			case 'holiday_column':
				return this.user.getHolidayDays(monthDescription.month, format, digits);
				break;

			case 'pot_column':
				return this.user.getPotDays(monthDescription.month, format, digits);
				break;

			case 'available_column':
				return this.user.getAvailableDays(monthDescription.month, format, digits);
				break;

			case 'real_column':
				return this.user.getRealDays(monthDescription.month, format, digits);
				break;

			case 'rest_column':
				return this.user.getRestDays(monthDescription.month, format, digits);
				break;

			case 'all_column':
				return this.user.getAllDays(monthDescription.month, format, digits);
				break;

			case 'real_plan_column':
				return this.user.getNotYetPlannedTime(monthDescription.month, format, digits);
				break;

			case 'month_column':
				return monthDescription.label;
				break;

			default:
				return '';
				break;
		}
	}

	/*

                        <div class="month-column" *ngIf="column == 'real_plan_column'">
                            <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn"
                                title="{{getTooltipString(row.getNotYetPlannedTime(monthDescription.month))}} verfügbar">
                                <div>-</div>
                                <div class="tempo-info" class="{{cssClassPlannedTime(row.accountId, monthDescription)}}">
                                    {{row.getNotYetPlannedTime(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS, 1)}}
                                </div>
                            </div>
                        </div>

<div class="month-column" *ngIf="column == 'holiday_column'">
  <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn"
      title="{{getTooltipString(row.getHolidayDays(monthDescription.month, UNIT.HOURS, 2))}}">
      <div>&nbsp;</div>
      <div class="tempo-info">
          <button [matMenuTriggerFor]="appMenu" (click)="setHolidaySickStartDate(row.accountId, monthDescription); $event.stopPropagation()" class="cursor-pointer">
              {{row.getHolidayDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}
          </button>
      </div>
  </div>
</div>
*/

}
