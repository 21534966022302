import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { concat, EMPTY, forkJoin, Observable, of, Subject, throwError, timer, zip } from 'rxjs';
import { AP$ } from 'src/polyfills';
import * as moment from 'moment';
import { RessourceCube, RessourceMonthPlan } from '../model/RessourceCube';
import { DataDescription } from '../model/DataDescription';
import { CLASS_TYPE, DATA_TYPE, SOCKET_SUBJECT } from '../model/enums';
import { SocketService } from './socket.service';
import { environment } from 'src/environments/environment';
import { Project } from '../model/model';
import { VirtualGroup } from '../model/VirtualGroup';
import { TempoTeam } from '../model/TempoTeam';
import { catchError, delay, map, retry } from 'rxjs/operators';
import { LocalStorageWorker } from '../model/LocalStorageWorker';
import { compress, decompress } from 'lzutf8';
import { StorageMap } from '@ngx-pwa/local-storage';

export const TEMPO_IO_URL = new InjectionToken<string>('TempoIoUrl');
export const TEMPO_IO_AUTH_URL = new InjectionToken<string>('TempoIoAuthUrl');
@Injectable({
	providedIn: 'root'
})

export class TempoIoService {

	private headers = {
		referrer: undefined,
		Authorization: undefined
	};

	public connectedSubject = new Subject<any>();

	public setNewAuthorizationHeader (tempo_access_token: string) {
		this.headers.Authorization = 'Bearer ' + tempo_access_token;
	}

	constructor (
		@Inject(TEMPO_IO_URL) public tempoIoUrl: string,
		@Inject(TEMPO_IO_AUTH_URL) public tempoIoAuthUrl: string,
		public socketService: SocketService,
		private http: HttpClient,

		private storage: StorageMap
	) {
		// const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
		// const parts = parse_url.exec( window.location.href );
		// const baseUrl = parts[1] + ':' + parts[2] + parts[3];

		// this.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, HEAD, OPTIONS';
		// this.headers['X-HTTP-Method-Override'] = 'GET, POST, PUT, DELETE, HEAD, OPTIONS';
		// this.headers['Access-Control-Allow-Origin'] = '*';
		// this.headers['Origin'] = baseUrl;
		this.headers.referrer = '';

		// new Headers({'Content-type': 'application/json', 'Access-Control-Allow-Origin': '*',
		// 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, HEAD, OPTIONS',
		// 'X-HTTP-Method-Override': 'GET, POST, PUT, DELETE, HEAD, OPTIONS'});

		console.log(JSON.stringify(this.headers));

		const intervalId = setInterval(() => {
			if (socketService.sessionUser !== undefined) {
				clearInterval(intervalId);
				this.setAuthorizationHeader();
			}
		}, 1000);

	}

	public setContentTypeHeader (actionUrl: string): void {
		if (actionUrl.indexOf(this.tempoIoUrl) !== -1) {
			this.headers['Content-Type'] = 'application/json';
		} else {
			this.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		}
	}

	public get<T> (actionUrl: string, params?): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (!this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		if (actionUrl.indexOf('/?offset') !== -1) {
			return throwError('No concrete function called with offset');
		}

		return this.http.get<T>(actionUrl, { headers: this.headers, params });
	}

	public clearCachedPlans () {

		this.storage.keys().subscribe({
			next: (key) => {
				if (key.indexOf('plan') !== -1) {
					this.storage.delete(key).subscribe(() => { });
				}
			},
			complete: () => { console.log('Done'); }
		});
	}

	public getCachedAll<T> (actionUrl: string, params?: any,
		cached: boolean = true, cacheIntervall: number = 10 * 60 * 60 * 1000): Observable<T> {

		const observable = new Observable<T>((observer) => {

			const cachedKey = 'cachedData_' + encodeURIComponent(actionUrl);

			let observableGetAll = this.getAll<T>(actionUrl, params);

			if (cached) {

				this.storage.get(cachedKey).subscribe((cachedData) => {
					if (cachedData !== undefined && cachedData !== null) {
						const decompressedJsonCachedData = JSON.parse(decompress(cachedData, { inputEncoding: 'Base64' }));
						const timestamp = decompressedJsonCachedData?.timestamp * 1;
						if (timestamp + cacheIntervall > Date.now()) {
							observableGetAll = undefined;

							observer.next(decompressedJsonCachedData?.data);
							observer.complete();
						} else {
							this.storage.delete(cachedKey);
							// LocalStorageWorker.instance.remove('cachedData_' + actionUrl);
						}
					}

					observableGetAll?.subscribe((result) => {
						if (cached) {
							const cachedEntry = {
								timestamp: Date.now(),
								data: result
							};
							const compressedJsonBase64 = compress(JSON.stringify(cachedEntry), { outputEncoding: 'Base64' });

							this.storage.set(cachedKey, compressedJsonBase64).subscribe(() => { });

							// LocalStorageWorker.instance.set('cachedData_' + actionUrl, compressedJsonBase64);
						}
						observer.next(result);
						observer.complete();
					});

				});

			} else {
				observableGetAll?.subscribe((result) => {
					observer.next(result);
					observer.complete();
				});

			}

		});

		return observable;
	}

	public getAll<T> (actionUrl: string, params?: any, responseAll: any = null): Observable<T> {
		const _self = this;

		const limitIntervall = 1000;

		if (responseAll === null) {
			responseAll = {
				self: '',
				metadata: {
					count: 0,
					offset: 0,
					limit: limitIntervall
				},
				results: []
			};
		}

		actionUrl = actionUrl.replace('https://', '/');
		let baseUrl = '';

		const bulkActionUrls = [];
		const queryParams: any = {};
		let allLimit = limitIntervall;
		let requestOffset = 0;
		if (actionUrl.indexOf('?') !== -1) {
			baseUrl = actionUrl.split('?')[0];
			const queryString = actionUrl.split('?')[1];
			const ar_queryParts = queryString.split('&');
			for (const queryPart of ar_queryParts) {
				const ar_keyValue = queryPart.split('=');
				queryParams[ar_keyValue[0]] = ar_keyValue[1];
			}
			if (queryParams.limit > limitIntervall) {
				allLimit = parseFloat(queryParams.limit);
			}
			if (queryParams.offset !== undefined) {
				requestOffset = parseFloat(queryParams.offset);
			}

			for (let offset = requestOffset; offset < allLimit + requestOffset; offset += limitIntervall) {
				queryParams.offset = offset;
				queryParams.limit = limitIntervall;
				let queryString = '';
				for (const key of Object.keys(queryParams)) {
					if (queryParams[key] !== undefined) {
						queryString += key + '=' + queryParams[key] + '&';
					}
				}
				if (!baseUrl.endsWith('/')) {
					bulkActionUrls.push(baseUrl + '?' + queryString);
				}
			}

		} else {
			if (!actionUrl.endsWith('/')) {
				bulkActionUrls.push(actionUrl);
			}
		}

		const ar_oObservable: Observable<any>[] = [];
		for (const actionUrl of bulkActionUrls) {
			const random = Math.random() * 500 + 300;
			const getObservable = _self.get(actionUrl, params).pipe(delay(random)).pipe(
				catchError((err) => of({ isError: true, error: err })),
			); // .pipe(delay(random));

			//        const getObservable = concat(timer(random), _self.get<any>(actionUrl, params)).pipe(
			//          retry(5),
			//          catchError(err => of({isError: true, error: err})),
			//          catchError(() => {return EMPTY;}),
			//          shareReplay()
			//        ); // .pipe(delay(random));

			ar_oObservable.push(getObservable);
		}
		const observable = new Observable<T>((observer) => {
			const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((responses) => {
				let hasNoNext = false;
				let offset = 0;
				for (const response of responses) {
					if (response === undefined || response.self === undefined) {
						continue;
					}
					responseAll.self = response.self;
					responseAll.metadata.count = responseAll.metadata.count + response.metadata.count;
					responseAll.results.push(...response.results);

					offset = response.metadata.offset + response.metadata.limit;

					if (response.metadata.next === undefined) {
						hasNoNext = true;
						break;
					}
				}
				if (hasNoNext === true) {
					observer.next(responseAll);
					observer.complete();
				} else {
					queryParams.offset = offset;
					queryParams.limit = allLimit;
					let queryString = '';
					for (const key of Object.keys(queryParams)) {
						if (queryParams[key] !== undefined) {
							queryString += key + '=' + queryParams[key] + '&';
						}
					}

					if (!baseUrl.endsWith('/')) {
						_self.getAll(baseUrl + '?' + queryString, params, responseAll).subscribe((resAll: any) => {
							observer.next(responseAll);
						}, (error) => {
							observer.next(responseAll);
							//            observer.error(error);
						});
					} else {
						observer.next(responseAll);
					}
				}

			}, (error) => {
				observer.next(responseAll);
				//        observer.error(error);
			});

		});

		return observable;
	}

	/*
  public getAll<T>(actionUrl: string, params?: any, responseAll: any = null): Observable<T> {
    const _self = this;

    actionUrl = actionUrl.replace('https://', '/');

    responseAll = responseAll || {
        self: '',
        metadata: {
          count: 0,
          offset: 0,
          limit: 1000
        },
        results: []
      };

    const observable = new Observable<T>((observer) => {
      _self.get(actionUrl, params).subscribe((res: any) => {

        if (res.metadata === undefined) {
          observer.next(res);
          observer.complete();
         } else {
          responseAll.self = res.self;
          responseAll.metadata.count = responseAll.metadata.count + res.metadata.count;

          responseAll.results.push(...res.results);

          if (res.metadata.next === undefined) {
            observer.next(responseAll);
            observer.complete();
          } else {
            _self.getAll(res.metadata.next, params, responseAll).subscribe((resAll: any) => {
              observer.next(responseAll);
            }, (error) => {
              observer.error(error);
            });
          }

        }

      }, (error) => {
        observer.error(error);
      });
    });

    return observable;
  }
  */

	public put<T> (actionUrl: string, itemObject: any = {}, params?: any): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (!this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		return this.http.put<T>(actionUrl, itemObject, { headers: this.headers, params });
	}

	public post<T> (actionUrl: string, itemObject: any = {}, params?: any): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (actionUrl.indexOf(this.tempoIoUrl) !== -1 && !this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		if (this.headers.Authorization === undefined) {
			delete this.headers.Authorization;
		}

		return this.http.post<T>(actionUrl, itemObject, { headers: this.headers, params });
	}

	public update<T> (actionUrl: string, itemToUpdate: any): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (!this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		return this.http.put<T>(actionUrl, itemToUpdate, { headers: this.headers });
	}

	public delete<T> (actionUrl: string): Observable<T> {
		this.setContentTypeHeader(actionUrl);
		return this.http.delete<T>(actionUrl, { headers: this.headers });
	}

	public saveWorklogForUser (tempoWorklogId: string,
		accountId: string,
		issueKey: string,
		timeSpentSeconds: number,
		description: string,
		startDate: string,
		startTime: string = '08:00:00'): Observable<any> {

		const _self = this;
		const observable = new Observable<any>((observer) => {

			const obj = {
				issueKey,
				timeSpentSeconds,
				billableSeconds: timeSpentSeconds,
				startDate,
				startTime,
				description: 'P_COCKPIT - ' + description,
				authorAccountId: accountId
			};

			if (tempoWorklogId === undefined || tempoWorklogId === null) {
				_self.post(_self.tempoIoUrl + 'worklogs', obj)
					.subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});

			} else if (timeSpentSeconds > 0) {
				_self.put(_self.tempoIoUrl + 'worklogs/' + tempoWorklogId, obj)
					.subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});

			} else {
				_self.delete(_self.tempoIoUrl + 'worklogs/' + tempoWorklogId).subscribe((res: any) => {
					console.debug(JSON.stringify(res));

					observer.next(res);
					observer.complete();

				}, (error) => {
					observer.error(error);
				});
			}
		});
		return observable;
	}

	public getUserScheduleForMonth (accountId: string, monthFrom: string, monthTo: string = null): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			const startOfMonth = moment(monthFrom).startOf('month').format('YYYY-MM-DD');
			const endOfMonth = moment(monthTo ? monthTo : monthFrom).endOf('month').format('YYYY-MM-DD');

			//      _self.get(_self.tempoIoUrl + 'user-schedule/' + accountId +
			_self.getCachedAll(_self.tempoIoUrl + 'user-schedule' + ((accountId) ? '/' + accountId : '') +
        '?from=' + startOfMonth +
        '&to=' + endOfMonth + '', {}).subscribe((res: any) => {
				console.debug(JSON.stringify(res));

				const sumWorkingDay = {};
				const sumHoliday = {};
				const holidayDates = {};
				for (const item of res.results) {

					const month = moment(item.date).format('YYYY_M');

					switch (item.type) {
						case 'WORKING_DAY':
							sumWorkingDay[month] = (sumWorkingDay[month] || 0) + item.requiredSeconds;
							break;

						case 'HOLIDAY':
						case 'HOLIDAY_AND_NON_WORKING_DAY':
							sumHoliday[month] = (sumHoliday[month] || 0) + item.durationSeconds;
							const monthDate = moment(item.date).format('YYYY_MM_DD');
							holidayDates[monthDate] = true;
							break;

						default:
							break;
					}
				}

				observer.next({
					accountId,
					sumWorkingDay,
					sumHoliday,
					holidayDates
				});
				observer.complete();

			}, (error) => {
				observer.next(error);
				observer.complete();
				//          observer.error(error);
			});
		});

		return observable;

	}

	public getWorklogsForMonth (accountId: string, monthFrom: string, monthTo: string = null): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			const startOfMonth = moment(monthFrom).startOf('month').format('YYYY-MM-DD');
			const endOfMonth = moment(monthTo ? monthTo : monthFrom).endOf('month').format('YYYY-MM-DD');

			if (accountId) {
				_self.getAll(_self.tempoIoUrl + 'worklogs/user/' + accountId + '?from=' +
          startOfMonth + '&to=' +
          endOfMonth + '&limit=1000' +
          '', {}).subscribe((res: any) => {
					console.debug(JSON.stringify(res));

					observer.next(res);
					observer.complete();

				}, (error) => {
					observer.error(error);
				});
			} else {

				const monthDifference = moment(endOfMonth).diff(moment(startOfMonth), 'months', true);
				const limit = Math.round(monthDifference * 1500) + 1000;

				//        _self.getAll(_self.tempoIoUrl + 'worklogs?from=' +
				_self.getCachedAll(_self.tempoIoUrl + 'worklogs?from=' +
          startOfMonth + '&to=' +
          endOfMonth + '&limit=' + limit +
          '', {}).subscribe((res: any) => {
					console.debug(JSON.stringify(res));

					observer.next(res);
					observer.complete();

				}, (error) => {
					observer.next(error);
					observer.complete();
					//          observer.error(error);
				});

			}
		});

		return observable;

	}

	public getPlansForMonth (accountId: string, monthFrom: string, monthTo: string = null): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			const startOfMonth = moment(monthFrom).startOf('month').format('YYYY-MM-DD');
			const endOfMonth = moment(monthTo ? monthTo : monthFrom).endOf('month').format('YYYY-MM-DD');

			if (accountId) {
				_self.getAll(_self.tempoIoUrl + 'plans/user/' + accountId + '?from=' +
          startOfMonth + '&to=' +
          endOfMonth + '&limit=1000' +
          '', {}).subscribe((res: any) => {
					console.debug(JSON.stringify(res));

					observer.next(res);
					observer.complete();

				}, (error) => {
					observer.error(error);
				});
			} else {

				const monthDifference = moment(endOfMonth).diff(moment(startOfMonth), 'months', true);
				const limit = Math.round(monthDifference * 1000) + 1000;

				//        _self.getAll(_self.tempoIoUrl + 'plans?from=' +
				_self.getCachedAll(_self.tempoIoUrl + 'plans?from=' +
          startOfMonth + '&to=' +
          endOfMonth + '&limit=' + limit +
          '', {}).subscribe((res: any) => {
					console.debug(JSON.stringify(res));

					observer.next(res);
					observer.complete();

				}, (error) => {
					observer.next(error);
					observer.complete();
					//          observer.error(error);
				});
			}
		});

		return observable;

	}

	public savePlan (ressourceCube: RessourceCube, accountId: string,
		projectOrVirtualGroup: Project | VirtualGroup, monthDescription: any): Observable<any> {
		const _self = this;
		const projectOrVirtualGroupId = projectOrVirtualGroup.id;
		const projectId = (projectOrVirtualGroup.type === CLASS_TYPE.VIRTUAL_GROUP) ?
			projectOrVirtualGroup.parentProject.id : projectOrVirtualGroup.id;

		const ressourceMonthPlan: RessourceMonthPlan = ressourceCube.getPlan(accountId, projectOrVirtualGroupId, monthDescription.month);

		const observable = new Observable<any>((observer) => {

			const startOfPlan = moment(monthDescription.date).startOf('month').format('YYYY-MM-DD');
			const endOfPlan = moment(monthDescription.date).startOf('month').format('YYYY-MM-DD');

			let section = '';
			if (projectOrVirtualGroup.type === CLASS_TYPE.VIRTUAL_GROUP) {
				section = ' [SECTION: ' + projectOrVirtualGroup.name + '][SECTION-ID:' + projectOrVirtualGroupId + ']';
			}

			const obj = {
				startDate: startOfPlan,
				endDate: endOfPlan,
				description: 'P_COCKPIT' + section,
				plannedPerDaySeconds: ressourceMonthPlan.plannedSeconds,
				includeNonWorkingDays: false,
				rule: 'NEVER',
				recurrenceEndDate: endOfPlan,
				accountId,
				projectKey: projectId
			};

			if (ressourceMonthPlan.ids.length === 0) {
				if (ressourceMonthPlan.plannedSeconds !== 0) {
					_self.post(_self.tempoIoUrl + 'plans', obj).subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						const newId = res?.id;
						if (ressourceMonthPlan.ids.indexOf(newId) === -1) {
							ressourceMonthPlan.ids.push(newId);
						}

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});
				} else {
					observer.next({});
					observer.complete();

				}

			} else if (ressourceMonthPlan.ids.length === 1) {
				if (ressourceMonthPlan.plannedSeconds === 0) {

					_self.delete(_self.tempoIoUrl + 'plans/' + ressourceMonthPlan.ids[0] + '').subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						observer.next(res);
						observer.complete();

						ressourceMonthPlan.ids = [];

					}, (error) => {
						observer.error(error);
					});

				} else {

					_self.put(_self.tempoIoUrl + 'plans/' + ressourceMonthPlan.ids[0] + '', obj).subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});

				}

			} else {

				const ar_oObservable: Observable<any>[] = [];
				for (const id of ressourceMonthPlan.ids) {
					const deleteObservable = _self.delete(_self.tempoIoUrl + 'plans/' + id + '');
					ar_oObservable.push(deleteObservable);
				}
				ar_oObservable.push(timer(100));

				const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe(() => {

					ressourceMonthPlan.ids = [];

					_self.post(_self.tempoIoUrl + 'plans', obj).subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						const newId = res?.id;
						if (ressourceMonthPlan.ids.indexOf(newId) === -1) {
							ressourceMonthPlan.ids.push(newId);
						}

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});

				});

			}

		});

		return observable;

	}

	public getWorklogsForIssueForMonth (issueId: string, monthFrom: string, monthTo: string = null): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			const startOfMonth = moment(monthFrom).startOf('month').format('YYYY-MM-DD');
			const endOfMonth = moment(monthTo ? monthTo : monthFrom).endOf('month').format('YYYY-MM-DD');

			const ar_issueId = issueId.split(';');

			let sIssueId = '';

			for (const sActIssueId of ar_issueId) {
				sIssueId += 'issue=' + sActIssueId + '&';
			}

			_self.get(_self.tempoIoUrl + 'worklogs?' + sIssueId + 'from=' +
        startOfMonth + '&to=' +
        endOfMonth + '&limit=1000' +
        '', {}).subscribe((res: any) => {

				observer.next(res);
				observer.complete();

			}, (error) => {
				observer.error(error);
			});
		});

		return observable;

	}

	public getTeams (): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			_self.getAll(_self.tempoIoUrl + 'teams', {}).subscribe((res: any) => {

				const teams: TempoTeam[] = [];
				try {
					for (const item of res.results) {
						const tempoTeam = TempoTeam.create(item);
						teams.push(tempoTeam);
					}

				} catch (error) { }

				observer.next(teams);
				observer.complete();

			}, (error) => {
				observer.error(error);
			});
		});

		return observable;

	}

	public getTeamUserIds (tempoTeam: TempoTeam): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			_self.getAll(_self.tempoIoUrl + 'teams/' + tempoTeam.id + '/members', {}).subscribe({
        next: (res: any) => {

              const accountIds: string[] = [];
              try {
                for (const item of res.results) {
                  accountIds.push(item.member.accountId);
                }

              } catch (error) { }

              tempoTeam.memberUserIds = accountIds;

              observer.next(accountIds);
              observer.complete();

            }, 
        error: (error) => {
              observer.error(error);
			}});
		});

		return observable;

	}

	public getTeamsWithUserIds (): Observable<any> {
		const _self = this;

		const observable = new Observable<any>((observer) => {

			_self.getTeams().subscribe((teams: TempoTeam[]) => {

				try {
					const ar_oObservable = [];
					for (const tempoTeam of teams) {
						const teamMembersObservable = _self.getTeamUserIds(tempoTeam);
						ar_oObservable.push(teamMembersObservable);
					}

					const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe(() => {
						observer.next(teams);
						observer.complete();
					});

				} catch (error) { }

			}, (error) => {
				observer.error(error);
			});
		});

		return observable;

	}

	private hasAuthorizationHeader (): boolean {
		return Object.keys(this.headers).indexOf('Authorization') !== -1;
	}

	private setAuthorizationHeader (): void {
		const _self = this;

		const setAuthHeader = (tempo_access_token: string) => {
			_self.headers.Authorization = 'Bearer ' + tempo_access_token;

			this.getUserScheduleForMonth('', '2020-05-10').subscribe((res) => {
				console.log('TEST: ' + JSON.stringify(res));

				this.connectedSubject.next('CONNECTED');

			}, (error) => {
				AP$.cookie.erase('tempo_access_token');
				AP$.cookie.erase('tempo_refresh_token');
			});

			/*
      _self.get(_self.tempoIoUrl + 'user-schedule?from=2020-05-01&to=2020-05-31', {}).subscribe((res) => {
        console.log(JSON.stringify(res));
      }, (error) => {
        AP$.cookie.erase('tempo_access_token');
        AP$.cookie.erase('tempo_refresh_token');
      })
      */
		};

		let hasReceivedTempoAccess = false;

		AP$.getLocation((responseLocation: any) => {
			// const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
			// const parts = parse_url.exec( responseLocation );
			// const baseUrl = parts[1] + ':' + parts[2] + parts[3];
		});

		AP$.cookie.read('tempo_refresh_token', (tempo_refresh_token: any) => {
			AP$.cookie.read('tempo_access_token', (tempo_access_token: any) => {

				// const test = document.baseURI;
				// const base  = document.getElementsByTagName('base')[0];
				// console.log(test);

				if (tempo_access_token === null || tempo_access_token === undefined) {

					_self.socketService.handleDataChange().subscribe((message: any) => {

						const dataDescription: DataDescription = JSON.parse(message.body);

						if (!hasReceivedTempoAccess && dataDescription.uuid !== _self.socketService.sessionUser.getUUID()) {

							switch (message.subject) {
								case SOCKET_SUBJECT.SEND_TEMPO_ACCESS:

									tempo_access_token = dataDescription.body.tempo_access_token;
									tempo_refresh_token = dataDescription.body.tempo_refresh_token;

									if (tempo_access_token === null || tempo_access_token === undefined) {
										_self.requestAccessTokenFromSocketIo().subscribe((res) => {
											AP$.cookie.save('tempo_access_token', res.tempo_access_token, 60);
											if (res.tempo_refresh_token === null || res.tempo_refresh_token === undefined) {
												AP$.cookie.save('tempo_refresh_token', res.tempo_refresh_token, 365);
											}
											setAuthHeader(res.tempo_access_token);
										});
										/*
                    _self.requestAccessToken().subscribe((res) => {
                      setAuthHeader(res);
                    });
                    */
									} else {
										AP$.cookie.save('tempo_access_token', tempo_access_token, 60);
										if (tempo_refresh_token === null || tempo_refresh_token === undefined) {
											AP$.cookie.save('tempo_refresh_token', tempo_refresh_token, 365);
										}

										setAuthHeader(tempo_access_token);

									}

									hasReceivedTempoAccess = true;
									break;

								case SOCKET_SUBJECT.RESPONSE_GET_SERVER_TEMPO_ACCESS_TOKEN:

									console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + JSON.stringify(dataDescription));

									tempo_access_token = dataDescription.body.access;
									tempo_refresh_token = dataDescription.body.refresh;

									console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + tempo_access_token);
									console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + tempo_refresh_token);

									if (tempo_access_token === null || tempo_access_token === undefined) {
										_self.requestAccessTokenFromSocketIo().subscribe((res) => {
											AP$.cookie.save('tempo_access_token', res.tempo_access_token, 60);
											if (res.tempo_refresh_token === null || res.tempo_refresh_token === undefined) {
												AP$.cookie.save('tempo_refresh_token', res.tempo_refresh_token, 365);
											}
											setAuthHeader(res.tempo_access_token);
										});
										/*
                    _self.requestAccessToken().subscribe((res) => {
                      setAuthHeader(res);
                    });
                    */
									} else {
										AP$.cookie.save('tempo_access_token', tempo_access_token, 60);
										if (tempo_refresh_token === null || tempo_refresh_token === undefined) {
											AP$.cookie.save('tempo_refresh_token', tempo_refresh_token, 365);
										}

										setAuthHeader(tempo_access_token);

									}

									hasReceivedTempoAccess = true;
									break;

								default:
									break;
							}
						}
					});

					let dataDescription: DataDescription = new DataDescription();
					dataDescription.type = DATA_TYPE.GET_SERVER_TEMPO_ACCESS_TOKEN;
					dataDescription.body = {};
					_self.socketService.dataChange(dataDescription);

					dataDescription = new DataDescription();
					dataDescription.type = DATA_TYPE.REQUEST_TEMPO_ACCESS;
					_self.socketService.dataChange(dataDescription);

					setTimeout(() => {
						if (!hasReceivedTempoAccess) {
							_self.requestAccessTokenFromSocketIo().subscribe((res) => {
								AP$.cookie.save('tempo_access_token', res.tempo_access_token, 60);
								if (res.tempo_refresh_token === null || res.tempo_refresh_token === undefined) {
									AP$.cookie.save('tempo_refresh_token', res.tempo_refresh_token, 365);
								}
								setAuthHeader(res.tempo_access_token);
							});
							/*
              _self.requestAccessToken().subscribe((res) => {
                setAuthHeader(res);
              });
              */
						}
					}, 2000);

				} else {
					hasReceivedTempoAccess = true;
					setAuthHeader(tempo_access_token);

					const dataDescription: DataDescription = new DataDescription();
					dataDescription.type = DATA_TYPE.SET_SERVER_TEMPO_ACCESS_TOKEN;
					dataDescription.body = {
						tempo_access_token,
						tempo_refresh_token
					};
					// _self.socketService.dataChange(dataDescription);

				}
			});
		});
	}

	private requestAccessToken (): Observable<string> {
		const _self = this;
		const observable = new Observable<string>((observer) => {

			AP$.getLocation((responseLocation: any) => {

				const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
				const parts = parse_url.exec(responseLocation);
				const baseUrl = parts[1] + ':' + parts[2] + parts[3];

				const CLINET_ID = environment.hosts['https://api.tempo.io'].CLINET_ID;
				const CLIENT_SECRET = environment.hosts['https://api.tempo.io'].CLIENT_SECRET;
				const REDIRECT_URI = baseUrl + environment.hosts['https://api.tempo.io'].REDIRECT_URI;

				if (responseLocation.indexOf('code') === -1) {

					AP$.navigator.go('site', {
						absoluteUrl: baseUrl + '/plugins/servlet/ac/io.tempo.jira/oauth-authorize/?client_id=' +
              CLINET_ID + '&redirect_uri=' +
              REDIRECT_URI + '&access_type=tenant_user'
					});

					observer.error('request tempo code');

				} else {
					const regex = /.*code=/;

					const tempo_authorization_code = responseLocation.replace(regex, '');

					// alert('tempo_authorization_code' + tempo_authorization_code);

					const params = {
						grant_type: 'authorization_code',
						client_id: CLINET_ID,
						client_secret: CLIENT_SECRET,
						redirect_uri: REDIRECT_URI,
						code: tempo_authorization_code
					};

					const payload = new HttpParams()
						.set('grant_type', 'authorization_code')
						.set('client_id', CLINET_ID)
						.set('client_secret', CLIENT_SECRET)
						.set('redirect_uri', REDIRECT_URI)
						.set('code', tempo_authorization_code);

					console.log('payload.toString(): ' + payload.toString());

					_self.post(_self.tempoIoAuthUrl + 'oauth/token/',
						payload.toString()).subscribe((res: { access_token: any, refresh_token: any }) => {

						const tempo_access_token = res.access_token;
						const tempo_refresh_token = res.refresh_token;

						AP$.cookie.save('tempo_access_token', tempo_access_token, 60);
						AP$.cookie.save('tempo_refresh_token', tempo_refresh_token, 365);
						// alert('tempo_access_token' + tempo_access_token);
						const dataDescription: DataDescription = new DataDescription();
						dataDescription.type = DATA_TYPE.SET_SERVER_TEMPO_ACCESS_TOKEN;
						dataDescription.body = {
							tempo_access_token,
							tempo_refresh_token
						};
						// _self.socketService.dataChange(dataDescription);

						_self.headers.Authorization = 'Bearer ' + tempo_access_token;

						observer.next(tempo_access_token);
						observer.complete();
					}, (error) => {
						AP$.cookie.erase('tempo_access_token');
						AP$.cookie.erase('tempo_refresh_token');
					});
				}
			});
			// getLocationFunction(AP$._hostOrigin);
		});
		return observable;
	}

	public requestAccessTokenFromSocketIo (): Observable<{
		tempo_access_token: string,
		tempo_refresh_token: string,
		localSameAsServer: boolean}> {
		const _self = this;
		const observable = new Observable<{
			tempo_access_token: string,
			tempo_refresh_token: string,
			localSameAsServer: boolean}>((observer) => {

			const subscription = _self.socketService.handleDataChange().subscribe((message: any) => {

				const dataDescription: DataDescription = JSON.parse(message.body);

				if (dataDescription.uuid !== _self.socketService.sessionUser.getUUID()) {

					switch (message.subject) {
						case SOCKET_SUBJECT.RESPONSE_GET_SERVER_TEMPO_ACCESS_TOKEN:
							console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + JSON.stringify(dataDescription));

							try {
								const str = JSON.stringify(dataDescription);

								console.log('GET_SERVER_TEMPO_ACCESS_TOKEN str: ' + str);

								const myObj = JSON.stringify(JSON.parse(str));

								console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + myObj);

							} catch (error) {
								console.log(error);
							}

							const tempo_access_token = dataDescription.body.access;
							const tempo_refresh_token = dataDescription.body.refresh;

							console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + tempo_access_token);
							console.log('GET_SERVER_TEMPO_ACCESS_TOKEN: ' + tempo_refresh_token);

							AP$.cookie.save('tempo_access_token', tempo_access_token, 60);
							if (tempo_refresh_token === null || tempo_refresh_token === undefined) {
								AP$.cookie.save('tempo_refresh_token', tempo_refresh_token, 365);
							}

							if (tempo_access_token !== null && tempo_access_token !== undefined) {
								const response = {
									tempo_access_token,
									tempo_refresh_token,
									localSameAsServer: this.headers.Authorization === 'Bearer ' + tempo_access_token
								};

								subscription.unsubscribe();

								observer.next(response);
								observer.complete();
							}

							break;

						default:
							const response = {
								tempo_access_token: undefined,
								tempo_refresh_token: undefined,
								localSameAsServer: true
							};

							subscription.unsubscribe();

							observer.next(response);
							observer.complete();

							break;
					}
				}
			});

			const dataDescription: DataDescription = new DataDescription();
			dataDescription.type = DATA_TYPE.GET_SERVER_TEMPO_ACCESS_TOKEN;
			dataDescription.body = {};
			_self.socketService.dataChange(dataDescription);

		});

		return observable;

	}

	public refreshAccessToken (): Observable<string> {

		const _self = this;
		const observable = new Observable<string>((observer) => {

			AP$.cookie.read('tempo_refresh_token', (tempo_refresh_token: any) => {

				AP$.getLocation((responseLocation: any) => {

					const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
					const parts = parse_url.exec(responseLocation);
					const baseUrl = parts[1] + ':' + parts[2] + parts[3];

					const CLINET_ID = environment.hosts['https://api.tempo.io'].CLINET_ID;
					const CLIENT_SECRET = environment.hosts['https://api.tempo.io'].CLIENT_SECRET;
					const REDIRECT_URI = baseUrl + environment.hosts['https://api.tempo.io'].REDIRECT_URI;

					const payload = new HttpParams()
						.set('grant_type', 'refresh_token')
						.set('client_id', CLINET_ID)
						.set('client_secret', CLIENT_SECRET)
						.set('redirect_uri', REDIRECT_URI)
						.set('refresh_token', tempo_refresh_token);

					console.log('payload.toString(): ' + payload.toString());

					this.post(this.tempoIoAuthUrl + 'oauth/token/',
						payload.toString()).subscribe((res: { access_token: any, refresh_token: any }) => {

						const tempo_access_token = res.access_token;
						const tempo_refresh_token = res.refresh_token;

						AP$.cookie.save('tempo_access_token', tempo_access_token, 60);
						AP$.cookie.save('tempo_refresh_token', tempo_refresh_token, 365);
						// alert('tempo_access_token' + tempo_access_token);
						const dataDescription: DataDescription = new DataDescription();
						dataDescription.type = DATA_TYPE.SET_SERVER_TEMPO_ACCESS_TOKEN;
						dataDescription.body = {
							tempo_access_token,
							tempo_refresh_token
						};
						// _self.socketService.dataChange(dataDescription);

						observer.next(tempo_access_token);
						observer.complete();
					}, (error) => {
						AP$.cookie.erase('tempo_access_token');
						AP$.cookie.erase('tempo_refresh_token');

						observer.error(error);

					});
				});

			});

		});
		return observable;

	}
}
