import { REQUIRED_SECONDS_PER_DAY } from './constants';
import { PROJECT_TYPE } from './enums';
import { Invoice } from './Invoice';
import { Project } from './Project';
import { ProjectGroup } from './ProjectGroup';
import { RequirementAnalysis } from './RequirementAnalysis';

export class ProjectRequirementAnalysisWrapper {
	project: Project | ProjectGroup;
	requirementAnalysis: RequirementAnalysis;

	get avatar (): string {
		return this.project?.avatar;
	}
	get name (): string {
		return this.project?.name;
	}
	get type (): string {
		return this.project?.type;
	}

	private _timeSpentSeconds = 0;
	get timeSpentSeconds (): number {
		if (this.project.type === PROJECT_TYPE.GROUP) {
			this._timeSpentSeconds = (this.project as ProjectGroup).timeSpentSeconds;
		} else {
			if (this._timeSpentSeconds === 0) {
				this._timeSpentSeconds = this.requirementAnalysis?.projectWorkload[this.project.id] ?? 0;
			}
		}
		return this._timeSpentSeconds;
	}

	get timeSpentWorkDays (): number {
		return this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY;
	}

	private _budgetObject: {
		IN_RANGE: number;
		IN_RANGE_BUDGET: number;
		ALL: number;
		ALL_BUDGET: number;
	};
	get budgetObject (): {
		IN_RANGE: number;
		IN_RANGE_BUDGET: number;
		ALL: number;
		ALL_BUDGET: number;
	} {
		if (this._budgetObject === undefined) {
			this._budgetObject = this.project.getFinancedWorkloadInRange(
				this.requirementAnalysis.startDate, this.requirementAnalysis.endDate);
		}
		return this._budgetObject;
	}

	get budget (): number {
		return Math.max(this.budgetObject?.ALL_BUDGET ?? 0, this.invoiceObject?.ALL ?? 0);
	}
	get budgetInRange (): number {
		return Math.max(this.budgetObject?.IN_RANGE_BUDGET ?? 0, this.invoiceObject?.IN_RANGE_WITH_PLANNED ?? 0);
	}

	get budgetInRangePerWorkDay (): number {
		return this.budgetInRange / this.timeSpentWorkDays;
	}

	get invoiceInRangePerWorkDay (): number {
		return this.invoiceNettoInRange / this.timeSpentWorkDays;
	}

	private _invoiceObject: {
		IN_AND_BEFORE_RANGE: number;
		IN_RANGE: number;
		AFTER_RANGE: number;
		ALL: number;
		IN_AND_BEFORE_RANGE_WITH_PLANNED: number;
		IN_RANGE_WITH_PLANNED: number;
		AFTER_RANGE_WITH_PLANNED: number;
		ALL_WITH_PLANNED: number;
	};
	get invoiceObject (): {
		IN_AND_BEFORE_RANGE: number;
		IN_RANGE: number;
		AFTER_RANGE: number;
		ALL: number;
		IN_AND_BEFORE_RANGE_WITH_PLANNED: number;
		IN_RANGE_WITH_PLANNED: number;
		AFTER_RANGE_WITH_PLANNED: number;
		ALL_WITH_PLANNED: number;
	} {
		if (this._invoiceObject === undefined) {
			console.log('IN getInvoicsObject');
			try {

				const ar_oInvoices = [ ...this.project.get_invoices('ALL', false), ...this.project.get_invoices('ALL', true)];

				if (ar_oInvoices && ar_oInvoices.length > 0) {

					let outputString = '';
					for (const invoice of ar_oInvoices) {
						outputString += invoice.netto + ', ';
					}
					console.log(this.project.name + ': ' + outputString);

					const startDate = this.requirementAnalysis.startDate;
					const endDate = this.requirementAnalysis.endDate;

					let IN_AND_BEFORE_RANGE = 0;
					let IN_RANGE = 0;
					let AFTER_RANGE = 0;
					let ALL = 0;

					let IN_AND_BEFORE_RANGE_WITH_PLANNED = 0;
					let IN_RANGE_WITH_PLANNED = 0;
					let AFTER_RANGE_WITH_PLANNED = 0;
					let ALL_WITH_PLANNED = 0;

					for (const invoice of ar_oInvoices) {
						if (!invoice.virtual) { // invoice.paid || invoice.notPaidYet || invoice.planed) {
							const netto = (!invoice.planed) ? invoice.netto : 0;
							const nettoWithPlanned = invoice.netto;
							ALL += netto;
							ALL_WITH_PLANNED += nettoWithPlanned;
							if (invoice.invoiceDate < startDate) {
								IN_AND_BEFORE_RANGE += netto;
								IN_AND_BEFORE_RANGE_WITH_PLANNED += nettoWithPlanned;
							}
							if (invoice.invoiceDate >= startDate && invoice.invoiceDate <= endDate) {
								IN_RANGE += netto;
								IN_RANGE_WITH_PLANNED += nettoWithPlanned;
							}
							/*
							if (invoice.invoiceDate <= startDate) {
								IN_AND_BEFORE_RANGE += netto;
								IN_AND_BEFORE_RANGE_WITH_PLANNED += nettoWithPlanned;
							}
							*/
							if (invoice.invoiceDate > endDate) {
								AFTER_RANGE += netto;
								AFTER_RANGE_WITH_PLANNED += nettoWithPlanned;
							}
						}
					}

					this._invoiceObject = {
						IN_AND_BEFORE_RANGE,
						IN_RANGE,
						AFTER_RANGE,
						ALL,
						IN_AND_BEFORE_RANGE_WITH_PLANNED,
						IN_RANGE_WITH_PLANNED,
						AFTER_RANGE_WITH_PLANNED,
						ALL_WITH_PLANNED
					};

				}

			} catch (error) {}
		}
		return this._invoiceObject;
	}

	get invoiceNettoInRange (): number {
		return this.invoiceObject?.IN_RANGE ?? 0;
	}

	get invoiceNettoInRangeWithPlanned (): number {
		return this.invoiceObject?.AFTER_RANGE_WITH_PLANNED ?? 0;
	}

	get workDayQuota (): number {
		return this.timeSpentWorkDays / this.requirementAnalysis.timeSpentWorkDays;
	}

	get fullTimeEquivalent (): number {
		return this.timeSpentWorkDays / this.requirementAnalysis.maxWorkingDaysInRange;
	}

	get budgetInRangePerFullTimeEquivalent (): number {
		return this.budgetInRange / this.fullTimeEquivalent;
	}

	get invoiceNettoInRangePerFullTimeEquivalent (): number {
		return this.invoiceNettoInRange / this.fullTimeEquivalent;
	}

	get hasTooLitleFullTimeEquivalent (): boolean {
		return this.invoiceNettoInRangePerFullTimeEquivalent > this.invoiceNettoInRange ||
            this.budgetInRangePerFullTimeEquivalent > this.budgetInRange;
	}

	get projectWrappers (): ProjectRequirementAnalysisWrapper[] {
		const projectWrappers = (this.project as ProjectGroup)?.projectWrappers ?? [];
		return projectWrappers;
	}

	private _accountIds: string[];
	get accountIds (): string[] {
		if (this._accountIds === undefined || this._accountIds.length === 0) {
			if (this.project.type === PROJECT_TYPE.GROUP) {
				this._accountIds = (this.project as ProjectGroup).accountIds;
			} else {
				this._accountIds = this.requirementAnalysis.projectAccountId[this.project.id];
			}

		}
		return this._accountIds;
	}

	get personCount (): number {
		return this.accountIds?.length ?? 0;
	}

	getWorkloadForAccountId (accountId: string): number {
		let actWorkload = 0;
		for (const projectWrapper of this.projectWrappers) {
			actWorkload += projectWrapper.getWorkloadForAccountId(accountId);
		}
		actWorkload += (this.requirementAnalysis.projectAccountIdWorkload[this.project.id] ?? {})[accountId] ?? 0;
		return actWorkload;
	}

	refreshData () {
		this._timeSpentSeconds = 0;
		this._budgetObject = undefined;
		this._invoiceObject = undefined;
	}

	constructor (obj: { projectOrProjectGroup: Project | ProjectGroup, requirementAnalysis: RequirementAnalysis }) {
		this.project = obj.projectOrProjectGroup;
		this.requirementAnalysis = obj.requirementAnalysis;
	}
}
