import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subscription, throwError, timer } from 'rxjs';
import { InitializedRessourceCube, RESSOURCE_CUBE_ACTION } from '../actions/ressource-cube.actions';
import { CLASS_TYPE } from '../model/enums';
import { Project } from '../model/Project';
import { RessourceCube, RessourceMonthData, RessourceMonthPlan } from '../model/RessourceCube';
import { VirtualGroup } from '../model/VirtualGroup';
import { AppState } from '../reducers';
import * as moment from 'moment';

export const RESSOURCE_TEMPO_IO_URL = new InjectionToken<string>('RessourceTempoIoUrl');

@Injectable({
	providedIn: 'root'
})
export class RessourceCubeIoService {

	private headers = {
		referrer: '',
		Authorization: 'undefined'
	};

	private reduxSubscription: Subscription = new Subscription();

	constructor (
		private store: Store<AppState>,
		private http: HttpClient,

		@Inject(RESSOURCE_TEMPO_IO_URL) public ressourceTempoIoUrl: string,

	) {
		this.reduxSubscription.add(this.store.select((state: AppState) => state.ressourceCube).subscribe((state) => {
			switch (state.action) {
				case RESSOURCE_CUBE_ACTION.RELOAD_DATA:

					this.getDataFromRessourceUrl(state.startDate, state.endDate).subscribe(({ userSchedules, ressourceCube }) => {

						this.store.dispatch(InitializedRessourceCube({ userSchedules, ressourceCube }));
					});

					break;

				default:
					break;
			}

		}));
	}

	private setContentTypeHeader (actionUrl: string): void {
		this.headers['Content-Type'] = 'application/json';
	}
	private hasAuthorizationHeader (): boolean {
		return Object.keys(this.headers).indexOf('Authorization') !== -1;
	}

	public get<T> (actionUrl: string, params?): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (!this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		if (actionUrl.indexOf('/?offset') !== -1) {
			return throwError('No concrete function called with offset');
		}

		return this.http.get<T>(actionUrl, { headers: this.headers, params });
	}

	public post<T> (actionUrl: string, itemObject: any = {}, params?: any): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (!this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		if (this.headers.Authorization === undefined) {
			delete this.headers.Authorization;
		}

		return this.http.post<T>(actionUrl, itemObject, { headers: this.headers, params });
	}

	public put<T> (actionUrl: string, itemObject: any = {}, params?: any): Observable<T> {
		this.setContentTypeHeader(actionUrl);

		if (!this.hasAuthorizationHeader()) {
			return throwError('No Authorization set');
		}

		return this.http.put<T>(actionUrl, itemObject, { headers: this.headers, params });
	}

	public delete<T> (actionUrl: string): Observable<T> {
		this.setContentTypeHeader(actionUrl);
		return this.http.delete<T>(actionUrl, { headers: this.headers });
	}

	getDataFromRessourceUrl (startDate: string, endDate: string): Observable<{ userSchedules: any, ressourceCube: RessourceCube }> {
		console.log('RESSOURCE TABLE: GET RESSOURCE CUBE');

		const observable = new Observable<{ userSchedules: any, ressourceCube: RessourceCube }>((observer) => {
			const observableRessourceCube = this.get<any>(
				this.ressourceTempoIoUrl + 'ressource-cube', {
					from: startDate,
					to: endDate
				}
			);

			observableRessourceCube.subscribe((result) => {
				console.log('RESSOURCE CUBE!');
				console.log('RESSOURCE CUBE: ' + result);

				const userSchedules = result.userSchedules;
				const res = result.ressourceCube;

				const ressourceCube = new RessourceCube();

				for (const accountId of Object.keys(res._users)) {
					const user = res._users[accountId];

					for (const projectId of Object.keys(user._projects)) {
						const project = user._projects[projectId];

						for (const month of Object.keys(project._months)) {
							const ressourceMonthData = new RessourceMonthData();
							ressourceMonthData.timeSpentSeconds = project._months[month].timeSpentSeconds;
							ressourceCube.setData(accountId, projectId, month, ressourceMonthData);

						}
						for (const month of Object.keys(project._monthsPlan)) {
							const ressourceMonthPlan = new RessourceMonthPlan();
							ressourceMonthPlan.ids = project._monthsPlan[month].ids;
							ressourceMonthPlan.plannedSeconds = project._monthsPlan[month].plannedSeconds;
							ressourceCube.setPlan(accountId, projectId, month, ressourceMonthPlan);

						}

					}

				}

				observer.next({ userSchedules, ressourceCube });
				observer.complete();

			}, (err) => {
				console.log('RESSOURCE CUBE ERROR: ' + err);

				observer.error(err);
				observer.complete();
			});

		});

		return observable;

	}

	public savePlan (ressourceCube: RessourceCube, accountId: string,
		projectOrVirtualGroup: Project | VirtualGroup, monthDescription: any): Observable<any> {
		const _self = this;
		const projectOrVirtualGroupId = projectOrVirtualGroup.id;
		const projectId = (projectOrVirtualGroup.type === CLASS_TYPE.VIRTUAL_GROUP) ?
			projectOrVirtualGroup.parentProject.id : projectOrVirtualGroup.id;

		const ressourceMonthPlan: RessourceMonthPlan = ressourceCube.getPlan(accountId, projectOrVirtualGroupId, monthDescription.month);

		const observable = new Observable<any>((observer) => {

			const startOfPlan = moment(monthDescription.date).startOf('month').format('YYYY-MM-DD');
			const endOfPlan = moment(monthDescription.date).startOf('month').format('YYYY-MM-DD');

			let section = '';
			if (projectOrVirtualGroup.type === CLASS_TYPE.VIRTUAL_GROUP) {
				section = ' [SECTION: ' + projectOrVirtualGroup.name + '][SECTION-ID:' + projectOrVirtualGroupId + ']';
			}

			const obj = {
				startDate: startOfPlan,
				endDate: endOfPlan,
				description: 'P_COCKPIT' + section,
				plannedPerDaySeconds: ressourceMonthPlan.plannedSeconds,
				includeNonWorkingDays: false,
				rule: 'NEVER',
				recurrenceEndDate: endOfPlan,
				accountId,
				projectKey: projectId
			};

			if (ressourceMonthPlan.ids.length === 0) {
				if (ressourceMonthPlan.plannedSeconds !== 0) {
					_self.post(_self.ressourceTempoIoUrl + 'plans', obj).subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						const newId = res?.id;
						if (ressourceMonthPlan.ids.indexOf(newId) === -1) {
							ressourceMonthPlan.ids.push(newId);
						}

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});
				} else {
					observer.next({});
					observer.complete();

				}

			} else if (ressourceMonthPlan.ids.length === 1) {
				if (ressourceMonthPlan.plannedSeconds === 0) {

					_self.delete(_self.ressourceTempoIoUrl + 'plans/' + ressourceMonthPlan.ids[0] + '').subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						observer.next(res);
						observer.complete();

						ressourceMonthPlan.ids = [];

					}, (error) => {
						observer.error(error);
					});

				} else {

					_self.put(_self.ressourceTempoIoUrl + 'plans/' + ressourceMonthPlan.ids[0] + '', obj).subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});

				}

			} else {

				const ar_oObservable: Observable<any>[] = [];
				for (const id of ressourceMonthPlan.ids) {
					const deleteObservable = _self.delete(_self.ressourceTempoIoUrl + 'plans/' + id + '');
					ar_oObservable.push(deleteObservable);
				}
				ar_oObservable.push(timer(100));

				const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe(() => {

					ressourceMonthPlan.ids = [];

					_self.post(_self.ressourceTempoIoUrl + 'plans', obj).subscribe((res: any) => {
						console.debug(JSON.stringify(res));

						const newId = res?.id;
						if (ressourceMonthPlan.ids.indexOf(newId) === -1) {
							ressourceMonthPlan.ids.push(newId);
						}

						observer.next(res);
						observer.complete();

					}, (error) => {
						observer.error(error);
					});

				});

			}

		});

		return observable;

	}

}
