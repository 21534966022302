import { ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromRessourcesTable from './ressources-table.reducer';
import * as fromJiraApi from './jira-api.reducer';
import * as fromProjectService from './project-service.reducer';
import * as fromReportGeneratorService from './report-generator-service.reducer';
import * as fromCapabilityService from './capability-service.reducer';
import * as fromRequirementAnalysis from './requirement-analysis.reducer';
import * as fromRessourcesCube from './ressource-cube.reducer';

/**
 * Selectors JiraApi
 */

/**
 * App State/Reducers
 */
export interface AppState {
	[fromRessourcesTable.ressourcesTableFeatureKey]: fromRessourcesTable.State;
	[fromJiraApi.jiraApiFeatureKey]: fromJiraApi.State;
	[fromProjectService.projectServiceFeatureKey]: fromProjectService.State;
	[fromReportGeneratorService.reportGeneratorServiceFeatureKey]: fromReportGeneratorService.State;
	[fromCapabilityService.capabilityServiceFeatureKey]: fromCapabilityService.State;
	[fromRequirementAnalysis.requirementAnalysisFeatureKey]: fromRequirementAnalysis.State;
	[fromRessourcesCube.ressourceCubeFeatureKey]: fromRessourcesCube.State;
}

export const reducers: ActionReducerMap<AppState> = {
	[fromRessourcesTable.ressourcesTableFeatureKey]: fromRessourcesTable.reducer,
	[fromJiraApi.jiraApiFeatureKey]: fromJiraApi.reducer,
	[fromProjectService.projectServiceFeatureKey]: fromProjectService.reducer,
	[fromReportGeneratorService.reportGeneratorServiceFeatureKey]: fromReportGeneratorService.reducer,
	[fromCapabilityService.capabilityServiceFeatureKey]: fromCapabilityService.reducer,
	[fromRequirementAnalysis.requirementAnalysisFeatureKey]: fromRequirementAnalysis.reducer,
	[fromRessourcesCube.ressourceCubeFeatureKey]: fromRessourcesCube.reducer
};

/**
 * MetaReducers
 */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];
