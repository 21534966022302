import { Pipe, PipeTransform } from '@angular/core';
import { TempoTeam } from '../model/TempoTeam';

@Pipe({
	name: 'allUsersInTeamShow'
})
export class AllUsersInTeamShowPipe implements PipeTransform {

	transform (tempoTeam: TempoTeam, checkValue: string, userShowMap: any): boolean {
		let countAll = 0;
		let countShow = 0;
		for (const user of tempoTeam.memberUsers) {
			countAll += 1;
			countShow += userShowMap[user.accountId] === true ? 1 : 0;
		}
		const result = (countShow === 0) ? 'unchecked' : (countAll > countShow) ? 'mixed' : 'checked';
		return result === checkValue;
	}

}
