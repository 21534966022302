import { REQUIRED_SECONDS_PER_DAY } from './constants';
import { Invoice } from './Invoice';
import { Project } from './Project';
import { ProjectGroup } from './ProjectGroup';
import { ProjectRequirementAnalysisWrapper } from './ProjectRequirementAnalysisWrapper';

export class RequirementAnalysis {

	name = 'standard';
	get suffix (): string {
		return (this.name && this.name !== 'standard') ? '-' + this.name : '';
	}
	projectWorkload = {};
	projectAccountIdWorkload = {};

	projectAccountId = {};

	invoices: Invoice[];

	startDate: Date;
	endDate: Date;

	maxWorkingDaysInRange: number;

	private _projectWorkDays: any;
	get projectWorkDays (): any {
		if (this._projectWorkDays === undefined) {
			const projectWorkDays = {};
			for (const projectId of Object.keys(this.projectWorkload)) {
				projectWorkDays[projectId] = (this.projectWorkload[projectId] ?? 0) / REQUIRED_SECONDS_PER_DAY;
			}
			this._projectWorkDays = projectWorkDays;
		}
		return this._projectWorkDays;
	}

	projectGroupByProjectId = {};

	projectGroups: ProjectGroup[] = [];

	private _allProjectGroups: ProjectGroup[];
	get allProjectGroups (): ProjectGroup[] {
		if (this._allProjectGroups === undefined) {
			let allProjectGroups: ProjectGroup[] = [];
			for (const projectGroup of this.projectGroups) {
				allProjectGroups.push(projectGroup);
				allProjectGroups = allProjectGroups.concat(projectGroup.projectGroups);
			}
			this._allProjectGroups = allProjectGroups;
		}
		return this._allProjectGroups;
	}

	get timeSpentSeconds (): number {
		let actTimeSpentSeconds = 0;
		for (const projectGroups of this.projectGroups) {
			actTimeSpentSeconds += projectGroups.timeSpentSeconds ?? 0;
		}
		return actTimeSpentSeconds;
	}

	get timeSpentWorkDays (): number {
		return this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY;
	}

	get budget (): number {
		let actBudget = 0;
		for (const projectGroups of this.projectGroups) {
			actBudget += projectGroups.budget ?? 0;
		}
		return actBudget;
	}
	get budgetInRange (): number {
		let actBudgetInRange = 0;
		for (const projectGroups of this.projectGroups) {
			actBudgetInRange += projectGroups.budgetInRange ?? 0;
		}
		return actBudgetInRange;
	}

	get budgetInRangePerWorkDay (): number {
		return this.budgetInRange / this.timeSpentWorkDays;
	}

	get invoiceNettoInRange (): number {
		let actInvoiceNettoInRange = 0;
		for (const projectGroups of this.projectGroups) {
			actInvoiceNettoInRange += projectGroups.invoiceNettoInRange ?? 0;
		}
		return actInvoiceNettoInRange;
	}

	get invoiceInRangePerWorkDay (): number {
		return this.invoiceNettoInRange / this.timeSpentWorkDays;
	}

	get workDayQuota (): number {
		return this.timeSpentWorkDays / this.timeSpentWorkDays;
	}

	get fullTimeEquivalent (): number {
		return this.timeSpentWorkDays / this.maxWorkingDaysInRange;
	}

	get budgetInRangePerFullTimeEquivalent (): number {
		return this.budgetInRange / this.fullTimeEquivalent;
	}

	get invoiceNettoInRangePerFullTimeEquivalent (): number {
		return this.invoiceNettoInRange / this.fullTimeEquivalent;
	}

	get hasTooLitleFullTimeEquivalent (): boolean {
		return this.invoiceNettoInRangePerFullTimeEquivalent > this.invoiceNettoInRange ||
            this.budgetInRangePerFullTimeEquivalent > this.budgetInRange;
	}

	private _accountIds: string[];
	get accountIds (): string[] {
		if (this._accountIds === undefined || this._accountIds.length === 0) {
			let accountIds = [];
			for (const projectGroup of this.projectGroups) {
				accountIds = [...new Set([...accountIds , ...projectGroup.accountIds])];
			}

			this._accountIds = accountIds;
		}
		return this._accountIds;
	}

	get personCount (): number {
		return this.accountIds?.length ?? 0;
	}

	getProjectGroupByProjectId (projectId: string) {
		return this.projectGroupByProjectId[projectId];
	}

	putProjectToGroup (project: Project | ProjectGroup, projectGroupId?: string): ProjectGroup {
		const newProjectGroup = this.allProjectGroups.find((item) => item.id === projectGroupId);
		let projectGroup = newProjectGroup ?? this.projectGroupByProjectId[project.id];
		if (projectGroup === undefined) {

			projectGroup = this.allProjectGroups.find((item) => item.name === 'nicht zugeordnet');
			if (projectGroup === undefined) {
				projectGroup = new ProjectGroup();
				projectGroup.requirementAnalysis = this;
				projectGroup.name = 'nicht zugeordnet';

				this.projectGroups.push(projectGroup);
				this._allProjectGroups = undefined;
			}
		} else {
			const oldProjectGroup = this.projectGroupByProjectId[project.id];
			if (oldProjectGroup && projectGroup !== oldProjectGroup) {
				const index = oldProjectGroup?.projects?.findIndex((item) => item.id === project.id);
				if (index !== -1) {
					oldProjectGroup.projects.splice(index, 1);
					oldProjectGroup.refreshData();
				}
			}
		}
		this.projectGroupByProjectId[project.id] = projectGroup;

		const index = projectGroup.projects.findIndex((item) => item.id === project.id);
		const indexIdAndName = projectGroup.projects.findIndex((item) => item.id === project.id && item.name === project.name);
		if (index === -1) {
			projectGroup.projects.push(project);
			projectGroup.refreshData();
		} else {
			if (index !== indexIdAndName) {
				projectGroup.projects.splice(index, 1);
				projectGroup.projects.push(project);
				projectGroup.refreshData();
			}
		}

		return projectGroup;
	}

	reassignProjects(projectsById: {}): void {
		for (const projectGroup of this.projectGroups) {
			projectGroup.reassignProjects(projectsById);
		}
	}

	getWorkloadForProjectAndAccountId (projectOrProjectGroup: ProjectRequirementAnalysisWrapper | ProjectGroup, accountId: string): number {
		return projectOrProjectGroup.getWorkloadForAccountId(accountId);
	}

	addProjectGroup (name: string) {
		let projectGroup = this.projectGroups.find((item) => item.name === name);
		if (projectGroup === undefined) {
			projectGroup = new ProjectGroup();
			projectGroup.requirementAnalysis = this;
			projectGroup.name = name;

			this.projectGroups.push(projectGroup);
		}
	}

	deleteProjectGroup (projectGroup: ProjectGroup) {
		const indexIdAndName = this.projectGroups.findIndex((item) => item.id === projectGroup.id && item.name === projectGroup.name);
		if (indexIdAndName !== -1) {

			const newProjectGroup = this.projectGroups.find((item) => item.name === 'nicht zugeordnet');
			for (const project of this.projectGroups[indexIdAndName].projects) {
				this.putProjectToGroup(project, newProjectGroup.id);
			}

			this.projectGroups.splice(indexIdAndName, 1);
			projectGroup.refreshData();
		}

	}

	resetData () {
		this.projectWorkload = {};
		this.projectAccountIdWorkload = {};
		this.projectAccountId = {};
		// this.invoices = undefined;
		// this.startDate = undefined;
		// this.endDate = undefined;
		this.maxWorkingDaysInRange = undefined;
		this._projectWorkDays = undefined;
		// this.projectGroupByProjectId = {};
		// this.projectGroups = [];

		this.refreshData();
	}

	refreshData () {
		for (const projectGroup of this.projectGroups) {
			projectGroup.refreshData();
		}
		this._allProjectGroups = undefined;
	}

	toJSON () {
		const jsonObj: any = {};

		jsonObj.name = this.name;
		jsonObj.projectGroups = this.projectGroups;

		return jsonObj;

	}

}
