import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { REPORT_GENERATOR_ACTION } from 'src/app/actions/report-generator-service.actions';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CLASS_TYPE, COLUMN_TYPE, PROJECT_TYPE } from 'src/app/model/enums';
import { JiraIssue } from 'src/app/model/JiraIssue';
import { JiraProject } from 'src/app/model/JiraProject';
import { AppState } from 'src/app/reducers';
import { IssueOrProject, ReportGeneratorService } from '../../services/report-generator.service';

@Component({
	selector: 'app-report-generator-part',
	templateUrl: './report-generator-part.component.html',
	styleUrls: ['./report-generator-part.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGeneratorPartComponent implements OnInit, OnDestroy {

	private _issueOrProjects: IssueOrProject[] = [];
	@Input() public get issueOrProjects(): IssueOrProject[] {
		return this._issueOrProjects;
	}
	public set issueOrProjects(value: IssueOrProject[]) {
		if (this._issueOrProjects !== value) {
			this._issueOrProjects = value;
			this.handleRefreshed();	
		}
	}

	public treeIssueOrProjects: Array<JiraIssue | JiraProject>[] = [];

	public allWorklogs: any[] = [];

	public myControl = new FormControl();
	public resultData: { type: CLASS_TYPE, id: string, name: string }[] = [];
	public filteredOptions: Observable<any>;

	private reduxSubscription: Subscription = new Subscription();

	public isInitalizing = true;
	
	@Input() public expanded = false;
	@Input() public title = '';
	@Input() public description = '';

	private _startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
	@Input()
	public get startDate() {
		return this._startDate;
	}
	public set startDate(value) {
		if (this._startDate !== value) {
			this._startDate = value;
			this.handleRefreshed();
		}
	}
	private _endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 3, 1, 0, 0, 0, 0).getTime() - 1000);
	@Input()
	public get endDate() {
		return this._endDate;
	}
	public set endDate(value) {
		if (this._endDate !== value) {
			this._endDate = value;
			this.handleRefreshed();
		}
	}

	@Output() refreshed = new EventEmitter<IssueOrProject[]>();

	range = new UntypedFormGroup({
		start: new UntypedFormControl(this.startDate),
		end: new UntypedFormControl(this.endDate)
	});

	COLUMN_TYPE = COLUMN_TYPE;
	PROJECT_TYPE = PROJECT_TYPE;
	Object = Object;
	Math = Math;
	JSON = JSON;

	constructor(
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private _jiraconnector: JiraconnectorService,
		private _reportGeneratorService: ReportGeneratorService
	) {
		this.filteredOptions = this.myControl.valueChanges
			.pipe(
				startWith(''),
				debounceTime(400),
				distinctUntilChanged(),
				switchMap(val => {
					return this.filter(val || '')
				})
			);

		this.reduxSubscription.add(this.store.select((state: AppState) => state.reportGeneratorService).subscribe((state) => {
			switch (state.action) {
				case REPORT_GENERATOR_ACTION.REPORT_GENERATOR_WORKLOGS_REFRESHED:
					this.handleRefreshed(this.issueOrProjects);

					break;

				default:
					break;
			}

		}));
	
	}

	filter(keyOrName: string): Observable<any[]> {
		return this.getData(keyOrName)
			.pipe(
				map(response => response)
			)
	}

	ngOnDestroy(): void {
		this.reduxSubscription.unsubscribe();
	}

	ngOnInit(): void {

		//this.getAllWorklogs().subscribe((res) => {
		//	this.allWorklogs = res;

			/* 						
									this.getIssueWithSubIssuesWorklogs('TEAM1KLANG-12').subscribe((jiraIssues) => {
										console.log('REPORT GENERATOR: GET TEAM1KLANG-12 WORKLOADS');
										console.dir(jiraIssues);
			
										let baseSplitUpTimeSeconds = 0;
			
										for (const jiraIssue of jiraIssues) {
											baseSplitUpTimeSeconds += jiraIssue.allTimeSpentSeconds;
										}
			
										
											// Relevante Boards in 1KLANG:
											// 	naNah (Schlüssel: “NANAH”
											// 	1KLANG Team (Schlüssel: “TEAM1KLANG”)
											// 	Steigt-UM (Schlüssel: “STUM”)
											// 	DVBHTD (Schlüssel: “DHTD”
											// 		Projekt/Epic “HTD-API2.0”
											// 		Projekt/Epic “Deutschlandticket DVB”
											// 		Projekt/Epic “Deutschlandticket VVV”
											// 		Projekt/Epic “Mehraufwand durch HanseCom”
											// 	DVBVVOVVV-WebApp (Schlüssel: “DVBVVOVVV”)
											// 		Projekt/Epic “HTD-API2.0”)
											// 		Projekt/Epic “Mehraufwand durch HanseCom”
											// 		Projekt/Epic “Wartung”
											// 		Projekt/Epic “Deutschlandticket DVB/VVO”
											// 	VRB Web/App (Schlüssel: “VRB”)
											// 		Projekt/Epic “Wartung”
											// 		Projekt/Epic “CR, Weiterbeauftragung”
											// 		Projekt/Epic “Projektinfrastruktur”
											// 		Projekt/Epic “Karte”
										
			
										const projectIds = ['NANAH', 'TEAM1KLANG', 'STUM', 'VRB', 'DHTD', 'DVBVVOVVV'];
			
										this.getProjectsWithSubIssuesWorklogs(projectIds).subscribe((jiraProjects) => {
											console.log('REPORT GENERATOR: GET PROJECTS [' + projectIds.join(', ') + '] WORKLOADS');
											console.dir(jiraProjects);
			
											let sumAllTimeSpentSeconds = 0;
			
											for (const jiraProject of jiraProjects) {
												sumAllTimeSpentSeconds += jiraProject.allTimeSpentSeconds;
											}
			
											for (const jiraProject of jiraProjects) {
												console.log(jiraProject.id + ' -> ' + jiraProject.name);
												console.dir(jiraProject.getPercentage(sumAllTimeSpentSeconds, baseSplitUpTimeSeconds));
											}
			
										});
			
										const epicIds = ['DHTD-47', 'DHTD-161', 'DHTD-260', 'DHTD-64'];
			
										this.getEpicsWithSubIssuesWorklogs(epicIds).subscribe((jiraIssues) => {
											console.log('REPORT GENERATOR: GET EPICS [' + epicIds.join(', ') + '] WORKLOADS');
											console.dir(jiraIssues);
										});
			
										
										// for (const epicId of epicIds) {
										// 	this.getEpicWithSubIssuesWorklogs(epicId).subscribe((jiraIssues) => {
										// 		console.log('REPORT GENERATOR: GET ' + epicId + ' WORKLOADS');
										// 		console.dir(jiraIssues);
										// 	});
												
										// }
										
			
									}); 
			*/

		// });

		this._ChangeDetectorRef.detectChanges();


	}

	getData(nameOrKey: string) {
		/*
				return this._jiraconnector.searchProjects('&query=' + nameOrKey).pipe(map(res => {
						const resultData: { type: CLASS_TYPE, id: string, name: string }[] = [];
						for (const item of res.values) {
							const projectObj = { type: CLASS_TYPE.JIRA_PROJECT, id: item.key, name: item.name };
							resultData.push(projectObj);
						}
						this.resultData = resultData;
						return resultData;
					}));
		*/
		if (nameOrKey.indexOf('-') !== -1) {
			return this._jiraconnector.getSearchAll(`issue = ${nameOrKey} OR (parent = ${nameOrKey} OR key in linkedIssues("${nameOrKey}"))`).pipe(
				map(res => res?.issues?.map((item: any) => ({ type: CLASS_TYPE.JIRA_ISSUE, id: item.key, name: ((item.fields?.parent) ? 'Sub: ' : '') + item.fields?.summary })))
			);
		}

		return this._jiraconnector.searchProjects('&query=' + nameOrKey).pipe(
			map(res => res?.values?.map((item: any) => ({ type: CLASS_TYPE.JIRA_PROJECT, id: item.key, name: item.name })))
		);

	}

	handleRefreshed(issueOrProjects: IssueOrProject[] = this.issueOrProjects): void {
		console.log('refreshed');
		console.dir(issueOrProjects);

		if (issueOrProjects && issueOrProjects.length > 0) {
			this._reportGeneratorService.getIssueOrProjectsWorklogs(issueOrProjects).subscribe(res => {
				console.log('getIssueOrProjectsWorklogs -> result');
				console.dir(res);
	
				this._issueOrProjects = issueOrProjects;
				this.treeIssueOrProjects = res;

				// this.refreshed.emit(this.issueOrProjects);

				this._ChangeDetectorRef.detectChanges();
	
			});	
		} else {

			this._issueOrProjects = issueOrProjects ?? [];
			this.treeIssueOrProjects = [];

			this._ChangeDetectorRef.detectChanges();
		}

	}

}
