import { Environment } from './environment.base';

export const environment: Environment = {
	appVersion: require('../../package.json').version,
	production: true,
	hosts: {
		'https://projektionisten.atlassian.net': {
			product: 'jira',
			username: 'basicAuth-username',
			password: 'basicAuth-password'
		},
		/* "https://api.tempo.io": {
        "product": "tempo",
        "CLINET_ID": "BfiISvGS9wh1JqNgUoD0h94bIV1NcNgQJhUjMIGJ",
        "CLIENT_SECRET": "U4aWlnvHfWWlfawwBP1Nfdysy3iYEF6CqfQzjEoaa5HXhaDgB9ScnZE9YzVE99HIHWpM6YPHEtwBGHbDeqhXYAdkHQzXWdnMzwT0Gr9fMh6Kvkvd0t94y7pb5sLKCivo",
        "REDIRECT_URI": "/plugins/servlet/ac/p-ressources-test-addon/prod-p-ressources-jira/tempo_oauth_success"
    },*/
		/* 'https://api.tempo.io': {
      product: 'tempo',
      CLINET_ID: 'ZHuvPwLJb1TbedxEp4DOv6XTW6iMuEZYpCKPGI5s',
      CLIENT_SECRET: 'mCbt425Zs2q6a3pp3yrvr128PGBOl7DnfPbyMlsZKzeB3tbbd6Lf6py31OHiG6h6ii6eTxCd5dVwrZFw7r0wlLBqOysAH6Q5UqAvTfbMT6zyeZmaQL2dehnlE29VROf6',
      REDIRECT_URI: '/plugins/servlet/ac/ml-p-ressources-test-addon/prod-ml-p-ressources-jira/tempo_oauth_success'
    },*/
		'https://api.tempo.io': {
			product: 'tempo',
			CLINET_ID: 'HE3IET5hJzjPyqKjoNYbYCmF1KhSycUyMm9g1Xit',
			CLIENT_SECRET: 'MNKE5j8CtNlgFQiylDfhTvXPy5BDhLPy8O5QnPOyRsakYsjDPX1br5izGvtzfI6jc8SwGNs9yMiM1Lb3qSi38jrGRRFAogS5ieVCdiLerQfu0Vtw0iPpx7COzM0jKcK1',
			REDIRECT_URI: '/plugins/servlet/ac/dev-pre-prod-p-ressources-dev-addon/dev-pre-prod-p-ressources-jira/tempo_oauth_success'
		}
		/* 'https://api.tempo.io': {
      product: 'tempo',
      CLINET_ID: 'pxfmFKCfvuHliW4lm4JJmoKNbgmT8tV4D1vF7OWC',
      CLIENT_SECRET: 'hycZvdulH4Ds9eTYsgTmZrhUorrYwQBsOSVPYYmli4Qn2y4bTlV6QogyohtBo8Yo1pFHFZxIJYjrELlbw7SapfDUQcFt5S57xo3TMLyH7OJfNGNFaPhbdNMRHUf6sNfQ',
      REDIRECT_URI: '/plugins/servlet/ac/prod-p-ressources-test-addon/prod-p-ressources-jira/tempo_oauth_success'
    }*/
	},
	selectedVirtualGroup: null
};
