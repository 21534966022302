import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isVirtualGroup'
})
export class IsVirtualGroupPipe implements PipeTransform {

	transform (column: string, ...args: unknown[]): boolean {
		try {
			return column.indexOf('___') !== -1;
		} catch (error) {
			return false;
		}
	}

}
