import { formatNumber } from '@angular/common';
import { UNIT } from './enums';
import { REQUIRED_SECONDS_PER_DAY } from './constants';

export class RessourceCube {

	private _hmPlannedTimeForProjectFormated: Map<string, string> = new Map<string, string>();
	private _hmRessourceMonthPlan: Map<string, RessourceMonthPlan[]> = new Map<string, RessourceMonthPlan[]>();

	private _users: Map<string, RessourceUserSlice> = new Map<string, RessourceUserSlice>();

	private _dataLoading: any = {};
	private _dataLoaded: any = {};

	public setUsers (users: Map<string, RessourceUserSlice>): void {
		this._users = users;
	}

	public getRessourceUserSlice (accountId: string): RessourceUserSlice {
		return this._users[accountId];
	}

	public clone (): RessourceCube {
		const cloneRessourceCube = new RessourceCube();
		cloneRessourceCube.setUsers(this._users);

		return cloneRessourceCube;
	}

	public setDataLoading (accountId: string, loading: boolean = true): void {
		this._dataLoading[accountId] = loading;
		this._dataLoaded[accountId] = !loading;

		this._hmPlannedTimeForProjectFormated.clear();
		this._hmRessourceMonthPlan.clear();
	}

	public setDataLoaded (accountId: string, loaded: boolean = true): void {
		this._dataLoading[accountId] = !loaded;
		this._dataLoaded[accountId] = loaded;
	}

	public isDataLoading (accountId: string): boolean {
		return this._dataLoading[accountId] === true;
	}

	public isDataLoaded (accountId: string): boolean {
		return this._dataLoaded[accountId] === true;
	}

	public getData (accountId: string, projectId: string, month: string): RessourceMonthData {
		try {
			return this._users[accountId].getSlice(projectId).getData(month);
		} catch (error) {
			return new RessourceMonthData();
		}
	}

	public removeData (accountId: string, month: string) {
		const ressourceUserSlice = this._users[accountId];
		if (ressourceUserSlice) {
			for (const ressourceProjectSlice of ressourceUserSlice.getSlices()) {
				const ressourceMonthData = ressourceProjectSlice.getData(month);
				if (ressourceMonthData) {
					ressourceMonthData.timeSpentSeconds = 0;
				}
			}
		}
	}

	public removePlan (accountId: string, month: string) {
		const ressourceUserSlice = this._users[accountId];
		if (ressourceUserSlice) {
			for (const ressourceProjectSlice of ressourceUserSlice.getSlices()) {
				const ressourceMonthPlan = ressourceProjectSlice.getPlan(month);
				if (ressourceMonthPlan) {
					ressourceMonthPlan.plannedSeconds = 0;
				}
			}
		}
	}

	public setData (accountId: string, projectId: string, month: string, value: RessourceMonthData) {
		const ressourceUserSlice = this._users[accountId] || new RessourceUserSlice();
		ressourceUserSlice.setData(projectId, month, value);
		this._users[accountId] = ressourceUserSlice;
	}

	public getPlan (accountId: string, projectId: string, month: string): RessourceMonthPlan {
		try {
			return this._users[accountId].getSlice(projectId).getPlan(month);
		} catch (error) {
			return new RessourceMonthPlan();
		}
	}

	public setPlan (accountId: string, projectId: string, month: string, value: RessourceMonthPlan | number) {
		const ressourceUserSlice: RessourceUserSlice = this._users[accountId] || new RessourceUserSlice();
		ressourceUserSlice.setPlan(projectId, month, value);
		this._users[accountId] = ressourceUserSlice;
	}

	public getUsersWithPlannedTimeForProject (projectId: string, month: string): any {
		const plannedUsers = {};

		const key = projectId + '_' + month;
		for (const accountId of Object.keys(this._users)) {
			let ressourceMonthPlan: RessourceMonthPlan;
			try {
				ressourceMonthPlan = this._users[accountId].getSlice(projectId).getPlan(month);
			} catch (error) { }
			if (ressourceMonthPlan !== undefined && ressourceMonthPlan !== null) {
				if (ressourceMonthPlan.plannedSeconds !== undefined && ressourceMonthPlan.plannedSeconds > 0) {
					plannedUsers[accountId] = true;
				}
			}
		}

		return plannedUsers;
	}

	public getUsersWithDataForProject (projectId: string, month: string): any {
		const usersWithData = {};

		const key = projectId + '_' + month;
		for (const accountId of Object.keys(this._users)) {
			let ressourceMonthData: RessourceMonthData;
			try {
				ressourceMonthData = this._users[accountId].getSlice(projectId).getData(month);
			} catch (error) { }
			if (ressourceMonthData !== undefined && ressourceMonthData !== null) {
				if (ressourceMonthData.timeSpentSeconds !== undefined && ressourceMonthData.timeSpentSeconds > 0) {
					usersWithData[accountId] = true;
				}
			}
		}

		return usersWithData;
	}
	public getUsersForProject (projectId: string, month: string): any {
		const plannedUsers = this.getUsersWithPlannedTimeForProject(projectId, month);
		const usersWithData = this.getUsersWithDataForProject(projectId, month);

		for (const accountId of Object.keys(usersWithData)) {
			plannedUsers[accountId] = true;
		}

		return plannedUsers;
	}

	public getPlansForProject (projectId: string, month: string): RessourceMonthPlan[] {
		const key = projectId + '_' + month;
		let ar_oRessourceMonthPlan: RessourceMonthPlan[] = this._hmRessourceMonthPlan[key];

		if (ar_oRessourceMonthPlan === undefined) {
			ar_oRessourceMonthPlan = [];
			for (const accountId of Object.keys(this._users)) {
				let ressourceMonthPlan: RessourceMonthPlan;
				try {
					ressourceMonthPlan = this._users[accountId].getSlice(projectId).getPlan(month);
				} catch (error) { }
				if (ressourceMonthPlan !== undefined && ressourceMonthPlan !== null) {
					if (ressourceMonthPlan.plannedSeconds !== undefined) {
						ar_oRessourceMonthPlan.push(ressourceMonthPlan);
					}
				}
			}
			if (ar_oRessourceMonthPlan.length > 0) {
				this._hmRessourceMonthPlan[key] = ar_oRessourceMonthPlan;
			}
		}

		return ar_oRessourceMonthPlan;
	}

	public getPlannedTimeForProject (projectId: string, month: string): number {
		let plannedSeconds = 0;

		for (const ressourceMonthPlan of this.getPlansForProject(projectId, month)) {
			plannedSeconds += ressourceMonthPlan.plannedSeconds || 0;
		}

		/*
    if (projectId.indexOf('VRB___B') !== -1 && plannedSeconds > 28800) {
      let xxx = 1;
    }
    */

		return plannedSeconds;
	}

	getPlannedTimeForProjectConverted (projectId: string, month: string, format: string = UNIT.HOURS): number {
		const plannedSeconds = this.getPlannedTimeForProject(projectId, month);

		try {
			let factor = 1;
			switch (format) {
				case UNIT.HOURS:
					factor = 8;
					break;

				default:
					break;
			}

			return plannedSeconds ? plannedSeconds / REQUIRED_SECONDS_PER_DAY * factor : 0;
		} catch (error) {
			return 0;
		}
	}

	getPlannedTimeForProjectFormated (projectId: string, month: string, format: string = UNIT.HOURS, digits: number = 2): string {
		// if (true) return '10';

		const key = projectId + '_' + month + '_' + format + '_' + digits;
		let plannedTimeString = this._hmPlannedTimeForProjectFormated[key];

		if (plannedTimeString === undefined) {
			const plannedTime = this.getPlannedTimeForProjectConverted(projectId, month, format);

			if (plannedTime !== 0) {
				plannedTimeString = plannedTime ? formatNumber(plannedTime, 'de', '1.0-' + digits) : '-';

				this._hmPlannedTimeForProjectFormated[key] = plannedTimeString;
			} else {
				plannedTimeString = '-';
			}
		}

		return plannedTimeString;
	}

	public getPlansForUser (accountId: string, month: string): RessourceMonthPlan[] {
		const ar_oRessourceMonthPlan: RessourceMonthPlan[] = [];

		for (const slice of this._users[accountId].getSlices()) {
			let ressourceMonthPlan;
			try {
				ressourceMonthPlan = slice.getPlan(month);
			} catch (error) { }
			if (ressourceMonthPlan !== undefined && ressourceMonthPlan !== null) {
				ar_oRessourceMonthPlan.push(ressourceMonthPlan);
			}
		}
		return ar_oRessourceMonthPlan;
	}

	public getPlannedTimeForUser (accountId: string, month: string): number {
		let plannedSeconds = 0;

		for (const ressourceMonthPlan of this.getPlansForUser(accountId, month)) {
			plannedSeconds += ressourceMonthPlan.plannedSeconds || 0;
		}

		return plannedSeconds;
	}

	toJSON () {
		const jsonObj: any = {test: 1};

		for (const key of this._hmRessourceMonthPlan.keys()) {
			jsonObj.hmRessourceMonthPlan[key] = this._hmRessourceMonthPlan.get(key);
		}

		return jsonObj;
	}

}

export class RessourceUserSlice {
	private _projects: Map<string, RessourceProjectSlice> = new Map<string, RessourceProjectSlice>();

	public getProjects (): Map<string, RessourceProjectSlice> {
		return this._projects;
	}

	public getSlice (projectId: string): RessourceProjectSlice {
		try {
			return this._projects[projectId];
		} catch (error) {
			return new RessourceProjectSlice();
		}
	}

	public getSlices (): RessourceProjectSlice[] {
		const ar_oRessourceProjectSlice: RessourceProjectSlice[] = [];

		for (const projectId of Object.keys(this._projects)) {
			try {
				ar_oRessourceProjectSlice.push(this._projects[projectId]);
			} catch (error) { }
		}

		return ar_oRessourceProjectSlice;
	}

	public setData (projectId: string, month: string, value: RessourceMonthData) {
		const ressourceProjectSlice = this._projects[projectId] || new RessourceProjectSlice();
		ressourceProjectSlice.setData(month, value);
		this._projects[projectId] = ressourceProjectSlice;
	}

	public setPlan (projectId: string, month: string, value: RessourceMonthPlan | number) {
		const ressourceProjectSlice: RessourceProjectSlice = this._projects[projectId] || new RessourceProjectSlice();

		if (typeof value === 'number') {
			const newValue = ressourceProjectSlice.getPlan(month) || new RessourceMonthPlan();
			newValue.plannedSeconds = value;
			value = newValue;
		}

		ressourceProjectSlice.setPlan(month, value);
		this._projects[projectId] = ressourceProjectSlice;
	}

}

export class RessourceProjectSlice {
	private _months: Map<string, RessourceMonthData> = new Map<string, RessourceMonthData>();
	private _monthsPlan: Map<string, RessourceMonthPlan> = new Map<string, RessourceMonthPlan>();

	public getData (month: string): RessourceMonthData {
		return this._months[month] || new RessourceMonthData();
	}

	public setData (month: string, value: RessourceMonthData) {
		this._months[month] = value;
	}

	public getPlan (month: string): RessourceMonthPlan {
		return this._monthsPlan[month] || new RessourceMonthPlan();
	}

	public setPlan (month: string, value: RessourceMonthPlan) {
		this._monthsPlan[month] = value;
	}

}

export class RessourceMonthData {
	timeSpentSeconds: number;

	get timeSpendPT (): string {
		try {
			return this.timeSpentSeconds ? formatNumber(this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY, 'de', '1.0-2') : '-';
		} catch (error) {
			return '-';
		}
	}

	getTimeSpendFormated (format: string = UNIT.HOURS, digits: number = 2): string {
		try {
			let factor = 1;
			switch (format) {
				case UNIT.HOURS:
					factor = 8;
					break;

				case UNIT.DAYS:
					factor = 1;
					break;

				default:
					break;
			}

			return this.timeSpentSeconds ? formatNumber(this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits) : '-';
		} catch (error) {
			return '-';
		}
	}

}

export class RessourceMonthPlan {
	plannedSeconds: number;
	ids: number[] = [];

	get plannedPT (): string {
		try {
			return this.plannedSeconds ? formatNumber(this.plannedSeconds / REQUIRED_SECONDS_PER_DAY, 'de', '1.0-2') : '-';
		} catch (error) {
			return '-';
		}
	}

	getPlannedConverted (format: string = UNIT.HOURS): number {
		try {
			let factor = 1;
			switch (format) {
				case UNIT.HOURS:
					factor = 8;
					break;

				case UNIT.DAYS:
					factor = 1;
					break;

				default:
					break;
			}

			return this.plannedSeconds ? this.plannedSeconds / REQUIRED_SECONDS_PER_DAY * factor : 0;
		} catch (error) {
			return 0;
		}
	}

	getPlannedFormated (format: string = UNIT.HOURS, digits: number = 2): string {
		try {
			let factor = 1;
			switch (format) {
				case UNIT.HOURS:
					factor = 8;
					break;

				case UNIT.DAYS:
					factor = 1;
					break;

				default:
					break;
			}

			return this.plannedSeconds ? formatNumber(this.plannedSeconds / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits) : '-';
		} catch (error) {
			return '-';
		}
	}

}
