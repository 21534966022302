import { SortByPipe } from '../pipes/sort-by.pipe';
import { REQUIRED_SECONDS_PER_DAY } from './constants';
import { PROJECT_TYPE, WORKLOAD_UNIT } from './enums';
import { Invoice } from './Invoice';
import { Project } from './Project';
import { ProjectRequirementAnalysisWrapper } from './ProjectRequirementAnalysisWrapper';
import { RequirementAnalysis } from './RequirementAnalysis';

export class ProjectGroup {
	requirementAnalysis: RequirementAnalysis;

	public type: PROJECT_TYPE = PROJECT_TYPE.GROUP;

	name: string;
	projects: any[] = [];
	projectGroups: ProjectGroup[] = [];
	parentProjectGroup: ProjectGroup;

	get pathName (): string {
		return (this.parentProjectGroup) ? this.parentProjectGroup.name + ' > ' + this.name : this.name;
	}

	get avatar (): string {
		let avatar: string;
		for (const project of new SortByPipe().transform(this.projects, 'desc', 'timeSpentWorkDays')) {
			if (project.avatar) {
				avatar = project.avatar;
				break;
			}
		}
		return avatar;
	}

	private _id: string;
	get id (): string {
		if (this._id === undefined) {
			this._id = this.pathName?.toLocaleUpperCase();
		}
		return this._id ?? '';
	}

	private _timeSpentSeconds: number;
	get timeSpentSeconds (): number {
		if (this._timeSpentSeconds === undefined || this._timeSpentSeconds === 0) {
			let actTimeSpentSeconds = 0;
			for (const projectGroup of this.projectGroups) {
				actTimeSpentSeconds += projectGroup.timeSpentSeconds ?? 0;
			}
			for (const project of this.projects) {
				actTimeSpentSeconds += this.requirementAnalysis.projectWorkload[project.id] ?? 0;
			}
			this._timeSpentSeconds = actTimeSpentSeconds;
		}
		return this._timeSpentSeconds;
	}

	get timeSpentWorkDays (): number {
		return this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY;
	}

	private _budget: number;
	get budget (): number {
		if (this._budget === undefined || this._budget === 0) {
			let actBudget = 0;
			for (const projectWrapper of this.projectWrappers) {
				actBudget += projectWrapper.budget ?? 0;
			}
			this._budget = actBudget;
		}
		return this._budget;
	}
	private _budgetInRange: number;
	get budgetInRange (): number {
		if (this._budgetInRange === undefined || this._budgetInRange === 0) {
			let actBudgetInRange = 0;
			for (const projectWrapper of this.projectWrappers) {
				actBudgetInRange += projectWrapper.budgetInRange ?? 0;
			}
			this._budgetInRange = actBudgetInRange;
		}
		return this._budgetInRange;
	}

	get budgetInRangePerWorkDay (): number {
		return this.budgetInRange / this.timeSpentWorkDays;
	}

	get invoiceInRangePerWorkDay (): number {
		return this.invoiceNettoInRange / this.timeSpentWorkDays;
	}

	private _invoiceNettoInRange: number;
	get invoiceNettoInRange (): number {
		if (this._invoiceNettoInRange === undefined || this._invoiceNettoInRange === 0) {
			let actNettoInRange = 0;
			for (const projectWrapper of this.projectWrappers) {
				actNettoInRange += projectWrapper.invoiceNettoInRange ?? 0;
			}
			this._invoiceNettoInRange = actNettoInRange;
		}
		return this._invoiceNettoInRange;
	}

	get workDayQuota (): number {
		return this.timeSpentWorkDays / this.requirementAnalysis.timeSpentWorkDays;
	}

	get fullTimeEquivalent (): number {
		return this.timeSpentWorkDays / this.requirementAnalysis.maxWorkingDaysInRange;
	}

	get budgetInRangePerFullTimeEquivalent (): number {
		return this.budgetInRange / this.fullTimeEquivalent;
	}

	get invoiceNettoInRangePerFullTimeEquivalent (): number {
		return this.invoiceNettoInRange / this.fullTimeEquivalent;
	}

	get hasTooLitleFullTimeEquivalent (): boolean {
		return this.invoiceNettoInRangePerFullTimeEquivalent > this.invoiceNettoInRange ||
            this.budgetInRangePerFullTimeEquivalent > this.budgetInRange;
	}

	private _projectIds: string[];
	public get projectIds (): string[] {
		const projectIds = [];
		for (const project of this.projects) {
			projectIds.push(project.id);
		}
		return projectIds;
	}
	public set projectIds (value: string[]) {
		this._projectIds = value;
	}

	private _accountIds: string[];
	get accountIds (): string[] {

		if (this._accountIds === undefined || this._accountIds.length === 0) {
			let accountIds = [];
			for (const projectWrapper of this.projectWrappers) {
				accountIds = [...new Set([...accountIds , ...projectWrapper.accountIds ?? []])];
			}

			this._accountIds = accountIds;
		}

		return this._accountIds;
	}

	get personCount (): number {
		return this.accountIds?.length ?? 0;
	}

	private _projectWrappers: ProjectRequirementAnalysisWrapper[];
	get projectWrappers (): ProjectRequirementAnalysisWrapper[] {
		if (this._projectWrappers === undefined || this._projectWrappers.length === 0) {

			const projectWrappers: ProjectRequirementAnalysisWrapper[] = [];
			for (const projectOrProjectGroup of this.projectGroups) {
				projectWrappers.push(new ProjectRequirementAnalysisWrapper(
					{projectOrProjectGroup, requirementAnalysis: this.requirementAnalysis}));
			}
			for (const projectOrProjectGroup of this.projects) {
				projectWrappers.push(new ProjectRequirementAnalysisWrapper(
					{projectOrProjectGroup, requirementAnalysis: this.requirementAnalysis}));
			}

			this._projectWrappers = projectWrappers;
		}
		return this._projectWrappers;
	}
	set projectWrappers (value: ProjectRequirementAnalysisWrapper[]) {
		this._projectWrappers = value;
	}

	reassignProjects(projectsById: {}): void {
		for (let index = 0; index < this.projects.length; index++) {
			const project = this.projects[index];
			if (project.id === project.name) {
				const actProject = projectsById[project.id];
				if (actProject) {
					this.projects[index] = actProject;
				}
			}
		}
		for (const projectGroup of this.projectGroups) {
			projectGroup.reassignProjects(projectsById);
		}
	}

	get_invoices (key: string, otherOperatingIncome: boolean) {
		let ar_invoices: Invoice[] = [];
		for (const project of this.projects) {
			ar_invoices = ar_invoices.concat( ...project.get_invoices(key, otherOperatingIncome) );
		}
		return ar_invoices;
	}

	getWorkloadForAccountId (accountId: string): number {
		let actWorkload = 0;
		for (const projectWrapper of this.projectWrappers) {
			actWorkload += projectWrapper.getWorkloadForAccountId(accountId);
		}
		for (const project of this.projects) {
			actWorkload += (this.requirementAnalysis.projectAccountIdWorkload[project.id] ?? {})[accountId] ?? 0;
		}
		return actWorkload;
	}

	getFinancedWorkloadInRange (startDate: Date, endDate: Date, workload_unit: WORKLOAD_UNIT = WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR): {
		IN_RANGE: number,
		IN_RANGE_BUDGET: number,
		ALL: number,
		ALL_BUDGET: number
	} {
		const sums: any = {
			IN_RANGE: 0,
			IN_RANGE_BUDGET: 0,
			ALL: 0,
			ALL_BUDGET: 0
		};
		for (const item of this.projects) {
			const range = item.getFinancedWorkloadInRange(startDate, endDate, workload_unit);
			sums.IN_RANGE = sums.IN_RANGE + range.IN_RANGE;
			sums.IN_RANGE_BUDGET = sums.IN_RANGE_BUDGET + range.IN_RANGE_BUDGET;
			sums.ALL = sums.ALL + range.ALL;
			sums.ALL_BUDGET = sums.ALL_BUDGET + range.ALL_BUDGET;
		}
		return {
			IN_RANGE: sums.IN_RANGE,
			IN_RANGE_BUDGET: sums.IN_RANGE_BUDGET,
			ALL: sums.ALL,
			ALL_BUDGET: sums.ALL_BUDGET
		};
	}

	addProjectGroup (name: string) {
		let projectGroup = this.projectGroups.find((item) => item.name === name);
		if (projectGroup === undefined) {
			projectGroup = new ProjectGroup();
			projectGroup.parentProjectGroup = this;
			projectGroup.requirementAnalysis = this.requirementAnalysis;
			projectGroup.name = name;

			this.projectGroups.push(projectGroup);
		}
	}

	deleteProjectGroup (projectGroup: ProjectGroup) {
		const indexIdAndName = this.projectGroups.findIndex((item) => item.id === projectGroup.id && item.name === projectGroup.name);
		if (indexIdAndName !== -1) {

			const newProjectGroup = this.projectGroups.find((item) => item.name === 'nicht zugeordnet');
			for (const project of this.projectGroups[indexIdAndName].projects) {
				this.requirementAnalysis.putProjectToGroup(project, newProjectGroup.id);
			}

			this.projectGroups.splice(indexIdAndName, 1);
			projectGroup.refreshData();
		}

	}

	refreshData () {
		this._invoiceNettoInRange = undefined;
		this._timeSpentSeconds = undefined;
		this._budgetInRange = undefined;
		this._budget = undefined;

		this.projectWrappers = undefined;
	}

	toJSON () {
		const jsonObj: any = {};

		jsonObj.name = this.name;
		jsonObj.projectIds = this.projectIds;

		jsonObj.projectGroups = this.projectGroups;

		return jsonObj;

	}
}
