import { createAction, props } from '@ngrx/store';

export enum RESSOURCE_TABLE_ACTION {
	NOTHING = 'NOTHING',
	USER_LOADING = 'USER_LOADING',
	INITIALIZING = 'INITIALIZING',
	INITIALIZED = 'INITIALIZED',
	UPDATE_PROJECT_CELL = 'UPDATE_PROJECT_CELL',
	UPDATE_PROJECT_OVERVIEW_CELL = 'UPDATE_PROJECT_OVERVIEW_CELL',
	REFRESH_COLUMNS = 'REFRESH_COLUMNS',
	TOGGLE_REPORTMODE = 'TOGGLE_REPORTMODE',
}

export const UserLoadingRessourceTables = createAction(
	'[UserLoadingRessourceTables] Action RessourceTables'
);

export const InitializingRessourceTables = createAction(
	'[InitializingRessourceTables] Action RessourceTables'
);

export const InitializedRessourceTables = createAction(
	'[InitializedRessourceTables] Action RessourceTables'
);

export const RefreshColumnsRessourceTables = createAction(
	'[RefreshColumnsRessourceTables] Action RessourceTables'
);

export const UpdateProjectCellRessourceTables = createAction(
	'[UpdateProjectCellRessourceTables] Action RessourceTables',
	props<{ projectId: string, accountId: string }>(),
);

export const UpdateProjectOverviewCellRessourceTables = createAction(
	'[UpdateProjectOverviewCellRessourceTables] Action RessourceTables',
	props<{ projectId: string }>(),
);

export const ToggleReportModeRessourceTables = createAction(
	'[ToggleReportModeRessourceTables] Action RessourceTables',
);
