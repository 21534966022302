import { formatCurrency, formatDate } from '@angular/common';
import { Invoice } from './Invoice';
import { WORKLOAD_UNIT } from './enums';

export class SubBudget {
	name: string;
	private _startDate: Date;
	private _endDate: Date;
	private _pt: number;

	get startDate (): Date {
		return this._startDate;
	}
	set startDate (date: Date) {
		// if (typeof date === 'string') {
		this._startDate = new Date(date);
		// }
		// this._startDate = date;
		this.stored_workload = {};
	}
	get endDate (): Date {
		return this._endDate;
	}
	set endDate (date: Date) {
		this._endDate = new Date(date);
		this.stored_workload = {};
	}

	get sum_budget_netto (): number {
		const sum_netto = this.sum_invoices_netto;
		return this.budget > sum_netto ? this.budget : sum_netto;
	}
	get sum_invoices_netto (): number {
		return this.calc_invoice_value('sum_netto');
	}

	get pt (): number {
		return this._pt;
	}
	set pt (value: number) {
		this._pt = value;
		this.stored_workload = {};
	}

	budget: number;
	vat = '0.19';

	private _prognose: string | boolean;

	get prognose (): string | boolean {
		switch (this._prognose) {
			case true:
				return '0.0';
				break;

			case null:
			case undefined:
				return '1.0';
				break;

			case false:
				return '0.0';
				break;

			default:
				return this._prognose as string;
				break;
		}
	}
	set prognose (value: string | boolean) {
		switch (value) {
			case true:
				this._prognose = '0.0';
				break;

			case null:
			case undefined:
				this._prognose = '1.0';
				break;

			case false:
				this._prognose = '1.0';
				break;

			default:
				this._prognose = value as string;
				break;
		}
	}

	get prognoseNumber (): number {
		return parseFloat(this.prognose as string);
	}

	get prognoseString (): string {
		switch (this.prognoseNumber) {
			case 0:
				return 'Potentiell (0-24%)';
				break;

			case 0.25:
				return 'Angebahnt (25-49%)';
				break;

			case 0.5:
				return 'Abgestimmt (50-74%)';
				break;

			case 0.75:
				return 'Mündlich bestätigt (75-99%)';
				break;

			default:
				return '';
				break;
		}
	}

	clearingAddDays = 30;

	otherOperatingIncome = false;

	private stored_values: any = {};
	private stored_invoice_values: any = {};
	private stored_invoice_liquidity: any = {};
	private stored_workload: any = {};

	private _invoices: Invoice[] = [];

	get invoices (): Invoice[] {
		this._invoices = this._invoices.sort((a, b) => {
			if (a.invoiceDate < b.invoiceDate) {
				return -1;
			}
			if (a.invoiceDate > b.invoiceDate) {
				return 1;
			}
			return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
		});
		return this._invoices;
	}

	set invoices (invoices: Invoice[]) {
		const ar_oInvoice = [];
		for (const item of invoices) {
			const oInvoice = new Invoice();
			Object.assign(oInvoice, item);
			ar_oInvoice.push(oInvoice);
		}

		this._invoices = ar_oInvoice;
	}

	get invoicesWithSubBudgetRest (): Invoice[] {

		let ar_oInvoice = [...this._invoices];

		let rest = this.budget;
		this._invoices.forEach((element) => {
			const sign = element.incoming ? -1 : 1;
			rest = rest - sign * element.netto;
		});

		if (rest > 0) {
			const oInvoice = new Invoice();
			oInvoice.name = 'Rest: ' + this.name;
			oInvoice.vat = this.vat;
			oInvoice.netto = rest;
			oInvoice.invoiceDate = this.endDate;
			oInvoice.clearingDate = new Date(this.endDate.getTime() + this.clearingAddDays * 24 * 60 * 60 * 1000);
			oInvoice.virtual = true;

			ar_oInvoice.push(oInvoice);
		}

		ar_oInvoice = ar_oInvoice.sort((a, b) => {
			if (a.invoiceDate < b.invoiceDate) {
				return -1;
			}
			if (a.invoiceDate > b.invoiceDate) {
				return 1;
			}
			return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
		});
		return ar_oInvoice;
	}

	getCustomerNumber () {
		let customerNumber = null;
		this.invoices.forEach((element) => {
			if (customerNumber === null) {
				customerNumber = element.customerNumber;
			}
		});
		return customerNumber;
	}

	toJSON () {
		const jsonObj: any = {};

		jsonObj.vat = this.vat;
		jsonObj.name = this.name;
		jsonObj.startDate = this.startDate;
		jsonObj.endDate = this.endDate;
		jsonObj.budget = this.budget;
		jsonObj.pt = this.pt;
		jsonObj.prognose = this.prognose;
		jsonObj.clearingAddDays = this.clearingAddDays;
		jsonObj.otherOperatingIncome = this.otherOperatingIncome;
		jsonObj.invoices = this.invoices;

		return jsonObj;
	}

	get vatNumber (): number {
		return parseFloat(this.vat);
	}

	get ptBudgetString (): string {
		let ptBudget;
		try {
			ptBudget = this.budget / this.pt;
		} catch (error) {
			// do nothing
		}
		return ptBudget ? formatCurrency(ptBudget, 'de', '€/PT') : '---';
	}

	get budgetString (): string {
		return this.budget ? formatCurrency(this.budget, 'de', '€') : '---';
	}

	get sum_budget_nettoString (): string {
		const sum_budget_netto = this.sum_budget_netto;
		return sum_budget_netto ? formatCurrency(sum_budget_netto, 'de', '€') : '---';
	}

	get sum_invoices_nettoString (): string {
		const sum_invoices_netto = this.sum_invoices_netto;
		return sum_invoices_netto ? formatCurrency(sum_invoices_netto, 'de', '€') : '---';
	}

	get startDateString (): string {
		return this.startDate ? formatDate(this.startDate, 'dd.MM.yyyy', 'de') : '---';
	}

	get endDateString (): string {
		return this.endDate ? formatDate(this.endDate, 'dd.MM.yyyy', 'de') : '---';
	}

	get nameString (): string {
		return this.name + ' | ' + this.startDateString + ' - ' + this.endDateString + ' | Zahlungsziel: +' + this.clearingAddDays +
    ' Tage | ' + this.budgetString + ' (' + this.sum_invoices_nettoString + ') | ' + this.pt + ' PT | ' + this.ptBudgetString;
	}

	getValue (key: string): number {
		return this.stored_values[key] || this.calc_value(key);
	}

	getLiquidity (key: string, incoming: boolean = false): number {
		const stored_invoice_liquidity = this.stored_invoice_liquidity[key];
		if (stored_invoice_liquidity != null && stored_invoice_liquidity !== 0 && incoming === false) {
			return this.calc_invoice_liquidity(key, incoming);
			//      return stored_invoice_liquidity;
		} else {
			return this.calc_invoice_liquidity(key, incoming);
		}
	}

	getWorkload (key: string, workload_unit: WORKLOAD_UNIT = WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR): number {
		const stored_workload = this.stored_workload[key];
		if (stored_workload !== undefined && stored_workload !== null && stored_workload !== 0) {
			return stored_workload / workload_unit;
		} else {
			return this.calc_workload(key) / workload_unit;
		}
	}

	calc_value (key: string): number {
		this.stored_values = {};
		this.stored_invoice_values = {};

		// console.debug('calc_value: ' + key);
		const sum_netto = this.calc_invoice_value('sum_netto');
		const subBudgetBudget = this.budget > sum_netto ? this.budget : sum_netto;
		let remaining_budget = this.budget - sum_netto;
		if (remaining_budget < 0) {
			remaining_budget = 0;
		}
		// console.debug('subBudgetBudget: ' + subBudgetBudget);
		// console.debug('remaining_budget: ' + remaining_budget);
		let internal_startDate: Date = this.stored_invoice_values.last_date;
		if (internal_startDate == null || this.startDate > internal_startDate) {
			internal_startDate = this.startDate;
		}

		const monthCount = this.monthDiff(internal_startDate, this.endDate) + 1;
		const startMonth = this.startDate.getMonth() + 1;
		const partBudget = (monthCount === 0) ? remaining_budget : remaining_budget / monthCount;
		// console.debug('partBudget: ' + partBudget + ', monthCount: ' + monthCount);
		const startMonthDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1, 0, 0, 0, 0);
		const endMonthDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1, 0, 0, 0, 0);
		let addedBudget = 0;
		let oActDate = startMonthDate;
		let qartalString = this.getQuartalString(oActDate);
		let monthString = oActDate.getFullYear() + '_' + (oActDate.getMonth() + 1);
		let yearString = oActDate.getFullYear() + '_year';

		for (oActDate = startMonthDate; oActDate <= endMonthDate; oActDate = new Date(oActDate.setMonth(oActDate.getMonth() + 1))) {

			qartalString = this.getQuartalString(oActDate);
			monthString = oActDate.getFullYear() + '_' + (oActDate.getMonth() + 1);
			yearString = oActDate.getFullYear() + '_year';

			let addPartBudget = (sum_netto > 0) ? this.calc_invoice_value(monthString) : 0; // partBudget;
			if (addPartBudget == null) {
				addPartBudget = 0;
			}
			addedBudget += addPartBudget;

			this.stored_values[yearString] = ((this.stored_values[yearString]) ? this.stored_values[yearString] : 0) + addPartBudget;
			this.stored_values[monthString] = ((this.stored_values[monthString]) ? this.stored_values[monthString] : 0) + addPartBudget;
			this.stored_values[qartalString] = ((this.stored_values[qartalString]) ? this.stored_values[qartalString] : 0) + addPartBudget;

			// console.debug('yearString: ' + yearString + ' = ' + this.stored_values[yearString] + ' :: ' + addPartBudget);
			// console.debug('qartalString: ' + qartalString + ' = ' + this.stored_values[qartalString] + ' :: ' + addPartBudget);
			// console.debug('monthString: ' + monthString + ' = ' + this.stored_values[monthString] + ' :: ' + addPartBudget);
		}
		if (addedBudget < subBudgetBudget) {
			this.stored_values[yearString] = ((this.stored_values[yearString]) ?
				this.stored_values[yearString] : 0) + (subBudgetBudget - addedBudget);
			this.stored_values[monthString] = ((this.stored_values[monthString]) ?
				this.stored_values[monthString] : 0) + (subBudgetBudget - addedBudget);
			this.stored_values[qartalString] = ((this.stored_values[qartalString]) ?
				this.stored_values[qartalString] : 0) + (subBudgetBudget - addedBudget);
		}

		// this.stored_values.sum_budget_netto = sum_netto;

		return this.stored_values[key];
	}

	get_invoice_value (key: string) {
		const stored_invoice_values_key = this.stored_invoice_values[key];
		if (stored_invoice_values_key != null && stored_invoice_values_key !== 0) {
			return stored_invoice_values_key;
		} else {
			return this.calc_invoice_value(key);
		}
	}
	get_invoice_liquidity (key: string) {
		const stored_invoice_liquidity_key = this.stored_invoice_liquidity[key];
		if (stored_invoice_liquidity_key != null && stored_invoice_liquidity_key !== 0) {
			return stored_invoice_liquidity_key;
		} else {
			return this.calc_invoice_liquidity(key);
		}
	}
	calc_invoice_value (key: string) {
		this.stored_invoice_values = {};
		let sum_netto = 0;
		let sum_brutto = 0;
		for (const oInvoice of this.invoices) {
			if (oInvoice.invoiceDate === undefined || oInvoice.invoiceDate === null) {
				continue;
			}
			const qartalString = this.getQuartalString(oInvoice.invoiceDate);
			const monthString = oInvoice.invoiceDate.getFullYear() + '_' + (oInvoice.invoiceDate.getMonth() + 1);
			const yearString = oInvoice.invoiceDate.getFullYear() + '_year';

			const sign = oInvoice.incoming ? -1 : 1;

			sum_netto += sign * oInvoice.netto;
			sum_brutto += sign * oInvoice.netto * (1.0 + parseFloat(oInvoice.vat));

			this.stored_invoice_values[yearString] = ((this.stored_invoice_values[yearString]) ?
				this.stored_invoice_values[yearString] : 0) + sign * oInvoice.netto;
			this.stored_invoice_values[monthString] = ((this.stored_invoice_values[monthString]) ?
				this.stored_invoice_values[monthString] : 0) + sign * oInvoice.netto;
			this.stored_invoice_values[qartalString] = ((this.stored_invoice_values[qartalString]) ?
				this.stored_invoice_values[qartalString] : 0) + sign * oInvoice.netto;

			this.stored_invoice_values.last_date = oInvoice.invoiceDate;
		}
		this.stored_invoice_values.sum_netto = sum_netto;
		this.stored_invoice_values.sum_brutto = sum_brutto;

		return this.stored_invoice_values[key];
	}

	calc_invoice_liquidity (key: string, incoming: boolean = false) {
		this.stored_invoice_liquidity = {};
		let sum_netto = 0;
		let sum_brutto = 0;
		for (const oInvoice of this.invoicesWithSubBudgetRest) {
			const clearingDate = oInvoice.clearingDateOrActDate;

			if (clearingDate == null || oInvoice.incoming !== incoming) {
				continue;
			}
			const qartalString = this.getQuartalString(clearingDate);
			const monthString = clearingDate.getFullYear() + '_' + (clearingDate.getMonth() + 1);
			const yearString = clearingDate.getFullYear() + '_year';

			const nBrutto = oInvoice.netto * (1.0 + parseFloat(oInvoice.vat));

			sum_netto += oInvoice.netto;
			sum_brutto += nBrutto;

			this.stored_invoice_liquidity[yearString] = ((this.stored_invoice_liquidity[yearString]) ?
				this.stored_invoice_liquidity[yearString] : 0) + nBrutto;
			this.stored_invoice_liquidity[monthString] = ((this.stored_invoice_liquidity[monthString]) ?
				this.stored_invoice_liquidity[monthString] : 0) + nBrutto;
			this.stored_invoice_liquidity[qartalString] = ((this.stored_invoice_liquidity[qartalString]) ?
				this.stored_invoice_liquidity[qartalString] : 0) + nBrutto;

			this.stored_invoice_liquidity.last_date = clearingDate;
		}
		this.stored_invoice_liquidity.sum_netto = sum_netto;
		this.stored_invoice_liquidity.sum_brutto = sum_brutto;

		return this.stored_invoice_liquidity[key];
	}

	calc_sum_workload () {
		let sum = 0;
		for (const key of Object.keys(this.stored_workload)) {
			sum += this.stored_workload[key];
		}
		return sum;
	}

	calc_workload (key: string) {
		this.stored_workload = {};

		const sum_workload = this.calc_sum_workload(); // Math.max(this.calc_sum_workload(), this.pt * WORKLOAD_UNIT.MINUTES_PER_WORKING_DAY);
		const subBudgetWorkload = this.pt * WORKLOAD_UNIT.MINUTES_PER_WORKING_DAY > sum_workload ?
			this.pt * WORKLOAD_UNIT.MINUTES_PER_WORKING_DAY : sum_workload;
		let remaining_workload = this.pt * WORKLOAD_UNIT.MINUTES_PER_WORKING_DAY - sum_workload;
		if (remaining_workload < 0) {
			remaining_workload = 0;
		}

		let internal_startDate: Date = this.stored_workload.last_date;
		if (internal_startDate == null || this.startDate > internal_startDate) {
			internal_startDate = this.startDate;
		}

		const monthCount = this.monthDiff(internal_startDate, this.endDate) + 1;
		const startMonth = this.startDate.getMonth() + 1;
		const partWorkload = (monthCount === 0) ? remaining_workload : remaining_workload / monthCount;
		// console.debug('partBudget: ' + partBudget + ', monthCount: ' + monthCount);
		const startMonthDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1, 0, 0, 0, 0);
		const endMonthDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1, 0, 0, 0, 0);
		let addedWorkload = 0;
		let oActDate = startMonthDate;
		let qartalString = this.getQuartalString(oActDate);
		let monthString = oActDate.getFullYear() + '_' + (oActDate.getMonth() + 1);
		let yearString = oActDate.getFullYear() + '_year';

		for (oActDate = startMonthDate; oActDate <= endMonthDate; oActDate = new Date(oActDate.setMonth(oActDate.getMonth() + 1))) {

			qartalString = this.getQuartalString(oActDate);
			monthString = oActDate.getFullYear() + '_' + (oActDate.getMonth() + 1);
			yearString = oActDate.getFullYear() + '_year';

			addedWorkload += partWorkload;

			this.stored_workload[yearString] = ((this.stored_workload[yearString]) ? this.stored_workload[yearString] : 0) + partWorkload;
			this.stored_workload[monthString] = ((this.stored_workload[monthString]) ? this.stored_workload[monthString] : 0) + partWorkload;
			this.stored_workload[qartalString] = ((this.stored_workload[qartalString]) ? this.stored_workload[qartalString] : 0) + partWorkload;

		}
		if (addedWorkload < subBudgetWorkload) {
			this.stored_workload[yearString] = ((this.stored_workload[yearString]) ?
				this.stored_workload[yearString] : 0) + (subBudgetWorkload - partWorkload);
			this.stored_workload[monthString] = ((this.stored_workload[monthString]) ?
				this.stored_workload[monthString] : 0) + (subBudgetWorkload - partWorkload);
			this.stored_workload[qartalString] = ((this.stored_workload[qartalString]) ?
				this.stored_workload[qartalString] : 0) + (subBudgetWorkload - partWorkload);
		}
		return this.stored_workload[key];
	}

	get_invoices (key: string) {
		const ar_oInvoice: Invoice[] = [];
		for (const oInvoice of this.invoicesWithSubBudgetRest) { // } this.invoices) {
			const clearingDate = oInvoice.clearingDateOrActDate;

			if (clearingDate == null) {
				continue;
			}
			const qartalString = this.getQuartalString(clearingDate);
			const monthString = clearingDate.getFullYear() + '_' + (clearingDate.getMonth() + 1);
			const yearString = clearingDate.getFullYear() + '_year';

			if (key === monthString || key === yearString || key === qartalString || key === 'ALL') {
				ar_oInvoice.push(oInvoice);
			}
		}
		return ar_oInvoice;
	}

	getQuartalString (oActDate: Date): string {
		let qartalString = oActDate.getFullYear() + '_q1';
		switch (oActDate.getMonth() + 1) {
			case 4:
			case 5:
			case 6:
				qartalString = oActDate.getFullYear() + '_q2';
				break;
			case 7:
			case 8:
			case 9:
				qartalString = oActDate.getFullYear() + '_q3';
				break;
			case 10:
			case 11:
			case 12:
				qartalString = oActDate.getFullYear() + '_q4';
				break;
		}
		return qartalString;
	}
	monthDiff (d1: Date, d2: Date) {
		let months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth() + 1;
		months += d2.getMonth() + 1;
		return months <= 0 ? 0 : months;
	}

	get yearSum (): number {
		return this.budget;
	}

	setBudget (budget: any): void {

		try {
			budget = (budget as string).replace('.', '');
			budget = (budget as string).replace(',', '.');

			Number.parseFloat(budget as string);
			// console.debug('BUDGET: ' + budget + ' --- ' + Number.parseFloat(budget as string));
			this.budget = 1 * budget as number;
		} catch (error) {
			this.budget = budget;
			// console.debug('BUDGET: ' + error + ' -> ' + typeof budget + ' -> ' + JSON.stringify(budget));
		}
	}

	isValid (): boolean {
		return !(this.name == null ||
      this.startDate == null ||
      this.endDate == null ||
      this.budget == null ||
      this.pt == null);
	}
}
