import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from 'src/app/services/project.service';
import { Invoice } from 'src/app/model/Invoice';
import { formatCurrency } from '@angular/common';
import { trackByFn } from 'src/app/model/globalFunctions';
import * as moment from 'moment';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { Subscription } from 'rxjs';
import { GetProjects, PROJECT_ACTION } from 'src/app/actions/project-service.actions';
import { filter } from 'rxjs/operators';
import { Project } from 'src/app/model/Project';

@Component({
	selector: 'app-all-invoices-list',
	templateUrl: './all-invoices-list.component.html',
	styleUrls: ['./all-invoices-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllInvoicesListComponent implements OnInit, OnDestroy {

	private reduxSubscription: Subscription = new Subscription();

	public expandedElement;

	public columnsToDisplay = [];
	public columnsToDisplayInfo = {};

	selectedInvoice: Invoice;

	netto: number;
	brutto: number;

	paidNetto: number;
	paidBrutto: number;

	trackByFn = trackByFn;

	private startDate: Date = moment(new Date()).subtract(1, 'months').startOf('month').toDate();
	private endDate: Date = moment(new Date()).endOf('month').toDate();

	range = new UntypedFormGroup({
		start: new UntypedFormControl(this.startDate),
		end: new UntypedFormControl(this.endDate)
	});

	get nettoString (): string {
		return this.netto ? formatCurrency(this.netto, 'de', '€') : '---';
	}

	get bruttoString (): string {
		return this.brutto ? formatCurrency(this.brutto, 'de', '€') : '---';
	}

	get paidNettoString (): string {
		return this.paidNetto ? formatCurrency(this.paidNetto, 'de', '€') : '---';
	}

	get paidBruttoString (): string {
		return this.paidBrutto ? formatCurrency(this.paidBrutto, 'de', '€') : '---';
	}

	private stored_ar_oInvoices: Invoice[];
	private stored_ar_oPaidInvoices: Invoice[];

	get invoices (): Invoice[] {
		if (this.stored_ar_oInvoices) {
			return this.stored_ar_oInvoices;
		} else {
			let ar_oInvoices: Invoice[] = [];

			ar_oInvoices = this.projectService.invoices.filter((invoice: Invoice) => invoice.incoming === false &&
        invoice.paid !== true &&
        invoice.planed !== true &&
        invoice.virtual !== true &&
        invoice.isValid());

			ar_oInvoices.sort((a, b) => {
				if (a.invoiceDate < b.invoiceDate) { return -1; }
				if (a.invoiceDate > b.invoiceDate) { return 1; }
				if (a.documentNumber < b.documentNumber) { return -1; }
				if (a.documentNumber > b.documentNumber) { return 1; }
				return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
			});

			this.netto = 0;
			this.brutto = 0;
			for (const invoice of ar_oInvoices) {
				this.netto += invoice.netto;
				this.brutto += invoice.netto * (1 + invoice.vatNumber);
			}

			if (ar_oInvoices.length > 0) {
				this.stored_ar_oInvoices = ar_oInvoices;
			}
		}

		return this.stored_ar_oInvoices;
	}
	set invoices (invoices: Invoice[]) {
		//
	}

	get paidInvoices (): Invoice[] {
		if (this.stored_ar_oPaidInvoices) {
			return this.stored_ar_oPaidInvoices;
		} else {
			let ar_oInvoices: Invoice[] = [];

			ar_oInvoices = this.projectService.invoices.filter((invoice: Invoice) => invoice.incoming === false &&
        invoice.paid === true &&
        invoice.planed !== true &&
        invoice.virtual !== true &&
        invoice.clearingDate >= this.startDate &&
        invoice.clearingDate <= this.endDate &&
        invoice.isValid());

			ar_oInvoices.sort((a, b) => {
				if (a.invoiceDate < b.invoiceDate) { return -1; }
				if (a.invoiceDate > b.invoiceDate) { return 1; }
				if (a.documentNumber < b.documentNumber) { return -1; }
				if (a.documentNumber > b.documentNumber) { return 1; }
				return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
			});

			this.paidNetto = 0;
			this.paidBrutto = 0;
			for (const invoice of ar_oInvoices) {
				this.paidNetto += invoice.netto;
				this.paidBrutto += invoice.netto * (1 + invoice.vatNumber);
			}

			if (ar_oInvoices.length > 0) {
				this.stored_ar_oPaidInvoices = ar_oInvoices;
			}
		}

		return this.stored_ar_oPaidInvoices;
	}
	set paidInvoices (invoices: Invoice[]) {
		//
	}

	constructor (
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
		dialog: MatDialog,

		public projectService: ProjectService
	) { }

	ngOnDestroy (): void {
		this.reduxSubscription.unsubscribe();
	}

	ngOnInit () {

		const columns = [];
		const columnsInfo = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-name', title: 'Name' };

		colName = 'documentNumber';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-text', title: 'R-Nr.' };

		colName = 'invoiceDateString';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-text', title: 'R-Datum' };

		colName = 'clearingDateString';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-text', title: 'Bez-Datum' };

		colName = 'customerNumber';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-text', title: 'K-Nr.' };

		colName = 'projectLongName';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-project', title: 'Projekt' };

		colName = 'nettoString';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-number', title: 'Netto' };

		colName = 'bruttoString';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-number', title: 'Brutto' };

		colName = 'tools';
		columns.push(colName);
		columnsInfo[colName] = { class: 'c-tools', title: '' };

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;

		this.invoices = [];

		this.reduxSubscription.add(this.store.select((state: AppState) => state.projectService).subscribe((state) => {
			switch (state.action) {
				case PROJECT_ACTION.SET_PROJECTS:
					this.invoices = [];
					this.stored_ar_oInvoices = undefined;
					this.stored_ar_oPaidInvoices = undefined;
					this._ChangeDetectorRef.detectChanges();

					break;

				default:
					break;
			}

		}));

		const storedProjectsSubscition = this.store.select((state: AppState) => state.projectService.projects)
			.pipe()
			.subscribe((storedProjects) => {
				if (storedProjects === undefined || storedProjects.length === 0) {
					this.store.dispatch(GetProjects());
				}
			});

	}

	handleRefreshSavePaidInvoice (invoice: Invoice) {
		this.stored_ar_oInvoices = undefined;
		this.stored_ar_oPaidInvoices = undefined;

		this._ChangeDetectorRef.detectChanges();
	}

	monthAdd (monthAdd: number) {
		const dateBegin = this.range.value.start;
		const dateEnd = this.range.value.end;

		const startDate = moment(dateBegin).add(monthAdd, 'M').startOf('month');
		const endDate = moment(dateEnd).add(monthAdd, 'M').endOf('month');

		this.startDate = startDate.toDate();
		this.endDate = endDate.toDate();

		this.range = new UntypedFormGroup({
			start: new UntypedFormControl(this.startDate),
			end: new UntypedFormControl(this.endDate)
		});

		this.stored_ar_oInvoices = undefined;
		this.stored_ar_oPaidInvoices = undefined;

		this.dateRangeChange(undefined);
	}

	dateRangeChange ($event) {

		if (!this.range.valid || this.range.value.start === null || this.range.value.end === null) {
			return;
		}

		this.startDate = this.range.value.start;
		this.endDate = this.range.value.end;

		this.stored_ar_oInvoices = undefined;
		this.stored_ar_oPaidInvoices = undefined;

		this._ChangeDetectorRef.detectChanges();
	}

}
