import {v4 as uuidv4} from 'uuid';
import { IssueOrProject } from '../services/report-generator.service';

import { CLASS_TYPE } from './enums';
import { JiraIssue } from './JiraIssue';
import { JiraProject } from './JiraProject';

export class JiraReportGenerator {
	type = CLASS_TYPE.JIRA_REPORT_GENERATOR;
	id: string = uuidv4();
	name: string;
	description: string;

	startDate: Date;
	endDate: Date;

	worklogsToSplit: IssueOrProject[] = [];
	worklogsToSum: IssueOrProject[] = [];
	reportsToGenerate: Array<{
			name: string,
			worklogs: IssueOrProject[],
			worklogsToDistribute: IssueOrProject[]
		}> = [];

	toJSON () {
		const jsonObj = {
			type: this.type,
			id: this.id,
			name: this.name,
			startDate: this.startDate,
			endDate: this.endDate,
			description: this.description,
			worklogsToSplit: this.worklogsToSplit,
			worklogsToSum: this.worklogsToSum,
			reportsToGenerate: this.reportsToGenerate
		};

		return jsonObj;
	}

}

