import { createAction, props } from '@ngrx/store';
import { Project } from '../model/Project';

export enum PROJECT_ACTION {
	NOTHING = 'NOTHING',
	GET_PROJECTS = 'GET_PROJECTS',
	GET_FULL_PROJECTS = 'GET_FULL_PROJECTS',
	SET_PROJECTS = 'SET_PROJECTS',
}

export const GetProjects = createAction(
	'[GetProjects] Get Projects'
);

export const GetFullProjects = createAction(
	'[GetFullProjects] Get Full Projects'
);

export const SetProjects = createAction(
	'[SetProjects] Set Projects',
	props<{ projects: Project[] }>()
);
