import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, EventEmitter, OnInit, Output, Injectable, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject, debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, startWith, Subscription, switchMap, tap, toArray } from 'rxjs';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CLASS_TYPE } from 'src/app/model/enums';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { IssueOrProject } from '../../services/report-generator.service';
import { JiraIssue } from 'src/app/model/JiraIssue';
import { JiraProject } from 'src/app/model/JiraProject';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { REPORT_GENERATOR_ACTION } from 'src/app/actions/report-generator-service.actions';

/**
 * Node for to-do item
 */
export class IssueOrProjectItemNode {
	children: IssueOrProjectItemNode[];
	item: JiraIssue | JiraProject;
}

/** Flat to-do item node with expandable and level information */
export class IssueOrProjectItemFlatNode {
	item: JiraIssue | JiraProject;
	level: number;
	expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
const TREE_DATA = {
	Groceries: {
		'Almond Meal flour': null,
		'Organic eggs': null,
		'Protein Powder': null,
		Fruits: {
			Apple: null,
			Berries: ['Blueberry', 'Raspberry'],
			Orange: null,
		},
	},
	Reminders: ['Cook dinner', 'Read the Material Design spec', 'Upgrade Application to Angular'],
};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
	dataChange = new BehaviorSubject<IssueOrProjectItemNode[]>([]);

	get data(): IssueOrProjectItemNode[] {
		return this.dataChange.value;
	}

	constructor() {
		// this.initialize();
	}

	initialize(array: Array<JiraIssue | JiraProject>[]) {
		// Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
		//     file node as children.

		const rootNode = new JiraProject();
		rootNode.id = 'ROOT_NODE';
		rootNode.hideId = true;
		rootNode.name = 'Gesamt';

		const data = {
			item: rootNode,
			children: []
		};

		console.log('initialize --> before forOf');
		for (const issueOrProject of array) {
			console.log('initialize --> in forOf');
			console.dir(issueOrProject);
			rootNode.childIssues.push(...issueOrProject);
			data.children.push(...this.buildFileTree(issueOrProject, 0));
		}

		console.log('Database initialize');
		console.dir(data);

		// Notify the change.
		this.dataChange.next([data]);
	}

	/**
	 * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
	 * The return value is the list of `TodoItemNode`.
	 */ 
	buildFileTree(array: Array<JiraIssue | JiraProject>, level: number): IssueOrProjectItemNode[] {
		return Object.keys(array).reduce<IssueOrProjectItemNode[]>((accumulator, key) => {
			const obj = array[key];
			const node = new IssueOrProjectItemNode();

			if (obj != null) {
				node.children = this.buildFileTree(obj.childIssues, level + 1);
				node.item = obj;
			}

			return accumulator.concat(node);
		}, []);
	}
/*
	buildFileTree(obj: { [key: string]: any }, level: number): IssueOrProjectItemNode[] {
		return Object.keys(obj).reduce<IssueOrProjectItemNode[]>((accumulator, key) => {
			const value = obj[key];
			const node = new IssueOrProjectItemNode();
			node.item = key;

			if (value != null) {
				if (typeof value === 'object') {
					node.children = this.buildFileTree(value, level + 1);
				} else {
					node.item = value;
				}
			}

			return accumulator.concat(node);
		}, []);
	}
*/
	/** Add an item to to-do list */
	/*
	insertItem(parent: IssueOrProjectItemNode, name: string) {
		if (parent.children) {
			parent.children.push({ item: name } as IssueOrProjectItemNode);
			this.dataChange.next(this.data);
		}
	}

	updateItem(node: IssueOrProjectItemNode, name: string) {
		node.item = name;
		this.dataChange.next(this.data);
	}
	*/
}

@Component({
	selector: 'report-generator-issue-project-tree',
	templateUrl: './report-generator-issue-project-tree.component.html',
	styleUrls: ['./report-generator-issue-project-tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ChecklistDatabase]
})

export class ReportGeneratorIssueProjectTreeComponent implements OnInit, AfterContentInit, OnDestroy {

	private _treeIssueOrProjects: Array<JiraIssue | JiraProject>[] = [];
	@Input()
	public get treeIssueOrProjects(): Array<JiraIssue | JiraProject>[] {
		return this._treeIssueOrProjects;
	}
	public set treeIssueOrProjects(value: Array<JiraIssue | JiraProject>[]) {
		this._treeIssueOrProjects = value;

		this._database.initialize(value);
		
	}

	public refreshing = false;

	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap = new Map<IssueOrProjectItemFlatNode, IssueOrProjectItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap = new Map<IssueOrProjectItemNode, IssueOrProjectItemFlatNode>();

	/** A selected parent node to be inserted */
	selectedParent: IssueOrProjectItemFlatNode | null = null;

	/** The new item's name */
	newItemName = '';

	treeControl: FlatTreeControl<IssueOrProjectItemFlatNode>;

	treeFlattener: MatTreeFlattener<IssueOrProjectItemNode, IssueOrProjectItemFlatNode>;

	dataSource: MatTreeFlatDataSource<IssueOrProjectItemNode, IssueOrProjectItemFlatNode>;

	/** The selection for checklist */
	checklistSelection = new SelectionModel<IssueOrProjectItemFlatNode>(true /* multiple */);

	private reduxSubscription: Subscription = new Subscription();

	constructor(
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private _database: ChecklistDatabase
	) {
		this.treeFlattener = new MatTreeFlattener(
			this.transformer,
			this.getLevel,
			this.isExpandable,
			this.getChildren,
		);
		this.treeControl = new FlatTreeControl<IssueOrProjectItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

		_database.dataChange.subscribe(data => {
			this.dataSource.data = data;

			setTimeout(() => {
				this._ChangeDetectorRef.detectChanges();
			}, 50);

		});
	}

	ngOnDestroy(): void {
		//
	}

	ngAfterContentInit(): void {
		//
	}

	ngOnInit(): void {
		this.reduxSubscription.add(this.store.select((state: AppState) => state.reportGeneratorService).subscribe((state) => {
			switch (state.action) {
				case REPORT_GENERATOR_ACTION.REPORT_GENERATOR_WORKLOGS_REFRESHING:
					this.refreshing = true;

					break;

				case REPORT_GENERATOR_ACTION.REPORT_GENERATOR_WORKLOGS_REFRESHED:
					this.refreshing = false;

					break;

				default:
					break;
			}

		}));

	}

	getLevel = (node: IssueOrProjectItemFlatNode) => node.level;

	isExpandable = (node: IssueOrProjectItemFlatNode) => node.expandable;

	getChildren = (node: IssueOrProjectItemNode): IssueOrProjectItemNode[] => node.children;

	hasChild = (_: number, _nodeData: IssueOrProjectItemFlatNode) => _nodeData.expandable;

	hasNoContent = (_: number, _nodeData: IssueOrProjectItemFlatNode) => _nodeData.item === undefined;

	/**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
	transformer = (node: IssueOrProjectItemNode, level: number) => {
		console.log('in --> transformer');
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode =
			existingNode && existingNode.item?.id === node.item?.id ? existingNode : new IssueOrProjectItemFlatNode();
		flatNode.item = node.item;
		flatNode.level = level;
		flatNode.expandable = !!node.children?.length;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		console.dir(node);
		console.dir(flatNode);
		console.log('out --> transformer');
		return flatNode;
	};

	/** Whether all the descendants of the node are selected. */
	descendantsAllSelected(node: IssueOrProjectItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every(child => {
				return this.checklistSelection.isSelected(child);
			});
		return descAllSelected;
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: IssueOrProjectItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some(child => this.checklistSelection.isSelected(child));
		return result && !this.descendantsAllSelected(node);
	}

	/** Toggle the to-do item selection. Select/deselect all the descendants node */
	todoItemSelectionToggle(node: IssueOrProjectItemFlatNode): void {
		this.checklistSelection.toggle(node);
		const descendants = this.treeControl.getDescendants(node);
		this.checklistSelection.isSelected(node)
			? this.checklistSelection.select(...descendants)
			: this.checklistSelection.deselect(...descendants);

		// Force update for the parent
		descendants.forEach(child => this.checklistSelection.isSelected(child));
		this.checkAllParentsSelection(node);
	}

	/** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
	todoLeafItemSelectionToggle(node: IssueOrProjectItemFlatNode): void {
		this.checklistSelection.toggle(node);
		this.checkAllParentsSelection(node);
	}

	/* Checks all the parents when a leaf node is selected/unselected */
	checkAllParentsSelection(node: IssueOrProjectItemFlatNode): void {
		let parent: IssueOrProjectItemFlatNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	/** Check root node checked state and change it accordingly */
	checkRootNodeSelection(node: IssueOrProjectItemFlatNode): void {
		const nodeSelected = this.checklistSelection.isSelected(node);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every(child => {
				return this.checklistSelection.isSelected(child);
			});
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}

	/* Get the parent node of a node */
	getParentNode(node: IssueOrProjectItemFlatNode): IssueOrProjectItemFlatNode | null {
		const currentLevel = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	/** Select the category so we can insert the new item. */
	addNewItem(node: IssueOrProjectItemFlatNode) {
		// const parentNode = this.flatNodeMap.get(node);
		// this._database.insertItem(parentNode!, '');
		// this.treeControl.expand(node);
	}

	/** Save the node to database */
	saveNode(node: IssueOrProjectItemFlatNode, itemValue: string) {
		// const nestedNode = this.flatNodeMap.get(node);
		// this._database.updateItem(nestedNode!, itemValue);
	}
}
