import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-project-workload-item-renderer',
	templateUrl: './project-workload-item-renderer.component.html',
	styleUrls: ['./project-workload-item-renderer.component.scss']
})
export class ProjectWorkloadItemRendererComponent implements OnInit {

	constructor () { }

	ngOnInit () {
	}

}
