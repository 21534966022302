// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-chart {
  min-height: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/liquidity-chart/open-liquidity-chart-dialog.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;AACD","sourcesContent":[".button-chart {\n\tmin-height: 0;\n\tpadding: 0;\n\tfont-size: 12px;\n\tline-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
