import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { debounceTime, distinctUntilChanged, forkJoin, map, Observable, of, startWith, Subscription, switchMap, tap, toArray } from 'rxjs';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CLASS_TYPE } from 'src/app/model/enums';
import { IssueOrProject } from '../../services/report-generator.service';

@Component({
	selector: 'report-generator-issue-project-autocomplete',
	templateUrl: './report-generator-issue-project-autocomplete.component.html',
	styleUrls: ['./report-generator-issue-project-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReportGeneratorIssueProjectAutoCompleteComponent implements OnInit, AfterContentInit, OnDestroy {

	public issueOrProjectInputValue = '';
	
	public issueOrProjectControl = new FormControl('');
	public issueOrProjectFormGroup = new FormGroup({
		issueOrProjectInput: this.issueOrProjectControl
	});
	public resultData: IssueOrProject[] = [];
	public filteredOptions: Observable<any>;

	public addOnBlur = true;
	public readonly separatorKeysCodes = [ENTER, COMMA] as const;
	@Input() public issueOrProjects: IssueOrProject[] = [];

	@Output() issueProject = new EventEmitter<IssueOrProject>();

	@Output() refreshed = new EventEmitter<IssueOrProject[]>();

	JSON = JSON;

	private reduxSubscription: Subscription = new Subscription();

	constructor(
		public _ChangeDetectorRef: ChangeDetectorRef,
		private _jiraconnector: JiraconnectorService,
	) {

		this.filteredOptions = this.issueOrProjectControl.valueChanges
			.pipe(
				startWith(''),
				debounceTime(400),
				distinctUntilChanged(),
				switchMap(val => {
					return this.filter(val || '')
				})
			);
	}

	filter(keyOrName: string): Observable<any[]> {
		return this.getData(keyOrName)
			.pipe(
				map(response => response)
			)
	}

	ngOnDestroy(): void {
		this.reduxSubscription.unsubscribe();
	}

	ngAfterContentInit(): void {
		//
	}

	ngOnInit(): void {
		//
	}

	getData(nameOrKey: string): Observable<IssueOrProject[]> {
		/*
				return this._jiraconnector.searchProjects('&query=' + nameOrKey).pipe(map(res => {
						const resultData: { type: CLASS_TYPE, id: string, name: string }[] = [];
						for (const item of res.values) {
							const projectObj = { type: CLASS_TYPE.JIRA_PROJECT, id: item.key, name: item.name };
							resultData.push(projectObj);
						}
						this.resultData = resultData;
						return resultData;
					}));
		*/
		if (nameOrKey.indexOf('-') !== -1) {
			return this._jiraconnector.getSearchAll(`issue = ${nameOrKey} OR (parent = ${nameOrKey} OR key in linkedIssues("${nameOrKey}"))`).pipe(
				map(res => res?.issues?.map((item: any) => ({ type: CLASS_TYPE.JIRA_ISSUE, id: item.key, name: ((item.fields?.parent) ? 'Sub: ' : '') + item.fields?.summary })))
			);
		}

		return this._jiraconnector.searchProjects('&query=' + nameOrKey).pipe(
			map(res => res?.values?.map((item: any) => ({ type: CLASS_TYPE.JIRA_PROJECT, id: item.key, name: item.name })))
		);
	}

	selectedIssueOrProject(value: any): void {
		console.dir(value);
		const valueObj = JSON.parse(value.option._element.nativeElement.dataset.object);
		console.dir(valueObj);
	}

	add(event: any): void {
		const value = (event.value || '').trim();
		const valueObj = JSON.parse(event.option._element.nativeElement.dataset.object);

		if (valueObj) {
			this.issueOrProjects.push({ type: valueObj.type, id: valueObj.id, name: valueObj.name });
		}

		console.dir(this.issueOrProjects);

		// Clear the input value
		// this.issueOrProjectForm.form.reset();
		// this.issueOrProjectControl.setValue('');

		//this.issueOrProjectFormGroup.patchValue({issueOrProjectInput: ''});

		this.issueOrProjectControl.patchValue('');

		this.issueOrProjectControl.reset('');

		this.issueOrProjectInputValue = '';
		this.issueOrProjectFormGroup.reset();
		// event.chipInput!.clear();

		this.refreshed.emit(this.issueOrProjects);

		setTimeout(() => {
			this.issueOrProjectControl.patchValue('');
		}, 100);
	}

	remove(issueOrProject: IssueOrProject): void {
		const index = this.issueOrProjects.indexOf(issueOrProject);

		if (index >= 0) {
			this.issueOrProjects.splice(index, 1);
		}
		this.refreshed.emit(this.issueOrProjects);
	}

	edit(issueOrProject: IssueOrProject, event: MatChipEditedEvent) {
		const value = event.value.trim();

		// Remove fruit if it no longer has a name
		if (!value) {
			this.remove(issueOrProject);
			return;
		}

		// Edit existing fruit
		const index = this.issueOrProjects.indexOf(issueOrProject);
		if (index >= 0) {
			this.issueOrProjects[index].name = value;
		}
		this.refreshed.emit(this.issueOrProjects);
	}
}
