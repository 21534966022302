// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidenav {
  width: 250px;
}

.toggle {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 25px 0;
}
.toggle:hover {
  cursor: pointer;
}

.nav .active-link {
  background-color: #FAFAFA;
  border-left: solid 3px #1976D2;
}
.nav .hover {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, border, background-color;
}
.nav mat-list-item,
.nav a {
  border: none;
  box-sizing: border-box;
  text-decoration: none;
}
.nav mat-list-item:hover,
.nav a:hover {
  cursor: pointer;
  background-color: #FAFAFA;
  border-left: solid 3px #1976D2;
}
.nav mat-icon {
  color: #1976D2;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sidenav/sidenav.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;AACD;;AAEA;EACC,WAAA;EACA,aAAA;EACA,6BAAA;EACA,eAAA;AACD;AACC;EACC,eAAA;AACF;;AAKC;EACC,yBAAA;EACA,8BAAA;AAFF;AAKC;EACC,0BAAA;EACA,4DAAA;EACA,wDAAA;AAHF;AAMC;;EAEC,YAAA;EACA,sBAAA;EACA,qBAAA;AAJF;AAME;;EACC,eAAA;EACA,yBAAA;EACA,8BAAA;AAHH;AAOC;EACC,cAAA;AALF","sourcesContent":[".sidenav {\n\twidth: 250px;\n}\n\n.toggle {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: space-around;\n\tpadding: 25px 0;\n\n\t&:hover {\n\t\tcursor: pointer;\n\t}\n}\n\n.nav {\n\n\t.active-link {\n\t\tbackground-color: #FAFAFA;\n\t\tborder-left: solid 3px #1976D2;\n\t}\n\n\t.hover {\n\t\ttransition-duration: 400ms;\n\t\ttransition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);\n\t\ttransition-property: transform, border, background-color;\n\t}\n\n\tmat-list-item,\n\ta {\n\t\tborder: none;\n\t\tbox-sizing: border-box;\n\t\ttext-decoration: none;\n\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t\tbackground-color: #FAFAFA;\n\t\t\tborder-left: solid 3px #1976D2;\n\t\t}\n\t}\n\n\tmat-icon {\n\t\tcolor: #1976D2;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
