import { formatCurrency } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CELL_TYPE, UNIT, VIEW_STATE } from 'src/app/model/enums';

@Component({
	selector: 'app-cell-item-renderer-euro-value',
	templateUrl: './cell-item-renderer-euro-value.component.html',
	styleUrls: ['./cell-item-renderer-euro-value.component.scss']
})
export class CellItemRendererEuroValueComponent implements OnInit {

	@ViewChild('valueInput', { static: false }) valueInputElement: ElementRef;

	@Input() public type: CELL_TYPE = CELL_TYPE.EURO_VALUE;
	@Input() public editable = false;
	@Input() public valueObject: any;
	@Input() public key: any;
	@Input() public projectId: any;
	@Input() public month: any;
	@Input() public threshold1: number;
	@Input() public threshold2: number;
	@Input() public viewUnit: UNIT = UNIT.HOURS;

	@Output() refresh = new EventEmitter<any>();

	@Input() public showSpinner = false;

	public bClickNext = false;

	public state: VIEW_STATE = VIEW_STATE.SHOW;

	VIEW_STATE = VIEW_STATE;
	CELL_TYPE = CELL_TYPE;

	private _value: number;

	constructor () { }

	ngOnInit (): void {
	}

	get value (): any {
		if (this._value === undefined) {
			let value = this.valueObject.getValue(this.key);
			if (value === undefined || value === null) {
				value = this.valueObject[this.key];
			}
			this._value = value;
		}
		return this._value;
	}

	get valueString (): string {
		try {
			if (this.type === CELL_TYPE.EURO_VALUE && typeof this.value === 'number') {

				return (this.value === undefined || this.value === null
                                         || isNaN(this.value)
                                         || (this.value <= 0.005 && this.value >= -0.005)) ? '' : formatCurrency(
						this.value,
						'de',
						'€',
						'EUR',
						'0.2',
					);
			}
			return this.value;
		} catch (error) {
			return '-';
		}
	}

	set valueString (value: string) {
		//
	}

	get isRed (): boolean {
		return false;
	}
	get isYellow (): boolean {
		return false;
	}
	get isGreen (): boolean {
		return false;
	}

	handleFocus (event) {
		try {
			event.target.focus();
			event.target.select();
		} catch (error) {
		}
	}

	handleValueClick () {
		if (this.editable) {
			this.state = VIEW_STATE.EDIT;
		}
	}

	handleBlur (value: any, bClickNext: boolean = false) {
		this.bClickNext = bClickNext;

		this.state = VIEW_STATE.SHOW;

		try {
			if (this.type === CELL_TYPE.EURO_VALUE) {
				value = (value as string).replace('.', '');
				value = (value as string).replace(',', '.');

				console.log('VALUE: ' + value);
				console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));
				this.valueObject.setValue(this.key, 1 * (value as number));
			} else if (this.type === CELL_TYPE.STRING) {
				this.valueObject[this.key] = value;
			}
		} catch (error) {
			console.log(error);
		}

		this.refresh.emit(this);

	}

	handleKeyPress (event: any): void {
		const charCode = !event.charCode ? event.which : event.charCode;
		if ([0, 8].indexOf(charCode) !== -1) {
			return;
		}
	}

	handleKeyTab (event){
		const element = event.srcElement.nextElementSibling; // get the sibling element

		if (element == null) {  // check if its null
			return;
		}
		else {
			element.focus();
		}   // focus if not null
	}

}
