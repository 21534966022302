import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { trackByFn } from 'src/app/model/globalFunctions';
import { Project } from 'src/app/model/model';
import { Timesheet } from 'src/app/model/Timesheet';
import { ProjectService } from 'src/app/services/project.service';

@Component({
	selector: 'app-timesheet-funding-project-selector',
	templateUrl: './timesheet-funding-project-selector.component.html',
	styleUrls: ['./timesheet-funding-project-selector.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetFundingProjectSelectorComponent implements OnInit {
	@Input()
	public get timesheet (): Timesheet {
		return this._timesheet;
	}
	public set timesheet (value: Timesheet) {
		this._timesheet = value;
	}

	constructor (
		private formBuilder: FormBuilder,
		public _ChangeDetectorRef: ChangeDetectorRef,

		public projectService: ProjectService,
	) {

		this.fundingFormModel = this.formBuilder.group(
			this.formBuilder.control(this.fundingProjectCtrl) //{value: this.inputValue, disabled: false})
		);

		this.filteredFundingProjects = this.fundingProjectCtrl.valueChanges.pipe(
			startWith(null),
			map((fundingProjectId: string | null) =>
				fundingProjectId ? this._filterFundingProjects(fundingProjectId) : this.projectService.fundingProjectIds.slice()));
	}

	visible = true;
	selectable = true;
	removable = true;
	inputValue = '';
	separatorKeysCodes: number[] = [ENTER, COMMA];
	fundingProjectCtrl = new FormControl();
	fundingFormModel: FormGroup;
	filteredFundingProjects: Observable<string[]>;
	fundingProjectIds: string[] = [];

	private _timesheet: Timesheet;

	@Output() refresh = new EventEmitter<any>();

	@ViewChild('fundingProjectInput', {static: false}) fundingProjectInput: ElementRef<HTMLInputElement>;
	@ViewChild('fundingProjectAuto', {static: false}) matAutocomplete: MatAutocomplete;

	trackByFn = trackByFn;

	ngOnInit (): void {

		this.getFundingProjectsFromTimesheetRows();

	}

	getFundingProjectsFromTimesheetRows () {

		if (this.timesheet.fundingProjects.length > 0) {
			this.fundingProjectIds = [];
			for (const project of this.timesheet.fundingProjects) {
				if (this.fundingProjectIds.indexOf(project.id) === -1) {
					this.fundingProjectIds.push(project.id);
				}
			}
		} else {
			for (const fundingProjectId of this.projectService.fundingProjectIds) {
				if (fundingProjectId !== 'D4UM' && this.fundingProjectIds.indexOf(fundingProjectId) === -1) {
					this.fundingProjectIds.push(fundingProjectId);
				}
			}

			for (const timeSheetRow of this.timesheet.timeSheetRows) {
				if (timeSheetRow.selectedFundingProjectId !== undefined && timeSheetRow.selectedFundingProjectId !== null) {
					if (this.fundingProjectIds.indexOf(timeSheetRow.selectedFundingProjectId) === -1) {
						this.fundingProjectIds.push(timeSheetRow.selectedFundingProjectId);
					}
				}
				if (this.projectService.fundingProjectIds.indexOf(timeSheetRow.projectId) !== -1) {
					if (this.fundingProjectIds.indexOf(timeSheetRow.projectId) === -1) {
						this.fundingProjectIds.push(timeSheetRow.projectId);
					}
				}
			}
		}

		this.putFundingProjectsToTimeSheet();
	}

	addFundingProject (event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our FundingProject
		if ((value || '').trim()) {
			this.fundingProjectIds.push(value.trim());
			this.putFundingProjectsToTimeSheet();
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.fundingProjectCtrl.setValue(null);
	}

	removeFundingProject (fundingProjectId: string): void {
		const index = this.fundingProjectIds.indexOf(fundingProjectId);

		if (index >= 0) {
			this.fundingProjectIds.splice(index, 1);
			this.putFundingProjectsToTimeSheet();
			this.fundingProjectInput.nativeElement.value = '';
			this.fundingProjectInput.nativeElement.blur();
			this.fundingProjectCtrl.setValue(null);
		}
	}

	putFundingProjectsToTimeSheet () {
		const fundingProjects: Project[] = [];
		for (const fundingProjectId of this.fundingProjectIds) {
			const fundingProject = this.projectService.projectsById[fundingProjectId];
			if (fundingProject !== undefined) {
				fundingProjects.push(fundingProject);
			}
		}
		this.timesheet.fundingProjects = fundingProjects;

		// this.timesheet.refresh();
		this._ChangeDetectorRef.detectChanges();

		this.refresh.emit();
	}

	selectedFundingProject (event: MatAutocompleteSelectedEvent): void {

		if (this.fundingProjectIds.indexOf(event.option.value) === -1) {
			this.fundingProjectIds.push(event.option.value);
			this.fundingProjectInput.nativeElement.value = '';
			this.fundingProjectInput.nativeElement.blur();
			this.fundingProjectCtrl.setValue(null);
		}

		this.putFundingProjectsToTimeSheet();
	}

	private _filterFundingProjects (fundingProjectId: string): string[] {
		const lowerFundingProjectId = fundingProjectId.toLocaleLowerCase();

		return this.projectService.fundingProjectIds.filter((fundingProject) =>
			fundingProject.toLocaleLowerCase().indexOf(lowerFundingProjectId) !== -1);
	}

	getFundingProjectById (fundingProjectId) {
		try {
			return this.projectService?.projectsById[fundingProjectId] ?? {};
		} catch (error) {
			return {};
		}
	}

}
