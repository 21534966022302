import { createAction, props } from '@ngrx/store';
import { RequirementAnalysis } from '../model/RequirementAnalysis';

export enum REQUIREMENT_ANALYSIS_ACTION {
	NOTHING = 'NOTHING',
	GET_REQUIREMENT_ANALYSIS = 'GET_REQUIREMENT_ANALYSIS',
	SET_REQUIREMENT_ANALYSIS = 'SET_REQUIREMENT_ANALYSIS',
	REQUIREMENT_ANALYSIS_LOADED = 'REQUIREMENT_ANALYSIS_LOADED',
	SAVE_REQUIREMENT_ANALYSIS = 'SAVE_REQUIREMENT_ANALYSIS',
	REQUIREMENT_ANALYSIS_SAVED = 'REQUIREMENT_ANALYSIS_SAVED',
	GET_REQUIREMENT_ANALYSIS_WORKLOAD = 'GET_REQUIREMENT_ANALYSIS_WORKLOAD',
	REQUIREMENT_ANALYSIS_WORKLOAD_LOADED = 'REQUIREMENT_ANALYSIS_WORKLOAD_LOADED',
}

export const RequirementAnalysisNothing = createAction(
	'[RequirementAnalysis] RequirementAnalysis Nothing'
);

export const GetRequirementAnalysis = createAction(
	'[RequirementAnalysis] Get RequirementAnalysis',
	props<{ suffix: string }>()
);

export const SetRequirementAnalysis = createAction(
	'[RequirementAnalysis] Set RequirementAnalysis',
	props<{ requirementAnalysis: RequirementAnalysis }>()
);

export const SaveRequirementAnalysis = createAction(
	'[RequirementAnalysis] Save RequirementAnalysis',
	props<{ requirementAnalysis: RequirementAnalysis, suffix: string }>()
);

export const RequirementAnalysisSaved = createAction(
	'[RequirementAnalysis] RequirementAnalysis Saved',
	props<{ requirementAnalysis: RequirementAnalysis }>()
);

export const RequirementAnalysisLoaded = createAction(
	'[RequirementAnalysis] RequirementAnalysis Loaded',
	props<{ requirementAnalysis: RequirementAnalysis }>()
);

export const RequirementAnalysisWorkloadLoaded = createAction(
	'[RequirementAnalysis] RequirementAnalysisWorkload Loaded'
);
export const GetRequirementAnalysisWorkload = createAction(
	'[RequirementAnalysis] Get RequirementAnalysisWorkload'
);
