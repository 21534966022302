import { formatCurrency } from '@angular/common';
import { ProjectShort } from './ProjectShort';
import { Invoice } from './Invoice';
import { SubBudget } from './SubBudget';
import { SECONDS_PER_MINUTE } from './constants';
import { PROJECT_TYPE, WORKLOAD_UNIT } from './enums';
import { VirtualGroup } from './VirtualGroup';
import { JiraIssue } from './JiraIssue';
import { JiraComponent } from './JiraComponent';
import { JiraVersion } from './JiraVersion';
import * as moment from 'moment';

export class Project extends ProjectShort {
	epics: JiraIssue[];
	components: JiraComponent[];
	versions: JiraVersion[];

	get avatar (): string {
		if (this._avatar == null) {
			for (const item of this.subProjects) {
				if (item.avatar != null) {
					return item.avatar;
				}
			}
		}
		return this._avatar;
	}
	set avatar (value: string) {
		this._avatar = value;
	}

	private _subBudgets: SubBudget[] = [];

	get subBudgets (): SubBudget[] {
		this._subBudgets = this._subBudgets.sort((a, b) => {
			if (a.startDate < b.startDate) {
				return -1;
			}
			if (a.startDate > b.startDate) {
				return 1;
			}
			if (a.endDate < b.endDate) {
				return -1;
			}
			if (a.endDate > b.endDate) {
				return 1;
			}
			return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
		});
		return this._subBudgets;
	}
	set subBudgets (subBudgets: SubBudget[]) {
		const ar_oSubBudget = [];
		for (const item of subBudgets) {
			const oSubBudget = new SubBudget();
			Object.assign(oSubBudget, item);
			ar_oSubBudget.push(oSubBudget);
		}

		this._subBudgets = ar_oSubBudget;
	}

	private _subProjects: Project[] = [];

	get subProjects (): Project[] {
		this._subProjects = this._subProjects.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
		return this._subProjects;
	}
	set subProjects (projects: Project[]) {
		const ar_oProject = [];
		for (const item of projects) {
			const oProject = new Project();
			Object.assign(oProject, item);
			ar_oProject.push(oProject);
		}

		this._subProjects = ar_oProject;
	}

	private _virtualGroups: VirtualGroup[] = [];

	get virtualGroups (): VirtualGroup[] {
		this._virtualGroups = this._virtualGroups.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
		return this._virtualGroups;
	}
	set virtualGroups (virtualGroups: VirtualGroup[]) {
		const ar_oVirtualGroups: VirtualGroup[] = [];
		for (const item of virtualGroups) {
			const oVirtualGroup = VirtualGroup.create(item);
			ar_oVirtualGroups.push(oVirtualGroup);
		}

		this._virtualGroups = ar_oVirtualGroups;
	}

	addVirtualGroup (virtualGroup: VirtualGroup) {
		if (!this.virtualGroups.includes(virtualGroup)) {
			virtualGroup.parentProject = this;
			this.virtualGroups.push(virtualGroup);
		}
	}
	deleteVirtualGroup (virtualGroup: VirtualGroup) {
		const index = this.virtualGroups.indexOf(virtualGroup);
		if (index !== -1) {
			this.virtualGroups.splice(index, 1);
		}
	}

	getValue (key: string) {
		// console.debug('this.type: ' + this.type);
		if (key === '2020_6' && this.id === 'AMELINGHAU') {
			const xxx = 1;
		}

		if (this.type === PROJECT_TYPE.SHORT) {
			return super.getValue(key);
		} else {
			let sum = 0;
			for (const item of this.subBudgets) {
				sum = sum + (item.getValue(key) || 0);
			}
			for (const item of this.subProjects) {
				sum = sum + (item.getValue(key) || 0);
			}
			// if (sum !== 0) this.preview_values[key] = sum;
			this.preview_values[key] = sum;
			return sum;
		}
	}

	getLiquidity (key: string, otherOperatingIncome: boolean, incoming: boolean = false) {
		// console.debug('this.type: ' + this.type);
		if (this.type === PROJECT_TYPE.SHORT && incoming === false) {
			return super.getLiquidity(key, otherOperatingIncome, incoming);
		} else {
			let sum = 0;
			for (const item of this.subBudgets) {
				if (item.otherOperatingIncome === otherOperatingIncome) {
					sum = sum + (item.getLiquidity(key, incoming) || 0);
				}
			}
			for (const item of this.subProjects) {
				sum = sum + (item.getLiquidity(key, otherOperatingIncome, incoming) || 0);
			}
			if (sum !== 0 && incoming === false) {
				this.preview_liquidity[key] = sum;
			}
			return sum;
		}
	}
	getFinancedWorkloadInRange (startDate: Date, endDate: Date, workload_unit: WORKLOAD_UNIT = WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR): {
		IN_RANGE: number,
		IN_RANGE_BUDGET: number,
		ALL: number,
		ALL_BUDGET: number
	} {
		const sums: any = {
			IN_RANGE: 0,
			IN_RANGE_BUDGET: 0,
			ALL: 0,
			ALL_BUDGET: 0
		};
		for (const item of this.subBudgets) {

			const minutes = item.pt * WORKLOAD_UNIT.MINUTES_PER_WORKING_DAY;

			const monthDifference =  moment(item.endDate).diff(moment(item.startDate), 'months', false);
			const monthBudget = (monthDifference !== 0) ? item.budget / monthDifference : 0;
			const intersectStartDate = item.startDate < startDate ? startDate : item.startDate;
			const intersectEndDate = item.endDate > endDate ? endDate : item.endDate;
			const monthIntersectDifference =  moment(intersectEndDate).diff(moment(intersectStartDate), 'months', false);
			const intersectBudget = monthBudget * monthIntersectDifference;
			if (
				(startDate === undefined || endDate === undefined) ||
            (item.startDate <= startDate && item.endDate >= startDate) ||
            (item.startDate >= startDate && item.startDate <= endDate && item.endDate >= endDate) ||
            (item.startDate >= startDate && item.startDate <= endDate && item.endDate <= endDate) ||
            (item.startDate <= startDate && item.endDate >= endDate)
			) {
				sums.IN_RANGE = (sums.IN_RANGE ?? 0) + minutes;
				sums.IN_RANGE_BUDGET = (sums.IN_RANGE_BUDGET ?? 0) + intersectBudget;
			}
			sums.ALL = (sums.ALL ?? 0) + minutes;
			sums.ALL_BUDGET = (sums.ALL_BUDGET ?? 0) + intersectBudget;
		}
		for (const item of this.subProjects) {
			const range = item.getFinancedWorkloadInRange(startDate, endDate, workload_unit);
			sums.IN_RANGE = sums.IN_RANGE + range.IN_RANGE * workload_unit;
			sums.IN_RANGE_BUDGET = sums.IN_RANGE_BUDGET + range.IN_RANGE_BUDGET;
			sums.ALL = sums.ALL + range.ALL * workload_unit;
			sums.ALL_BUDGET = sums.ALL_BUDGET + range.IN_RANGE_BUDGET;
		}
		if (this.name.indexOf('cloudMenu Website') !== -1 && this.subProjects.length >= 0) {
			const xxx = 1;
		}
		return {
			IN_RANGE: sums.IN_RANGE / workload_unit,
			IN_RANGE_BUDGET: sums.IN_RANGE_BUDGET,
			ALL: sums.ALL / workload_unit,
			ALL_BUDGET: sums.ALL_BUDGET
		};
	}

	getWorkload (key: string, workload_unit: WORKLOAD_UNIT = WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR) {
		// console.debug('this.type: ' + this.type);
		if (this.type === PROJECT_TYPE.SHORT) {
			return super.getWorkload(key, workload_unit);
		} else {

			if (this.manually_planned[key] !== undefined) {
				return (1 * this.manually_planned[key]) / SECONDS_PER_MINUTE / workload_unit;
			}

			let sum = 0;
			for (const item of this.subBudgets) {
				// if (item.otherOperatingIncome === otherOperatingIncome) {
				sum = sum + (item.getWorkload(key, WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR) || 0);
				// }
			}
			for (const item of this.subProjects) {
				sum = sum + (item.getWorkload(key, WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR) || 0);
			}
			this.preview_workload[key] = sum;
			return sum / workload_unit;
		}
	}

	getCustomerNumber () {
		let customerNumber = null;
		for (const item of this.subBudgets) {
			item.invoices.forEach((element) => {
				if (customerNumber === null) {
					customerNumber = element.customerNumber;
				}
			});
		}
		return customerNumber;
	}

	get_invoices (key: string, otherOperatingIncome: boolean) {
		const ar_oInvoice: Invoice[] = [];
		if (this.type !== PROJECT_TYPE.SHORT) {
			for (const item of this.subBudgets) {
				if (item.otherOperatingIncome === otherOperatingIncome) {
					ar_oInvoice.push(...item.get_invoices(key));
				}
			}
			for (const item of this.subProjects) {
				ar_oInvoice.push(...item.get_invoices(key, otherOperatingIncome));
			}
		}

		ar_oInvoice.forEach((element) => {
			if (this.type !== PROJECT_TYPE.GROUP) {
				element.project = this;
				element.projectName = this.name;
			}
		});

		return ar_oInvoice;
	}

	get sum_invoices_netto (): number {
		const sum_netto = this.getValue('sum_invoices_netto');
		if (sum_netto != null && sum_netto !== 0) {
			return sum_netto;
		} else {
			return this.calc_invoice_value();
		}
	}

	get sum_invoices_nettoString (): string {
		const sum_invoices_netto = this.sum_invoices_netto;
		return sum_invoices_netto ? formatCurrency(sum_invoices_netto, 'de', '€') : '---';
	}

	get sum_budget_netto (): number {
		const sum_budget_netto = this.getValue('sum_budget_netto');
		if (sum_budget_netto != null && sum_budget_netto !== 0) {
			return sum_budget_netto;
		} else {
			return this.calc_budget_value();
		}
	}

	get sum_budget_nettoString (): string {
		const sum_budget_netto = this.sum_budget_netto;
		return sum_budget_netto ? formatCurrency(sum_budget_netto, 'de', '€') : '---';
	}

	calc_budget_value (): number {
		let sum = 0;

		if (this.type === PROJECT_TYPE.GROUP) {
			for (const oSubProject of this.subProjects) {
				sum += oSubProject.calc_budget_value();
			}
		} else {

			for (const oSubBudget of this.subBudgets) {
				try {
					sum += oSubBudget.sum_budget_netto;
				} catch (error) {
					// do nothing
				}
			}
		}

		if (sum !== 0) {
			this.setValue('sum_budget_netto', sum);
		}
		return sum;
	}

	calc_invoice_value (): number {
		let sum = 0;

		if (this.type === PROJECT_TYPE.GROUP) {
			for (const oSubProject of this.subProjects) {
				sum += oSubProject.calc_invoice_value();
			}
		} else {

			for (const oSubBudget of this.subBudgets) {
				try {
					sum += oSubBudget.calc_invoice_value('sum_netto');
				} catch (error) {
					// do nothing
				}
			}
		}

		if (sum !== 0) {
			this.setValue('sum_invoices_netto', sum);
		}
		return sum;
	}

	calc_invoice_liquidity (): number {
		let sum = 0;
		for (const oSubBudget of this.subBudgets) {
			try {
				sum += oSubBudget.calc_invoice_liquidity('sum_brutto');
			} catch (error) {
				// do nothing
			}
		}
		this.setValue('sum_invoices_brutto', sum);
		return sum;
	}

	toJSON () {
		const jsonObj: any = super.toJSON();

		jsonObj.name = this.name;
		jsonObj.avatar = this.avatar;
		jsonObj.type = this.type;
		jsonObj.id = this.id;
		jsonObj.parentId = this.parentId;
		jsonObj.preview_values = this.preview_values;
		jsonObj.subBudgets = this.subBudgets;
		jsonObj.virtualGroups = this.virtualGroups;
		// jsonObj.subProjects = this.subProjects;
		jsonObj.manually_planned = this.manually_planned;
		jsonObj.fundingProject = this.fundingProject;
		jsonObj.fundingNumber = this.fundingNumber;
		jsonObj.flatRateSurcharge = this.flatRateSurcharge;
		jsonObj.fundingTitle = this.fundingTitle;

		return jsonObj;
	}

}
