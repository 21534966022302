import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, TemplateRef, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { REPORT_MODE, PROJECT_TYPE, CELL_TYPE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { Invoice } from 'src/app/model/Invoice';
import { LiqudityTableRow } from 'src/app/model/LiqudityTableRow';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { ProjectService } from 'src/app/services/project.service';
import { forkJoin, Observable, Subject, timer } from 'rxjs';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { LiquidityService } from 'src/app/services/liquidity.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-liquidity-table',
	templateUrl: './liquidity-table.component.html',
	styleUrls: ['./liquidity-table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiquidityTableComponent implements OnInit, AfterContentInit {

	REPORT_MODE = REPORT_MODE;
	PROJECT_TYPE = PROJECT_TYPE;
	CELL_TYPE = CELL_TYPE;

	@ViewChild('LiquidityTable', {static: false}) oLiquidityTable: MatTable<any>;
	@ViewChild('SubLiquidityTable', {static: false}) oSubLiquidityTable: MatTable<any>;
	@ViewChild('deleteDialog', {static: true}) deleteDialog: TemplateRef<any>;
	@ViewChild('actClearingDateDialog', {static: false}) actClearingDateDialog: TemplateRef<any>;

	@Input() public reportMode: REPORT_MODE = REPORT_MODE.MONTH_FULL_YEAR;
	@Input() public projectList: Subject<Project[]>;
	private _selectedDateLiquidityView: Date = new Date();
	@Input() public get selectedDateLiquidityView (): Date {
		return this._selectedDateLiquidityView;
	}
	public set selectedDateLiquidityView (value: Date) {
		this._selectedDateLiquidityView = value;
		this.yearString = this._selectedDateLiquidityView.getFullYear();
		this.nextYearString = this._selectedDateLiquidityView.getFullYear() + 1;
		this.next2YearString = this._selectedDateLiquidityView.getFullYear() + 2;

		this.setColumns();
		this.reloadLiqudityTableData();

	}

	public yearString = this._selectedDateLiquidityView.getFullYear();
	public nextYearString = this._selectedDateLiquidityView.getFullYear() + 1;
	public next2YearString = this._selectedDateLiquidityView.getFullYear() + 2;

	public columnsToDisplay     = [];
	public columnsToDisplayInfo = {};

	public selectedLiquidityTableRow: LiqudityTableRow;
	public selectedSubLiquidityTableRow: LiqudityTableRow;

	public selectedInvoice: Invoice;

	public expandedElement;
	public expandedSubElement;
	public showSpinner = false;

	constructor (
		private _jiraconnector: JiraconnectorService,
		public _ChangeDetectorRef: ChangeDetectorRef,
		private dialog: MatDialog,

		public projectService: ProjectService,
		public liquidityService: LiquidityService
	) {
	}

	ngOnInit () {

	}

	ngAfterContentInit () {
		console.log('LIQIDITY Component initialised!');

		this.setColumns();
		setTimeout(() => {
			this.reloadLiqudityTableData();

			setTimeout(() => {
				this._ChangeDetectorRef.detectChanges();
			}, 100);

		}, 1500);

	}

	setColumns () {

		const columns = [];
		const columnsInfo = {};

		let colName = 'name';
		columns.push(colName);
		columnsInfo[colName] = {class: 'c-name', key: 'name', title: 'Name'};

		/*
    for (let iCount = 1; iCount <= 4; iCount++) {
      var colName = 'q' + iCount;
      columns.push(colName);
      columnsInfo[colName] = {class: 'c-q', key: this.yearString + '_q' + iCount, title: 'Q' + iCount + ' / 2020'};
    }
    */
		let sClass = 'c-m';

		colName = this.yearString + '01_add';
		columns.push(colName);
		columnsInfo[colName] = {class: sClass, key: this.yearString + '_1_add', title: '', isEditable: true};

		if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
			for (let iCount = 1; iCount <= 12; iCount++) {

				const sMonth = ((iCount < 10) ? '0' : '') + iCount;

				const colName = 'm' + this.yearString + sMonth;
				columns.push(colName);

				columnsInfo[colName] = {class: sClass, key: this.yearString + '_' + iCount,
					title: sMonth + ' / ' + this.yearString, isEditable: true};
			}
		} else {
			sClass = 'c-q';

			for (let iCount = 1; iCount <= 4; iCount++) {

				const sQuarter = ((iCount < 10) ? '0' : '') + iCount;

				const colName = 'q' + sQuarter;
				columns.push(colName);

				columnsInfo[colName] = {class: sClass, key: this.yearString + '_q' + iCount,
					title: 'Q' + iCount + ' / ' + this.yearString, isEditable: true};
			}
		}

		colName = 'year_' + this.yearString;
		columns.push(colName);
		columnsInfo[colName] = {class: sClass + ' column-year', key: this.yearString + '_year',
			title: '' + this.yearString, isEditable: false};

		if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
			for (let iCount = 1; iCount <= 12; iCount++) {

				const sMonth = ((iCount < 10) ? '0' : '') + iCount;

				const colName = 'm' + this.nextYearString + sMonth;
				columns.push(colName);

				columnsInfo[colName] = {class: sClass, key: this.nextYearString + '_' +
                                iCount, title: sMonth + ' / ' + this.nextYearString, isEditable: true};
			}
		}

		colName = 'year_' + this.nextYearString;
		columns.push(colName);
		columnsInfo[colName] = {class: sClass + ' column-year', key: this.nextYearString + '_year',
			title: '' + this.nextYearString, isEditable: false};

		if (this.reportMode === REPORT_MODE.MONTH_FULL_YEAR) {
			for (let iCount = 1; iCount <= 12; iCount++) {

				const sMonth = ((iCount < 10) ? '0' : '') + iCount;

				const colName = 'm' + this.next2YearString + sMonth;
				columns.push(colName);

				columnsInfo[colName] = {class: sClass, key: this.next2YearString + '_' + iCount,
					title: sMonth + ' / ' + this.next2YearString, isEditable: true};
			}
		}

		colName = 'year_' + this.next2YearString;
		columns.push(colName);
		columnsInfo[colName] = {class: sClass + ' column-year', key: this.next2YearString + '_year',
			title: '' + this.next2YearString, isEditable: false};

		colName = 'tools';
		columns.push(colName);
		columnsInfo[colName] = {class: sClass, title: 'Tools'};

		this.columnsToDisplay = columns;
		this.columnsToDisplayInfo = columnsInfo;

		this.liquidityService.columnsToDisplay = this.columnsToDisplay;
		this.liquidityService.columnsToDisplayInfo = this.columnsToDisplayInfo;

	}

	reloadLiqudityTableData () {
		if (this.projectService.projects.length === 0) {
			const projectListSubscribtions = this.projectList.subscribe((results) => {
				projectListSubscribtions.unsubscribe();
				this._reloadLiqudityTableData();
			});
		} else {
			this._reloadLiqudityTableData();
		}
	}

	_reloadLiqudityTableData () {
		this.showSpinner = true;
		this._ChangeDetectorRef.detectChanges();

		const observableLiquidity = this.liquidityService.getLiquidityTableFromDataStore();

		//    let projectListSubscribtions = this.projectList.subscribe(results => {
		const projectListSubscribtions = observableLiquidity.subscribe((results) => {

			//    let projectListSubscribtions = this.projectService.projectSubject.subscribe(results => {

			projectListSubscribtions.unsubscribe();

			const liquidityTable: LiqudityTableRow[] = [];

			// const oLiqudityTableRow: LiqudityTableRow;

			const ar_oObservable: Observable<any>[] = [];
			for (const project of this.projectService.projects) {
				const ar_projectObservables = this.projectService.getFullProjectData(project);
				ar_oObservable.push(...ar_projectObservables);
			}
			ar_oObservable.push(timer(500));

			console.log('ar_oObservable: ' + ar_oObservable.length);

			// tslint:disable-next-line: deprecation
			const allObservablesSubscribtions = forkJoin<any>([...ar_oObservable]).subscribe(() => {
				console.log('Second Subscribtion ready!');

				console.log('results: ' + results);

				// allObservablesSubscribtions.unsubscribe();

				this.liquidityService.setLiqidityTableByProjects(this.projectService.projects);

				this.showSpinner = false;

				this._ChangeDetectorRef.detectChanges();

			});

			console.log('First Subscribtion ready!');
		});

	}

	addRow (row: LiqudityTableRow) {
		const oLiqudityTableRow = new LiqudityTableRow();

		oLiqudityTableRow.orderNumber = this.selectedLiquidityTableRow.orderNumber + '.' + (row.subLiqudityTableRow.length + 1);

		oLiqudityTableRow.name = 'Neuer Eintrag';

		row.subLiqudityTableRow.push(oLiqudityTableRow);

		console.log('oLiqudityTableRow: ' + JSON.stringify(oLiqudityTableRow));

		this.liquidityService.refreshSums();
		this.oSubLiquidityTable.renderRows();

		// this._ChangeDetectorRef.detectChanges();
	}

	moveUp (row: LiqudityTableRow) {
		const parentRow: LiqudityTableRow = this.selectedLiquidityTableRow;
		const index = parentRow.subLiqudityTableRow.findIndex((item) => item.orderNumber === row.orderNumber);
		const indexNew = index - 1;

		if (indexNew >= 0) {
			[parentRow.subLiqudityTableRow[index],
				parentRow.subLiqudityTableRow[indexNew]] = [parentRow.subLiqudityTableRow[indexNew], parentRow.subLiqudityTableRow[index]];

			let iCount = 1;
			parentRow.subLiqudityTableRow.forEach((item) => {
				item.orderNumber = parentRow.orderNumber + '.' + (iCount);
				iCount = iCount + 1;
			});

			this.liquidityService.refreshSums();
			this._ChangeDetectorRef.detectChanges();
			this.oSubLiquidityTable.renderRows();
		}
	}

	moveDown (row: LiqudityTableRow) {
		const parentRow: LiqudityTableRow = this.selectedLiquidityTableRow;
		const index = parentRow.subLiqudityTableRow.findIndex((item) => item.orderNumber === row.orderNumber);
		const indexNew = index + 1;

		if (indexNew < parentRow.subLiqudityTableRow.length) {
			[parentRow.subLiqudityTableRow[index],
				parentRow.subLiqudityTableRow[indexNew]] = [parentRow.subLiqudityTableRow[indexNew], parentRow.subLiqudityTableRow[index]];

			let iCount = 1;
			parentRow.subLiqudityTableRow.forEach((item) => {
				item.orderNumber = parentRow.orderNumber + '.' + (iCount);
				iCount = iCount + 1;
			});

			this.liquidityService.refreshSums();
			this._ChangeDetectorRef.detectChanges();
			this.oSubLiquidityTable.renderRows();
		}
	}
	isFirst (row: LiqudityTableRow) {
		const parentRow: LiqudityTableRow = this.selectedLiquidityTableRow;
		const index = parentRow.subLiqudityTableRow.findIndex((item) => item.orderNumber === row.orderNumber);
		return (index <= 0);
	}
	isLast (row: LiqudityTableRow) {
		const parentRow: LiqudityTableRow = this.selectedLiquidityTableRow;
		const index = parentRow.subLiqudityTableRow.findIndex((item) => item.orderNumber === row.orderNumber);
		return (index >= parentRow.subLiqudityTableRow.length - 1);
	}

	deleteRow (row: LiqudityTableRow) {
		// console.debug('Delete: ' + row);

		const parentSubLiquidityTableRow = this.selectedLiquidityTableRow;
		this.selectedSubLiquidityTableRow = row;

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {

				parentSubLiquidityTableRow.subLiqudityTableRow.splice(
					parentSubLiquidityTableRow.subLiqudityTableRow.findIndex((item) =>
						item.orderNumber === this.selectedLiquidityTableRow.orderNumber
					),
					1
				);

				let iCount = 1;
				parentSubLiquidityTableRow.subLiqudityTableRow.forEach((item) => {
					item.orderNumber = parentSubLiquidityTableRow.orderNumber + '.' + (iCount);
					iCount = iCount + 1;
				});

				this.liquidityService.refreshSums();
				this._ChangeDetectorRef.detectChanges();
				this.oSubLiquidityTable.renderRows();

			}

		});

	}

	expandRow (row: LiqudityTableRow) {
		this.expandedElement = this.expandedElement === row ? null : row;
		if (this.expandedElement !== null) {
			this.selectedLiquidityTableRow = this.expandedElement;
		}
	}

	/*
  saveInvoicePaid(oInvoice: Invoice) {
    // console.debug(oInvoice);

    this.selectedInvoice = oInvoice;

    if (oInvoice.project !== null) {
      const actDate = new Date();
      const clearingDateOrActDate = oInvoice.clearingDate > actDate ? oInvoice.clearingDate : actDate;

      const oldClearingDate = new Date(oInvoice.clearingDate);
      const oldPaid = oInvoice.paid;

      console.debug(oInvoice.clearingDate + ' !== ' + clearingDateOrActDate);

      // if (oInvoice.paid === true && oInvoice.clearingDate != clearingDateOrActDate) {

      const dialogRef = this.dialog.open(this.actClearingDateDialog);

      dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);

          if (result === 'confirm') {
            // oInvoice.clearingDate = clearingDateOrActDate;
            // oInvoice.paid = true;
            this.saveProject(this.selectedInvoice);
          } else {
            this.selectedInvoice.clearingDate = oldClearingDate;
            this.selectedInvoice.paid = oldPaid;
          }
        });

      // } else {
      //  this.saveProject(oInvoice);
      // }

    }
  }
  */

	handleRefreshSavePaidInvoice (invoice: Invoice) {
		this._ChangeDetectorRef.detectChanges();
		this.liquidityService?.refreshSums();
		this.oSubLiquidityTable?.renderRows();
		this.oLiquidityTable?.renderRows();
	}

	saveProject (oInvoice: Invoice) {
		this.projectService.saveProject(oInvoice.project, () => {
			this._ChangeDetectorRef.detectChanges();
			this.liquidityService?.refreshSums();
			this.oSubLiquidityTable?.renderRows();
			this.oLiquidityTable?.renderRows();
		});
	}

	handleBlur () {

		this.liquidityService.refreshSums(false);

		this.oLiquidityTable.dataSource =  new MatTableDataSource( [] );

		this.oLiquidityTable.renderRows();
		this.oSubLiquidityTable.renderRows();

		this._ChangeDetectorRef.detectChanges();
	}

}
