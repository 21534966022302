// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-standard-chip {
  border-radius: 5px;
  min-height: 24px;
  padding: 0;
}

mat-mdc-chip {
  width: 90%;
  min-width: 2em;
  font: inherit;
}

mat-mdc-chip span {
  margin: 0 auto;
}

.mat-form-field span {
  margin: 0 auto;
}

.planned-time label {
  font-size: 2px;
}

.planned-time-max-width {
  padding-top: 3px;
  padding-bottom: 1px;
  max-height: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  font-weight: bold;
}

input:not(:focus) {
  cursor: pointer !important;
  background-color: #DDD;
  border-radius: 5px;
  width: 94%;
}

input:not(:focus).show-strike {
  color: transparent;
}

input:focus {
  border-bottom: 1px solid #000;
}

.hide {
  height: 0 !important;
}

mat-spinner {
  background-color: #DDD;
  border-radius: 5px;
  width: 100%;
  margin: auto;
  text-align: center;
}

@keyframes planned-updated-animation {
  from {
    box-shadow: 0 0 10px 10px rgb(117, 209, 148);
  }
  to {
    box-shadow: 0 0 0 0 rgb(117, 209, 148);
  }
}
.planned-updated-glow {
  animation: planned-updated-animation 1s 5 alternate;
}

.cursor-pointer {
  cursor: pointer !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cell-item-renderer-planned-number/cell-item-renderer-planned-number.component.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,gBAAA;EACA,UAAA;AACD;;AAEA;EACC,UAAA;EACA,cAAA;EACA,aAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,cAAA;AACD;;AAEA;EACC,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AACD;;AAEA;EACC,0BAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EACC,6BAAA;AACD;;AAEA;EACC,oBAAA;AACD;;AAEA;EACC,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACD;;AAEA;EAEC;IACC,4CAAA;EAAA;EAGD;IACC,sCAAA;EADA;AACF;AAIA;EACC,mDAAA;AAFD;;AAKA;EACC,0BAAA;AAFD","sourcesContent":[".mat-mdc-standard-chip {\n\tborder-radius: 5px;\n\tmin-height: 24px;\n\tpadding: 0;\n}\n\nmat-mdc-chip {\n\twidth: 90%;\n\tmin-width: 2em;\n\tfont: inherit;\n}\n\nmat-mdc-chip span {\n\tmargin: 0 auto;\n}\n\n.mat-form-field span {\n\tmargin: 0 auto;\n}\n\n.planned-time label {\n\tfont-size: 2px;\n}\n\n.planned-time-max-width {\n\tpadding-top: 3px;\n\tpadding-bottom: 1px;\n\tmax-height: 21px;\n\theight: 21px;\n\tline-height: 21px;\n\ttext-align: center;\n\tfont-weight: bold;\n}\n\ninput:not(:focus) {\n\tcursor: pointer !important;\n\tbackground-color: #DDD;\n\tborder-radius: 5px;\n\twidth: 94%;\n}\n\ninput:not(:focus).show-strike {\n\tcolor: transparent;\n}\n\ninput:focus {\n\tborder-bottom: 1px solid #000;\n}\n\n.hide {\n\theight: 0 !important;\n}\n\nmat-spinner {\n\tbackground-color: #DDD;\n\tborder-radius: 5px;\n\twidth: 100%;\n\tmargin: auto;\n\ttext-align: center;\n}\n\n@keyframes planned-updated-animation {\n\n\tfrom {\n\t\tbox-shadow: 0 0 10px 10px rgb(117 209 148);\n\t}\n\n\tto {\n\t\tbox-shadow: 0 0 0 0 rgb(117 209 148);\n\t}\n}\n\n.planned-updated-glow {\n\tanimation: planned-updated-animation 1s 5 alternate;\n}\n\n.cursor-pointer {\n\tcursor: pointer !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
