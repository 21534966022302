import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../model/User';

@Pipe({
	name: 'timeScheduleInfoCssClass'
})
export class TimeScheduleInfoCssClassPipe implements PipeTransform {

	transform (observable: any, user: User, actColumn: string, monthDescription: any): string {

		const check = observable;

		switch (actColumn) {
			case 'real_plan_column':

				const monthKey = monthDescription.month;
				const available = ((user.pot_column[monthKey]) ? user.pot_column[monthKey] : 0) -
            ((user.holiday_column[monthKey]) ? user.holiday_column[monthKey] : 0);

				if (user.real_plan_column[monthKey] === undefined || user.real_plan_column[monthKey] === 0) {
					return 'planned-green';
				}

				if (user.real_plan_column[monthKey] > available) {
					return 'planned-red';
				} else if (user.real_plan_column[monthKey] < available) {
					return 'planned-green';
				} else {
					return 'planned-white';
				}

				break;

			default:
				return '';

				break;
		}
	}
}
