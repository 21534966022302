import { Pipe, PipeTransform } from '@angular/core';
import { REPORT_MODE, UNIT } from '../model/enums';
import { RessourceCube } from '../model/RessourceCube';

@Pipe({
	name: 'plannedTimeForProjectFormated'
})
export class PlannedTimeForProjectFormatedPipe implements PipeTransform {

	transform (ressourceCube: RessourceCube, projectId: string,
		monthDescription: any, reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS): string {

		return ressourceCube.getPlannedTimeForProjectFormated(
			projectId, monthDescription.month, (reportMode === REPORT_MODE.RESSOURCE_HOURS) ? UNIT.HOURS : UNIT.DAYS);

	}

}
