import { Component, OnInit, Input, ViewChild, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/model/Project';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

@Component({
	selector: 'app-project-group-input',
	templateUrl: './project-group-input.component.html',
	styleUrls: ['./project-group-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectGroupInputComponent implements OnInit {
	@ViewChild('deleteDialog', {static: true}) deleteDialog: TemplateRef<any>;

	@Input() public project: Project;
	@Input() public oProjectTable: MatTable<any>;

	myControl = new UntypedFormControl();

	get projectGroupNames (): string[] {
		const ar_sProjectGroupNames: string[] = [];

		for (const item of this.projectService.projectGroups) {
			ar_sProjectGroupNames.push(item.name);
		}
		return ar_sProjectGroupNames;
	}

	filteredOptions: Observable<string[]>;

	constructor (
		private dialog: MatDialog,
		private _ChangeDetectorRef: ChangeDetectorRef,
		public projectService: ProjectService
	) { }

	ngOnInit () {
		this.filteredOptions = this.myControl.valueChanges
			.pipe(
				startWith(''),
				map((value) => this._filter(value))
			);

		setTimeout(() => {
			this._ChangeDetectorRef.detectChanges();
		}, 100);

	}

	private _filter (value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.projectGroupNames.filter((option) => option.toLowerCase().includes(filterValue));
	}

	public saveProject () {
		this.projectService.saveProject(this.project);
	}

	public handleProjectGroup () {
		this.projectService.setProjectGroupForProject(this.myControl.value, this.project);
	}

	public removeProjectFromGroup (project: Project): void {
		// console.debug('Remove: ' + project);

		const dialogRef = this.dialog.open(this.deleteDialog);

		dialogRef.afterClosed().subscribe((result) => {
			console.log(`Dialog result: ${result}`);

			if (result === 'confirm') {
				this.projectService.removeProjectFromGroup(project);

				this.oProjectTable.renderRows();
			}

		});

	}

}
