import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JiraconnectorModule } from './jiraconnector/jiraconnector.module';
import { JIRA_URL, JIRA_AUTH_URL } from './jiraconnector/jiraconnector.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SubBudgetInputComponent } from './components/sub-budget-input/sub-budget-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SelectProjectDialogComponent, DialogContentSelectProjectComponent } from './components/select-project-dialog/select-project-dialog.component';
import { ProjectService } from './services/project.service';
import * as moment from 'moment';
import { SubBudgetListComponent } from './components/sub-budget-list/sub-budget-list.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoiceInputComponent } from './components/invoice-input/invoice-input.component';
import { CustomDateAdapter } from './adapter/custom.date.adapter';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectGroupInputComponent } from './components/project-group-input/project-group-input.component';
import { LiquidityTableComponent } from './components/liquidity-table/liquidity-table.component';
import { CellItemRendererComponent } from './components/cell-item-renderer/cell-item-renderer.component';
import { SocketService } from './services/socket.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgChartsModule as ChartsModule } from 'ng2-charts';

import { OpenLiquidityChartDialogComponent, LiquidityChartComponent } from './components/liquidity-chart/liquidity-chart.component';
import { RessourcesTableComponent } from './components/ressources-table/ressources-table.component';

import { ProjectWorkloadItemRendererComponent } from './components/project-workload-item-renderer/project-workload-item-renderer.component';
import { TempoOauthSuccessComponent } from './components/tempo-oauth-success/tempo-oauth-success.component';
import { TempoIoService, TEMPO_IO_URL, TEMPO_IO_AUTH_URL } from './services/tempo.io.service';
import { AllInvoicesListComponent } from './components/all-invoices-list/all-invoices-list.component';
import { TimesheetsTableComponent } from './components/timesheets-table/timesheets-table.component';
import { TimesheetFundingProjectSelectorComponent } from './components/timesheet-funding-project-selector/timesheet-funding-project-selector.component';
import { NgxPrintModule } from 'ngx-print';
import { SettingsComponent } from './components/settings/settings.component';
import { JiraGroupSelectorComponent } from './components/jira-group-selector/jira-group-selector.component';
import { JiraUserSelectorComponent } from './components/jira-user-selector/jira-user-selector.component';
import { TimeStatementTableComponent } from './components/time-statement-table/time-statement-table.component';
import { TimeStatementHourlyRateTableComponent } from './components/time-statement-hourly-rate-table/time-statement-hourly-rate-table.component';
import { TimesheetRowFundingSelectorComponent } from './components/timesheet-row-funding-selector/timesheet-row-funding-selector.component';
import { TimesheetRowTableComponent } from './components/timesheet-row-table/timesheet-row-table.component';
import { TimeStatementTableGroupComponent } from './components/time-statement-table-group/time-statement-table-group.component';
import { TimeStaffCostsOverviewComponent } from './components/time-staff-costs-overview/time-staff-costs-overview.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { CapabilitiesTableComponent } from './components/capabilities-table/capabilities-table.component';
import { CellItemRendererPlannedNumberComponent } from './components/cell-item-renderer-planned-number/cell-item-renderer-planned-number.component';
import { CellItemRendererProjectWorkloadNumberComponent } from './components/cell-item-renderer-project-workload-number/cell-item-renderer-project-workload-number.component';
import { RessourcesTableContentRowComponent } from './components/ressources-table-content-row/ressources-table-content-row.component';
import { RessourcesTableContentInfoCellComponent } from './components/ressources-table-content-info-cell/ressources-table-content-info-cell.component';
import { RessourcesTableContentProjectCellComponent } from './components/ressources-table-content-project-cell/ressources-table-content-project-cell.component';
import { RessourcesToolTipPipe } from './pipes/ressources-tool-tip.pipe';
import { PlannedTimeForProjectFormatedPipe } from './pipes/planned-time-for-project-formated.pipe';
import { TimeScheduleInfoPipe } from './pipes/time-schedule-info.pipe';
import { TimeScheduleInfoCssClassPipe } from './pipes/time-schedule-info-css-class.pipe';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ProjectIdFromColumnPipe } from './pipes/project-id-from-column.pipe';
import { IsVirtualGroupPipe } from './pipes/is-virtual-group.pipe';
import { TestFormComponent } from './components/test-form/test-form.component';
import { PlannedTimeForProjectPipe } from './pipes/planned-time-for-project.pipe';

import { MatSidenavModule } from '@angular/material/sidenav';
import { CellItemRendererEuroValueComponent } from './components/cell-item-renderer-euro-value/cell-item-renderer-euro-value.component';
import { ProjectOverAllValuePipe } from './pipes/project-over-all-value.pipe';
import { AllUsersInTeamShowPipe } from './pipes/all-users-in-team-show.pipe';
import { InvoicePaidCheckboxComponent } from './components/invoice-paid-checkbox/invoice-paid-checkbox.component';
import { MonitorInterceptor } from './interceptors/monitor.interceptor';
import { FilterPipe } from './pipes/filter.pipe';
import { RequirementAnalysisComponent } from './components/requirement-analysis/requirement-analysis.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { RequirementAnalysisProjectGroupSelectorComponent } from './components/requirement-analysis-project-group-selector/requirement-analysis-project-group-selector.component';
import { EmployeeNamesPipe } from './pipes/employee-names.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { RessourceCubeIoService, RESSOURCE_TEMPO_IO_URL } from './services/ressource-cube.io.service';
import { ReportGeneratorComponent } from './components/report-generator/report-generator.component';
import { ReportGeneratorIssueProjectAutoCompleteComponent } from './components/report-generator-issue-project-autocomplete/report-generator-issue-project-autocomplete.component';
import { ReportGeneratorIssueProjectTreeComponent } from './components/report-generator-issue-project-tree/report-generator-issue-project-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { ReportGeneratorPartComponent } from './components/report-generator-part/report-generator-part.component';
import { ReportGeneratorService } from './services/report-generator.service';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import { ReportGeneratorBodyComponent } from './components/report-generator-body/report-generator-body.component';

// the second parameter 'fr-FR' is optional
registerLocaleData(localeDe);

const config: SocketIoConfig = { url: 'wss://' + window.location.hostname, options: {} };

// AOT compilation support
export function HttpLoaderFactory (http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        SubBudgetInputComponent,
        SelectProjectDialogComponent,
        DialogContentSelectProjectComponent,
        SubBudgetListComponent,
        InvoiceListComponent,
        InvoiceInputComponent,
        ProjectListComponent,
        ProjectGroupInputComponent,
        LiquidityTableComponent,
        CellItemRendererComponent,
        LiquidityChartComponent,
        OpenLiquidityChartDialogComponent,
        RessourcesTableComponent,
        ProjectWorkloadItemRendererComponent,
        TempoOauthSuccessComponent,
        AllInvoicesListComponent,
        TimesheetsTableComponent,
        TimesheetFundingProjectSelectorComponent,
        SettingsComponent,
        JiraGroupSelectorComponent,
        JiraUserSelectorComponent,
        TimeStatementTableComponent,
        TimeStatementHourlyRateTableComponent,
        TimesheetRowFundingSelectorComponent,
        TimesheetRowTableComponent,
        TimeStatementTableGroupComponent,
        TimeStaffCostsOverviewComponent,
        CapabilitiesTableComponent,
        CellItemRendererPlannedNumberComponent,
        CellItemRendererProjectWorkloadNumberComponent,
        RessourcesTableContentRowComponent,
        RessourcesTableContentInfoCellComponent,
        RessourcesTableContentProjectCellComponent,
        RessourcesToolTipPipe,
        PlannedTimeForProjectFormatedPipe,
        TimeScheduleInfoPipe,
        TimeScheduleInfoCssClassPipe,
        SidenavComponent,
        ProjectIdFromColumnPipe,
        IsVirtualGroupPipe,
        TestFormComponent,
        PlannedTimeForProjectPipe,
        CellItemRendererEuroValueComponent,
        ProjectOverAllValuePipe,
        AllUsersInTeamShowPipe,
        InvoicePaidCheckboxComponent,
        FilterPipe,
        RequirementAnalysisComponent,
        SortByPipe,
        RequirementAnalysisProjectGroupSelectorComponent,
        EmployeeNamesPipe,
        ReportGeneratorComponent,
        ReportGeneratorIssueProjectAutoCompleteComponent,
        ReportGeneratorIssueProjectTreeComponent,
        ReportGeneratorPartComponent,
        ReportGeneratorBodyComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'DE'
        }),
        AppRoutingModule,
        JiraconnectorModule,
        MatTableModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatListModule,
        MatTabsModule,
        MatMenuModule,
        MatCardModule,
        MatFormFieldModule,
        MatRippleModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatTreeModule,
        MatExpansionModule,
        MatGridListModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ChartsModule,
        FormsModule,
        NgxPrintModule,
        SocketIoModule.forRoot(config),
        StoreModule.forRoot(reducers, {
            metaReducers
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot([])], providers: [
        { provide: JIRA_URL, useValue: '/rest/api/3/' },
        { provide: JIRA_AUTH_URL, useValue: '/rest/' },
        { provide: TEMPO_IO_URL, useValue: '/api.tempo.io/core/3/' },
        { provide: TEMPO_IO_AUTH_URL, useValue: '/api.tempo.io/' },
        { provide: RESSOURCE_TEMPO_IO_URL, useValue: '/ressource.api.tempo.io/' },
        { provide: 'moment', useFactory: (): any => moment },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MonitorInterceptor,
            multi: true
        },
        ProjectService,
        SocketService,
        TempoIoService,
        RessourceCubeIoService,
        ReportGeneratorService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
