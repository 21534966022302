import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'app-test-form',
	templateUrl: './test-form.component.html',
	styleUrls: ['./test-form.component.scss']
})
export class TestFormComponent implements OnInit {

	range = new UntypedFormGroup({
		start: new UntypedFormControl(),
		end: new UntypedFormControl()
	});

	constructor () { }

	ngOnInit (): void {
	}

}
