import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { AP$ } from 'src/polyfills';
import { environment } from 'src/environments/environment';
import { TempoIoService } from '../services/tempo.io.service';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {

	constructor (
		public tempoIoService: TempoIoService,
	) {}

	intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			retryWhen((error) =>
				error.pipe(
					concatMap((error, count) => {
						if (error.status === 401 && request.url.indexOf('api.tempo.io') !== -1) {

							this.tempoIoService.requestAccessTokenFromSocketIo().subscribe((response) => {
								if (1 === 1) { // !response.localSameAsServer) {
									AP$.cookie.save('tempo_access_token', response.tempo_access_token, 60);
									if (response.tempo_refresh_token === null || response.tempo_refresh_token === undefined) {
										AP$.cookie.save('tempo_refresh_token', response.tempo_refresh_token, 365);
									}

									this.tempoIoService.setNewAuthorizationHeader(response.tempo_access_token);
								} else {
									this.tempoIoService.refreshAccessToken().subscribe((tempo_access_token) => {
										this.tempoIoService.setNewAuthorizationHeader(tempo_access_token);

										return of(error);
									}, (refreshError) => {
										AP$.getLocation((responseLocation: any) => {
											const parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
											const parts = parse_url.exec( responseLocation );
											const baseUrl = parts[1] + ':' + parts[2] + parts[3];

											const CLINET_ID = environment.hosts['https://api.tempo.io'].CLINET_ID;
											const REDIRECT_URI = baseUrl + environment.hosts['https://api.tempo.io'].REDIRECT_URI;

											AP$.navigator.go('site', {
												absoluteUrl: baseUrl + '/plugins/servlet/ac/io.tempo.jira/oauth-authorize/?client_id=' +
                                    CLINET_ID + '&redirect_uri=' +
                                    REDIRECT_URI + '&access_type=tenant_user'
											});
										});

										return throwError(refreshError);

									});

								}
							});

						} else if (count <= retryCount && error.status === 429) {
							return of(error);
						}
						return throwError(error);
					}),
					delay(retryWaitMilliSeconds)
				)
			)
		);
	}
}
