import { CLASS_TYPE } from './enums';
import { JiraIssue } from './JiraIssue';

export class JiraProject {
	type = CLASS_TYPE.JIRA_PROJECT;
	id: string;
	name: string;
	hideId: boolean = false;

	get projectId():string {
		return this.id;
	};
	get projectName():string {
		return this.name;
	};

	childIssues: (JiraIssue | JiraProject)[] = [];

	public get allTimeSpentSeconds(): number {
		let childTimeSpentSeconds = 0;
		for (const childJiraIssue of this.childIssues) {
			childTimeSpentSeconds += childJiraIssue.allTimeSpentSeconds;
		}

		return childTimeSpentSeconds;
	}

	public get allTimeSpentMinutes(): number {
		return this.allTimeSpentSeconds / 60;
	}

	public get allTimeSpentHours(): number {
		return this.allTimeSpentMinutes / 60;
	}

	getPercentage(baseAllTimeSpentSeconds: number, splitUpAllTimeSpentSeconds: number): {
		percentage: number,
		splitUpAllTimeSpentSeconds: number, 
		splitUpAllTimeSpentMinutes: number, 
		splitUpAllTimeSpentHours: number 
	} {
		const percentage = this.allTimeSpentSeconds / baseAllTimeSpentSeconds;
		return {
			percentage: percentage,
			splitUpAllTimeSpentSeconds: splitUpAllTimeSpentSeconds * percentage,
			splitUpAllTimeSpentMinutes: splitUpAllTimeSpentSeconds * percentage / 60,
			splitUpAllTimeSpentHours: splitUpAllTimeSpentSeconds * percentage / 60 / 60
		};
	}

	toJSON () {
		const jsonObj = {
			type: this.type,
			id: this.id,
			name: this.name,
			childIssues: this.childIssues,
			allTimeSpentSeconds: this.allTimeSpentSeconds,
			allTimeSpentMinutes: this.allTimeSpentMinutes,
			allTimeSpentHours: this.allTimeSpentHours
		};

		return jsonObj;
	}


}

