import { CAPABILITY_TYPE } from './enums';

export class CapabilityCategory {
	name: string;
}
export class CapabilityGroup {
	name: string;
}

export class Capability {
	name: string;
	description: string;
	type: CAPABILITY_TYPE;
	category: CapabilityCategory;
	group: CapabilityGroup;

	relatedProjectIds: string[];
	relatedAccountIdsAndSkillLevel: {accountId: string; skillLevel: number}[];
}
