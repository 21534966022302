import { formatCurrency } from '@angular/common';
import { SECONDS_PER_MINUTE } from './constants';
import { PROJECT_TYPE, WORKLOAD_UNIT } from './enums';
import { Project } from './Project';

export class ProjectShort {

	fundingProject = false;
	private _flatRateSurcharge = 100;
	public get flatRateSurcharge (): number {
		switch (this.id) {
			case 'DEM':
			case 'D4UM':
				return 120;
			case 'MOT':
				return 85.07;
			default:
				return this._flatRateSurcharge;
		}
	}
	public set flatRateSurcharge (value: number) {
		if (value) {
			if (typeof(value) === 'string') {
				this._flatRateSurcharge = parseFloat(value);
			} else {
				this._flatRateSurcharge = value;
			}
		}
	}
	fundingNumber = '';
	fundingTitle = '';

	get avatar (): string {
		return this._avatar;
	}
	set avatar (value: string) {
		this._avatar = value;
	}

	get sum_invoices_netto (): number {
		return this.getValue('sum_invoices_netto');
	}

	get sum_invoices_nettoString (): string {
		const sum_invoices_netto = this.sum_invoices_netto;
		return sum_invoices_netto ? formatCurrency(sum_invoices_netto, 'de', '€') : '---';
	}

	get sum_budget_netto (): number {
		return this.getValue('sum_budget_netto');
	}

	get sum_budget_nettoString (): string {
		const sum_budget_netto = this.sum_budget_netto;
		return sum_budget_netto ? formatCurrency(sum_budget_netto, 'de', '€') : '---';
	}

	constructor (obj?: any) {
		if (obj != null) {
			for (const key of Object.keys(this)) {
				// // console.debug('key: ' + key + ' -> ' + JSON.stringify(obj[key]));
				this[key] = obj[key];
			}
		}
	}
	public type: PROJECT_TYPE = PROJECT_TYPE.SHORT;
	name: string = null;

	_avatar: string = null;

	id: string = null;
	parentId: string = null;
	parentProject: Project = null;

	preview_values: any = {};
	preview_liquidity: any = {};
	preview_workload: any = {};
	manually_planned: any = {};

	q1: number = null;
	q2: number = null;
	q3: number = null;
	q4: number = null;
	year: number = null;

	getValue (key: string) {
		return this.preview_values[key];
	}
	setValue (key: string, value: number) {
		this.preview_values[key] = value;
	}

	getLiquidity (key: string, otherOperatingIncome: boolean, incoming: boolean = false) {
		return this.preview_liquidity[key];
	}
	setLiquidity (key: string, value: number) {
		this.preview_liquidity[key] = value;
	}

	getWorkload (key: string, workload_unit: WORKLOAD_UNIT = WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR) {
		if (this.manually_planned[key] !== undefined) {
			return (1 * this.manually_planned[key]) / SECONDS_PER_MINUTE / workload_unit;
		}

		return this.preview_workload[key] / workload_unit;
	}
	setWorkload (key: string, value: number) {
		this.preview_workload[key] = value;
	}

	setManuallyPlanned (key: string, value: number) {
		this.manually_planned[key] = value;
	}

	getPartName (maxPartLength: number = 15): string {
		if (this.name.length < maxPartLength) {
			return this.name;
		} else {
			return this.name.substr(0, maxPartLength) + '...';
		}
	}

	setType (type: PROJECT_TYPE) {
		this.type = type;
	}

	toJSON () {
		const jsonObj: any = {};

		jsonObj.name = this.name;
		jsonObj.avatar = this.avatar;
		jsonObj.type = this.type;
		jsonObj.id = this.id;
		jsonObj.parentId = this.parentId;
		jsonObj.preview_values = this.preview_values;
		// jsonObj["preview_liquidity"] = this.preview_liquidity;
		jsonObj.manually_planned = this.manually_planned;
		jsonObj.fundingProject = this.fundingProject;
		jsonObj.flatRateSurcharge = this.flatRateSurcharge;
		jsonObj.fundingNumber = this.fundingNumber;
		jsonObj.fundingTitle = this.fundingTitle;

		return jsonObj;
	}

}
