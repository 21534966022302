import { createAction, props } from '@ngrx/store';
import { Capability } from '../model/Capability';

export enum CAPABILITY_ACTION {
	NOTHING = 'NOTHING',
	GET_CAPABILITIES = 'GET_CAPABILITIES',
	SET_CAPABILITIES = 'SET_CAPABILITIES',
	SAVE_CAPABILITIES = 'SAVE_CAPABILITIES',
}

export const GetCapabilities = createAction(
	'[GetCapabilities] Get Capabilities'
);

export const SetCapabilities = createAction(
	'[SetCapabilities] Set Capabilities',
	props<{ capabilities: Capability[] }>()
);

export const SaveCapabilities = createAction(
	'[SaveCapabilities] Save Capabilities',
	props<{ capabilities: Capability[] }>()
);

