import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { RESSOURCE_TABLE_ACTION } from 'src/app/actions/ressource-table.actions';
import { COLUMN_TYPE, REPORT_MODE } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { RessourceCube } from 'src/app/model/RessourceCube';
import { User } from 'src/app/model/User';
import { AppState } from 'src/app/reducers';

@Component({
	selector: 'app-ressources-table-content-row',
	templateUrl: './ressources-table-content-row.component.html',
	styleUrls: ['./ressources-table-content-row.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RessourcesTableContentRowComponent implements OnInit, OnDestroy {

	private _actProjectId: string;

	public baseProjectId: string;

	@Input() public updatedPlannedTimePosition: any;
	@Input() public reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS;

	@Input() public user: User;
	@Input() public column: string;
	@Input() public selectedColumnName: string;

	@Input() public get actProjectId (): string {
		return this._actProjectId;
	}
	public set actProjectId (value: string) {
		this._actProjectId = value;

		this.baseProjectId = value.split('___')[0] ?? value;
	}

	@Input() public columnsToDisplayInfo: [];
	@Input() public projectCollapsedMap: any;
	@Input() public isUserExpanded: boolean;

	@Input() public ressourceCube: RessourceCube;

	@Input() public selectedMonths: any[];

	@Input() updateInfoCellsEmitter: EventEmitter<any>;

	@Output() handleExpandRow = new EventEmitter<any>();
	@Output() handlePlannedNumberUpdate = new EventEmitter<any>();

	trackByFn = trackByFn;
	COLUMN_TYPE = COLUMN_TYPE;

	private reduxSubscription: Subscription = new Subscription();

	constructor (
		private store: Store<AppState>,
		public _ChangeDetectorRef: ChangeDetectorRef,
	) { }

	ngOnInit (): void {

		this.reduxSubscription.add(this.store.select((state: AppState) => state.ressourcesTable).subscribe((state) => {
			switch (state.action) {
				case RESSOURCE_TABLE_ACTION.USER_LOADING:
					this._ChangeDetectorRef.detectChanges();

					break;

				default:
					break;
			}

		}));

	}

	ngOnDestroy (): void {
		this.reduxSubscription.unsubscribe();
	}

	expandRow (user: User): void {
		console.log(user);
		this._ChangeDetectorRef.detectChanges();
		this.handleExpandRow.emit(user);
	}

	handleBlur ({accountId, projectId, monthDescription, cellRenderer}) {
		this._ChangeDetectorRef.detectChanges();
		this.handlePlannedNumberUpdate.emit({accountId, projectId, monthDescription, cellRenderer});
	}

}
