import { createAction, props } from '@ngrx/store';
import { JiraReportGenerator } from '../model/JiraReportGenerator';

export enum REPORT_GENERATOR_ACTION {
	NOTHING = 'NOTHING',
	REPORT_GENERATOR_WORKLOGS_REFRESHING = 'REPORT_GENERATOR_WORKLOGS_REFRESHING',
	REPORT_GENERATOR_WORKLOGS_REFRESHED = 'REPORT_GENERATOR_WORKLOGS_REFRESHED',
	GET_REPORT_GENERATOR_LIST = 'GET_REPORT_GENERATOR_LIST',
	GET_REPORT_GENERATOR_LIST_COMPLETE = 'GET_REPORT_GENERATOR_LIST_COMPLETE',
	SET_ACT_REPORT_GENERATOR = 'SET_ACT_REPORT_GENERATOR',
	PUT_REPORT_GENERATOR_LIST = 'PUT_REPORT_GENERATOR_LIST',
	PUT_REPORT_GENERATOR_LIST_COMPLETE = 'PUT_REPORT_GENERATOR_LIST_COMPLETE',
}

export const ReportGeneratorWorklogsRefreshing = createAction(
	'[ReportGeneratorWorklogsRefreshing] Report Generator Worklogs Refreshing'
);

export const ReportGeneratorWorklogsRefreshed = createAction(
	'[ReportGeneratorWorklogsRefreshed] Report Generator Worklogs Refreshed'
);

export const GetReportGeneratorList = createAction(
	'[GetReportGeneratorList] Get Report Generator List'
);

export const GetReportGeneratorListComplete = createAction(
	'[GetReportGeneratorListComplete] Get Report Generator List Complete',
	props<{ reports: JiraReportGenerator[] }>()
);

export const SetActReportGenerator = createAction(
	'[SetActReportGenerator] Set Act Report Generator',
	props<{ actReport: JiraReportGenerator}>()
);

export const PutReportGeneratorListComplete = createAction(
	'[PutReportGeneratorListComplete] Put Report Generator List Complete'
);

export const PutReportGeneratorList = createAction(
	'[PutReportGeneratorList] Put Report Generator List',
	props<{ reports: JiraReportGenerator[] }>()
);
